import { useStore } from 'app-engine/store'
import { ModalButtonsContainer, ModalConfirmButton } from 'app-view/components/Modal'
import { FlexBox } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard'
import { SelfieCheckIcon, SelfiePlaceHolder } from 'app-view/components/icons'
import { PhotoIdPlaceHolder } from 'app-view/components/icons/PhotoIdPlaceHolder'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { useSelfie } from 'app-view/hooks/use-selfie'
import styled from 'app-view/styles/styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseButton } from './components/CloseButton'
import { KYCReasonButton } from './components/KYCReasonBubtton'
import { CameraVideoBorder, PermissionText, PlainButton } from './components/Styles'

const ConfirmButton = styled(ModalConfirmButton)<{ wideActions?: boolean }>`
  max-width: ${(props) => (props.wideActions ? '230px' : '150px')}};
`

const stepIndicatorStyles: React.CSSProperties = {
  position: 'absolute',
  top: 10,
  right: 10,
  fontSize: 16,
  fontWeight: 900,
  color: '#fff',
  backgroundColor: '#1ED761',
  borderRadius: '50%',
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const Alert = ({ close, next, goTo }: WizardStepProps) => {
  const { t } = useTranslation(['account'])
  const [isGantedPermissions, setIsGantedPermissions] = useState(false)
  const { setError, selfie, idCard } = useSelfie()
  const [{ accountProfile }, {}] = useProfile()
  const authed = useStore.useAuthed()
  const [selfieWidth, setSelfieWidth] = useState(0)
  const [photoIDWidth, setPhotoIDWidth] = useState(0)

  const grantPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })

      if (stream) {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            () => {
              setIsGantedPermissions(true)
            },
            (geoError) => {
              console.log('Error getting geolocation: ' + geoError.message)
              if (geoError.message.indexOf('Permission') > -1) {
                setError(
                  'Geolocation permission denied. Please check your browser settings to grant permission.',
                )
              } else {
                setError('Error getting geolocation: ' + geoError.message)
              }
            },
          )
        } else {
          setError('Geolocation is not supported by your browser.')
        }
      } else {
        setError(
          'Camera permission denied. Please check your browser settings to grant permission.',
        )
      }
    } catch (error) {
      console.log('Error granting camera permission: ' + error.message)
      if (error.message.indexOf('Permission') > -1) {
        setError(
          'Camera permission denied. Please check your browser settings to grant permission.',
        )
      } else {
        setError('Error getting geolocation: ' + error.message)
      }
    }
  }

  const checkPermissions = async () => {
    try {
      const geolocationPermissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      })
      const cameraPermissionStatus = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      })

      if (
        geolocationPermissionStatus.state === 'granted' &&
        cameraPermissionStatus.state === 'granted'
      ) {
        setIsGantedPermissions(true)
      } else {
        setIsGantedPermissions(false)
      }
    } catch (error) {
      setIsGantedPermissions(false)
    }
  }

  useEffect(() => {
    const handleImageLoad = (imageId, setWidth) => {
      const imgElement = document.getElementById(imageId)
      if (imgElement) {
        setWidth(imgElement.offsetWidth)
      }
    }

    const setupImageLoadListener = (imageId, setWidth) => {
      const imgElement = document.getElementById(imageId)
      if (imgElement) {
        handleImageLoad(imageId, setWidth)
        imgElement.addEventListener('load', () => handleImageLoad(imageId, setWidth))
      }
    }

    setupImageLoadListener('image', setSelfieWidth)
    setupImageLoadListener('photo_id', setPhotoIDWidth)

    return () => {
      const imgElement1 = document.getElementById('image')
      if (imgElement1) {
        imgElement1.removeEventListener('load', () => handleImageLoad('image', setSelfieWidth))
      }

      const imgElement2 = document.getElementById('photo_id')
      if (imgElement2) {
        imgElement2.removeEventListener('load', () => handleImageLoad('photo_id', setPhotoIDWidth))
      }
    }
  }, [accountProfile, selfie, idCard])
  useEffect(() => {
    checkPermissions()
  }, [])

  return (
    <FlexBox flexDirection="column">
      <FlexBox
        style={{
          borderRadius: '10px',
          justifyContent: 'space-between',
          width: '93%',
          maxWidth: 400,
        }}
      >
        <div style={{ position: 'relative', width: '50%', display: 'flex', alignItems: 'center' }}>
          {!accountProfile?.selfie && !selfie && <span style={stepIndicatorStyles}>1</span>}
          {accountProfile?.selfie || selfie ? (
            <img
              id="image"
              src={accountProfile?.selfie || selfie}
              alt="user selfie"
              style={{
                height: `${selfieWidth}px`,
                width: '100%',
                borderRadius: '10px',
                objectFit: 'cover',
                maxHeight: '200px',
                objectPosition: 'center',
                maxWidth: '200px',
              }}
            />
          ) : (
            <SelfiePlaceHolder style={{ height: 'auto', width: '100%' }} />
          )}

          <CameraVideoBorder selfieStatus={accountProfile?.selfie || selfie ? true : null} />
          {accountProfile?.selfie || selfie ? (
            <SelfieCheckIcon style={{ position: 'absolute', top: '10px', right: '10px' }} />
          ) : (
            ''
          )}
        </div>
        <div style={{ position: 'relative', width: '50%', display: 'flex', alignItems: 'center' }}>
          {!accountProfile?.photo_id && !idCard && (
            <span style={stepIndicatorStyles}>{accountProfile?.selfie || selfie ? 1 : 2}</span>
          )}
          {accountProfile?.photo_id || idCard ? (
            <img
              src={accountProfile?.photo_id || idCard}
              id="photo_id"
              alt="user id card photo"
              style={{
                height: `${photoIDWidth}px`,
                width: '100%',
                borderRadius: '10px',
                objectFit: 'cover',
                maxHeight: '200px',
                maxWidth: '200px',
                objectPosition: 'center',
              }}
            />
          ) : (
            <PhotoIdPlaceHolder style={{ height: 'auto', width: '100%' }} />
          )}
          <CameraVideoBorder selfieStatus={accountProfile?.photo_id || idCard ? true : null} />
          {accountProfile?.photo_id || idCard ? (
            <SelfieCheckIcon style={{ position: 'absolute', top: '10px', right: '10px' }} />
          ) : (
            ''
          )}
        </div>
      </FlexBox>
      <p style={{ width: '100%', maxWidth: 360, margin: '6px auto' }}>
        {/* <b>{authed ? t('selfie_notification_regular') : t('selfie_notification_investor')}</b> */}
        <b>
          {t('selfie_notification_regular')} {t('selfie_notification_investor')}
        </b>
      </p>
      <FlexBox
        style={{
          marginBlock: '16px',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: 360,
          marginInline: 'auto',
          paddingInline: 10,
          fontWeight: 600,
        }}
      >
        <span
          dangerouslySetInnerHTML={{ __html: t('selfie_notifications_1') }}
          style={{ width: 'calc(50% - 5px)' }}
        />
        <span
          dangerouslySetInnerHTML={{ __html: t('selfie_notifications_2') }}
          style={{ width: 'calc(50% - 5px)' }}
        />
      </FlexBox>
      {!isGantedPermissions ? (
        <PermissionText>
          {t('require_permission')}
          <PlainButton
            onClick={() => {
              grantPermission()
            }}
          >
            {t('grant_permission')}.
          </PlainButton>
          <KYCReasonButton />
        </PermissionText>
      ) : (
        ''
      )}
      <ModalButtonsContainer
        style={{
          flexDirection: 'column-reverse',
          flexWrap: 'wrap',
          marginBottom: 0,
        }}
      >
        <ConfirmButton
          disabled={!isGantedPermissions}
          wideActions
          onClick={() => {
            if (accountProfile?.selfie) {
              goTo('take_photo_id')
            } else next()
          }}
        >
          {t('continue_verification')}
        </ConfirmButton>
      </ModalButtonsContainer>
      <CloseButton
        onClick={() => {
          close()
        }}
      />
    </FlexBox>
  )
}
