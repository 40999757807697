import * as env from 'env-var'

const getEnvKey = (key: string, bool?: boolean) =>
  bool ? env.get(key).required().asBool() : env.get(key).required().asString()
export interface BitcashContracts {
  bitcashBank: string
  bitcashAccounts: string
  bitcashToken: string
  bitcashHedger: string
  bitcashMockedTokens: string
}

export interface BitcashServices {
  chainRpc: string
  bitcashGraphQL: string
  chaingraphGraphQL: string
  phoneLookup: {
    host: string
    apiKey: string
  }
}

export interface BitcashFeatures {
  usdtDepositOnly: boolean
  daoRecoveryPartner: string
  qr: boolean
}

export interface AppConfig {
  appUrl: string
  environment: string
  bitcashAuth: string
  eosChainId: string
  eosChainName: string
  chaingraphKey: string
  bitcashAdmin: string
  bitcashSupervisors: string[]
  onramperKey: string
  sentryDsn: string | null
  contracts: BitcashContracts
  services: BitcashServices
  features: BitcashFeatures
  wssUrl: string
  reactiveSessionWS: string
  maintenanceMode: boolean
  desktopAcess: boolean
  apolloURL: string
}

const contracts: BitcashContracts = {
  bitcashBank: getEnvKey('REACT_APP_BITCASH_BANK_CONTRACT') as string,
  bitcashAccounts: getEnvKey('REACT_APP_BITCASH_ACCOUNTS_CONTRACT') as string,
  bitcashToken: getEnvKey('REACT_APP_BITCASH_TOKEN_CONTRACT') as string,
  bitcashHedger: getEnvKey('REACT_APP_BITCASH_HEDGER_CONTRACT') as string,
  bitcashMockedTokens: getEnvKey('REACT_APP_BITCASH_MOCKED_CONTRACT') as string,
}

const services: BitcashServices = {
  chainRpc: getEnvKey('REACT_APP_EOSIO_API_HOST') as string,
  bitcashGraphQL: getEnvKey('REACT_APP_BITCASH_GRAPHQL_HOST') as string,
  chaingraphGraphQL: getEnvKey('REACT_APP_CHAINGRAPH_GRAPHQL_HOST') as string,
  phoneLookup: {
    host: getEnvKey('REACT_APP_PHONE_LOOKUP_HOST') as string,
    apiKey: getEnvKey('REACT_APP_PHONE_LOOKUP_API_KEY') as string,
  },
}

const sentryDsn = new Map([
  ['app.bitcash.org', 'https://6b95a279ea2248e5950663f661cfe3a6@o792504.ingest.sentry.io/5801051'],
  ['test.bitcash.org', 'https://5d5ca84f7cb54b5b8cc5d7d2c98c0f2d@o792504.ingest.sentry.io/6184405'],
  ['prev.bitcash.org', 'https://5d5ca84f7cb54b5b8cc5d7d2c98c0f2d@o792504.ingest.sentry.io/6184405'],
])

const features = {
  usdtDepositOnly: getEnvKey('REACT_APP_BITCASH_FEATURE_USDT_DEPOSIT_ONLY') === 'true',
  daoRecoveryPartner: getEnvKey('REACT_APP_DAO_RECOVERY_PARTNER') as string,
  qr: true,
}

export const config: AppConfig = {
  environment: getEnvKey('REACT_APP_ENVIRONMENT') as string,
  appUrl: getEnvKey('REACT_APP_URL') as string,
  bitcashAuth: getEnvKey('REACT_APP_BITCASH_AUTH') as string,
  eosChainId: getEnvKey('REACT_APP_CHAIN_ID') as string,
  eosChainName: getEnvKey('REACT_APP_CHAIN_NAME') as string,
  chaingraphKey: getEnvKey('REACT_APP_CHAINGRAPH_API_KEY') as string,
  bitcashAdmin: getEnvKey('REACT_APP_BITCASH_ADMIN_ACCOUNT') as string,
  reactiveSessionWS: getEnvKey('REACT_APP_REACTIVE_SESSION_WS') as string,
  maintenanceMode: getEnvKey('REACT_APP_MAINTENANCE_MODE', true) as boolean,
  desktopAcess: getEnvKey('REACT_APP_DESKTOP_ACCESS', true) as boolean,
  bitcashSupervisors: getEnvKey('REACT_APP_BITCASH_SUPERVISOR_ACCOUNTS').toString().split(','),
  onramperKey: getEnvKey('REACT_APP_ONRAMPER_KEY') as string,
  sentryDsn: sentryDsn.get(location.host) || null,
  contracts,
  services,
  features,
  wssUrl: (getEnvKey('REACT_APP_WSS_URL') as string) || 'ws://',
  apolloURL: getEnvKey('REACT_APP_APOLLO_URL') as string,
}

// Function to get a query parameter value by name
function getQueryParam(name: string) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}
