import theme from 'app-config/theme'
import { Label } from 'app-view/components/InputField'
import { Loader } from 'app-view/components/Spinner'
import { FlexBox, ScrollableFlexBoxArea } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard/types'
import { GreenButton, RedButton } from 'app-view/components/buttons'
import { CheckingIcon, TimesIcon } from 'app-view/components/icons'
import styled from 'app-view/styles/styled'
import { CountryIdTypes } from 'pages/AccountView/routes/shared/AccountFormFields'
import { useAdminActions } from 'pages/AdminView/hooks/useAdminActions'
import { CheckboxInput } from 'pages/P2PView/steps/open-offer-filter/Filter'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ImageThumbnail = styled.img`
  margin-top: 5%;
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: center;
  max-width: 325px;
  max-height: 325px;
  border: 1px solid #757575;
  border-radius: 24px;
`

const rowInfoItemStyles = {
  width: '100%',
  borderBottom: '1px solid #F2F2F2',
  paddingBlock: 6,
  justifyContent: 'space-between',
}

export const AccountInfo = ({ goTo }: WizardStepProps) => {
  const [isVerified, setIsVerified] = React.useState(false)
  const { t } = useTranslation(['account'])
  const [
    { account, loading },
    { cancelAccountRequest, approveAccountRequest, retryAccountKYCRequest },
  ] = useAdminActions()
  const [selfieWidth, setSelfieWidth] = useState(0)
  const [photoIDWidth, setPhotoIDWidth] = useState(0)

  useEffect(() => {
    if (account?.is_verified) {
      setIsVerified(true)
    }
  }, [account])

  useEffect(() => {
    const handleImageLoad = (imageId, setWidth) => {
      const imgElement = document.getElementById(imageId)
      if (imgElement) {
        setWidth(imgElement.offsetWidth)
      }
    }

    const setupImageLoadListener = (imageId, setWidth) => {
      const imgElement = document.getElementById(imageId)
      if (imgElement) {
        handleImageLoad(imageId, setWidth)
        imgElement.addEventListener('load', () => handleImageLoad(imageId, setWidth))
      }
    }

    setupImageLoadListener('image', setSelfieWidth)
    setupImageLoadListener('photo_id', setPhotoIDWidth)

    return () => {
      const imgElement1 = document.getElementById('image')
      if (imgElement1) {
        imgElement1.removeEventListener('load', () => handleImageLoad('image', setSelfieWidth))
      }

      const imgElement2 = document.getElementById('photo_id')
      if (imgElement2) {
        imgElement2.removeEventListener('load', () => handleImageLoad('photo_id', setPhotoIDWidth))
      }
    }
  }, [account])

  const rejectAccount = async () => {
    const { success } = await cancelAccountRequest()
    if (success) goTo('success')
  }

  const approveAccount = async () => {
    const { success } = await approveAccountRequest(isVerified)
    if (success) goTo('success')
  }

  const retryKYC = async () => {
    const { success } = await retryAccountKYCRequest()
    if (success) goTo('success')
  }

  if (!account) return <Loader />
  // ? When users doesn't have information, we immediately reject it
  else if (
    !account.profile?.full_name &&
    !account.profile?.phone &&
    !account.profile?.email &&
    !account.profile?.country_id &&
    !account.profile?.selfie &&
    !account.profile?.photo_id
  ) {
    goTo('no_account_info')
  }
  if (loading) return <Loader text={t('loading_action')} />

  const isMissingInfo =
    !account.profile?.full_name ||
    !account.profile?.phone ||
    !account.profile?.email ||
    !account.profile?.country_id ||
    !account.profile?.selfie ||
    !account.profile?.photo_id

  return (
    <>
      <ScrollableFlexBoxArea
        style={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          maxHeight: '45%',
          paddingBlock: 24,
        }}
      >
        <Label htmlFor="is_verified" style={{ fontWeight: 700, verticalAlign: 'middle' }}>
          <CheckboxInput
            id="is_verified"
            onChange={() => {
              setIsVerified(!isVerified)
            }}
            type="checkbox"
            style={{ height: 20, width: 20, marginRight: 10 }}
            checked={isVerified}
          />
          {t('user_verified')}
        </Label>
        <FlexBox
          style={{ width: '100%', padding: '0 24px' }}
          justifyContent="center"
          flexDirection="column"
        >
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('p2p:full_name_label')}:</b>
            <span>{account.profile?.full_name}</span>
          </FlexBox>
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('phone_number_label')}</b>
            <span>{account.profile?.phone}</span>
          </FlexBox>
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('email_address_label')}</b>
            <span>{account.profile?.email}</span>
          </FlexBox>
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('settings:country')}:</b>
            <span>
              {account.profile?.country_id
                ? account.profile.country_id?.country ?? ''
                : 'not registered'}
            </span>
          </FlexBox>
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('settings:register_ip')}:</b>
            <span>
              {account.profile?.registration_ip
                ? account.profile?.registration_ip
                : 'not registered'}
            </span>
          </FlexBox>
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('settings:date_of_birth')}:</b>
            <span>
              {account.profile?.date_of_birth &&
              account.profile?.date_of_birth !== '1500-01-01T00:00:00+00:00'
                ? formatDate(account.profile?.date_of_birth)
                : 'not registered'}
            </span>
          </FlexBox>
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('country_id_label')}:</b>
            <span>
              {account.profile?.country_id
                ? `${
                    CountryIdTypes.filter((id) => id.value === account.profile.country_id.type)[0]
                      .label
                  }, ${account.profile.country_id?.id}`
                : 'not registered'}
            </span>
          </FlexBox>
          <FlexBox style={rowInfoItemStyles}>
            <b>{t('created_at')}:</b>
            <span>{formatDate(account.created_at)}</span>
          </FlexBox>
        </FlexBox>
      </ScrollableFlexBoxArea>
      <ScrollableFlexBoxArea
        style={{ width: '100%', flexDirection: 'column', alignItems: 'center', maxHeight: '55%' }}
      >
        <ImageThumbnail
          style={{
            height: `${selfieWidth}px`,
          }}
          id="image"
          src={
            account.profile?.selfie
              ? account.profile?.selfie
              : 'https://via.placeholder.com/325?text=No%20Selfie%20Found'
          }
          alt={account.account}
        />
        <ImageThumbnail
          id="photo_id"
          style={{
            height: `${photoIDWidth}px`,
          }}
          src={
            account.profile?.photo_id
              ? account.profile?.photo_id
              : 'https://via.placeholder.com/325?text=No%20PhotoID%20Found'
          }
          alt={account.account}
        />
      </ScrollableFlexBoxArea>
      <FlexBox
        style={{ width: '100%', marginTop: '10px', padding: '24px' }}
        justifyContent="center"
      >
        {!Boolean(account?.created) && (
          <RedButton onClick={rejectAccount}>
            {t('p2p:reject')}{' '}
            <TimesIcon
              style={{
                height: '14.15px',
                width: '14.15px',
                stroke: theme.colors.white,
                marginLeft: '8px',
              }}
              color={theme.colors.white}
            />
          </RedButton>
        )}
        {isMissingInfo && (
          <RedButton onClick={retryKYC}>
            {'Retry KYC '}
            <TimesIcon
              style={{
                height: '14.15px',
                width: '14.15px',
                stroke: theme.colors.white,
                marginLeft: '8px',
              }}
              color={theme.colors.white}
            />
          </RedButton>
        )}
        <GreenButton disabled={!isVerified} onClick={approveAccount}>
          {t('approve')}{' '}
          <CheckingIcon
            style={{
              height: '21.44px',
              width: '21.44px',
              marginLeft: '8px',
            }}
          />
        </GreenButton>
      </FlexBox>
    </>
  )
}

const formatDate = (dateString) => {
  const options = {
    year: 'numeric' as 'numeric' | '2-digit',
    month: 'long' as 'numeric' | '2-digit' | 'long' | 'short' | 'narrow',
    day: 'numeric' as 'numeric' | '2-digit',
    hour: 'numeric' as 'numeric' | '2-digit',
    minute: 'numeric' as 'numeric' | '2-digit',
    hour12: true,
    timeZoneName: 'short' as
      | 'long'
      | 'short'
      | 'shortOffset'
      | 'longOffset'
      | 'shortGeneric'
      | 'longGeneric',
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString))

  return formattedDate
}
