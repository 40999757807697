export default {
  total: 'Total Available',
  total_stable: 'Available bitcash',
  buy: 'Buy',
  swap: 'Swap',
  sell: 'Sell',
  all: 'All',
  p2p: 'P2P',
  share_link: 'Share Link Generator',
  open: 'Open',
  cancelled: 'Cancelled',
  filled: 'Filled',
  cancelled_on: 'Cancelled on',
  filled_on: 'Filled on',
  support_channel: 'Support chat',
  support: 'Support',
  available: 'Available bitUSD',
  error: 'An error has ocurred.',
  loading: 'Loading your data',
  ok: 'OK',
  back: 'Back',
  no_messages: 'No messages, yet',
  cancel: 'Cancel',
  received: 'Received',
  generic_error: 'Sorry, there was a glitch. Please try again.',
  appStatePausedMsn: 'Sorry, trading is currently paused for maintenance.',
  push_transaction_error: `We're sorry!
  We couldn't {{action}} offer,
  please try again in a few seconds.
  `,
  push_transaction_error_action_process: 'process your',
  non_auth_user: `The logged user has not
  been approved yet. Connect to
  our Discord channel:`,
  remind_me_later: 'Remind Me Later',
  add_trust: 'Add to Network',
  remove_trust: 'Remove from Network',
  trust_request_sent: 'Pending',
  added_trust: 'Added to your trust network',
  add_second_device: 'Remember to add a Second Device to back up your account to avoid data loss.',
  navigation_trade: 'Trade',
  navigation_wallet: 'Wallet',
  navigation_history: 'History',
  navigation_p2p: 'P2P',
  navigation_swap: 'Swap',
  navigation_bank: 'Bank',
  order_type: 'Order Type:',
  last_update: 'Last Update:',
  order_amount: 'Coin Amount:',
  net_amount: 'Amount to Receive:',
  order_cost: 'Order Cost:',
  minus_network: '(minus network fees)',
  confirm: 'Confirm',
  continue_question: `Do you want to continue?
    
  `,
  no_data: 'No Data',
  take_me_there: 'Take me there',
  required_action: 'Required Action',
  data_warning_message:
    'To qualify for rewards and to enable account recovery, please update your information.',

  login_request: 'LOGIN REQUEST',
  login_request_description: 'Do you want to log in to <0>{{appName}}</0>?',
  signature_request: 'SIGNATURE REQUEST',
  dboard_voting_transaction: `Please confirm you would like to
  <0>vote {{option}}</0> on <1>dBoard</1>.`,
  evm_transfer_transaction: `Would you like to transfer <0>{{amount}}</0>
  to your <1>{{account}}</1> from <2>{{appName}}</2>?`,
  pomelo_grant_donation: `Please confirm you would like to
  <0> donate to {{grant}}</0> on <1>Pomelo</1>.`,
  overview: 'Overview',
  details: 'Details',
  transaction: 'Transaction',
  data: 'Data',
  raw_data: 'Raw Data',
  qr_reader: 'QR READER',
  sign_success: 'SIGN SUCCESSFUL',
  loading_signature: 'LOADING REQUEST',
}
