import { captureException } from '@sentry/react'
import {
  useInsertAccountInformationMutation,
  useUpdateAccountInfoMutation,
} from 'app-engine/graphql/generated/bitcash'
import { storeIP, uploadImage } from 'app-engine/services'
import { useStore } from 'app-engine/store'
import { AccountVerificationStatusTag } from 'app-view/components/AccountVerificationStatusTag'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError, ModalSuccess } from 'app-view/components/Modal'
import { Wizard, WizardProvider, useWizard } from 'app-view/components/Wizard'
import { BlackButton, ButtonTransparent, GreenButton } from 'app-view/components/buttons'
import { DeleteIcon, EditIcon, PlusIcon } from 'app-view/components/icons'
import { SettingsContainer as MainContainer } from 'app-view/components/settings'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { useLoggedUser } from 'app-view/hooks/use-realtime/use-logged-user'
import { SelfieProvider, useSelfie } from 'app-view/hooks/use-selfie'
import { useKYCForm } from 'app-view/hooks/useKYCForm'
import styled from 'app-view/styles/styled'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import { groupBy } from 'lodash'
import { dataURItoArrayBuffer } from 'pages/AccountView/routes/shared/step-for-upload-selfie/take-image/take-image-utils'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { NavLink } from 'react-router-dom'
import {
  BankTransferDetails,
  PhoneTransferDetails,
  deleteProfilePaymentMethod,
  getCountryMethodsByCountry,
  getParsedPaymentMethods,
} from '../P2PView/hooks/useP2POffer'
import { AddPaymentMethodSteps, EditPaymentMethodSteps } from './AddMethod'
import { ProfileFormFields } from './ProfileFormFields'
import { Label } from './Shared'

const TopSettingsNavigation = styled.div`
  display: flex;
  padding: 17px 29px;
  align-items: center;
  p {
    margin: 0px;
  }
  a {
    padding-top: 5px;
  }
`

const BodyContainer = styled.div`
  background-color: #fff;
`

const Row = styled.div`
  padding: 9px 23px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-inline: 25px;
  padding-block: 10px;
`

const Section: React.FC<React.PropsWithChildren> = ({ children }) => <Row>{children}</Row>

const Header = styled(Label)`
  justify-content: center;
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
`

const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  margin-bottom: 18px;
`

const SubHeader = styled(Label)`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:last-of-type span {
    max-width: 33.33%;
  }
`

const ColumnsWrapper = styled.ul`
  width: 100%;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 12px;
  margin-block-end: 12px;
`

const MethodDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`

const ColumnTwo = styled.li`
  width: 100%;
  display: grid;
  align-items: center;
  gap: 12px;
  grid-template-columns: 24px 0.7fr 1fr 18px;
`

const ProfileMethodWrapper = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
`

const animationPaymentOptions = {
  animate: {
    clipPath: 'inset(0% 0% 0% 0%)',
    height: 'auto',
  },
  initial: {
    clipPath: 'inset(0% 0% 100% 0%)',
    height: 0,
  },
  exit: {
    clipPath: 'inset(0% 0% 100% 0%)',
    height: 0,
  },
  transition: {
    duration: 0.42,
    delayChildren: 0.24,
    staggerChildren: 0.24,
  },
}

const AnimationWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
`

type BankMethodProps = {
  details: BankTransferDetails
  method: string
  onDelete: () => void
  onEdit: () => void
}

const BankMethodDetails = ({ details, method, onDelete }: BankMethodProps) => {
  const [, globalModalActions] = useGlobalModal()
  const setCurrentMethod = useStore.useSetCurrentPaymentMethod()
  const [, { start: initWizard }] = useWizard()
  const { t } = useTranslation(['profile'])
  const account = useStore.useAccount()
  const deleteMethod = () => {
    globalModalActions.openConfirm({
      showClose: true,
      isDestructive: true,
      confirmText: t('delete'),
      text: t('delete_payment_option_warning'),
      onConfirm: () => {
        deleteProfilePaymentMethod(account, details)
        onDelete()
      },
    })
  }

  const editMethod = () => {
    setCurrentMethod(method)
    initWizard(EditPaymentMethodSteps)
  }
  return (
    <MethodDetailsContainer>
      <ColumnsWrapper>
        <ColumnTwo>
          {/* // TODO: Fix bug with targeting multiple payment methods. Disabling buttons */}
          <ButtonTransparent style={{ scale: '1.5' }} onClick={editMethod} disabled>
            <EditIcon />
          </ButtonTransparent>
          <div> {details.accountName}</div>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {details.accountNumber}
          </div>
          <ButtonTransparent onClick={deleteMethod} style={{ scale: '2' }}>
            <DeleteIcon />
          </ButtonTransparent>
        </ColumnTwo>
      </ColumnsWrapper>
    </MethodDetailsContainer>
  )
}

type PhoneMethodProps = {
  details: PhoneTransferDetails
  method: string
  onDelete: () => void
  onEdit: () => void
}

const PhoneMethodDetails = ({ details, method, onDelete }: PhoneMethodProps) => {
  const [, globalModalActions] = useGlobalModal()
  const setCurrentMethod = useStore.useSetCurrentPaymentMethod()
  const { t } = useTranslation(['profile'])
  const [, { start: initWizard }] = useWizard()
  const account = useStore.useAccount()
  const deleteMethod = () => {
    globalModalActions.openConfirm({
      showClose: true,
      isDestructive: true,
      confirmText: t('delete'),
      text: t('delete_payment_option_warning'),
      onConfirm: () => {
        deleteProfilePaymentMethod(account, details)
        onDelete()
      },
    })
  }
  const editMethod = () => {
    setCurrentMethod(method)
    initWizard(EditPaymentMethodSteps)
  }
  return details ? (
    <MethodDetailsContainer>
      <ColumnsWrapper>
        <ColumnTwo>
          {/* // TODO: Fix bug with targeting multiple payment methods. Disabling buttons */}
          <ButtonTransparent style={{ scale: '1.5' }} onClick={editMethod} disabled>
            <EditIcon />
          </ButtonTransparent>
          <div>{details.fullName ? details.fullName : ''}</div>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}> {details.phoneNumber}</div>
          <ButtonTransparent style={{ scale: '2' }} onClick={deleteMethod}>
            <DeleteIcon />
          </ButtonTransparent>
        </ColumnTwo>
      </ColumnsWrapper>
    </MethodDetailsContainer>
  ) : (
    <></>
  )
}

const AvailableMethods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`

const ChevronWrapper = styled(motion.span)`
  cursor: pointer;
  transform-origin: center center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SaveButton = styled(GreenButton)`
  min-width: 134px;
`

const RevertButton = styled(BlackButton)`
  min-width: 134px;
`

const ProfileViewComponent = () => {
  const { t } = useTranslation(['profile', 'p2p'])
  return (
    <MainContainer>
      <TopSettingsNavigation>
        <NavLink to="/settings">
          <AiOutlineLeft />
        </NavLink>
        <Header>{t('profile:heading')}</Header>
      </TopSettingsNavigation>
      <BodyContainer>
        {/* @ts-expect-error */}
        <WizardProvider>
          <ProfilePaymentMethodComponent />
          <Wizard error="" title="" resetError={() => {}} />
        </WizardProvider>
        <SelfieProvider>
          {/* @ts-expect-error */}
          <WizardProvider>
            <ProfileFormComponent />
          </WizardProvider>
        </SelfieProvider>
      </BodyContainer>
    </MainContainer>
  )
}

const ProfileFormComponent = () => {
  const { t } = useTranslation(['profile', 'p2p'])
  const [, globalModalActions] = useGlobalModal()
  const [loading, setLoading] = useState(false)
  const [isChangeableSelfie, setIsChangeableSelfie] = useState(false)
  const [isChangeablePhotoId, setIsChangeablePhotoId] = useState(false)
  const account = useStore.useAccount()

  const [{ accountProfile }, { refreshProfile }] = useProfile()

  const [updateAccountInfoMutation, { loading: updatingAccountInfo }] =
    useUpdateAccountInfoMutation()
  const [insertAccountInformationMutation, { loading: insertingAccountInfo }] =
    useInsertAccountInformationMutation()
  const {
    selfie: preSelfieUpdate,
    setSelfie: setPreSelfie,
    setDownloadData,
    idCard,
    setIdCard,
    setDownloadPhotoIDData,
  } = useSelfie()

  const fullName = (accountProfile?.full_name || '').split('_').join(', ')
  const firstName = fullName.split(', ')[0] || ''
  const middleName = fullName.split(', ')[1] || ''
  const surName = fullName.split(', ')[2] || ''
  const dateOfBirth = accountProfile?.date_of_birth || ''
  const phoneNumber = accountProfile?.phone || ''
  const emailAddress = accountProfile?.email || ''
  const preSelfie = accountProfile?.selfie || null
  const prePhotoId = accountProfile?.photo_id || null
  const newsletterSubscription = accountProfile?.newsletter_subscription || true
  const preCountryId = accountProfile?.country_id?.type ? accountProfile?.country_id?.id : ''
  const preIdType = accountProfile?.country_id?.type || 'national_id'
  const preCountry = accountProfile?.country_id?.type ? accountProfile?.country_id?.country : ''

  const { setValue, setError, formState, watch, handleSubmit, register } = useForm({
    defaultValues: {
      surName,
      firstName,
      middleName,
      dateOfBirth,
      phoneNumber,
      emailAddress,
      newsletterSubscription,
      selfie: preSelfie,
      countryId: preCountryId,
      idType: preIdType,
      country: preCountry,
      photoId: prePhotoId,
    },
  })

  const submitAccountKYC = handleSubmit(
    async ({
      phoneNumber,
      emailAddress,
      surName,
      firstName,
      middleName,
      dateOfBirth,
      newsletterSubscription,
      selfie,
      countryId,
      idType,
      country,
      photoId,
    }) => {
      const full_name = `${firstName}_${middleName}_${surName}`.replace(/\s+/g, '')
      try {
        setLoading(true)

        if (
          (!Boolean(accountProfile?.selfie) && (selfie || preSelfieUpdate)) ||
          (Boolean(accountProfile?.selfie) && !Boolean(accountProfile?.photo_id))
        ) {
          await uploadImage(selfie || preSelfieUpdate || accountProfile?.selfie, account, true)
        }

        if (!Boolean(accountProfile?.photo_id) && (photoId || idCard)) {
          await uploadImage(photoId || idCard, account, false)
        }

        // If no profile exists, insert new profile
        if (!accountProfile?.id) {
          await insertAccountInformationMutation({
            variables: {
              input: {
                account,
                country_id: {
                  id: countryId,
                  type: idType,
                  country,
                },
                phone: phoneNumber,
                email: emailAddress,
                full_name,
                date_of_birth: dateOfBirth,
                newsletter_subscription: newsletterSubscription,
                selfie: preSelfieUpdate || selfie,
                photo_id: idCard || photoId,
              },
            },
          })
        } else {
          await updateAccountInfoMutation({
            variables: {
              pk_columns: { account, id: accountProfile?.id },
              _set: {
                phone: phoneNumber,
                email: emailAddress,
                full_name,
                date_of_birth: dateOfBirth,
                newsletter_subscription: newsletterSubscription,
                ...(accountProfile?.selfie ? {} : { selfie: preSelfieUpdate || selfie }),
                ...(accountProfile?.photo_id ? {} : { photo_id: idCard || photoId }),
                ...(accountProfile?.country_id?.type
                  ? {}
                  : {
                      country_id: {
                        id: countryId,
                        type: idType,
                        country,
                      },
                    }),
              },
            },
          })
        }

        if (!accountProfile?.registration_ip) {
          await storeIP(account)
        }

        globalModalActions.open({
          content: () => <ModalSuccess text={t('profile:profile_update_success')} />,
          iconType: 'LOADER',
          showClose: true,
          autoHide: true,
          title: t('profile:profile_information'),
        })
        return
      } catch (error) {
        console.log('Updating User Info::Error::', error)
        captureException(error, {
          tags: {
            account,
          },
          extra: {
            phoneNumber,
            emailAddress,
            surName,
            firstName,
            middleName,
            dateOfBirth,
            newsletterSubscription,
            selfie: Boolean(selfie),
            countryId,
            idType,
            country,
            photoId: Boolean(photoId),
          },
        })
        globalModalActions.open({
          content: () => <ModalError error={t('account:processing_error')} />,
          iconType: 'ERROR',
          showClose: true,
          title: t('p2p:unexpected_error'),
        })
      } finally {
        refreshProfile()
        setLoading(false)
      }
    },
  )

  const {
    handleCountryIdChange,
    setIdType,
    setCountry,
    formatUserPhoneNumber,
    verifyEmailAddress,
    setSurNameValue,
    setFirstNameValue,
    setMiddleNameValue,
    setDateOfBirthValue,
    idType,
    country,
    countryIdValue,
    surNameValue,
    firstNameValue,
    middleNameValue,
    dateOfBirthValue,
    phoneValue,
    emailValue,
    newsletterSubscriptionValue,
    phoneNumberCountryCode,
    formattedValue,
    validPhoneNumberState,
    shouldOmitSubmit,
    checkCountryIdState,
    selfie,
    setSelfie,
    isChangeableID,
    setIsChangeableID,
    checkEmailState,
    photoId,
    setPhotoId,
  } = useKYCForm({
    submitAccountKYC,
    setValue,
    setError,
    formState,
    watch,
    initialIsChangeableID: false,
  })

  const onRevert = () => {
    setValue('phoneNumber', phoneNumber)
    setValue('newsletterSubscription', newsletterSubscription)
    setValue('emailAddress', emailAddress)
    setValue('surName', surName)
    setValue('firstName', firstName)
    setValue('middleName', middleName)
    setValue('dateOfBirth', dateOfBirth)
    setSelfie(preSelfie)
    setCountry(preCountry)
    setPreSelfie(preSelfie)
    setPhotoId(prePhotoId)
    setIdCard(prePhotoId)
    if (preSelfie) {
      const updatedImageSrc = URL.createObjectURL(
        new Blob([dataURItoArrayBuffer(preSelfie)], { type: 'image/jpeg' }),
      )
      setDownloadData(updatedImageSrc)
    } else {
      setDownloadData('')
    }
    if (prePhotoId) {
      const updatedImageSrc = URL.createObjectURL(
        new Blob([dataURItoArrayBuffer(prePhotoId)], { type: 'image/jpeg' }),
      )
      setDownloadPhotoIDData(updatedImageSrc)
    } else {
      setDownloadPhotoIDData('')
    }
    setIdType(preIdType)
    setValue('countryId', preCountryId)
    setError('phoneNumber', {
      message: '',
    })
    setError('emailAddress', {
      message: '',
    })
  }

  useEffect(() => {
    onRevert()
    if (accountProfile?.country_id?.type) {
      setIsChangeableID(false)
    } else {
      setIsChangeableID(true)
    }

    setIsChangeableSelfie(!accountProfile?.selfie)
    setIsChangeablePhotoId(!accountProfile?.photo_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountProfile?.email,
    accountProfile?.newsletter_subscription,
    accountProfile?.phone,
    accountProfile?.selfie,
    accountProfile?.photo_id,
    accountProfile?.country_id,
  ])

  const isRevertDisabled =
    (surName === surNameValue &&
      firstName === firstNameValue &&
      middleName === middleNameValue &&
      phoneNumber === phoneValue &&
      emailAddress === emailValue &&
      dateOfBirth === dateOfBirthValue &&
      newsletterSubscriptionValue === newsletterSubscription &&
      countryIdValue === preCountryId &&
      preSelfie === selfie &&
      prePhotoId === photoId) ||
    loading
  const isSaveDisabled =
    (isRevertDisabled && (updatingAccountInfo || insertingAccountInfo || shouldOmitSubmit)) ||
    loading ||
    !preSelfieUpdate ||
    !idCard ||
    !countryIdValue ||
    !phoneValue ||
    !surNameValue ||
    !firstNameValue ||
    !dateOfBirthValue ||
    !emailValue
  const isMissingInfo =
    (!accountProfile?.photo_id ||
      !accountProfile?.selfie ||
      !accountProfile?.full_name ||
      !accountProfile?.phone ||
      !accountProfile?.email ||
      !accountProfile?.country_id) &&
    !accountProfile?.reg_account?.is_verified

  return (
    <Section>
      <SubHeader>
        {t('profile:profile_information')}
        <div
          style={{
            marginBlock: 24,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            fontSize: 13,
            justifyContent: 'end',
          }}
        >
          <span>{t('profile:verification_status')}</span>{' '}
          <AccountVerificationStatusTag
            style={{ position: 'relative', top: 0 }}
            isVerified={accountProfile?.reg_account?.is_verified}
            isMissingInfo={isMissingInfo}
          />
        </div>
      </SubHeader>
      <ProfileFormFields
        register={register}
        formState={formState}
        validPhoneNumberState={validPhoneNumberState}
        verifyEmailAddress={verifyEmailAddress}
        formatUserPhoneNumber={formatUserPhoneNumber}
        setSurNameValue={setSurNameValue}
        setFirstNameValue={setFirstNameValue}
        setMiddleNameValue={setMiddleNameValue}
        setDateOfBirthValue={setDateOfBirthValue}
        phoneNumberCountryCode={phoneNumberCountryCode}
        formattedValue={formattedValue}
        phoneValue={phoneValue}
        emailValue={emailValue}
        surNameValue={surNameValue}
        firstNameValue={firstNameValue}
        middleNameValue={middleNameValue}
        dateOfBirthValue={dateOfBirthValue}
        setIdType={setIdType}
        setCountry={setCountry}
        handleCountryIdChange={handleCountryIdChange}
        checkCountryIdState={checkCountryIdState}
        countryIdValue={countryIdValue}
        country={country}
        idType={idType}
        selfie={selfie}
        setSelfie={setSelfie}
        isChangeableID={isChangeableID}
        isChangeableSelfie={isChangeableSelfie}
        isChangeablePhotoId={isChangeablePhotoId}
        photoId={photoId}
        setPhotoId={setPhotoId}
        checkEmailState={checkEmailState}
      />
      <Actions>
        <RevertButton size="sm" onClick={onRevert} disabled={isRevertDisabled}>
          {t('revert')}
        </RevertButton>
        <SaveButton size="sm" onClick={submitAccountKYC} disabled={isSaveDisabled}>
          {t('save')}
        </SaveButton>
      </Actions>
    </Section>
  )
}

const ProfilePaymentMethodComponent = () => {
  const { t } = useTranslation(['profile', 'p2p'])

  const setAvailablePaymentMethods = useStore.useSetAvailablePaymentMethods()
  const profilePaymentMethods = useStore.useProfilePaymentMethods()
  const setProfilePaymentMethods = useStore.useSetProfilePaymentMethods()

  const [{ open }, { start: initWizard }] = useWizard()
  const [activeIndex, setActiveIndex] = useState(0)
  const countryDataLoggedUser = useLoggedUser()
  const account = useStore.useAccount()

  const syncPaymentMethods = () => {
    const userPayments = getParsedPaymentMethods(account)
    if (!userPayments) return
    setProfilePaymentMethods(userPayments.sort((a, b) => a.method.localeCompare(b.method)))
  }

  useEffect(() => {
    syncPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (!countryDataLoggedUser) return
    const methods = Array.from(
      new Set([...getCountryMethodsByCountry(countryDataLoggedUser.countryCode)]),
    ).sort()

    setAvailablePaymentMethods(
      methods.filter(
        (method) =>
          !profilePaymentMethods
            .filter((profileMethod) => profileMethod.method !== 'bank_transfer')
            .map(({ method }) => method)
            .includes(method),
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryDataLoggedUser, open, profilePaymentMethods])

  const togglePaymentMethod = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  const addPaymentMethod = () => {
    initWizard(AddPaymentMethodSteps)
  }

  const filteredProfiledMethods = groupBy(profilePaymentMethods, 'method')

  return (
    <Section>
      <SubHeaderContainer>
        <SubHeader style={{ marginBottom: 0 }}>{t('profile:payment_methods')}</SubHeader>
        <ButtonTransparent onClick={addPaymentMethod}>
          <PlusIcon />
        </ButtonTransparent>
      </SubHeaderContainer>
      <AvailableMethods>
        {profilePaymentMethods?.length === 0 && (
          <Label gray center>
            {t('no_methods')}
          </Label>
        )}
        {Object.keys(filteredProfiledMethods)?.map((methodKey, index) => (
          <ProfileMethodWrapper key={methodKey}>
            <SubHeaderContainer
              role="button"
              onClick={() => {
                togglePaymentMethod(index)
              }}
              style={{ marginBottom: 0, cursor: 'pointer' }}
            >
              {t(`p2p:${methodKey}`)}
              <ChevronWrapper
                {...{
                  animate: {
                    transform: activeIndex === index ? 'rotateZ(90deg)' : 'rotateZ(0deg)',
                  },
                  initial: {
                    transform: activeIndex === index ? 'rotateZ(0deg)' : 'rotateZ(90deg)',
                  },
                }}
              >
                <AiOutlineRight />
              </ChevronWrapper>
            </SubHeaderContainer>

            {filteredProfiledMethods[methodKey].map((method) => (
              <AnimatePresence>
                {activeIndex === index && (
                  <AnimationWrapper {...animationPaymentOptions}>
                    {[method.method].map(() =>
                      method.method === 'bank_transfer' ? (
                        <BankMethodDetails
                          key={method.method}
                          method={method.method}
                          details={method.details as BankTransferDetails}
                          onDelete={syncPaymentMethods}
                          onEdit={syncPaymentMethods}
                        />
                      ) : (
                        <PhoneMethodDetails
                          key={method.method}
                          method={method.method}
                          details={method.details as PhoneTransferDetails}
                          onDelete={syncPaymentMethods}
                          onEdit={syncPaymentMethods}
                        />
                      ),
                    )}
                  </AnimationWrapper>
                )}
              </AnimatePresence>
            ))}
          </ProfileMethodWrapper>
        ))}
      </AvailableMethods>
    </Section>
  )
}

export const ProfileView = () => <ProfileViewComponent />
