import { HintError as HintErrorTemplate, Input, InputSection } from 'app-view/components/InputField'
import Spinner from 'app-view/components/Spinner'
import styled from 'app-view/styles/styled'
import { Label } from 'pages/ProfileView/Shared'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ProfileFormProps {
  register: any
  formState: any
  validPhoneNumberState: any
  verifyEmailAddress: (event: React.ChangeEvent<HTMLInputElement>) => void
  formatUserPhoneNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
  setSurNameValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFirstNameValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  setMiddleNameValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  phoneNumberCountryCode: string
  formattedValue: string
  phoneValue: string
  emailValue: string
  surNameValue: string
  firstNameValue: string
  middleNameValue: string
}

const HintError = styled(HintErrorTemplate)`
  position: initial;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 74%;
  input {
    color: ${(props) => props.theme.colors.black}!important;
  }
  .KeyRecoveryProfileForm {
    height: 55px;
  }
`

export const KeyRecoveryProfileForm: React.FC<ProfileFormProps> = ({
  register,
  formState,
  validPhoneNumberState,
  setSurNameValue,
  setFirstNameValue,
  setMiddleNameValue,
  verifyEmailAddress,
  formatUserPhoneNumber,
  phoneNumberCountryCode,
  formattedValue,
  phoneValue,
  emailValue,
  surNameValue,
  firstNameValue,
  middleNameValue,
}) => {
  const { t } = useTranslation(['profile', 'p2p'])

  return (
    <Container>
      <div>
        <Label>{t('account:surname_label')}</Label>
        <InputSection
          border="grey"
          className="KeyRecoveryProfileForm"
          error={Boolean(!formState.isValidating && formState.errors.surName?.message)}
        >
          <Input
            radius="soft"
            placeholder="Surname"
            fontWeight="700"
            {...register('surName')}
            onChange={setSurNameValue}
            value={surNameValue}
          />
        </InputSection>
        {!formState.isValidating && formState.errors.surName?.message && (
          <HintError align="left">{formState.errors.surName?.message}</HintError>
        )}
      </div>
      <div>
        <Label>{t('account:first_name_label')}</Label>
        <InputSection
          border="grey"
          className="KeyRecoveryProfileForm"
          error={Boolean(!formState.isValidating && formState.errors.firstName?.message)}
        >
          <Input
            radius="soft"
            placeholder="First Name"
            fontWeight="700"
            {...register('firstName')}
            onChange={setFirstNameValue}
            value={firstNameValue}
          />
        </InputSection>
        {!formState.isValidating && formState.errors.firstName?.message && (
          <HintError align="left">{formState.errors.firstName?.message}</HintError>
        )}
      </div>
      <div>
        <Label>{t('account:middle_name_label')}</Label>
        <InputSection
          border="grey"
          className="KeyRecoveryProfileForm"
          error={Boolean(!formState.isValidating && formState.errors.middleName?.message)}
        >
          <Input
            radius="soft"
            placeholder="Middle Name"
            fontWeight="700"
            {...register('middleName')}
            onChange={setMiddleNameValue}
            value={middleNameValue}
          />
        </InputSection>
        {!formState.isValidating && formState.errors.middleName?.message && (
          <HintError align="left">{formState.errors.middleName?.message}</HintError>
        )}
      </div>
      <div>
        <Label>{t('phone_number')}</Label>
        <InputSection
          border="grey"
          className="KeyRecoveryProfileForm"
          error={Boolean(
            phoneValue && !formState.isValidating && formState.errors.phoneNumber?.message,
          )}
        >
          <Input
            radius="soft"
            fontWeight="700"
            {...register('phoneNumber')}
            onChange={formatUserPhoneNumber}
            placeholder={t('phone_number_placeholder', {
              phoneNumberCountryCode: phoneNumberCountryCode ? `(${phoneNumberCountryCode}) ` : '',
            })}
            type="tel"
            value={formattedValue}
          />
          {validPhoneNumberState.loading && <Spinner boxSize={20} />}
        </InputSection>
        {!phoneNumberCountryCode &&
          !phoneValue &&
          (!formState.errors.phoneNumber?.message || !validPhoneNumberState.valid) && (
            <HintError align="left">{t('include_country_code')}</HintError>
          )}
        {!formState.isValidating &&
          phoneValue &&
          (formState.errors.phoneNumber?.message || validPhoneNumberState.valid) && (
            <HintError align="left">{formState.errors.phoneNumber?.message}</HintError>
          )}
      </div>
      <div>
        <Label>{t('email')}</Label>
        <InputSection
          border="grey"
          className="KeyRecoveryProfileForm"
          error={Boolean(!formState.isValidating && formState.errors.emailAddress?.message)}
        >
          <Input
            radius="soft"
            placeholder="example@email.com"
            fontWeight="700"
            {...register('emailAddress')}
            inputMode="email"
            value={emailValue}
            onChange={verifyEmailAddress}
          />
        </InputSection>
        {!formState.isValidating && emailValue && formState.errors.emailAddress?.message && (
          <HintError align="left">{formState.errors.emailAddress?.message}</HintError>
        )}
      </div>
    </Container>
  )
}
