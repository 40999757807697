import React, { useEffect, useState } from 'react'
import { HeaderStep } from './HeaderStep'
import { FooterStep } from './FooterStep'
import { KeyRecoveryProfileForm } from '../../components/KeyRecovery/KeyRecoveryProfileForm'
import { useStore } from 'app-engine/store'
import { useForm } from 'react-hook-form'
import { useKYCForm } from 'app-view/hooks/useKYCForm'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useKeyRecovery } from 'pages/DeviceKeysView/hooks/useKeyRecovery'

export const RecoveryProfile = ({ next }: WizardStepProps) => {
  const { registerAccount } = useStore()
  const [, { storeProfileData, setState }] = useKeyRecovery()
  const [successStep, setSuccessStep] = useState(false)

  const fullName = (registerAccount?.profile?.full_name || '').split('_').join(', ')
  const firstName = fullName.split(', ')[0] || ''
  const middleName = fullName.split(', ')[1] || ''
  const surName = fullName.split(', ')[2] || ''
  const phoneNumber = registerAccount?.profile?.phone || ''
  const emailAddress = registerAccount?.profile?.email || ''

  const { setValue, setError, formState, watch, handleSubmit, register } = useForm({
    defaultValues: {
      surName,
      firstName,
      middleName,
      phoneNumber,
      emailAddress,
    },
  })

  const submitAccountKYC = handleSubmit((data) => {
    setState({ loading: true })
    storeProfileData(data)
    next()
  })

  const {
    formatUserPhoneNumber,
    verifyEmailAddress,
    setSurNameValue,
    setFirstNameValue,
    setMiddleNameValue,
    surNameValue,
    firstNameValue,
    middleNameValue,
    phoneValue,
    emailValue,
    phoneNumberCountryCode,
    formattedValue,
    validPhoneNumberState,
  } = useKYCForm({ submitAccountKYC, setValue, setError, formState, watch })

  useEffect(() => {
    const isFullProfileData = () => {
      const errors =
        Boolean(formState.errors.surName?.message) ||
        Boolean(formState.errors.firstName?.message) ||
        Boolean(formState.errors.middleName?.message) ||
        Boolean(formState.errors.emailAddress?.message) ||
        Boolean(formState.errors.phoneNumber?.message)

      if (
        watch('surName') &&
        watch('firstName') &&
        watch('middleName') &&
        watch('phoneNumber') &&
        watch('emailAddress') &&
        !errors
      ) {
        setSuccessStep(true)
      } else {
        setSuccessStep(false)
      }
    }

    isFullProfileData()
  }, [formState, watch])

  return (
    <>
      <HeaderStep />
      <KeyRecoveryProfileForm
        register={register}
        formState={formState}
        validPhoneNumberState={validPhoneNumberState}
        verifyEmailAddress={verifyEmailAddress}
        formatUserPhoneNumber={formatUserPhoneNumber}
        setSurNameValue={setSurNameValue}
        setFirstNameValue={setFirstNameValue}
        setMiddleNameValue={setMiddleNameValue}
        phoneNumberCountryCode={phoneNumberCountryCode}
        formattedValue={formattedValue}
        phoneValue={phoneValue}
        emailValue={emailValue}
        surNameValue={surNameValue}
        firstNameValue={firstNameValue}
        middleNameValue={middleNameValue}
      />
      <FooterStep isSuccessStep={successStep} clickSuccess={submitAccountKYC} />
    </>
  )
}
