import { SIGNING_REQUEST_STEPS } from 'app-view/components/SignRequest/steps'
import { useWizard } from 'app-view/components/Wizard'
import { IconBaseProps } from 'react-icons/lib'
import { LuScanLine } from 'react-icons/lu'

export function QRScanButton(props: IconBaseProps) {
  const [, { start, goTo }] = useWizard()

  return (
    <LuScanLine
      color="white"
      style={{ alignSelf: 'center', cursor: 'pointer' }}
      size={40}
      onClick={() => {
        start(SIGNING_REQUEST_STEPS)
        goTo('qr_reader')
      }}
      {...props}
    />
  )
}
