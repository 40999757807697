// account.ts
export default {
  create_account_title: 'Buat Akun Pengguna Anda',
  create_account_format_description:
    'Harap buat nama akun yang unik dan mudah diingat dengan 9 karakter menggunakan huruf antara <b>a-z</b> dan angka <b>1-5</b>.',
  create_account_user_account_placeholder: 'Ketik nama akun anda',
  create_account_user_account_error_required: 'Silakan masukkan nama akun Anda',
  create_account_user_account_error_pattern: 'Hanya a-z dan 1-5 yang diizinkan',
  create_account_referrer_account_error_pattern:
    'Hanya a-z, 1-5 dan akun yang berakhir dengan .bk yang diizinkan',
  create_account_user_account_error_unavailable:
    'Akun tidak tersedia! Silakan coba dengan lainnya.',
  create_account_referrer_label: 'Akun referensi',
  create_account_referrer_account_placeholder: 'referrer1',
  create_account_referrer_error_required: 'Silakan masukkan referensi Anda',
  create_account_referrer_error_not_registered: 'Referensi tidak terdaftar',
  create_account_accounts_submit: 'Konfirmasi',
  create_account_referrer_error_unexistent:
    'Akun saat ini tidak terdaftar atau disetujui oleh bitcashBank.',
  create_account_key_title: 'Tambah Kunci Smartphone',
  create_account_key_description:
    "Perangkat smartphone Anda akan menjadi kunci utama untuk mengakses akun Anda. Silakan pilih opsi 'Gunakan perangkat ini dengan kunci layar' saat diminta setelah mengklik tombol di bawah ini.",
  create_account_key_device_label: 'Nama perangkat',
  create_account_key_device_placeholder: 'Masukkan nama perangkat Anda',
  create_account_key_device_error_required: 'Silakan masukkan nama perangkat Anda',
  create_account_key_submit: 'Berikutnya',
  register_account_success_text:
    'Pendaftaran Anda berhasil diajukan. Silakan beri tahu referensi Anda atau bergabung dengan grup Discord bitcashBank untuk mempercepat persetujuan.',
  create_account_success_text:
    'Pembuatan akun Anda berhasil diselesaikan. Silakan beri tahu referensi Anda atau bergabung dengan grup Discord bitcashBank untuk mempercepat persetujuan.',
  register_account_success_button: 'Buka Discord',
  register_account_title: 'Daftarkan Akun Pengguna Anda',
  account_title: 'Nama Akun',
  account_title_description:
    'Silakan masukkan nama akun yang ingin Anda masuk dengan perangkat ini.',
  account_setup_account_button: 'Masuk',
  account_user_account_available: 'Akun ini tersedia',
  account_user_account_registered: 'Akun ini valid',
  account_user_account_not_registered: 'Akun tidak terdaftar',
  add_device_account_title: 'Masukkan Nama Akun yang Sudah Ada',
  migrate_device_account_title: 'Masukkan Nama Akun yang Sudah Ada',
  add_device_account_description:
    'Ini harus akun yang telah Anda gunakan pada perangkat sebelumnya. Anda akan memerlukan akses ke perangkat itu untuk menyetujui penambahan kunci lain.',
  migrate_device_account_description:
    'Ini harus akun yang telah Anda gunakan pada perangkat sebelumnya.',
  add_device_key_title: 'Kunci Perangkat',
  add_device_key_description:
    '<p>Apakah Anda ingin menambahkan smartphone ini atau kunci keamanan (mis. Yubikey) sebagai kunci tambahan untuk mengakses <b>{{account}}</b>?</p><p>Jika Anda ingin menggunakan smartphone ini pilih <b>Touch atau Face ID</b> untuk menggunakan perangkat ini sebagai kunci saat diminta setelah mengklik tombol di bawah ini.</p><p>Jika tidak, jika Anda memiliki kunci keamanan pilih opsi <b>Gunakan Kunci Keamanan</b> saat diminta.</p>',
  add_device_key_submit: 'Tambah Kunci',
  add_device_success_description:
    'Silakan gunakan perangkat asli Anda untuk menyetujui penambahan kunci baru ke <b>{{account}}</b>',
  migrate_device_success_description: 'Silakan tunggu admin.',
  select_device_title: 'Pilih Perangkat',
  select_device_description: 'Silakan, pilih salah satu perangkat Anda untuk masuk.',
  new_device: 'Perangkat Baru',
  continue: 'Lanjutkan',
  warning_apple_user: 'Peringatan pengguna Apple',
  warning_apple_user_modal_info_browser:
    'Ingatlah untuk mendaftarkan browser baru sebagai <0>Perangkat Baru</0> jika Anda akan mendaftarkan akun ini dengan biometrik.',
  warning_apple_user_modal_info_confirm:
    'Mengerti, saya akan memastikan untuk mendaftarkan browser baru sebelum menggunakannya.',
  list_devices: 'daftar perangkat {{account}}',
  singup_invitation:
    'Bitcash App ditenagai oleh bitcashBank beta hanya dengan undangan saja.<br /> <br /> Silakan kunjungi <a href="https://bitcash.org">bitcash.org</a> untuk mendaftar undangan.',
  approved_accounts: 'Akun Disetujui',
  pending_accounts: 'Akun Menunggu',
  approving_account: 'Menyetujui Akun',
  rejecting_account: 'Menolak Akun',
  successfully_rejected: 'Akun {{account}} berhasil ditolak!',
  successfully_approved: 'Akun {{acct.account}} berhasil disetujui!',

  country_id_label: 'ID',
  phone_number_label: 'Nomor telepon',
  email_address_label: 'Alamat email',
  date_of_birth_label: 'Tanggal Lahir',
  first_name_label: 'Nama Depan',
  middle_name_label: 'Nama Tengah',
  surname_label: 'Nama Belakang',
  subscribe_to_newsletter: 'Tetap beri tahu saya tentang acara dan pendidikan crypto.',
  phone_valid: 'Nomor telepon valid!',
  phone_number_placeholder: '{{phoneNumberCountryCode}}#Nomor telp',
  include_country_code: 'Sertakan node negara:',
  create_account_kyc: 'Informasi Akun',
  create_account_kyc_title: 'Verifikasi Akun',
  create_account_kyc_description:
    'Silakan masukkan nomor telepon dan alamat email Anda di bawah ini untuk melengkapi pendaftaran akun Anda. Kami akan menggunakan informasi ini untuk memverifikasi identitas Anda dan memulihkan akun Anda jika Anda kehilangan perangkat Anda.',
  invalid_phone_provenance: 'Asal nomor telepon tidak valid. Coba lagi atau dengan yang lain.',
  account_details: 'Silakan masukkan info di bawah ini untuk tujuan pemulihan akun:',
  this_country_id_already_registered: 'ID ini sudah terdaftar.',
  this_email_already_registered: 'Email ini sudah terdaftar.',
  email_valid: 'Emailnya valid.',
  id_invalid: 'Tanda pengenal tidak valid.',
  phone_invalid: 'Nomor telepon tidak valid.',
  email_invalid: 'Email tidak valid.',
  surname_invalid: 'Nama belakang tidak valid.',
  first_name_invalid: 'Nama depan tidak valid.',
  middle_name_invalid: 'Nama tengah tidak valid.',
  this_phone_number_already_registered: 'Nomor telepon ini sudah terdaftar.',
  loading_action: 'Memproses Tanda Tangan...',
  user_verified: 'Pengguna Terverifikasi',
  created_at: 'Dibuat di',
  approve: 'Menyetujui',
  selfie_label: 'foto ID',
  photo_invalid: 'Identifikasi foto tidak valid.',
  continue_verification: 'Lanjutkan Verifikasi',
  cancel_verification: 'Mungkin nanti',
  selfie_notification_regular:
    'Memprioritaskan keamanan Anda, kami memerlukan verifikasi identitas untuk pemulihan akun, perlindungan, dan hadiah aplikasi.',
  selfie_notification_investor: 'Pastikan selfie dan foto ID Anda mencakup:',
  selfie_notifications_1: `<ul>
    <li>Wajah penuh dan ID terlihat.</li>
    <li>Tanpa aksesori.</li>
    <li>Kedua mata terbuka, ekspresi netral.</li>
   </ul>`,
  selfie_notifications_2: `<ul>
  <li>Pengaturan yang jelas dan terang.</li>
  <li>Latar belakang polos.</li>
  <li>Tidak ada filter atau perubahan.</li>
  </ul>`,
  photo_id_notifications: `<ul>
  <li>Sejajarkan ID Anda dengan bingkai di layar Anda dan ID tersebut akan terlihat.</li>
  <li>Foto sebaiknya diambil di lingkungan yang cukup terang dan tidak silau.</li>
  <li>Tidak boleh ada bagian ID yang tertutup oleh jari atau benda apa pun.</li>
  </ul>`,
  require_permission:
    'Kami memerlukan kamera Anda, dan izin GPS untuk memenuhi syarat untuk selfie.',
  grant_permission: 'Berikan Izin',
  granting_permission: 'Memberikan Izin...',
  take_selfie: 'Ambil Selfie',
  retake_selfie: 'Ambil kembali Selfie',
  verifying: 'Memverifikasi...',
  photo_is_ok: 'Foto oke.',
  processing_error: 'Terjadi masalah saat memproses foto Anda.',
  invalid_photo: 'Foto tidak memenuhi persyaratan.',
  processing: 'Pengolahan...',
  download_selfie: 'Unduh selfie',
  take_selfie_id: 'Ambil ID Selfie',
  retake_photos: 'Ambil Ulang Foto',
  invalid_photo_id: 'Dokumen tidak terlihat.',
  take_selfie_instructions: 'Petunjuk Foto Selfie',
  take_id_card_photo_instructions: 'Petunjuk Foto Kartu ID',
  question_kyc_reason: 'Mengapa saya harus memberikan izin?',
  return: 'Kembali',
  kyc_help_text_1:
    'Langkah-langkah keamanan ini melindungi akun Anda dari akses tidak sah dan memastikan Anda adalah pemilik yang sah. Hal ini juga memungkinkan kami memberikan penghargaan aplikasi secara akurat yang disesuaikan dengan wilayah Anda.',
  kyc_help_text_2:
    "Setelah Anda siap, kembali dan klik 'Lanjutkan Verifikasi' untuk melanjutkan atau pilih 'Mungkin Nanti' jika Anda perlu menyiapkan ID atau menyesuaikan pengaturan perangkat Anda.",
  how_to_grant_permission: 'BAGAIMANA CARA MEMBERI IZIN?',
  please_grant_bitcash_app_permission:
    'Harap berikan izin aplikasi bitcash untuk mengakses kamera dan lokasi Anda',
  for_android: 'Untuk Android',
  android_setting: `<ol>
    <li>Buka 'Pengaturan' > 'Aplikasi & notifikasi' > 'Izin aplikasi'.</li>
    <li>Pilih 'aplikasi bitcash' dan aktifkan 'Kamera' dan 'Lokasi'.</li>
  </ol> `,
  additional_resources: 'Sumber daya tambahan',
  for_android_devices_via: 'Untuk perangkat Android melalui Portal Bantuan Scribble Maps',
  for_ios: 'Untuk iOS',
  ios_setting: `<ol>
    <li>Buka 'Pengaturan' > 'Privasi' > 'Layanan Lokasi'.</li>
    <li>Temukan 'aplikasi bitcash' dalam daftar dan atur izin ke 'Saat Menggunakan Aplikasi.'</li>
  </ol> `,
  for_ios_devices_via: 'Untuk perangkat iOS melalui Portal Bantuan Scribble Maps',
  for_chrome_specific: 'Untuk petunjuk khusus Chrome',
  enable_gps_location: 'Aktifkan GPS/Lokasi',
  google_chrome_support: 'Dukungan Google Chrome',
  bitlauncher_welcome_title: 'Selamat Datang!',
  bitlauncher_welcome_body: `Bitcash adalah dompet pembayaran kripto yang digunakan untuk masuk ke Bitlauncher.

Silakan lanjutkan mendaftarkan akun Anda dan menyelesaikan KYC Anda.`,
}
