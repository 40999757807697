import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  JSON: any
  bigint: any
  float8: any
  jsonb: any
  numeric: any
  timestamp: any
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Array<Scalars['Boolean']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Array<Scalars['Boolean']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export type PushSubscriptionInput = {
  account: Scalars['String']
  endpoint: Scalars['String']
  expirationTime?: InputMaybe<Scalars['String']>
  keys: PushSubscriptionKeysInput
}

export type PushSubscriptionKeysInput = {
  auth: Scalars['String']
  p256dh: Scalars['String']
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>
  _eq?: InputMaybe<Array<Scalars['String']>>
  _gt?: InputMaybe<Array<Scalars['String']>>
  _gte?: InputMaybe<Array<Scalars['String']>>
  _in?: InputMaybe<Array<Array<Scalars['String']>>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Array<Scalars['String']>>
  _lte?: InputMaybe<Array<Scalars['String']>>
  _neq?: InputMaybe<Array<Scalars['String']>>
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type UserEmailData = {
  account: Scalars['String']
  email: Scalars['String']
}

/** columns and relationships of "account_statistics" */
export type Account_Statistics = {
  orders_cancelled?: Maybe<Scalars['bigint']>
  orders_completed?: Maybe<Scalars['bigint']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "account_statistics" */
export type Account_Statistics_Aggregate = {
  aggregate?: Maybe<Account_Statistics_Aggregate_Fields>
  nodes: Array<Account_Statistics>
}

/** aggregate fields of "account_statistics" */
export type Account_Statistics_Aggregate_Fields = {
  avg?: Maybe<Account_Statistics_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Account_Statistics_Max_Fields>
  min?: Maybe<Account_Statistics_Min_Fields>
  stddev?: Maybe<Account_Statistics_Stddev_Fields>
  stddev_pop?: Maybe<Account_Statistics_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Account_Statistics_Stddev_Samp_Fields>
  sum?: Maybe<Account_Statistics_Sum_Fields>
  var_pop?: Maybe<Account_Statistics_Var_Pop_Fields>
  var_samp?: Maybe<Account_Statistics_Var_Samp_Fields>
  variance?: Maybe<Account_Statistics_Variance_Fields>
}

/** aggregate fields of "account_statistics" */
export type Account_Statistics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Statistics_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Account_Statistics_Avg_Fields = {
  orders_cancelled?: Maybe<Scalars['Float']>
  orders_completed?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "account_statistics". All fields are combined with a logical 'AND'. */
export type Account_Statistics_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Statistics_Bool_Exp>>
  _not?: InputMaybe<Account_Statistics_Bool_Exp>
  _or?: InputMaybe<Array<Account_Statistics_Bool_Exp>>
  orders_cancelled?: InputMaybe<Bigint_Comparison_Exp>
  orders_completed?: InputMaybe<Bigint_Comparison_Exp>
  user_id?: InputMaybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Account_Statistics_Max_Fields = {
  orders_cancelled?: Maybe<Scalars['bigint']>
  orders_completed?: Maybe<Scalars['bigint']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Account_Statistics_Min_Fields = {
  orders_cancelled?: Maybe<Scalars['bigint']>
  orders_completed?: Maybe<Scalars['bigint']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "account_statistics". */
export type Account_Statistics_Order_By = {
  orders_cancelled?: InputMaybe<Order_By>
  orders_completed?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** select columns of table "account_statistics" */
export enum Account_Statistics_Select_Column {
  /** column name */
  OrdersCancelled = 'orders_cancelled',
  /** column name */
  OrdersCompleted = 'orders_completed',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Account_Statistics_Stddev_Fields = {
  orders_cancelled?: Maybe<Scalars['Float']>
  orders_completed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Account_Statistics_Stddev_Pop_Fields = {
  orders_cancelled?: Maybe<Scalars['Float']>
  orders_completed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Account_Statistics_Stddev_Samp_Fields = {
  orders_cancelled?: Maybe<Scalars['Float']>
  orders_completed?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "account_statistics" */
export type Account_Statistics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Statistics_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Account_Statistics_Stream_Cursor_Value_Input = {
  orders_cancelled?: InputMaybe<Scalars['bigint']>
  orders_completed?: InputMaybe<Scalars['bigint']>
  user_id?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Account_Statistics_Sum_Fields = {
  orders_cancelled?: Maybe<Scalars['bigint']>
  orders_completed?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Account_Statistics_Var_Pop_Fields = {
  orders_cancelled?: Maybe<Scalars['Float']>
  orders_completed?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Account_Statistics_Var_Samp_Fields = {
  orders_cancelled?: Maybe<Scalars['Float']>
  orders_completed?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Account_Statistics_Variance_Fields = {
  orders_cancelled?: Maybe<Scalars['Float']>
  orders_completed?: Maybe<Scalars['Float']>
}

/** Additional information for account restoration and for kyc */
export type Accounts_Information = {
  account: Scalars['String']
  country_id: Scalars['jsonb']
  date_of_birth: Scalars['timestamptz']
  email: Scalars['String']
  full_name: Scalars['String']
  id: Scalars['uuid']
  newsletter_subscription?: Maybe<Scalars['Boolean']>
  phone: Scalars['String']
  photo_id?: Maybe<Scalars['String']>
  recovery_partners?: Maybe<Scalars['jsonb']>
  /** An object relationship */
  reg_account: Reg_Accounts
  registration_ip: Scalars['String']
  selfie?: Maybe<Scalars['String']>
}

/** Additional information for account restoration and for kyc */
export type Accounts_InformationCountry_IdArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Additional information for account restoration and for kyc */
export type Accounts_InformationRecovery_PartnersArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "accounts_information" */
export type Accounts_Information_Aggregate = {
  aggregate?: Maybe<Accounts_Information_Aggregate_Fields>
  nodes: Array<Accounts_Information>
}

export type Accounts_Information_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Accounts_Information_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Accounts_Information_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Accounts_Information_Aggregate_Bool_Exp_Count>
}

export type Accounts_Information_Aggregate_Bool_Exp_Bool_And = {
  arguments: Accounts_Information_Select_Column_Accounts_Information_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Accounts_Information_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Accounts_Information_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Accounts_Information_Select_Column_Accounts_Information_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Accounts_Information_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Accounts_Information_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Accounts_Information_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Accounts_Information_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "accounts_information" */
export type Accounts_Information_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Accounts_Information_Max_Fields>
  min?: Maybe<Accounts_Information_Min_Fields>
}

/** aggregate fields of "accounts_information" */
export type Accounts_Information_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Information_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "accounts_information" */
export type Accounts_Information_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Accounts_Information_Max_Order_By>
  min?: InputMaybe<Accounts_Information_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Accounts_Information_Append_Input = {
  country_id?: InputMaybe<Scalars['jsonb']>
  recovery_partners?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "accounts_information" */
export type Accounts_Information_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Information_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_Information_On_Conflict>
}

/** Boolean expression to filter rows from the table "accounts_information". All fields are combined with a logical 'AND'. */
export type Accounts_Information_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Information_Bool_Exp>>
  _not?: InputMaybe<Accounts_Information_Bool_Exp>
  _or?: InputMaybe<Array<Accounts_Information_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  country_id?: InputMaybe<Jsonb_Comparison_Exp>
  date_of_birth?: InputMaybe<Timestamptz_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  full_name?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  newsletter_subscription?: InputMaybe<Boolean_Comparison_Exp>
  phone?: InputMaybe<String_Comparison_Exp>
  photo_id?: InputMaybe<String_Comparison_Exp>
  recovery_partners?: InputMaybe<Jsonb_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  registration_ip?: InputMaybe<String_Comparison_Exp>
  selfie?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "accounts_information" */
export enum Accounts_Information_Constraint {
  /** unique or primary key constraint on columns "account" */
  AccountsInformationAccountKey = 'accounts_information_account_key',
  /** unique or primary key constraint on columns "id" */
  AccountsInformationIdKey = 'accounts_information_id_key',
  /** unique or primary key constraint on columns "phone" */
  AccountsInformationPhoneKey = 'accounts_information_phone_key',
  /** unique or primary key constraint on columns "account", "id" */
  AccountsInformationPkey = 'accounts_information_pkey',
  /** unique or primary key constraint on columns "country_id" */
  UniqueCountryId = 'unique_country_id',
  /** unique or primary key constraint on columns "email" */
  UniqueEmail = 'unique_email',
  /** unique or primary key constraint on columns "phone" */
  UniquePhone = 'unique_phone',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Accounts_Information_Delete_At_Path_Input = {
  country_id?: InputMaybe<Array<Scalars['String']>>
  recovery_partners?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Accounts_Information_Delete_Elem_Input = {
  country_id?: InputMaybe<Scalars['Int']>
  recovery_partners?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Accounts_Information_Delete_Key_Input = {
  country_id?: InputMaybe<Scalars['String']>
  recovery_partners?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "accounts_information" */
export type Accounts_Information_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  country_id?: InputMaybe<Scalars['jsonb']>
  date_of_birth?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  full_name?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  newsletter_subscription?: InputMaybe<Scalars['Boolean']>
  phone?: InputMaybe<Scalars['String']>
  photo_id?: InputMaybe<Scalars['String']>
  recovery_partners?: InputMaybe<Scalars['jsonb']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  registration_ip?: InputMaybe<Scalars['String']>
  selfie?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Accounts_Information_Max_Fields = {
  account?: Maybe<Scalars['String']>
  date_of_birth?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  full_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  phone?: Maybe<Scalars['String']>
  photo_id?: Maybe<Scalars['String']>
  registration_ip?: Maybe<Scalars['String']>
  selfie?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "accounts_information" */
export type Accounts_Information_Max_Order_By = {
  account?: InputMaybe<Order_By>
  date_of_birth?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  full_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  photo_id?: InputMaybe<Order_By>
  registration_ip?: InputMaybe<Order_By>
  selfie?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Accounts_Information_Min_Fields = {
  account?: Maybe<Scalars['String']>
  date_of_birth?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  full_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  phone?: Maybe<Scalars['String']>
  photo_id?: Maybe<Scalars['String']>
  registration_ip?: Maybe<Scalars['String']>
  selfie?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "accounts_information" */
export type Accounts_Information_Min_Order_By = {
  account?: InputMaybe<Order_By>
  date_of_birth?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  full_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  photo_id?: InputMaybe<Order_By>
  registration_ip?: InputMaybe<Order_By>
  selfie?: InputMaybe<Order_By>
}

/** response of any mutation on the table "accounts_information" */
export type Accounts_Information_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Information>
}

/** input type for inserting object relation for remote table "accounts_information" */
export type Accounts_Information_Obj_Rel_Insert_Input = {
  data: Accounts_Information_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_Information_On_Conflict>
}

/** on_conflict condition type for table "accounts_information" */
export type Accounts_Information_On_Conflict = {
  constraint: Accounts_Information_Constraint
  update_columns?: Array<Accounts_Information_Update_Column>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

/** Ordering options when selecting data from "accounts_information". */
export type Accounts_Information_Order_By = {
  account?: InputMaybe<Order_By>
  country_id?: InputMaybe<Order_By>
  date_of_birth?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  full_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  newsletter_subscription?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  photo_id?: InputMaybe<Order_By>
  recovery_partners?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  registration_ip?: InputMaybe<Order_By>
  selfie?: InputMaybe<Order_By>
}

/** primary key columns input for table: accounts_information */
export type Accounts_Information_Pk_Columns_Input = {
  account: Scalars['String']
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Accounts_Information_Prepend_Input = {
  country_id?: InputMaybe<Scalars['jsonb']>
  recovery_partners?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "accounts_information" */
export enum Accounts_Information_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  NewsletterSubscription = 'newsletter_subscription',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhotoId = 'photo_id',
  /** column name */
  RecoveryPartners = 'recovery_partners',
  /** column name */
  RegistrationIp = 'registration_ip',
  /** column name */
  Selfie = 'selfie',
}

/** select "accounts_information_aggregate_bool_exp_bool_and_arguments_columns" columns of table "accounts_information" */
export enum Accounts_Information_Select_Column_Accounts_Information_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  NewsletterSubscription = 'newsletter_subscription',
}

/** select "accounts_information_aggregate_bool_exp_bool_or_arguments_columns" columns of table "accounts_information" */
export enum Accounts_Information_Select_Column_Accounts_Information_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  NewsletterSubscription = 'newsletter_subscription',
}

/** input type for updating data in table "accounts_information" */
export type Accounts_Information_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  country_id?: InputMaybe<Scalars['jsonb']>
  date_of_birth?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  full_name?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  newsletter_subscription?: InputMaybe<Scalars['Boolean']>
  phone?: InputMaybe<Scalars['String']>
  photo_id?: InputMaybe<Scalars['String']>
  recovery_partners?: InputMaybe<Scalars['jsonb']>
  registration_ip?: InputMaybe<Scalars['String']>
  selfie?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "accounts_information" */
export type Accounts_Information_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Information_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Accounts_Information_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  country_id?: InputMaybe<Scalars['jsonb']>
  date_of_birth?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  full_name?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  newsletter_subscription?: InputMaybe<Scalars['Boolean']>
  phone?: InputMaybe<Scalars['String']>
  photo_id?: InputMaybe<Scalars['String']>
  recovery_partners?: InputMaybe<Scalars['jsonb']>
  registration_ip?: InputMaybe<Scalars['String']>
  selfie?: InputMaybe<Scalars['String']>
}

/** update columns of table "accounts_information" */
export enum Accounts_Information_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  NewsletterSubscription = 'newsletter_subscription',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhotoId = 'photo_id',
  /** column name */
  RecoveryPartners = 'recovery_partners',
  /** column name */
  RegistrationIp = 'registration_ip',
  /** column name */
  Selfie = 'selfie',
}

export type Accounts_Information_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Accounts_Information_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Accounts_Information_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Accounts_Information_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Accounts_Information_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Accounts_Information_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Information_Set_Input>
  /** filter the rows which have to be updated */
  where: Accounts_Information_Bool_Exp
}

/** columns and relationships of "accounts_referral" */
export type Accounts_Referral = {
  account: Scalars['String']
  clicks: Scalars['Int']
  link: Scalars['String']
  referrer: Scalars['String']
  /** An object relationship */
  reg_account: Reg_Accounts
  registrations: Scalars['Int']
  website: Scalars['String']
}

/** aggregated selection of "accounts_referral" */
export type Accounts_Referral_Aggregate = {
  aggregate?: Maybe<Accounts_Referral_Aggregate_Fields>
  nodes: Array<Accounts_Referral>
}

export type Accounts_Referral_Aggregate_Bool_Exp = {
  count?: InputMaybe<Accounts_Referral_Aggregate_Bool_Exp_Count>
}

export type Accounts_Referral_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Accounts_Referral_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "accounts_referral" */
export type Accounts_Referral_Aggregate_Fields = {
  avg?: Maybe<Accounts_Referral_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Accounts_Referral_Max_Fields>
  min?: Maybe<Accounts_Referral_Min_Fields>
  stddev?: Maybe<Accounts_Referral_Stddev_Fields>
  stddev_pop?: Maybe<Accounts_Referral_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Accounts_Referral_Stddev_Samp_Fields>
  sum?: Maybe<Accounts_Referral_Sum_Fields>
  var_pop?: Maybe<Accounts_Referral_Var_Pop_Fields>
  var_samp?: Maybe<Accounts_Referral_Var_Samp_Fields>
  variance?: Maybe<Accounts_Referral_Variance_Fields>
}

/** aggregate fields of "accounts_referral" */
export type Accounts_Referral_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "accounts_referral" */
export type Accounts_Referral_Aggregate_Order_By = {
  avg?: InputMaybe<Accounts_Referral_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Accounts_Referral_Max_Order_By>
  min?: InputMaybe<Accounts_Referral_Min_Order_By>
  stddev?: InputMaybe<Accounts_Referral_Stddev_Order_By>
  stddev_pop?: InputMaybe<Accounts_Referral_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Accounts_Referral_Stddev_Samp_Order_By>
  sum?: InputMaybe<Accounts_Referral_Sum_Order_By>
  var_pop?: InputMaybe<Accounts_Referral_Var_Pop_Order_By>
  var_samp?: InputMaybe<Accounts_Referral_Var_Samp_Order_By>
  variance?: InputMaybe<Accounts_Referral_Variance_Order_By>
}

/** input type for inserting array relation for remote table "accounts_referral" */
export type Accounts_Referral_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Referral_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_Referral_On_Conflict>
}

/** aggregate avg on columns */
export type Accounts_Referral_Avg_Fields = {
  clicks?: Maybe<Scalars['Float']>
  registrations?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "accounts_referral" */
export type Accounts_Referral_Avg_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "accounts_referral". All fields are combined with a logical 'AND'. */
export type Accounts_Referral_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Referral_Bool_Exp>>
  _not?: InputMaybe<Accounts_Referral_Bool_Exp>
  _or?: InputMaybe<Array<Accounts_Referral_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  clicks?: InputMaybe<Int_Comparison_Exp>
  link?: InputMaybe<String_Comparison_Exp>
  referrer?: InputMaybe<String_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  registrations?: InputMaybe<Int_Comparison_Exp>
  website?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "accounts_referral" */
export enum Accounts_Referral_Constraint {
  /** unique or primary key constraint on columns "link" */
  AccountsReferralPkey = 'accounts_referral_pkey',
}

/** input type for incrementing numeric columns in table "accounts_referral" */
export type Accounts_Referral_Inc_Input = {
  clicks?: InputMaybe<Scalars['Int']>
  registrations?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "accounts_referral" */
export type Accounts_Referral_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  clicks?: InputMaybe<Scalars['Int']>
  link?: InputMaybe<Scalars['String']>
  referrer?: InputMaybe<Scalars['String']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  registrations?: InputMaybe<Scalars['Int']>
  website?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Accounts_Referral_Max_Fields = {
  account?: Maybe<Scalars['String']>
  clicks?: Maybe<Scalars['Int']>
  link?: Maybe<Scalars['String']>
  referrer?: Maybe<Scalars['String']>
  registrations?: Maybe<Scalars['Int']>
  website?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "accounts_referral" */
export type Accounts_Referral_Max_Order_By = {
  account?: InputMaybe<Order_By>
  clicks?: InputMaybe<Order_By>
  link?: InputMaybe<Order_By>
  referrer?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
  website?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Accounts_Referral_Min_Fields = {
  account?: Maybe<Scalars['String']>
  clicks?: Maybe<Scalars['Int']>
  link?: Maybe<Scalars['String']>
  referrer?: Maybe<Scalars['String']>
  registrations?: Maybe<Scalars['Int']>
  website?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "accounts_referral" */
export type Accounts_Referral_Min_Order_By = {
  account?: InputMaybe<Order_By>
  clicks?: InputMaybe<Order_By>
  link?: InputMaybe<Order_By>
  referrer?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
  website?: InputMaybe<Order_By>
}

/** response of any mutation on the table "accounts_referral" */
export type Accounts_Referral_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Referral>
}

/** on_conflict condition type for table "accounts_referral" */
export type Accounts_Referral_On_Conflict = {
  constraint: Accounts_Referral_Constraint
  update_columns?: Array<Accounts_Referral_Update_Column>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

/** Ordering options when selecting data from "accounts_referral". */
export type Accounts_Referral_Order_By = {
  account?: InputMaybe<Order_By>
  clicks?: InputMaybe<Order_By>
  link?: InputMaybe<Order_By>
  referrer?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  registrations?: InputMaybe<Order_By>
  website?: InputMaybe<Order_By>
}

/** primary key columns input for table: accounts_referral */
export type Accounts_Referral_Pk_Columns_Input = {
  link: Scalars['String']
}

/** select columns of table "accounts_referral" */
export enum Accounts_Referral_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Clicks = 'clicks',
  /** column name */
  Link = 'link',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  Registrations = 'registrations',
  /** column name */
  Website = 'website',
}

/** input type for updating data in table "accounts_referral" */
export type Accounts_Referral_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  clicks?: InputMaybe<Scalars['Int']>
  link?: InputMaybe<Scalars['String']>
  referrer?: InputMaybe<Scalars['String']>
  registrations?: InputMaybe<Scalars['Int']>
  website?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Accounts_Referral_Stddev_Fields = {
  clicks?: Maybe<Scalars['Float']>
  registrations?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "accounts_referral" */
export type Accounts_Referral_Stddev_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Accounts_Referral_Stddev_Pop_Fields = {
  clicks?: Maybe<Scalars['Float']>
  registrations?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "accounts_referral" */
export type Accounts_Referral_Stddev_Pop_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Accounts_Referral_Stddev_Samp_Fields = {
  clicks?: Maybe<Scalars['Float']>
  registrations?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "accounts_referral" */
export type Accounts_Referral_Stddev_Samp_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "accounts_referral" */
export type Accounts_Referral_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Referral_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Accounts_Referral_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  clicks?: InputMaybe<Scalars['Int']>
  link?: InputMaybe<Scalars['String']>
  referrer?: InputMaybe<Scalars['String']>
  registrations?: InputMaybe<Scalars['Int']>
  website?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Accounts_Referral_Sum_Fields = {
  clicks?: Maybe<Scalars['Int']>
  registrations?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "accounts_referral" */
export type Accounts_Referral_Sum_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** update columns of table "accounts_referral" */
export enum Accounts_Referral_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Clicks = 'clicks',
  /** column name */
  Link = 'link',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  Registrations = 'registrations',
  /** column name */
  Website = 'website',
}

export type Accounts_Referral_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Accounts_Referral_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Referral_Set_Input>
  /** filter the rows which have to be updated */
  where: Accounts_Referral_Bool_Exp
}

/** aggregate var_pop on columns */
export type Accounts_Referral_Var_Pop_Fields = {
  clicks?: Maybe<Scalars['Float']>
  registrations?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "accounts_referral" */
export type Accounts_Referral_Var_Pop_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Accounts_Referral_Var_Samp_Fields = {
  clicks?: Maybe<Scalars['Float']>
  registrations?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "accounts_referral" */
export type Accounts_Referral_Var_Samp_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Accounts_Referral_Variance_Fields = {
  clicks?: Maybe<Scalars['Float']>
  registrations?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "accounts_referral" */
export type Accounts_Referral_Variance_Order_By = {
  clicks?: InputMaybe<Order_By>
  registrations?: InputMaybe<Order_By>
}

/** Blockchain Actions */
export type Actions = {
  action: Scalars['String']
  auth: Scalars['jsonb']
  contract: Scalars['String']
  data: Scalars['jsonb']
  global_sequence: Scalars['String']
  transaction_id: Scalars['String']
}

/** Blockchain Actions */
export type ActionsAuthArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Blockchain Actions */
export type ActionsDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "actions" */
export type Actions_Aggregate = {
  aggregate?: Maybe<Actions_Aggregate_Fields>
  nodes: Array<Actions>
}

/** aggregate fields of "actions" */
export type Actions_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Actions_Max_Fields>
  min?: Maybe<Actions_Min_Fields>
}

/** aggregate fields of "actions" */
export type Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Actions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Actions_Append_Input = {
  auth?: InputMaybe<Scalars['jsonb']>
  data?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "actions". All fields are combined with a logical 'AND'. */
export type Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Actions_Bool_Exp>>
  _not?: InputMaybe<Actions_Bool_Exp>
  _or?: InputMaybe<Array<Actions_Bool_Exp>>
  action?: InputMaybe<String_Comparison_Exp>
  auth?: InputMaybe<Jsonb_Comparison_Exp>
  contract?: InputMaybe<String_Comparison_Exp>
  data?: InputMaybe<Jsonb_Comparison_Exp>
  global_sequence?: InputMaybe<String_Comparison_Exp>
  transaction_id?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "actions" */
export enum Actions_Constraint {
  /** unique or primary key constraint on columns "global_sequence" */
  ActionsPkey = 'actions_pkey',
  /** unique or primary key constraint on columns "transaction_id" */
  ActionsTransactionIdKey = 'actions_transaction_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Actions_Delete_At_Path_Input = {
  auth?: InputMaybe<Array<Scalars['String']>>
  data?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Actions_Delete_Elem_Input = {
  auth?: InputMaybe<Scalars['Int']>
  data?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Actions_Delete_Key_Input = {
  auth?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "actions" */
export type Actions_Insert_Input = {
  action?: InputMaybe<Scalars['String']>
  auth?: InputMaybe<Scalars['jsonb']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  global_sequence?: InputMaybe<Scalars['String']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Actions_Max_Fields = {
  action?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  global_sequence?: Maybe<Scalars['String']>
  transaction_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Actions_Min_Fields = {
  action?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  global_sequence?: Maybe<Scalars['String']>
  transaction_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "actions" */
export type Actions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Actions>
}

/** on_conflict condition type for table "actions" */
export type Actions_On_Conflict = {
  constraint: Actions_Constraint
  update_columns?: Array<Actions_Update_Column>
  where?: InputMaybe<Actions_Bool_Exp>
}

/** Ordering options when selecting data from "actions". */
export type Actions_Order_By = {
  action?: InputMaybe<Order_By>
  auth?: InputMaybe<Order_By>
  contract?: InputMaybe<Order_By>
  data?: InputMaybe<Order_By>
  global_sequence?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: actions */
export type Actions_Pk_Columns_Input = {
  global_sequence: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Actions_Prepend_Input = {
  auth?: InputMaybe<Scalars['jsonb']>
  data?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "actions" */
export enum Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Auth = 'auth',
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  GlobalSequence = 'global_sequence',
  /** column name */
  TransactionId = 'transaction_id',
}

/** input type for updating data in table "actions" */
export type Actions_Set_Input = {
  action?: InputMaybe<Scalars['String']>
  auth?: InputMaybe<Scalars['jsonb']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  global_sequence?: InputMaybe<Scalars['String']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "actions" */
export type Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Actions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>
  auth?: InputMaybe<Scalars['jsonb']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  global_sequence?: InputMaybe<Scalars['String']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** update columns of table "actions" */
export enum Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Auth = 'auth',
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  GlobalSequence = 'global_sequence',
  /** column name */
  TransactionId = 'transaction_id',
}

export type Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Actions_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Actions_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Actions_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Actions_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Actions_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Actions_Set_Input>
  /** filter the rows which have to be updated */
  where: Actions_Bool_Exp
}

/** columns and relationships of "avg_pay_time" */
export type Avg_Pay_Time = {
  avg_pay_time_seconds?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "avg_pay_time" */
export type Avg_Pay_Time_Aggregate = {
  aggregate?: Maybe<Avg_Pay_Time_Aggregate_Fields>
  nodes: Array<Avg_Pay_Time>
}

/** aggregate fields of "avg_pay_time" */
export type Avg_Pay_Time_Aggregate_Fields = {
  avg?: Maybe<Avg_Pay_Time_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Avg_Pay_Time_Max_Fields>
  min?: Maybe<Avg_Pay_Time_Min_Fields>
  stddev?: Maybe<Avg_Pay_Time_Stddev_Fields>
  stddev_pop?: Maybe<Avg_Pay_Time_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Avg_Pay_Time_Stddev_Samp_Fields>
  sum?: Maybe<Avg_Pay_Time_Sum_Fields>
  var_pop?: Maybe<Avg_Pay_Time_Var_Pop_Fields>
  var_samp?: Maybe<Avg_Pay_Time_Var_Samp_Fields>
  variance?: Maybe<Avg_Pay_Time_Variance_Fields>
}

/** aggregate fields of "avg_pay_time" */
export type Avg_Pay_Time_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Avg_Pay_Time_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Avg_Pay_Time_Avg_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "avg_pay_time". All fields are combined with a logical 'AND'. */
export type Avg_Pay_Time_Bool_Exp = {
  _and?: InputMaybe<Array<Avg_Pay_Time_Bool_Exp>>
  _not?: InputMaybe<Avg_Pay_Time_Bool_Exp>
  _or?: InputMaybe<Array<Avg_Pay_Time_Bool_Exp>>
  avg_pay_time_seconds?: InputMaybe<Numeric_Comparison_Exp>
  user_id?: InputMaybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Avg_Pay_Time_Max_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Avg_Pay_Time_Min_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "avg_pay_time". */
export type Avg_Pay_Time_Order_By = {
  avg_pay_time_seconds?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** select columns of table "avg_pay_time" */
export enum Avg_Pay_Time_Select_Column {
  /** column name */
  AvgPayTimeSeconds = 'avg_pay_time_seconds',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Avg_Pay_Time_Stddev_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Avg_Pay_Time_Stddev_Pop_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Avg_Pay_Time_Stddev_Samp_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "avg_pay_time" */
export type Avg_Pay_Time_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Avg_Pay_Time_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Avg_Pay_Time_Stream_Cursor_Value_Input = {
  avg_pay_time_seconds?: InputMaybe<Scalars['numeric']>
  user_id?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Avg_Pay_Time_Sum_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Avg_Pay_Time_Var_Pop_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Avg_Pay_Time_Var_Samp_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Avg_Pay_Time_Variance_Fields = {
  avg_pay_time_seconds?: Maybe<Scalars['Float']>
}

/** columns and relationships of "avg_release_time" */
export type Avg_Release_Time = {
  avg_release_time_seconds?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "avg_release_time" */
export type Avg_Release_Time_Aggregate = {
  aggregate?: Maybe<Avg_Release_Time_Aggregate_Fields>
  nodes: Array<Avg_Release_Time>
}

/** aggregate fields of "avg_release_time" */
export type Avg_Release_Time_Aggregate_Fields = {
  avg?: Maybe<Avg_Release_Time_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Avg_Release_Time_Max_Fields>
  min?: Maybe<Avg_Release_Time_Min_Fields>
  stddev?: Maybe<Avg_Release_Time_Stddev_Fields>
  stddev_pop?: Maybe<Avg_Release_Time_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Avg_Release_Time_Stddev_Samp_Fields>
  sum?: Maybe<Avg_Release_Time_Sum_Fields>
  var_pop?: Maybe<Avg_Release_Time_Var_Pop_Fields>
  var_samp?: Maybe<Avg_Release_Time_Var_Samp_Fields>
  variance?: Maybe<Avg_Release_Time_Variance_Fields>
}

/** aggregate fields of "avg_release_time" */
export type Avg_Release_Time_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Avg_Release_Time_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Avg_Release_Time_Avg_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "avg_release_time". All fields are combined with a logical 'AND'. */
export type Avg_Release_Time_Bool_Exp = {
  _and?: InputMaybe<Array<Avg_Release_Time_Bool_Exp>>
  _not?: InputMaybe<Avg_Release_Time_Bool_Exp>
  _or?: InputMaybe<Array<Avg_Release_Time_Bool_Exp>>
  avg_release_time_seconds?: InputMaybe<Numeric_Comparison_Exp>
  user_id?: InputMaybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Avg_Release_Time_Max_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Avg_Release_Time_Min_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "avg_release_time". */
export type Avg_Release_Time_Order_By = {
  avg_release_time_seconds?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** select columns of table "avg_release_time" */
export enum Avg_Release_Time_Select_Column {
  /** column name */
  AvgReleaseTimeSeconds = 'avg_release_time_seconds',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Avg_Release_Time_Stddev_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Avg_Release_Time_Stddev_Pop_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Avg_Release_Time_Stddev_Samp_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "avg_release_time" */
export type Avg_Release_Time_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Avg_Release_Time_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Avg_Release_Time_Stream_Cursor_Value_Input = {
  avg_release_time_seconds?: InputMaybe<Scalars['numeric']>
  user_id?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Avg_Release_Time_Sum_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Avg_Release_Time_Var_Pop_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Avg_Release_Time_Var_Samp_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Avg_Release_Time_Variance_Fields = {
  avg_release_time_seconds?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>
  _gt?: InputMaybe<Scalars['bigint']>
  _gte?: InputMaybe<Scalars['bigint']>
  _in?: InputMaybe<Array<Scalars['bigint']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['bigint']>
  _lte?: InputMaybe<Scalars['bigint']>
  _neq?: InputMaybe<Scalars['bigint']>
  _nin?: InputMaybe<Array<Scalars['bigint']>>
}

export type Cancel_P2p_Approval_Input = {
  cancellation_approved_by: Scalars['String']
  cancellation_transaction: Scalars['String']
  id: Scalars['String']
}

export type Cancel_P2p_Offer_Input = {
  cancelled_by: Scalars['String']
  id: Scalars['String']
}

export type Cancel_Wallet_Request_Approval_Input = {
  cancellation_approved_by: Scalars['String']
  cancellation_transaction: Scalars['String']
  id: Scalars['String']
}

export type Cancel_Wallet_Request_Input = {
  cancelled_by: Scalars['String']
  id: Scalars['String']
}

/** columns and relationships of "combined_avg_times" */
export type Combined_Avg_Times = {
  avg_pay_time_minutes?: Maybe<Scalars['numeric']>
  avg_release_time_minutes?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "combined_avg_times" */
export type Combined_Avg_Times_Aggregate = {
  aggregate?: Maybe<Combined_Avg_Times_Aggregate_Fields>
  nodes: Array<Combined_Avg_Times>
}

/** aggregate fields of "combined_avg_times" */
export type Combined_Avg_Times_Aggregate_Fields = {
  avg?: Maybe<Combined_Avg_Times_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Combined_Avg_Times_Max_Fields>
  min?: Maybe<Combined_Avg_Times_Min_Fields>
  stddev?: Maybe<Combined_Avg_Times_Stddev_Fields>
  stddev_pop?: Maybe<Combined_Avg_Times_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Combined_Avg_Times_Stddev_Samp_Fields>
  sum?: Maybe<Combined_Avg_Times_Sum_Fields>
  var_pop?: Maybe<Combined_Avg_Times_Var_Pop_Fields>
  var_samp?: Maybe<Combined_Avg_Times_Var_Samp_Fields>
  variance?: Maybe<Combined_Avg_Times_Variance_Fields>
}

/** aggregate fields of "combined_avg_times" */
export type Combined_Avg_Times_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Combined_Avg_Times_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Combined_Avg_Times_Avg_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['Float']>
  avg_release_time_minutes?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "combined_avg_times". All fields are combined with a logical 'AND'. */
export type Combined_Avg_Times_Bool_Exp = {
  _and?: InputMaybe<Array<Combined_Avg_Times_Bool_Exp>>
  _not?: InputMaybe<Combined_Avg_Times_Bool_Exp>
  _or?: InputMaybe<Array<Combined_Avg_Times_Bool_Exp>>
  avg_pay_time_minutes?: InputMaybe<Numeric_Comparison_Exp>
  avg_release_time_minutes?: InputMaybe<Numeric_Comparison_Exp>
  user_id?: InputMaybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Combined_Avg_Times_Max_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['numeric']>
  avg_release_time_minutes?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Combined_Avg_Times_Min_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['numeric']>
  avg_release_time_minutes?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "combined_avg_times". */
export type Combined_Avg_Times_Order_By = {
  avg_pay_time_minutes?: InputMaybe<Order_By>
  avg_release_time_minutes?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** select columns of table "combined_avg_times" */
export enum Combined_Avg_Times_Select_Column {
  /** column name */
  AvgPayTimeMinutes = 'avg_pay_time_minutes',
  /** column name */
  AvgReleaseTimeMinutes = 'avg_release_time_minutes',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Combined_Avg_Times_Stddev_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['Float']>
  avg_release_time_minutes?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Combined_Avg_Times_Stddev_Pop_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['Float']>
  avg_release_time_minutes?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Combined_Avg_Times_Stddev_Samp_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['Float']>
  avg_release_time_minutes?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "combined_avg_times" */
export type Combined_Avg_Times_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Combined_Avg_Times_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Combined_Avg_Times_Stream_Cursor_Value_Input = {
  avg_pay_time_minutes?: InputMaybe<Scalars['numeric']>
  avg_release_time_minutes?: InputMaybe<Scalars['numeric']>
  user_id?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Combined_Avg_Times_Sum_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['numeric']>
  avg_release_time_minutes?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Combined_Avg_Times_Var_Pop_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['Float']>
  avg_release_time_minutes?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Combined_Avg_Times_Var_Samp_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['Float']>
  avg_release_time_minutes?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Combined_Avg_Times_Variance_Fields = {
  avg_pay_time_minutes?: Maybe<Scalars['Float']>
  avg_release_time_minutes?: Maybe<Scalars['Float']>
}

export type Confirm_P2p_Offer_Input = {
  buyer?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  sell_settlement_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
}

export type Confirm_Wallet_Request_Input = {
  buyer?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  sell_settlement_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
}

export type Create_Add_Bk_Account_Input = {
  account: Scalars['String']
  keys?: InputMaybe<Create_Add_Bk_Account_Keys_Input>
}

export type Create_Add_Bk_Account_Keys_Input = {
  private_key: Scalars['String']
  public_key: Scalars['String']
}

export type Create_Add_Bk_Account_Output = {
  data?: Maybe<Scalars['JSON']>
  error: Scalars['String']
  success: Scalars['Boolean']
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "devices" */
export type Devices = {
  account: Scalars['String']
  created_at?: Maybe<Scalars['timestamptz']>
  cred_id: Scalars['String']
  device_name: Scalars['String']
  public_key: Scalars['String']
  /** An object relationship */
  reg_account: Reg_Accounts
}

/** aggregated selection of "devices" */
export type Devices_Aggregate = {
  aggregate?: Maybe<Devices_Aggregate_Fields>
  nodes: Array<Devices>
}

export type Devices_Aggregate_Bool_Exp = {
  count?: InputMaybe<Devices_Aggregate_Bool_Exp_Count>
}

export type Devices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Devices_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Devices_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "devices" */
export type Devices_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Devices_Max_Fields>
  min?: Maybe<Devices_Min_Fields>
}

/** aggregate fields of "devices" */
export type Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Devices_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "devices" */
export type Devices_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Devices_Max_Order_By>
  min?: InputMaybe<Devices_Min_Order_By>
}

/** input type for inserting array relation for remote table "devices" */
export type Devices_Arr_Rel_Insert_Input = {
  data: Array<Devices_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Devices_On_Conflict>
}

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Devices_Bool_Exp = {
  _and?: InputMaybe<Array<Devices_Bool_Exp>>
  _not?: InputMaybe<Devices_Bool_Exp>
  _or?: InputMaybe<Array<Devices_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  cred_id?: InputMaybe<String_Comparison_Exp>
  device_name?: InputMaybe<String_Comparison_Exp>
  public_key?: InputMaybe<String_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
}

/** unique or primary key constraints on table "devices" */
export enum Devices_Constraint {
  /** unique or primary key constraint on columns "cred_id" */
  DevicesPkey = 'devices_pkey',
}

/** input type for inserting data into table "devices" */
export type Devices_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  public_key?: InputMaybe<Scalars['String']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Devices_Max_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  cred_id?: Maybe<Scalars['String']>
  device_name?: Maybe<Scalars['String']>
  public_key?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "devices" */
export type Devices_Max_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  cred_id?: InputMaybe<Order_By>
  device_name?: InputMaybe<Order_By>
  public_key?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Devices_Min_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  cred_id?: Maybe<Scalars['String']>
  device_name?: Maybe<Scalars['String']>
  public_key?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "devices" */
export type Devices_Min_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  cred_id?: InputMaybe<Order_By>
  device_name?: InputMaybe<Order_By>
  public_key?: InputMaybe<Order_By>
}

/** response of any mutation on the table "devices" */
export type Devices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Devices>
}

/** on_conflict condition type for table "devices" */
export type Devices_On_Conflict = {
  constraint: Devices_Constraint
  update_columns?: Array<Devices_Update_Column>
  where?: InputMaybe<Devices_Bool_Exp>
}

/** Ordering options when selecting data from "devices". */
export type Devices_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  cred_id?: InputMaybe<Order_By>
  device_name?: InputMaybe<Order_By>
  public_key?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
}

/** primary key columns input for table: devices */
export type Devices_Pk_Columns_Input = {
  cred_id: Scalars['String']
}

/** select columns of table "devices" */
export enum Devices_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key',
}

/** input type for updating data in table "devices" */
export type Devices_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  public_key?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "devices" */
export type Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Devices_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Devices_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  public_key?: InputMaybe<Scalars['String']>
}

/** update columns of table "devices" */
export enum Devices_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key',
}

export type Devices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Devices_Set_Input>
  /** filter the rows which have to be updated */
  where: Devices_Bool_Exp
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>
  _gt?: InputMaybe<Scalars['float8']>
  _gte?: InputMaybe<Scalars['float8']>
  _in?: InputMaybe<Array<Scalars['float8']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['float8']>
  _lte?: InputMaybe<Scalars['float8']>
  _neq?: InputMaybe<Scalars['float8']>
  _nin?: InputMaybe<Array<Scalars['float8']>>
}

export type Get_Inactive_Accounts_Args = {
  p_days_ago?: InputMaybe<Scalars['Int']>
}

export type Get_Login_Counts_Args = {
  p_interval?: InputMaybe<Scalars['String']>
}

export type Get_P2p_Offers_With_Old_Or_No_Messages_Args = {
  completed_status?: InputMaybe<Scalars['Boolean']>
  hours?: InputMaybe<Scalars['Int']>
  matched_status?: InputMaybe<Scalars['Boolean']>
}

export type Get_Tx_Statistics_Args = {
  p_interval?: InputMaybe<Scalars['String']>
}

export type Get_Volume_Statistics_Args = {
  p_interval?: InputMaybe<Scalars['String']>
}

export type Id_Verification_Input = {
  country: Scalars['String']
  id: Scalars['String']
  type: Scalars['String']
}

export type Id_Verification_Output = {
  existingRecord: Scalars['Boolean']
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>
  _eq?: InputMaybe<Scalars['jsonb']>
  _gt?: InputMaybe<Scalars['jsonb']>
  _gte?: InputMaybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>
  _in?: InputMaybe<Array<Scalars['jsonb']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['jsonb']>
  _lte?: InputMaybe<Scalars['jsonb']>
  _neq?: InputMaybe<Scalars['jsonb']>
  _nin?: InputMaybe<Array<Scalars['jsonb']>>
}

/** columns and relationships of "login_account" */
export type Login_Account = {
  account: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  reg_account: Reg_Accounts
}

/** aggregated selection of "login_account" */
export type Login_Account_Aggregate = {
  aggregate?: Maybe<Login_Account_Aggregate_Fields>
  nodes: Array<Login_Account>
}

export type Login_Account_Aggregate_Bool_Exp = {
  count?: InputMaybe<Login_Account_Aggregate_Bool_Exp_Count>
}

export type Login_Account_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Login_Account_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Login_Account_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "login_account" */
export type Login_Account_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Login_Account_Max_Fields>
  min?: Maybe<Login_Account_Min_Fields>
}

/** aggregate fields of "login_account" */
export type Login_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Login_Account_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "login_account" */
export type Login_Account_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Login_Account_Max_Order_By>
  min?: InputMaybe<Login_Account_Min_Order_By>
}

/** input type for inserting array relation for remote table "login_account" */
export type Login_Account_Arr_Rel_Insert_Input = {
  data: Array<Login_Account_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Login_Account_On_Conflict>
}

/** Boolean expression to filter rows from the table "login_account". All fields are combined with a logical 'AND'. */
export type Login_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Login_Account_Bool_Exp>>
  _not?: InputMaybe<Login_Account_Bool_Exp>
  _or?: InputMaybe<Array<Login_Account_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
}

/** unique or primary key constraints on table "login_account" */
export enum Login_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  LoginAccountPkey = 'login_account_pkey',
}

/** input type for inserting data into table "login_account" */
export type Login_Account_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Login_Account_Max_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "login_account" */
export type Login_Account_Max_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Login_Account_Min_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "login_account" */
export type Login_Account_Min_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "login_account" */
export type Login_Account_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Login_Account>
}

/** on_conflict condition type for table "login_account" */
export type Login_Account_On_Conflict = {
  constraint: Login_Account_Constraint
  update_columns?: Array<Login_Account_Update_Column>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

/** Ordering options when selecting data from "login_account". */
export type Login_Account_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
}

/** primary key columns input for table: login_account */
export type Login_Account_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "login_account" */
export enum Login_Account_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "login_account" */
export type Login_Account_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
}

/** Streaming cursor of the table "login_account" */
export type Login_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Login_Account_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Login_Account_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
}

/** update columns of table "login_account" */
export enum Login_Account_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
}

export type Login_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Login_Account_Set_Input>
  /** filter the rows which have to be updated */
  where: Login_Account_Bool_Exp
}

/** columns and relationships of "login_counts" */
export type Login_Counts = {
  accounts?: Maybe<Array<Scalars['String']>>
  date?: Maybe<Scalars['timestamptz']>
  login_count?: Maybe<Scalars['bigint']>
}

export type Login_Counts_Aggregate = {
  aggregate?: Maybe<Login_Counts_Aggregate_Fields>
  nodes: Array<Login_Counts>
}

/** aggregate fields of "login_counts" */
export type Login_Counts_Aggregate_Fields = {
  avg?: Maybe<Login_Counts_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Login_Counts_Max_Fields>
  min?: Maybe<Login_Counts_Min_Fields>
  stddev?: Maybe<Login_Counts_Stddev_Fields>
  stddev_pop?: Maybe<Login_Counts_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Login_Counts_Stddev_Samp_Fields>
  sum?: Maybe<Login_Counts_Sum_Fields>
  var_pop?: Maybe<Login_Counts_Var_Pop_Fields>
  var_samp?: Maybe<Login_Counts_Var_Samp_Fields>
  variance?: Maybe<Login_Counts_Variance_Fields>
}

/** aggregate fields of "login_counts" */
export type Login_Counts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Login_Counts_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Login_Counts_Avg_Fields = {
  login_count?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "login_counts". All fields are combined with a logical 'AND'. */
export type Login_Counts_Bool_Exp = {
  _and?: InputMaybe<Array<Login_Counts_Bool_Exp>>
  _not?: InputMaybe<Login_Counts_Bool_Exp>
  _or?: InputMaybe<Array<Login_Counts_Bool_Exp>>
  accounts?: InputMaybe<String_Array_Comparison_Exp>
  date?: InputMaybe<Timestamptz_Comparison_Exp>
  login_count?: InputMaybe<Bigint_Comparison_Exp>
}

/** input type for incrementing numeric columns in table "login_counts" */
export type Login_Counts_Inc_Input = {
  login_count?: InputMaybe<Scalars['bigint']>
}

/** input type for inserting data into table "login_counts" */
export type Login_Counts_Insert_Input = {
  accounts?: InputMaybe<Array<Scalars['String']>>
  date?: InputMaybe<Scalars['timestamptz']>
  login_count?: InputMaybe<Scalars['bigint']>
}

/** aggregate max on columns */
export type Login_Counts_Max_Fields = {
  accounts?: Maybe<Array<Scalars['String']>>
  date?: Maybe<Scalars['timestamptz']>
  login_count?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Login_Counts_Min_Fields = {
  accounts?: Maybe<Array<Scalars['String']>>
  date?: Maybe<Scalars['timestamptz']>
  login_count?: Maybe<Scalars['bigint']>
}

/** response of any mutation on the table "login_counts" */
export type Login_Counts_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Login_Counts>
}

/** Ordering options when selecting data from "login_counts". */
export type Login_Counts_Order_By = {
  accounts?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  login_count?: InputMaybe<Order_By>
}

/** select columns of table "login_counts" */
export enum Login_Counts_Select_Column {
  /** column name */
  Accounts = 'accounts',
  /** column name */
  Date = 'date',
  /** column name */
  LoginCount = 'login_count',
}

/** input type for updating data in table "login_counts" */
export type Login_Counts_Set_Input = {
  accounts?: InputMaybe<Array<Scalars['String']>>
  date?: InputMaybe<Scalars['timestamptz']>
  login_count?: InputMaybe<Scalars['bigint']>
}

/** aggregate stddev on columns */
export type Login_Counts_Stddev_Fields = {
  login_count?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Login_Counts_Stddev_Pop_Fields = {
  login_count?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Login_Counts_Stddev_Samp_Fields = {
  login_count?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "login_counts" */
export type Login_Counts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Login_Counts_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Login_Counts_Stream_Cursor_Value_Input = {
  accounts?: InputMaybe<Array<Scalars['String']>>
  date?: InputMaybe<Scalars['timestamptz']>
  login_count?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Login_Counts_Sum_Fields = {
  login_count?: Maybe<Scalars['bigint']>
}

export type Login_Counts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Login_Counts_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Login_Counts_Set_Input>
  /** filter the rows which have to be updated */
  where: Login_Counts_Bool_Exp
}

/** aggregate var_pop on columns */
export type Login_Counts_Var_Pop_Fields = {
  login_count?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Login_Counts_Var_Samp_Fields = {
  login_count?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Login_Counts_Variance_Fields = {
  login_count?: Maybe<Scalars['Float']>
}

export type Make_P2p_Buy_Offer_Input = {
  amount: Scalars['String']
  buyer: Scalars['String']
  buyer_method_details?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  method: Scalars['String']
  region: Scalars['String']
}

export type Make_P2p_Sell_Offer_Input = {
  amount: Scalars['String']
  bitcashbank_id: Scalars['String']
  id?: InputMaybe<Scalars['String']>
  method: Scalars['String']
  region: Scalars['String']
  sell_put_transaction: Scalars['String']
  seller: Scalars['String']
  seller_method_details?: InputMaybe<Scalars['String']>
}

export type Make_Wallet_Deposit_Request_Input = {
  amount: Scalars['String']
  buyer: Scalars['String']
  buyer_method_details?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  method: Scalars['String']
  region: Scalars['String']
}

export type Make_Wallet_Withdraw_Request_Input = {
  amount: Scalars['String']
  bitcashbank_id: Scalars['String']
  id?: InputMaybe<Scalars['String']>
  method: Scalars['String']
  region: Scalars['String']
  sell_put_transaction: Scalars['String']
  seller: Scalars['String']
  seller_method_details?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "messages" */
export type Messages = {
  from: Scalars['String']
  id: Scalars['uuid']
  message: Scalars['String']
  p2p_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  p2p_offer?: Maybe<P2p_Offers>
  /** An object relationship */
  regAccountByTo: Reg_Accounts
  /** An object relationship */
  reg_account: Reg_Accounts
  support_id?: Maybe<Scalars['uuid']>
  swap_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  swap_order?: Maybe<Swap_Orders>
  timestamp: Scalars['timestamptz']
  to: Scalars['String']
}

/** aggregated selection of "messages" */
export type Messages_Aggregate = {
  aggregate?: Maybe<Messages_Aggregate_Fields>
  nodes: Array<Messages>
}

export type Messages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Messages_Aggregate_Bool_Exp_Count>
}

export type Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Messages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Messages_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "messages" */
export type Messages_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Messages_Max_Fields>
  min?: Maybe<Messages_Min_Fields>
}

/** aggregate fields of "messages" */
export type Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Messages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "messages" */
export type Messages_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Messages_Max_Order_By>
  min?: InputMaybe<Messages_Min_Order_By>
}

/** input type for inserting array relation for remote table "messages" */
export type Messages_Arr_Rel_Insert_Input = {
  data: Array<Messages_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Messages_On_Conflict>
}

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Messages_Bool_Exp>>
  _not?: InputMaybe<Messages_Bool_Exp>
  _or?: InputMaybe<Array<Messages_Bool_Exp>>
  from?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  message?: InputMaybe<String_Comparison_Exp>
  p2p_id?: InputMaybe<Uuid_Comparison_Exp>
  p2p_offer?: InputMaybe<P2p_Offers_Bool_Exp>
  regAccountByTo?: InputMaybe<Reg_Accounts_Bool_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  support_id?: InputMaybe<Uuid_Comparison_Exp>
  swap_id?: InputMaybe<Uuid_Comparison_Exp>
  swap_order?: InputMaybe<Swap_Orders_Bool_Exp>
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>
  to?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "messages" */
export enum Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessagesPkey = 'messages_pkey',
}

/** input type for inserting data into table "messages" */
export type Messages_Insert_Input = {
  from?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  p2p_id?: InputMaybe<Scalars['uuid']>
  p2p_offer?: InputMaybe<P2p_Offers_Obj_Rel_Insert_Input>
  regAccountByTo?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  support_id?: InputMaybe<Scalars['uuid']>
  swap_id?: InputMaybe<Scalars['uuid']>
  swap_order?: InputMaybe<Swap_Orders_Obj_Rel_Insert_Input>
  timestamp?: InputMaybe<Scalars['timestamptz']>
  to?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Messages_Max_Fields = {
  from?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  p2p_id?: Maybe<Scalars['uuid']>
  support_id?: Maybe<Scalars['uuid']>
  swap_id?: Maybe<Scalars['uuid']>
  timestamp?: Maybe<Scalars['timestamptz']>
  to?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "messages" */
export type Messages_Max_Order_By = {
  from?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  p2p_id?: InputMaybe<Order_By>
  support_id?: InputMaybe<Order_By>
  swap_id?: InputMaybe<Order_By>
  timestamp?: InputMaybe<Order_By>
  to?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Messages_Min_Fields = {
  from?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  p2p_id?: Maybe<Scalars['uuid']>
  support_id?: Maybe<Scalars['uuid']>
  swap_id?: Maybe<Scalars['uuid']>
  timestamp?: Maybe<Scalars['timestamptz']>
  to?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "messages" */
export type Messages_Min_Order_By = {
  from?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  p2p_id?: InputMaybe<Order_By>
  support_id?: InputMaybe<Order_By>
  swap_id?: InputMaybe<Order_By>
  timestamp?: InputMaybe<Order_By>
  to?: InputMaybe<Order_By>
}

/** response of any mutation on the table "messages" */
export type Messages_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Messages>
}

/** on_conflict condition type for table "messages" */
export type Messages_On_Conflict = {
  constraint: Messages_Constraint
  update_columns?: Array<Messages_Update_Column>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** Ordering options when selecting data from "messages". */
export type Messages_Order_By = {
  from?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  p2p_id?: InputMaybe<Order_By>
  p2p_offer?: InputMaybe<P2p_Offers_Order_By>
  regAccountByTo?: InputMaybe<Reg_Accounts_Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  support_id?: InputMaybe<Order_By>
  swap_id?: InputMaybe<Order_By>
  swap_order?: InputMaybe<Swap_Orders_Order_By>
  timestamp?: InputMaybe<Order_By>
  to?: InputMaybe<Order_By>
}

/** primary key columns input for table: messages */
export type Messages_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "messages" */
export enum Messages_Select_Column {
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  P2pId = 'p2p_id',
  /** column name */
  SupportId = 'support_id',
  /** column name */
  SwapId = 'swap_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  To = 'to',
}

/** input type for updating data in table "messages" */
export type Messages_Set_Input = {
  from?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  p2p_id?: InputMaybe<Scalars['uuid']>
  support_id?: InputMaybe<Scalars['uuid']>
  swap_id?: InputMaybe<Scalars['uuid']>
  timestamp?: InputMaybe<Scalars['timestamptz']>
  to?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "messages" */
export type Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Messages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Messages_Stream_Cursor_Value_Input = {
  from?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  p2p_id?: InputMaybe<Scalars['uuid']>
  support_id?: InputMaybe<Scalars['uuid']>
  swap_id?: InputMaybe<Scalars['uuid']>
  timestamp?: InputMaybe<Scalars['timestamptz']>
  to?: InputMaybe<Scalars['String']>
}

/** update columns of table "messages" */
export enum Messages_Update_Column {
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  P2pId = 'p2p_id',
  /** column name */
  SupportId = 'support_id',
  /** column name */
  SwapId = 'swap_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  To = 'to',
}

export type Messages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Messages_Set_Input>
  /** filter the rows which have to be updated */
  where: Messages_Bool_Exp
}

/** columns and relationships of "migrate_device" */
export type Migrate_Device = {
  account: Scalars['String']
  created_at?: Maybe<Scalars['timestamptz']>
  cred_id: Scalars['String']
  device_name: Scalars['String']
  public_key: Scalars['String']
}

/** aggregated selection of "migrate_device" */
export type Migrate_Device_Aggregate = {
  aggregate?: Maybe<Migrate_Device_Aggregate_Fields>
  nodes: Array<Migrate_Device>
}

/** aggregate fields of "migrate_device" */
export type Migrate_Device_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Migrate_Device_Max_Fields>
  min?: Maybe<Migrate_Device_Min_Fields>
}

/** aggregate fields of "migrate_device" */
export type Migrate_Device_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Migrate_Device_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "migrate_device". All fields are combined with a logical 'AND'. */
export type Migrate_Device_Bool_Exp = {
  _and?: InputMaybe<Array<Migrate_Device_Bool_Exp>>
  _not?: InputMaybe<Migrate_Device_Bool_Exp>
  _or?: InputMaybe<Array<Migrate_Device_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  cred_id?: InputMaybe<String_Comparison_Exp>
  device_name?: InputMaybe<String_Comparison_Exp>
  public_key?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "migrate_device" */
export enum Migrate_Device_Constraint {
  /** unique or primary key constraint on columns "cred_id" */
  MigrateDevicePkey = 'migrate_device_pkey',
}

/** input type for inserting data into table "migrate_device" */
export type Migrate_Device_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  public_key?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Migrate_Device_Max_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  cred_id?: Maybe<Scalars['String']>
  device_name?: Maybe<Scalars['String']>
  public_key?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Migrate_Device_Min_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  cred_id?: Maybe<Scalars['String']>
  device_name?: Maybe<Scalars['String']>
  public_key?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "migrate_device" */
export type Migrate_Device_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Migrate_Device>
}

/** on_conflict condition type for table "migrate_device" */
export type Migrate_Device_On_Conflict = {
  constraint: Migrate_Device_Constraint
  update_columns?: Array<Migrate_Device_Update_Column>
  where?: InputMaybe<Migrate_Device_Bool_Exp>
}

/** Ordering options when selecting data from "migrate_device". */
export type Migrate_Device_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  cred_id?: InputMaybe<Order_By>
  device_name?: InputMaybe<Order_By>
  public_key?: InputMaybe<Order_By>
}

/** primary key columns input for table: migrate_device */
export type Migrate_Device_Pk_Columns_Input = {
  cred_id: Scalars['String']
}

/** select columns of table "migrate_device" */
export enum Migrate_Device_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key',
}

/** input type for updating data in table "migrate_device" */
export type Migrate_Device_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  public_key?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "migrate_device" */
export type Migrate_Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Migrate_Device_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Migrate_Device_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  public_key?: InputMaybe<Scalars['String']>
}

/** update columns of table "migrate_device" */
export enum Migrate_Device_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key',
}

export type Migrate_Device_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Migrate_Device_Set_Input>
  /** filter the rows which have to be updated */
  where: Migrate_Device_Bool_Exp
}

/** mutation root */
export type Mutation_Root = {
  approve_new_account?: Maybe<Scalars['String']>
  auto_cancel_p2p_offer?: Maybe<P2p_Offer_Output>
  cancel_p2p_approval?: Maybe<P2p_Offer_Output>
  cancel_p2p_offer?: Maybe<P2p_Offer_Output>
  cancel_wallet_request?: Maybe<Wallet_Request_Output>
  cancel_wallet_request_approval?: Maybe<Wallet_Request_Output>
  confirm_p2p_payment?: Maybe<P2p_Offer_Output>
  confirm_wallet_request_payment?: Maybe<Wallet_Request_Output>
  create_add_bk_account?: Maybe<Create_Add_Bk_Account_Output>
  /** delete data from the table: "accounts_information" */
  delete_accounts_information?: Maybe<Accounts_Information_Mutation_Response>
  /** delete single row from the table: "accounts_information" */
  delete_accounts_information_by_pk?: Maybe<Accounts_Information>
  /** delete data from the table: "accounts_referral" */
  delete_accounts_referral?: Maybe<Accounts_Referral_Mutation_Response>
  /** delete single row from the table: "accounts_referral" */
  delete_accounts_referral_by_pk?: Maybe<Accounts_Referral>
  /** delete data from the table: "actions" */
  delete_actions?: Maybe<Actions_Mutation_Response>
  /** delete single row from the table: "actions" */
  delete_actions_by_pk?: Maybe<Actions>
  /** delete data from the table: "devices" */
  delete_devices?: Maybe<Devices_Mutation_Response>
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: Maybe<Devices>
  /** delete data from the table: "login_account" */
  delete_login_account?: Maybe<Login_Account_Mutation_Response>
  /** delete single row from the table: "login_account" */
  delete_login_account_by_pk?: Maybe<Login_Account>
  /** delete data from the table: "login_counts" */
  delete_login_counts?: Maybe<Login_Counts_Mutation_Response>
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<Messages_Mutation_Response>
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<Messages>
  /** delete data from the table: "migrate_device" */
  delete_migrate_device?: Maybe<Migrate_Device_Mutation_Response>
  /** delete single row from the table: "migrate_device" */
  delete_migrate_device_by_pk?: Maybe<Migrate_Device>
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>
  /** delete data from the table: "p2p_offers" */
  delete_p2p_offers?: Maybe<P2p_Offers_Mutation_Response>
  /** delete single row from the table: "p2p_offers" */
  delete_p2p_offers_by_pk?: Maybe<P2p_Offers>
  /** delete data from the table: "p2p_pre_sell_offers" */
  delete_p2p_pre_sell_offers?: Maybe<P2p_Pre_Sell_Offers_Mutation_Response>
  /** delete single row from the table: "p2p_pre_sell_offers" */
  delete_p2p_pre_sell_offers_by_pk?: Maybe<P2p_Pre_Sell_Offers>
  /** delete data from the table: "preferences" */
  delete_preferences?: Maybe<Preferences_Mutation_Response>
  /** delete single row from the table: "preferences" */
  delete_preferences_by_pk?: Maybe<Preferences>
  /** delete data from the table: "reg_accounts" */
  delete_reg_accounts?: Maybe<Reg_Accounts_Mutation_Response>
  /** delete single row from the table: "reg_accounts" */
  delete_reg_accounts_by_pk?: Maybe<Reg_Accounts>
  /** delete data from the table: "reputation_score" */
  delete_reputation_score?: Maybe<Reputation_Score_Mutation_Response>
  /** delete single row from the table: "reputation_score" */
  delete_reputation_score_by_pk?: Maybe<Reputation_Score>
  /** delete data from the table: "signing_requests" */
  delete_signing_requests?: Maybe<Signing_Requests_Mutation_Response>
  /** delete single row from the table: "signing_requests" */
  delete_signing_requests_by_pk?: Maybe<Signing_Requests>
  /** delete data from the table: "support_sessions" */
  delete_support_sessions?: Maybe<Support_Sessions_Mutation_Response>
  /** delete single row from the table: "support_sessions" */
  delete_support_sessions_by_pk?: Maybe<Support_Sessions>
  /** delete data from the table: "swap_assets" */
  delete_swap_assets?: Maybe<Swap_Assets_Mutation_Response>
  /** delete single row from the table: "swap_assets" */
  delete_swap_assets_by_pk?: Maybe<Swap_Assets>
  /** delete data from the table: "swap_orders" */
  delete_swap_orders?: Maybe<Swap_Orders_Mutation_Response>
  /** delete single row from the table: "swap_orders" */
  delete_swap_orders_by_pk?: Maybe<Swap_Orders>
  /** delete data from the table: "system_notification_read_status" */
  delete_system_notification_read_status?: Maybe<System_Notification_Read_Status_Mutation_Response>
  /** delete single row from the table: "system_notification_read_status" */
  delete_system_notification_read_status_by_pk?: Maybe<System_Notification_Read_Status>
  /** delete data from the table: "system_notifications" */
  delete_system_notifications?: Maybe<System_Notifications_Mutation_Response>
  /** delete single row from the table: "system_notifications" */
  delete_system_notifications_by_pk?: Maybe<System_Notifications>
  /** delete data from the table: "system_type_notification" */
  delete_system_type_notification?: Maybe<System_Type_Notification_Mutation_Response>
  /** delete single row from the table: "system_type_notification" */
  delete_system_type_notification_by_pk?: Maybe<System_Type_Notification>
  /** delete data from the table: "table_rows" */
  delete_table_rows?: Maybe<Table_Rows_Mutation_Response>
  /** delete single row from the table: "table_rows" */
  delete_table_rows_by_pk?: Maybe<Table_Rows>
  /** delete data from the table: "trust_network" */
  delete_trust_network?: Maybe<Trust_Network_Mutation_Response>
  /** delete single row from the table: "trust_network" */
  delete_trust_network_by_pk?: Maybe<Trust_Network>
  /** delete data from the table: "trust_network_notification" */
  delete_trust_network_notification?: Maybe<Trust_Network_Notification_Mutation_Response>
  /** delete single row from the table: "trust_network_notification" */
  delete_trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>
  /** delete data from the table: "trust_network_status" */
  delete_trust_network_status?: Maybe<Trust_Network_Status_Mutation_Response>
  /** delete single row from the table: "trust_network_status" */
  delete_trust_network_status_by_pk?: Maybe<Trust_Network_Status>
  /** delete data from the table: "tx_statistics" */
  delete_tx_statistics?: Maybe<Tx_Statistics_Mutation_Response>
  /** delete data from the table: "volume_statistics" */
  delete_volume_statistics?: Maybe<Volume_Statistics_Mutation_Response>
  /** delete data from the table: "web_push" */
  delete_web_push?: Maybe<Web_Push_Mutation_Response>
  /** delete single row from the table: "web_push" */
  delete_web_push_by_pk?: Maybe<Web_Push>
  generate_short_link: Scalars['String']
  /** execute VOLATILE function "get_tx_statistics" which returns "tx_statistics" */
  get_tx_statistics: Array<Tx_Statistics>
  /** execute VOLATILE function "get_volume_statistics" which returns "volume_statistics" */
  get_volume_statistics: Array<Volume_Statistics>
  /** insert data into the table: "accounts_information" */
  insert_accounts_information?: Maybe<Accounts_Information_Mutation_Response>
  /** insert a single row into the table: "accounts_information" */
  insert_accounts_information_one?: Maybe<Accounts_Information>
  /** insert data into the table: "accounts_referral" */
  insert_accounts_referral?: Maybe<Accounts_Referral_Mutation_Response>
  /** insert a single row into the table: "accounts_referral" */
  insert_accounts_referral_one?: Maybe<Accounts_Referral>
  /** insert data into the table: "actions" */
  insert_actions?: Maybe<Actions_Mutation_Response>
  /** insert a single row into the table: "actions" */
  insert_actions_one?: Maybe<Actions>
  /** insert data into the table: "devices" */
  insert_devices?: Maybe<Devices_Mutation_Response>
  /** insert a single row into the table: "devices" */
  insert_devices_one?: Maybe<Devices>
  /** insert data into the table: "login_account" */
  insert_login_account?: Maybe<Login_Account_Mutation_Response>
  /** insert a single row into the table: "login_account" */
  insert_login_account_one?: Maybe<Login_Account>
  /** insert data into the table: "login_counts" */
  insert_login_counts?: Maybe<Login_Counts_Mutation_Response>
  /** insert a single row into the table: "login_counts" */
  insert_login_counts_one?: Maybe<Login_Counts>
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<Messages_Mutation_Response>
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<Messages>
  /** insert data into the table: "migrate_device" */
  insert_migrate_device?: Maybe<Migrate_Device_Mutation_Response>
  /** insert a single row into the table: "migrate_device" */
  insert_migrate_device_one?: Maybe<Migrate_Device>
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>
  /** insert data into the table: "p2p_offers" */
  insert_p2p_offers?: Maybe<P2p_Offers_Mutation_Response>
  /** insert a single row into the table: "p2p_offers" */
  insert_p2p_offers_one?: Maybe<P2p_Offers>
  /** insert data into the table: "p2p_pre_sell_offers" */
  insert_p2p_pre_sell_offers?: Maybe<P2p_Pre_Sell_Offers_Mutation_Response>
  /** insert a single row into the table: "p2p_pre_sell_offers" */
  insert_p2p_pre_sell_offers_one?: Maybe<P2p_Pre_Sell_Offers>
  /** insert data into the table: "preferences" */
  insert_preferences?: Maybe<Preferences_Mutation_Response>
  /** insert a single row into the table: "preferences" */
  insert_preferences_one?: Maybe<Preferences>
  /** insert data into the table: "reg_accounts" */
  insert_reg_accounts?: Maybe<Reg_Accounts_Mutation_Response>
  /** insert a single row into the table: "reg_accounts" */
  insert_reg_accounts_one?: Maybe<Reg_Accounts>
  /** insert data into the table: "reputation_score" */
  insert_reputation_score?: Maybe<Reputation_Score_Mutation_Response>
  /** insert a single row into the table: "reputation_score" */
  insert_reputation_score_one?: Maybe<Reputation_Score>
  /** insert data into the table: "signing_requests" */
  insert_signing_requests?: Maybe<Signing_Requests_Mutation_Response>
  /** insert a single row into the table: "signing_requests" */
  insert_signing_requests_one?: Maybe<Signing_Requests>
  /** insert data into the table: "support_sessions" */
  insert_support_sessions?: Maybe<Support_Sessions_Mutation_Response>
  /** insert a single row into the table: "support_sessions" */
  insert_support_sessions_one?: Maybe<Support_Sessions>
  /** insert data into the table: "swap_assets" */
  insert_swap_assets?: Maybe<Swap_Assets_Mutation_Response>
  /** insert a single row into the table: "swap_assets" */
  insert_swap_assets_one?: Maybe<Swap_Assets>
  /** insert data into the table: "swap_orders" */
  insert_swap_orders?: Maybe<Swap_Orders_Mutation_Response>
  /** insert a single row into the table: "swap_orders" */
  insert_swap_orders_one?: Maybe<Swap_Orders>
  /** insert data into the table: "system_notification_read_status" */
  insert_system_notification_read_status?: Maybe<System_Notification_Read_Status_Mutation_Response>
  /** insert a single row into the table: "system_notification_read_status" */
  insert_system_notification_read_status_one?: Maybe<System_Notification_Read_Status>
  /** insert data into the table: "system_notifications" */
  insert_system_notifications?: Maybe<System_Notifications_Mutation_Response>
  /** insert a single row into the table: "system_notifications" */
  insert_system_notifications_one?: Maybe<System_Notifications>
  /** insert data into the table: "system_type_notification" */
  insert_system_type_notification?: Maybe<System_Type_Notification_Mutation_Response>
  /** insert a single row into the table: "system_type_notification" */
  insert_system_type_notification_one?: Maybe<System_Type_Notification>
  /** insert data into the table: "table_rows" */
  insert_table_rows?: Maybe<Table_Rows_Mutation_Response>
  /** insert a single row into the table: "table_rows" */
  insert_table_rows_one?: Maybe<Table_Rows>
  /** insert data into the table: "trust_network" */
  insert_trust_network?: Maybe<Trust_Network_Mutation_Response>
  /** insert data into the table: "trust_network_notification" */
  insert_trust_network_notification?: Maybe<Trust_Network_Notification_Mutation_Response>
  /** insert a single row into the table: "trust_network_notification" */
  insert_trust_network_notification_one?: Maybe<Trust_Network_Notification>
  /** insert a single row into the table: "trust_network" */
  insert_trust_network_one?: Maybe<Trust_Network>
  /** insert data into the table: "trust_network_status" */
  insert_trust_network_status?: Maybe<Trust_Network_Status_Mutation_Response>
  /** insert a single row into the table: "trust_network_status" */
  insert_trust_network_status_one?: Maybe<Trust_Network_Status>
  /** insert data into the table: "tx_statistics" */
  insert_tx_statistics?: Maybe<Tx_Statistics_Mutation_Response>
  /** insert a single row into the table: "tx_statistics" */
  insert_tx_statistics_one?: Maybe<Tx_Statistics>
  /** insert data into the table: "volume_statistics" */
  insert_volume_statistics?: Maybe<Volume_Statistics_Mutation_Response>
  /** insert a single row into the table: "volume_statistics" */
  insert_volume_statistics_one?: Maybe<Volume_Statistics>
  /** insert data into the table: "web_push" */
  insert_web_push?: Maybe<Web_Push_Mutation_Response>
  /** insert a single row into the table: "web_push" */
  insert_web_push_one?: Maybe<Web_Push>
  make_p2p_buy_offer?: Maybe<P2p_Offer_Output>
  make_p2p_sell_offer?: Maybe<P2p_Offer_Output>
  make_wallet_deposit_request?: Maybe<Wallet_Request_Output>
  make_wallet_withdraw_request?: Maybe<Wallet_Request_Output>
  reject_new_account?: Maybe<Scalars['String']>
  request_new_account?: Maybe<Request_New_Account_Output>
  reserve_offer?: Maybe<Scalars['Boolean']>
  retry_account_kyc?: Maybe<Scalars['Boolean']>
  send_email_notification?: Maybe<Send_Email_Notification_Output>
  send_push_notification: Scalars['Boolean']
  store_ip_address?: Maybe<Store_Ip_Address_Output>
  subscribe_push_notification: Scalars['Boolean']
  toggle_trust_network?: Maybe<Toggle_Trust_Network_Output>
  unreserve_offer?: Maybe<Scalars['Boolean']>
  /** update data of the table: "accounts_information" */
  update_accounts_information?: Maybe<Accounts_Information_Mutation_Response>
  /** update single row of the table: "accounts_information" */
  update_accounts_information_by_pk?: Maybe<Accounts_Information>
  /** update multiples rows of table: "accounts_information" */
  update_accounts_information_many?: Maybe<Array<Maybe<Accounts_Information_Mutation_Response>>>
  /** update data of the table: "accounts_referral" */
  update_accounts_referral?: Maybe<Accounts_Referral_Mutation_Response>
  /** update single row of the table: "accounts_referral" */
  update_accounts_referral_by_pk?: Maybe<Accounts_Referral>
  /** update multiples rows of table: "accounts_referral" */
  update_accounts_referral_many?: Maybe<Array<Maybe<Accounts_Referral_Mutation_Response>>>
  /** update data of the table: "actions" */
  update_actions?: Maybe<Actions_Mutation_Response>
  /** update single row of the table: "actions" */
  update_actions_by_pk?: Maybe<Actions>
  /** update multiples rows of table: "actions" */
  update_actions_many?: Maybe<Array<Maybe<Actions_Mutation_Response>>>
  /** update data of the table: "devices" */
  update_devices?: Maybe<Devices_Mutation_Response>
  /** update single row of the table: "devices" */
  update_devices_by_pk?: Maybe<Devices>
  /** update multiples rows of table: "devices" */
  update_devices_many?: Maybe<Array<Maybe<Devices_Mutation_Response>>>
  /** update data of the table: "login_account" */
  update_login_account?: Maybe<Login_Account_Mutation_Response>
  /** update single row of the table: "login_account" */
  update_login_account_by_pk?: Maybe<Login_Account>
  /** update multiples rows of table: "login_account" */
  update_login_account_many?: Maybe<Array<Maybe<Login_Account_Mutation_Response>>>
  /** update data of the table: "login_counts" */
  update_login_counts?: Maybe<Login_Counts_Mutation_Response>
  /** update multiples rows of table: "login_counts" */
  update_login_counts_many?: Maybe<Array<Maybe<Login_Counts_Mutation_Response>>>
  /** update data of the table: "messages" */
  update_messages?: Maybe<Messages_Mutation_Response>
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<Messages>
  /** update multiples rows of table: "messages" */
  update_messages_many?: Maybe<Array<Maybe<Messages_Mutation_Response>>>
  /** update data of the table: "migrate_device" */
  update_migrate_device?: Maybe<Migrate_Device_Mutation_Response>
  /** update single row of the table: "migrate_device" */
  update_migrate_device_by_pk?: Maybe<Migrate_Device>
  /** update multiples rows of table: "migrate_device" */
  update_migrate_device_many?: Maybe<Array<Maybe<Migrate_Device_Mutation_Response>>>
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>
  /** update data of the table: "p2p_offers" */
  update_p2p_offers?: Maybe<P2p_Offers_Mutation_Response>
  /** update single row of the table: "p2p_offers" */
  update_p2p_offers_by_pk?: Maybe<P2p_Offers>
  /** update multiples rows of table: "p2p_offers" */
  update_p2p_offers_many?: Maybe<Array<Maybe<P2p_Offers_Mutation_Response>>>
  /** update data of the table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers?: Maybe<P2p_Pre_Sell_Offers_Mutation_Response>
  /** update single row of the table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers_by_pk?: Maybe<P2p_Pre_Sell_Offers>
  /** update multiples rows of table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers_many?: Maybe<Array<Maybe<P2p_Pre_Sell_Offers_Mutation_Response>>>
  /** update data of the table: "preferences" */
  update_preferences?: Maybe<Preferences_Mutation_Response>
  /** update single row of the table: "preferences" */
  update_preferences_by_pk?: Maybe<Preferences>
  /** update multiples rows of table: "preferences" */
  update_preferences_many?: Maybe<Array<Maybe<Preferences_Mutation_Response>>>
  /** update data of the table: "reg_accounts" */
  update_reg_accounts?: Maybe<Reg_Accounts_Mutation_Response>
  /** update single row of the table: "reg_accounts" */
  update_reg_accounts_by_pk?: Maybe<Reg_Accounts>
  /** update multiples rows of table: "reg_accounts" */
  update_reg_accounts_many?: Maybe<Array<Maybe<Reg_Accounts_Mutation_Response>>>
  /** update data of the table: "reputation_score" */
  update_reputation_score?: Maybe<Reputation_Score_Mutation_Response>
  /** update single row of the table: "reputation_score" */
  update_reputation_score_by_pk?: Maybe<Reputation_Score>
  /** update multiples rows of table: "reputation_score" */
  update_reputation_score_many?: Maybe<Array<Maybe<Reputation_Score_Mutation_Response>>>
  /** update data of the table: "signing_requests" */
  update_signing_requests?: Maybe<Signing_Requests_Mutation_Response>
  /** update single row of the table: "signing_requests" */
  update_signing_requests_by_pk?: Maybe<Signing_Requests>
  /** update multiples rows of table: "signing_requests" */
  update_signing_requests_many?: Maybe<Array<Maybe<Signing_Requests_Mutation_Response>>>
  /** update data of the table: "support_sessions" */
  update_support_sessions?: Maybe<Support_Sessions_Mutation_Response>
  /** update single row of the table: "support_sessions" */
  update_support_sessions_by_pk?: Maybe<Support_Sessions>
  /** update multiples rows of table: "support_sessions" */
  update_support_sessions_many?: Maybe<Array<Maybe<Support_Sessions_Mutation_Response>>>
  /** update data of the table: "swap_assets" */
  update_swap_assets?: Maybe<Swap_Assets_Mutation_Response>
  /** update single row of the table: "swap_assets" */
  update_swap_assets_by_pk?: Maybe<Swap_Assets>
  /** update multiples rows of table: "swap_assets" */
  update_swap_assets_many?: Maybe<Array<Maybe<Swap_Assets_Mutation_Response>>>
  /** update data of the table: "swap_orders" */
  update_swap_orders?: Maybe<Swap_Orders_Mutation_Response>
  /** update single row of the table: "swap_orders" */
  update_swap_orders_by_pk?: Maybe<Swap_Orders>
  /** update multiples rows of table: "swap_orders" */
  update_swap_orders_many?: Maybe<Array<Maybe<Swap_Orders_Mutation_Response>>>
  /** update data of the table: "system_notification_read_status" */
  update_system_notification_read_status?: Maybe<System_Notification_Read_Status_Mutation_Response>
  /** update single row of the table: "system_notification_read_status" */
  update_system_notification_read_status_by_pk?: Maybe<System_Notification_Read_Status>
  /** update multiples rows of table: "system_notification_read_status" */
  update_system_notification_read_status_many?: Maybe<
    Array<Maybe<System_Notification_Read_Status_Mutation_Response>>
  >
  /** update data of the table: "system_notifications" */
  update_system_notifications?: Maybe<System_Notifications_Mutation_Response>
  /** update single row of the table: "system_notifications" */
  update_system_notifications_by_pk?: Maybe<System_Notifications>
  /** update multiples rows of table: "system_notifications" */
  update_system_notifications_many?: Maybe<Array<Maybe<System_Notifications_Mutation_Response>>>
  /** update data of the table: "system_type_notification" */
  update_system_type_notification?: Maybe<System_Type_Notification_Mutation_Response>
  /** update single row of the table: "system_type_notification" */
  update_system_type_notification_by_pk?: Maybe<System_Type_Notification>
  /** update multiples rows of table: "system_type_notification" */
  update_system_type_notification_many?: Maybe<
    Array<Maybe<System_Type_Notification_Mutation_Response>>
  >
  /** update data of the table: "table_rows" */
  update_table_rows?: Maybe<Table_Rows_Mutation_Response>
  /** update single row of the table: "table_rows" */
  update_table_rows_by_pk?: Maybe<Table_Rows>
  /** update multiples rows of table: "table_rows" */
  update_table_rows_many?: Maybe<Array<Maybe<Table_Rows_Mutation_Response>>>
  /** update data of the table: "trust_network" */
  update_trust_network?: Maybe<Trust_Network_Mutation_Response>
  /** update single row of the table: "trust_network" */
  update_trust_network_by_pk?: Maybe<Trust_Network>
  /** update multiples rows of table: "trust_network" */
  update_trust_network_many?: Maybe<Array<Maybe<Trust_Network_Mutation_Response>>>
  /** update data of the table: "trust_network_notification" */
  update_trust_network_notification?: Maybe<Trust_Network_Notification_Mutation_Response>
  /** update single row of the table: "trust_network_notification" */
  update_trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>
  /** update multiples rows of table: "trust_network_notification" */
  update_trust_network_notification_many?: Maybe<
    Array<Maybe<Trust_Network_Notification_Mutation_Response>>
  >
  /** update data of the table: "trust_network_status" */
  update_trust_network_status?: Maybe<Trust_Network_Status_Mutation_Response>
  /** update single row of the table: "trust_network_status" */
  update_trust_network_status_by_pk?: Maybe<Trust_Network_Status>
  /** update multiples rows of table: "trust_network_status" */
  update_trust_network_status_many?: Maybe<Array<Maybe<Trust_Network_Status_Mutation_Response>>>
  /** update data of the table: "tx_statistics" */
  update_tx_statistics?: Maybe<Tx_Statistics_Mutation_Response>
  /** update multiples rows of table: "tx_statistics" */
  update_tx_statistics_many?: Maybe<Array<Maybe<Tx_Statistics_Mutation_Response>>>
  /** update data of the table: "volume_statistics" */
  update_volume_statistics?: Maybe<Volume_Statistics_Mutation_Response>
  /** update multiples rows of table: "volume_statistics" */
  update_volume_statistics_many?: Maybe<Array<Maybe<Volume_Statistics_Mutation_Response>>>
  /** update data of the table: "web_push" */
  update_web_push?: Maybe<Web_Push_Mutation_Response>
  /** update single row of the table: "web_push" */
  update_web_push_by_pk?: Maybe<Web_Push>
  /** update multiples rows of table: "web_push" */
  update_web_push_many?: Maybe<Array<Maybe<Web_Push_Mutation_Response>>>
  upload_image?: Maybe<Upload_Image_Output>
  validate_short_link?: Maybe<Validate_Short_Link_Output>
}

/** mutation root */
export type Mutation_RootApprove_New_AccountArgs = {
  is_verified?: InputMaybe<Scalars['Boolean']>
  request_id?: InputMaybe<Scalars['String']>
}

/** mutation root */
export type Mutation_RootAuto_Cancel_P2p_OfferArgs = {
  p2p_data?: InputMaybe<Cancel_P2p_Offer_Input>
}

/** mutation root */
export type Mutation_RootCancel_P2p_ApprovalArgs = {
  p2p_data?: InputMaybe<Cancel_P2p_Approval_Input>
}

/** mutation root */
export type Mutation_RootCancel_P2p_OfferArgs = {
  p2p_data?: InputMaybe<Cancel_P2p_Offer_Input>
}

/** mutation root */
export type Mutation_RootCancel_Wallet_RequestArgs = {
  p2p_data?: InputMaybe<Cancel_Wallet_Request_Input>
}

/** mutation root */
export type Mutation_RootCancel_Wallet_Request_ApprovalArgs = {
  p2p_data?: InputMaybe<Cancel_Wallet_Request_Approval_Input>
}

/** mutation root */
export type Mutation_RootConfirm_P2p_PaymentArgs = {
  p2p_data?: InputMaybe<Confirm_P2p_Offer_Input>
}

/** mutation root */
export type Mutation_RootConfirm_Wallet_Request_PaymentArgs = {
  p2p_data?: InputMaybe<Confirm_Wallet_Request_Input>
}

/** mutation root */
export type Mutation_RootCreate_Add_Bk_AccountArgs = {
  create_add_bk_account_input: Create_Add_Bk_Account_Input
}

/** mutation root */
export type Mutation_RootDelete_Accounts_InformationArgs = {
  where: Accounts_Information_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Accounts_Information_By_PkArgs = {
  account: Scalars['String']
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Accounts_ReferralArgs = {
  where: Accounts_Referral_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Accounts_Referral_By_PkArgs = {
  link: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_ActionsArgs = {
  where: Actions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Actions_By_PkArgs = {
  global_sequence: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_DevicesArgs = {
  where: Devices_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Devices_By_PkArgs = {
  cred_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Login_AccountArgs = {
  where: Login_Account_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Login_Account_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Login_CountsArgs = {
  where: Login_Counts_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
  where: Messages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Migrate_DeviceArgs = {
  where: Migrate_Device_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Migrate_Device_By_PkArgs = {
  cred_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_P2p_OffersArgs = {
  where: P2p_Offers_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P2p_Offers_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_P2p_Pre_Sell_OffersArgs = {
  where: P2p_Pre_Sell_Offers_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P2p_Pre_Sell_Offers_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_PreferencesArgs = {
  where: Preferences_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Preferences_By_PkArgs = {
  account: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Reg_AccountsArgs = {
  where: Reg_Accounts_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Reg_Accounts_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Reputation_ScoreArgs = {
  where: Reputation_Score_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Reputation_Score_By_PkArgs = {
  account: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Signing_RequestsArgs = {
  where: Signing_Requests_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Signing_Requests_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Support_SessionsArgs = {
  where: Support_Sessions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Support_Sessions_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Swap_AssetsArgs = {
  where: Swap_Assets_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Swap_Assets_By_PkArgs = {
  asset: Scalars['String']
  chain: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Swap_OrdersArgs = {
  where: Swap_Orders_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Swap_Orders_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_System_Notification_Read_StatusArgs = {
  where: System_Notification_Read_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_System_Notification_Read_Status_By_PkArgs = {
  notification_id: Scalars['uuid']
  reg_account: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_System_NotificationsArgs = {
  where: System_Notifications_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_System_Notifications_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_System_Type_NotificationArgs = {
  where: System_Type_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_System_Type_Notification_By_PkArgs = {
  type: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Table_RowsArgs = {
  where: Table_Rows_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Table_Rows_By_PkArgs = {
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Trust_NetworkArgs = {
  where: Trust_Network_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Trust_Network_By_PkArgs = {
  account: Scalars['String']
  trust: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Trust_Network_NotificationArgs = {
  where: Trust_Network_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Trust_Network_Notification_By_PkArgs = {
  account: Scalars['String']
  trust: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Trust_Network_StatusArgs = {
  where: Trust_Network_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Trust_Network_Status_By_PkArgs = {
  status: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Tx_StatisticsArgs = {
  where: Tx_Statistics_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Volume_StatisticsArgs = {
  where: Volume_Statistics_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Web_PushArgs = {
  where: Web_Push_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Web_Push_By_PkArgs = {
  auth: Scalars['String']
  p256dh: Scalars['String']
}

/** mutation root */
export type Mutation_RootGenerate_Short_LinkArgs = {
  short_link_input: Short_Link_Input
}

/** mutation root */
export type Mutation_RootGet_Tx_StatisticsArgs = {
  args: Get_Tx_Statistics_Args
  distinct_on?: InputMaybe<Array<Tx_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tx_Statistics_Order_By>>
  where?: InputMaybe<Tx_Statistics_Bool_Exp>
}

/** mutation root */
export type Mutation_RootGet_Volume_StatisticsArgs = {
  args: Get_Volume_Statistics_Args
  distinct_on?: InputMaybe<Array<Volume_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Volume_Statistics_Order_By>>
  where?: InputMaybe<Volume_Statistics_Bool_Exp>
}

/** mutation root */
export type Mutation_RootInsert_Accounts_InformationArgs = {
  objects: Array<Accounts_Information_Insert_Input>
  on_conflict?: InputMaybe<Accounts_Information_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Accounts_Information_OneArgs = {
  object: Accounts_Information_Insert_Input
  on_conflict?: InputMaybe<Accounts_Information_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Accounts_ReferralArgs = {
  objects: Array<Accounts_Referral_Insert_Input>
  on_conflict?: InputMaybe<Accounts_Referral_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Accounts_Referral_OneArgs = {
  object: Accounts_Referral_Insert_Input
  on_conflict?: InputMaybe<Accounts_Referral_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ActionsArgs = {
  objects: Array<Actions_Insert_Input>
  on_conflict?: InputMaybe<Actions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Actions_OneArgs = {
  object: Actions_Insert_Input
  on_conflict?: InputMaybe<Actions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_DevicesArgs = {
  objects: Array<Devices_Insert_Input>
  on_conflict?: InputMaybe<Devices_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Devices_OneArgs = {
  object: Devices_Insert_Input
  on_conflict?: InputMaybe<Devices_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Login_AccountArgs = {
  objects: Array<Login_Account_Insert_Input>
  on_conflict?: InputMaybe<Login_Account_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Login_Account_OneArgs = {
  object: Login_Account_Insert_Input
  on_conflict?: InputMaybe<Login_Account_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Login_CountsArgs = {
  objects: Array<Login_Counts_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_Login_Counts_OneArgs = {
  object: Login_Counts_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
  objects: Array<Messages_Insert_Input>
  on_conflict?: InputMaybe<Messages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
  object: Messages_Insert_Input
  on_conflict?: InputMaybe<Messages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Migrate_DeviceArgs = {
  objects: Array<Migrate_Device_Insert_Input>
  on_conflict?: InputMaybe<Migrate_Device_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Migrate_Device_OneArgs = {
  object: Migrate_Device_Insert_Input
  on_conflict?: InputMaybe<Migrate_Device_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>
  on_conflict?: InputMaybe<Notifications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input
  on_conflict?: InputMaybe<Notifications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P2p_OffersArgs = {
  objects: Array<P2p_Offers_Insert_Input>
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P2p_Offers_OneArgs = {
  object: P2p_Offers_Insert_Input
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P2p_Pre_Sell_OffersArgs = {
  objects: Array<P2p_Pre_Sell_Offers_Insert_Input>
  on_conflict?: InputMaybe<P2p_Pre_Sell_Offers_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P2p_Pre_Sell_Offers_OneArgs = {
  object: P2p_Pre_Sell_Offers_Insert_Input
  on_conflict?: InputMaybe<P2p_Pre_Sell_Offers_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PreferencesArgs = {
  objects: Array<Preferences_Insert_Input>
  on_conflict?: InputMaybe<Preferences_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Preferences_OneArgs = {
  object: Preferences_Insert_Input
  on_conflict?: InputMaybe<Preferences_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Reg_AccountsArgs = {
  objects: Array<Reg_Accounts_Insert_Input>
  on_conflict?: InputMaybe<Reg_Accounts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Reg_Accounts_OneArgs = {
  object: Reg_Accounts_Insert_Input
  on_conflict?: InputMaybe<Reg_Accounts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Reputation_ScoreArgs = {
  objects: Array<Reputation_Score_Insert_Input>
  on_conflict?: InputMaybe<Reputation_Score_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Reputation_Score_OneArgs = {
  object: Reputation_Score_Insert_Input
  on_conflict?: InputMaybe<Reputation_Score_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Signing_RequestsArgs = {
  objects: Array<Signing_Requests_Insert_Input>
  on_conflict?: InputMaybe<Signing_Requests_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Signing_Requests_OneArgs = {
  object: Signing_Requests_Insert_Input
  on_conflict?: InputMaybe<Signing_Requests_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_SessionsArgs = {
  objects: Array<Support_Sessions_Insert_Input>
  on_conflict?: InputMaybe<Support_Sessions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Sessions_OneArgs = {
  object: Support_Sessions_Insert_Input
  on_conflict?: InputMaybe<Support_Sessions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Swap_AssetsArgs = {
  objects: Array<Swap_Assets_Insert_Input>
  on_conflict?: InputMaybe<Swap_Assets_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Swap_Assets_OneArgs = {
  object: Swap_Assets_Insert_Input
  on_conflict?: InputMaybe<Swap_Assets_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Swap_OrdersArgs = {
  objects: Array<Swap_Orders_Insert_Input>
  on_conflict?: InputMaybe<Swap_Orders_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Swap_Orders_OneArgs = {
  object: Swap_Orders_Insert_Input
  on_conflict?: InputMaybe<Swap_Orders_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_System_Notification_Read_StatusArgs = {
  objects: Array<System_Notification_Read_Status_Insert_Input>
  on_conflict?: InputMaybe<System_Notification_Read_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_System_Notification_Read_Status_OneArgs = {
  object: System_Notification_Read_Status_Insert_Input
  on_conflict?: InputMaybe<System_Notification_Read_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_System_NotificationsArgs = {
  objects: Array<System_Notifications_Insert_Input>
  on_conflict?: InputMaybe<System_Notifications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_System_Notifications_OneArgs = {
  object: System_Notifications_Insert_Input
  on_conflict?: InputMaybe<System_Notifications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_System_Type_NotificationArgs = {
  objects: Array<System_Type_Notification_Insert_Input>
  on_conflict?: InputMaybe<System_Type_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_System_Type_Notification_OneArgs = {
  object: System_Type_Notification_Insert_Input
  on_conflict?: InputMaybe<System_Type_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Table_RowsArgs = {
  objects: Array<Table_Rows_Insert_Input>
  on_conflict?: InputMaybe<Table_Rows_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Table_Rows_OneArgs = {
  object: Table_Rows_Insert_Input
  on_conflict?: InputMaybe<Table_Rows_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Trust_NetworkArgs = {
  objects: Array<Trust_Network_Insert_Input>
  on_conflict?: InputMaybe<Trust_Network_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Trust_Network_NotificationArgs = {
  objects: Array<Trust_Network_Notification_Insert_Input>
  on_conflict?: InputMaybe<Trust_Network_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Trust_Network_Notification_OneArgs = {
  object: Trust_Network_Notification_Insert_Input
  on_conflict?: InputMaybe<Trust_Network_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Trust_Network_OneArgs = {
  object: Trust_Network_Insert_Input
  on_conflict?: InputMaybe<Trust_Network_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Trust_Network_StatusArgs = {
  objects: Array<Trust_Network_Status_Insert_Input>
  on_conflict?: InputMaybe<Trust_Network_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Trust_Network_Status_OneArgs = {
  object: Trust_Network_Status_Insert_Input
  on_conflict?: InputMaybe<Trust_Network_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tx_StatisticsArgs = {
  objects: Array<Tx_Statistics_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_Tx_Statistics_OneArgs = {
  object: Tx_Statistics_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_Volume_StatisticsArgs = {
  objects: Array<Volume_Statistics_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_Volume_Statistics_OneArgs = {
  object: Volume_Statistics_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_Web_PushArgs = {
  objects: Array<Web_Push_Insert_Input>
  on_conflict?: InputMaybe<Web_Push_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Web_Push_OneArgs = {
  object: Web_Push_Insert_Input
  on_conflict?: InputMaybe<Web_Push_On_Conflict>
}

/** mutation root */
export type Mutation_RootMake_P2p_Buy_OfferArgs = {
  offer?: InputMaybe<Make_P2p_Buy_Offer_Input>
}

/** mutation root */
export type Mutation_RootMake_P2p_Sell_OfferArgs = {
  offer?: InputMaybe<Make_P2p_Sell_Offer_Input>
}

/** mutation root */
export type Mutation_RootMake_Wallet_Deposit_RequestArgs = {
  offer?: InputMaybe<Make_Wallet_Deposit_Request_Input>
}

/** mutation root */
export type Mutation_RootMake_Wallet_Withdraw_RequestArgs = {
  offer?: InputMaybe<Make_Wallet_Withdraw_Request_Input>
}

/** mutation root */
export type Mutation_RootReject_New_AccountArgs = {
  request_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootRequest_New_AccountArgs = {
  account_data?: InputMaybe<Request_New_Account_Input>
}

/** mutation root */
export type Mutation_RootReserve_OfferArgs = {
  offerId: Scalars['String']
}

/** mutation root */
export type Mutation_RootRetry_Account_KycArgs = {
  request_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootSend_Email_NotificationArgs = {
  send_email_notification_input: Send_Email_Notification_Input
}

/** mutation root */
export type Mutation_RootStore_Ip_AddressArgs = {
  account?: InputMaybe<Scalars['String']>
}

/** mutation root */
export type Mutation_RootSubscribe_Push_NotificationArgs = {
  subscription: PushSubscriptionInput
}

/** mutation root */
export type Mutation_RootToggle_Trust_NetworkArgs = {
  input?: InputMaybe<Toggle_Trust_Network_Input>
}

/** mutation root */
export type Mutation_RootUnreserve_OfferArgs = {
  offerId: Scalars['String']
}

/** mutation root */
export type Mutation_RootUpdate_Accounts_InformationArgs = {
  _append?: InputMaybe<Accounts_Information_Append_Input>
  _delete_at_path?: InputMaybe<Accounts_Information_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Accounts_Information_Delete_Elem_Input>
  _delete_key?: InputMaybe<Accounts_Information_Delete_Key_Input>
  _prepend?: InputMaybe<Accounts_Information_Prepend_Input>
  _set?: InputMaybe<Accounts_Information_Set_Input>
  where: Accounts_Information_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Accounts_Information_By_PkArgs = {
  _append?: InputMaybe<Accounts_Information_Append_Input>
  _delete_at_path?: InputMaybe<Accounts_Information_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Accounts_Information_Delete_Elem_Input>
  _delete_key?: InputMaybe<Accounts_Information_Delete_Key_Input>
  _prepend?: InputMaybe<Accounts_Information_Prepend_Input>
  _set?: InputMaybe<Accounts_Information_Set_Input>
  pk_columns: Accounts_Information_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Accounts_Information_ManyArgs = {
  updates: Array<Accounts_Information_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Accounts_ReferralArgs = {
  _inc?: InputMaybe<Accounts_Referral_Inc_Input>
  _set?: InputMaybe<Accounts_Referral_Set_Input>
  where: Accounts_Referral_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Accounts_Referral_By_PkArgs = {
  _inc?: InputMaybe<Accounts_Referral_Inc_Input>
  _set?: InputMaybe<Accounts_Referral_Set_Input>
  pk_columns: Accounts_Referral_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Accounts_Referral_ManyArgs = {
  updates: Array<Accounts_Referral_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ActionsArgs = {
  _append?: InputMaybe<Actions_Append_Input>
  _delete_at_path?: InputMaybe<Actions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Actions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Actions_Delete_Key_Input>
  _prepend?: InputMaybe<Actions_Prepend_Input>
  _set?: InputMaybe<Actions_Set_Input>
  where: Actions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Actions_By_PkArgs = {
  _append?: InputMaybe<Actions_Append_Input>
  _delete_at_path?: InputMaybe<Actions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Actions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Actions_Delete_Key_Input>
  _prepend?: InputMaybe<Actions_Prepend_Input>
  _set?: InputMaybe<Actions_Set_Input>
  pk_columns: Actions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Actions_ManyArgs = {
  updates: Array<Actions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_DevicesArgs = {
  _set?: InputMaybe<Devices_Set_Input>
  where: Devices_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Devices_By_PkArgs = {
  _set?: InputMaybe<Devices_Set_Input>
  pk_columns: Devices_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Devices_ManyArgs = {
  updates: Array<Devices_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Login_AccountArgs = {
  _set?: InputMaybe<Login_Account_Set_Input>
  where: Login_Account_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Login_Account_By_PkArgs = {
  _set?: InputMaybe<Login_Account_Set_Input>
  pk_columns: Login_Account_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Login_Account_ManyArgs = {
  updates: Array<Login_Account_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Login_CountsArgs = {
  _inc?: InputMaybe<Login_Counts_Inc_Input>
  _set?: InputMaybe<Login_Counts_Set_Input>
  where: Login_Counts_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Login_Counts_ManyArgs = {
  updates: Array<Login_Counts_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
  _set?: InputMaybe<Messages_Set_Input>
  where: Messages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
  _set?: InputMaybe<Messages_Set_Input>
  pk_columns: Messages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Messages_ManyArgs = {
  updates: Array<Messages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Migrate_DeviceArgs = {
  _set?: InputMaybe<Migrate_Device_Set_Input>
  where: Migrate_Device_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Migrate_Device_By_PkArgs = {
  _set?: InputMaybe<Migrate_Device_Set_Input>
  pk_columns: Migrate_Device_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Migrate_Device_ManyArgs = {
  updates: Array<Migrate_Device_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: InputMaybe<Notifications_Set_Input>
  where: Notifications_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: InputMaybe<Notifications_Set_Input>
  pk_columns: Notifications_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_P2p_OffersArgs = {
  _set?: InputMaybe<P2p_Offers_Set_Input>
  where: P2p_Offers_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P2p_Offers_By_PkArgs = {
  _set?: InputMaybe<P2p_Offers_Set_Input>
  pk_columns: P2p_Offers_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P2p_Offers_ManyArgs = {
  updates: Array<P2p_Offers_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_P2p_Pre_Sell_OffersArgs = {
  _set?: InputMaybe<P2p_Pre_Sell_Offers_Set_Input>
  where: P2p_Pre_Sell_Offers_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P2p_Pre_Sell_Offers_By_PkArgs = {
  _set?: InputMaybe<P2p_Pre_Sell_Offers_Set_Input>
  pk_columns: P2p_Pre_Sell_Offers_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P2p_Pre_Sell_Offers_ManyArgs = {
  updates: Array<P2p_Pre_Sell_Offers_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_PreferencesArgs = {
  _append?: InputMaybe<Preferences_Append_Input>
  _delete_at_path?: InputMaybe<Preferences_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Preferences_Delete_Elem_Input>
  _delete_key?: InputMaybe<Preferences_Delete_Key_Input>
  _prepend?: InputMaybe<Preferences_Prepend_Input>
  _set?: InputMaybe<Preferences_Set_Input>
  where: Preferences_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Preferences_By_PkArgs = {
  _append?: InputMaybe<Preferences_Append_Input>
  _delete_at_path?: InputMaybe<Preferences_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Preferences_Delete_Elem_Input>
  _delete_key?: InputMaybe<Preferences_Delete_Key_Input>
  _prepend?: InputMaybe<Preferences_Prepend_Input>
  _set?: InputMaybe<Preferences_Set_Input>
  pk_columns: Preferences_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Preferences_ManyArgs = {
  updates: Array<Preferences_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Reg_AccountsArgs = {
  _set?: InputMaybe<Reg_Accounts_Set_Input>
  where: Reg_Accounts_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Reg_Accounts_By_PkArgs = {
  _set?: InputMaybe<Reg_Accounts_Set_Input>
  pk_columns: Reg_Accounts_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Reg_Accounts_ManyArgs = {
  updates: Array<Reg_Accounts_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Reputation_ScoreArgs = {
  _inc?: InputMaybe<Reputation_Score_Inc_Input>
  _set?: InputMaybe<Reputation_Score_Set_Input>
  where: Reputation_Score_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Reputation_Score_By_PkArgs = {
  _inc?: InputMaybe<Reputation_Score_Inc_Input>
  _set?: InputMaybe<Reputation_Score_Set_Input>
  pk_columns: Reputation_Score_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Reputation_Score_ManyArgs = {
  updates: Array<Reputation_Score_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Signing_RequestsArgs = {
  _set?: InputMaybe<Signing_Requests_Set_Input>
  where: Signing_Requests_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Signing_Requests_By_PkArgs = {
  _set?: InputMaybe<Signing_Requests_Set_Input>
  pk_columns: Signing_Requests_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Signing_Requests_ManyArgs = {
  updates: Array<Signing_Requests_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Support_SessionsArgs = {
  _set?: InputMaybe<Support_Sessions_Set_Input>
  where: Support_Sessions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Support_Sessions_By_PkArgs = {
  _set?: InputMaybe<Support_Sessions_Set_Input>
  pk_columns: Support_Sessions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Support_Sessions_ManyArgs = {
  updates: Array<Support_Sessions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Swap_AssetsArgs = {
  _set?: InputMaybe<Swap_Assets_Set_Input>
  where: Swap_Assets_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Swap_Assets_By_PkArgs = {
  _set?: InputMaybe<Swap_Assets_Set_Input>
  pk_columns: Swap_Assets_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Swap_Assets_ManyArgs = {
  updates: Array<Swap_Assets_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Swap_OrdersArgs = {
  _inc?: InputMaybe<Swap_Orders_Inc_Input>
  _set?: InputMaybe<Swap_Orders_Set_Input>
  where: Swap_Orders_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Swap_Orders_By_PkArgs = {
  _inc?: InputMaybe<Swap_Orders_Inc_Input>
  _set?: InputMaybe<Swap_Orders_Set_Input>
  pk_columns: Swap_Orders_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Swap_Orders_ManyArgs = {
  updates: Array<Swap_Orders_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_System_Notification_Read_StatusArgs = {
  _set?: InputMaybe<System_Notification_Read_Status_Set_Input>
  where: System_Notification_Read_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_System_Notification_Read_Status_By_PkArgs = {
  _set?: InputMaybe<System_Notification_Read_Status_Set_Input>
  pk_columns: System_Notification_Read_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_System_Notification_Read_Status_ManyArgs = {
  updates: Array<System_Notification_Read_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_System_NotificationsArgs = {
  _set?: InputMaybe<System_Notifications_Set_Input>
  where: System_Notifications_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_System_Notifications_By_PkArgs = {
  _set?: InputMaybe<System_Notifications_Set_Input>
  pk_columns: System_Notifications_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_System_Notifications_ManyArgs = {
  updates: Array<System_Notifications_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_System_Type_NotificationArgs = {
  _set?: InputMaybe<System_Type_Notification_Set_Input>
  where: System_Type_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_System_Type_Notification_By_PkArgs = {
  _set?: InputMaybe<System_Type_Notification_Set_Input>
  pk_columns: System_Type_Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_System_Type_Notification_ManyArgs = {
  updates: Array<System_Type_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Table_RowsArgs = {
  _append?: InputMaybe<Table_Rows_Append_Input>
  _delete_at_path?: InputMaybe<Table_Rows_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Table_Rows_Delete_Elem_Input>
  _delete_key?: InputMaybe<Table_Rows_Delete_Key_Input>
  _prepend?: InputMaybe<Table_Rows_Prepend_Input>
  _set?: InputMaybe<Table_Rows_Set_Input>
  where: Table_Rows_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Table_Rows_By_PkArgs = {
  _append?: InputMaybe<Table_Rows_Append_Input>
  _delete_at_path?: InputMaybe<Table_Rows_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Table_Rows_Delete_Elem_Input>
  _delete_key?: InputMaybe<Table_Rows_Delete_Key_Input>
  _prepend?: InputMaybe<Table_Rows_Prepend_Input>
  _set?: InputMaybe<Table_Rows_Set_Input>
  pk_columns: Table_Rows_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Table_Rows_ManyArgs = {
  updates: Array<Table_Rows_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Trust_NetworkArgs = {
  _set?: InputMaybe<Trust_Network_Set_Input>
  where: Trust_Network_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_By_PkArgs = {
  _set?: InputMaybe<Trust_Network_Set_Input>
  pk_columns: Trust_Network_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_ManyArgs = {
  updates: Array<Trust_Network_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_NotificationArgs = {
  _set?: InputMaybe<Trust_Network_Notification_Set_Input>
  where: Trust_Network_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Notification_By_PkArgs = {
  _set?: InputMaybe<Trust_Network_Notification_Set_Input>
  pk_columns: Trust_Network_Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Notification_ManyArgs = {
  updates: Array<Trust_Network_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_StatusArgs = {
  _set?: InputMaybe<Trust_Network_Status_Set_Input>
  where: Trust_Network_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Status_By_PkArgs = {
  _set?: InputMaybe<Trust_Network_Status_Set_Input>
  pk_columns: Trust_Network_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Status_ManyArgs = {
  updates: Array<Trust_Network_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Tx_StatisticsArgs = {
  _inc?: InputMaybe<Tx_Statistics_Inc_Input>
  _set?: InputMaybe<Tx_Statistics_Set_Input>
  where: Tx_Statistics_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Tx_Statistics_ManyArgs = {
  updates: Array<Tx_Statistics_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Volume_StatisticsArgs = {
  _inc?: InputMaybe<Volume_Statistics_Inc_Input>
  _set?: InputMaybe<Volume_Statistics_Set_Input>
  where: Volume_Statistics_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Volume_Statistics_ManyArgs = {
  updates: Array<Volume_Statistics_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Web_PushArgs = {
  _set?: InputMaybe<Web_Push_Set_Input>
  where: Web_Push_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Web_Push_By_PkArgs = {
  _set?: InputMaybe<Web_Push_Set_Input>
  pk_columns: Web_Push_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Web_Push_ManyArgs = {
  updates: Array<Web_Push_Updates>
}

/** mutation root */
export type Mutation_RootUpload_ImageArgs = {
  account: Scalars['String']
  image: Scalars['String']
  selfie?: InputMaybe<Scalars['Boolean']>
}

/** mutation root */
export type Mutation_RootValidate_Short_LinkArgs = {
  validate_short_link_input: Validate_Short_Link_Input
}

/** columns and relationships of "notifications" */
export type Notifications = {
  content_id?: Maybe<Scalars['uuid']>
  created_at: Scalars['timestamptz']
  from: Scalars['String']
  id: Scalars['uuid']
  /** An object relationship */
  notifications_reg_account: Reg_Accounts
  read: Scalars['Boolean']
  /** An object relationship */
  reg_account: Reg_Accounts
  to: Scalars['String']
  type: Scalars['String']
}

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  aggregate?: Maybe<Notifications_Aggregate_Fields>
  nodes: Array<Notifications>
}

export type Notifications_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>
}

export type Notifications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Notifications_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notifications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Notifications_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notifications_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Notifications_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Notifications_Max_Fields>
  min?: Maybe<Notifications_Min_Fields>
}

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Notifications_Max_Order_By>
  min?: InputMaybe<Notifications_Min_Order_By>
}

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>
}

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>
  _not?: InputMaybe<Notifications_Bool_Exp>
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>
  content_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  from?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  notifications_reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  read?: InputMaybe<Boolean_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  to?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey',
}

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  content_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  from?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  notifications_reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  read?: InputMaybe<Scalars['Boolean']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  to?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  content_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  from?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  to?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  content_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  from?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  to?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  content_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  from?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  to?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  content_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  from?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  to?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
}

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>
}

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint
  update_columns?: Array<Notifications_Update_Column>
  where?: InputMaybe<Notifications_Bool_Exp>
}

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  content_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  from?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  notifications_reg_account?: InputMaybe<Reg_Accounts_Order_By>
  read?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  to?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
}

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type',
}

/** select "notifications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Read = 'read',
}

/** select "notifications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Read = 'read',
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  content_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  from?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  read?: InputMaybe<Scalars['Boolean']>
  to?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  from?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  read?: InputMaybe<Scalars['Boolean']>
  to?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type',
}

export type Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>
  _gt?: InputMaybe<Scalars['numeric']>
  _gte?: InputMaybe<Scalars['numeric']>
  _in?: InputMaybe<Array<Scalars['numeric']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['numeric']>
  _lte?: InputMaybe<Scalars['numeric']>
  _neq?: InputMaybe<Scalars['numeric']>
  _nin?: InputMaybe<Array<Scalars['numeric']>>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type P2p_Offer_Output = {
  amount?: Maybe<Scalars['String']>
  bitcashbank_id?: Maybe<Scalars['String']>
  buyer?: Maybe<Scalars['String']>
  buyer_confirmed_payment?: Maybe<Scalars['Boolean']>
  buyer_method_details?: Maybe<Scalars['String']>
  cancel_approval_datetime?: Maybe<Scalars['String']>
  cancel_request_datetime?: Maybe<Scalars['String']>
  cancellation_approved_by?: Maybe<Scalars['String']>
  cancellation_transaction?: Maybe<Scalars['String']>
  cancelled?: Maybe<Scalars['Boolean']>
  cancelled_by?: Maybe<Scalars['String']>
  completed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  initiator?: Maybe<Scalars['String']>
  matched?: Maybe<Scalars['Boolean']>
  matched_datetime?: Maybe<Scalars['String']>
  method?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  sell_put_transaction?: Maybe<Scalars['String']>
  sell_settlement_transaction?: Maybe<Scalars['String']>
  seller?: Maybe<Scalars['String']>
  seller_confirmed_payment?: Maybe<Scalars['Boolean']>
  seller_method_details?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['String']>
}

/** columns and relationships of "p2p_offers" */
export type P2p_Offers = {
  amount: Scalars['String']
  bitcashbank_id?: Maybe<Scalars['String']>
  buyer?: Maybe<Scalars['String']>
  buyer_confirmed_datetime?: Maybe<Scalars['timestamptz']>
  buyer_confirmed_payment: Scalars['Boolean']
  buyer_method_details?: Maybe<Scalars['String']>
  cancel_approval_datetime?: Maybe<Scalars['timestamptz']>
  cancel_request_datetime?: Maybe<Scalars['timestamptz']>
  cancellation_approved_by?: Maybe<Scalars['String']>
  cancellation_transaction?: Maybe<Scalars['String']>
  cancelled: Scalars['Boolean']
  cancelled_by?: Maybe<Scalars['String']>
  completed: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  initiator: Scalars['String']
  matched: Scalars['Boolean']
  matched_datetime?: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  messages: Array<Messages>
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate
  method: Scalars['String']
  original_offer_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  regAccountByBuyer?: Maybe<Reg_Accounts>
  /** An object relationship */
  regAccountByCancellationApprovedBy?: Maybe<Reg_Accounts>
  /** An object relationship */
  regAccountByCancelledBy?: Maybe<Reg_Accounts>
  /** An object relationship */
  regAccountByInitiator: Reg_Accounts
  /** An object relationship */
  regAccountBySeller?: Maybe<Reg_Accounts>
  region?: Maybe<Scalars['String']>
  reserved_by?: Maybe<Scalars['String']>
  sell_put_transaction?: Maybe<Scalars['String']>
  sell_settlement_transaction?: Maybe<Scalars['String']>
  seller?: Maybe<Scalars['String']>
  seller_confirmed_datetime?: Maybe<Scalars['timestamptz']>
  seller_confirmed_payment: Scalars['Boolean']
  seller_method_details?: Maybe<Scalars['String']>
  /** An array relationship */
  system_notifications: Array<System_Notifications>
  /** An aggregate relationship */
  system_notifications_aggregate: System_Notifications_Aggregate
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "p2p_offers" */
export type P2p_OffersMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** columns and relationships of "p2p_offers" */
export type P2p_OffersMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** columns and relationships of "p2p_offers" */
export type P2p_OffersSystem_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

/** columns and relationships of "p2p_offers" */
export type P2p_OffersSystem_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

/** aggregated selection of "p2p_offers" */
export type P2p_Offers_Aggregate = {
  aggregate?: Maybe<P2p_Offers_Aggregate_Fields>
  nodes: Array<P2p_Offers>
}

export type P2p_Offers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp_Count>
}

export type P2p_Offers_Aggregate_Bool_Exp_Bool_And = {
  arguments: P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<P2p_Offers_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type P2p_Offers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<P2p_Offers_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type P2p_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<P2p_Offers_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<P2p_Offers_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "p2p_offers" */
export type P2p_Offers_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<P2p_Offers_Max_Fields>
  min?: Maybe<P2p_Offers_Min_Fields>
}

/** aggregate fields of "p2p_offers" */
export type P2p_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<P2p_Offers_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "p2p_offers" */
export type P2p_Offers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<P2p_Offers_Max_Order_By>
  min?: InputMaybe<P2p_Offers_Min_Order_By>
}

/** input type for inserting array relation for remote table "p2p_offers" */
export type P2p_Offers_Arr_Rel_Insert_Input = {
  data: Array<P2p_Offers_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>
}

/** Boolean expression to filter rows from the table "p2p_offers". All fields are combined with a logical 'AND'. */
export type P2p_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<P2p_Offers_Bool_Exp>>
  _not?: InputMaybe<P2p_Offers_Bool_Exp>
  _or?: InputMaybe<Array<P2p_Offers_Bool_Exp>>
  amount?: InputMaybe<String_Comparison_Exp>
  bitcashbank_id?: InputMaybe<String_Comparison_Exp>
  buyer?: InputMaybe<String_Comparison_Exp>
  buyer_confirmed_datetime?: InputMaybe<Timestamptz_Comparison_Exp>
  buyer_confirmed_payment?: InputMaybe<Boolean_Comparison_Exp>
  buyer_method_details?: InputMaybe<String_Comparison_Exp>
  cancel_approval_datetime?: InputMaybe<Timestamptz_Comparison_Exp>
  cancel_request_datetime?: InputMaybe<Timestamptz_Comparison_Exp>
  cancellation_approved_by?: InputMaybe<String_Comparison_Exp>
  cancellation_transaction?: InputMaybe<String_Comparison_Exp>
  cancelled?: InputMaybe<Boolean_Comparison_Exp>
  cancelled_by?: InputMaybe<String_Comparison_Exp>
  completed?: InputMaybe<Boolean_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  initiator?: InputMaybe<String_Comparison_Exp>
  matched?: InputMaybe<Boolean_Comparison_Exp>
  matched_datetime?: InputMaybe<Timestamptz_Comparison_Exp>
  messages?: InputMaybe<Messages_Bool_Exp>
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>
  method?: InputMaybe<String_Comparison_Exp>
  original_offer_id?: InputMaybe<Uuid_Comparison_Exp>
  regAccountByBuyer?: InputMaybe<Reg_Accounts_Bool_Exp>
  regAccountByCancellationApprovedBy?: InputMaybe<Reg_Accounts_Bool_Exp>
  regAccountByCancelledBy?: InputMaybe<Reg_Accounts_Bool_Exp>
  regAccountByInitiator?: InputMaybe<Reg_Accounts_Bool_Exp>
  regAccountBySeller?: InputMaybe<Reg_Accounts_Bool_Exp>
  region?: InputMaybe<String_Comparison_Exp>
  reserved_by?: InputMaybe<String_Comparison_Exp>
  sell_put_transaction?: InputMaybe<String_Comparison_Exp>
  sell_settlement_transaction?: InputMaybe<String_Comparison_Exp>
  seller?: InputMaybe<String_Comparison_Exp>
  seller_confirmed_datetime?: InputMaybe<Timestamptz_Comparison_Exp>
  seller_confirmed_payment?: InputMaybe<Boolean_Comparison_Exp>
  seller_method_details?: InputMaybe<String_Comparison_Exp>
  system_notifications?: InputMaybe<System_Notifications_Bool_Exp>
  system_notifications_aggregate?: InputMaybe<System_Notifications_Aggregate_Bool_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "p2p_offers" */
export enum P2p_Offers_Constraint {
  /** unique or primary key constraint on columns "cancellation_transaction" */
  P2pOffersCancellationTransactionKey = 'p2p_offers_cancellation_transaction_key',
  /** unique or primary key constraint on columns "id" */
  P2pOffersIdKey = 'p2p_offers_id_key',
  /** unique or primary key constraint on columns "id" */
  P2pOffersPkey = 'p2p_offers_pkey',
  /** unique or primary key constraint on columns "sell_put_transaction" */
  P2pOffersSellPutTransactionKey = 'p2p_offers_sell_put_transaction_key',
  /** unique or primary key constraint on columns "sell_settlement_transaction" */
  P2pOffersSellSettlementTransactionKey = 'p2p_offers_sell_settlement_transaction_key',
}

/** input type for inserting data into table "p2p_offers" */
export type P2p_Offers_Insert_Input = {
  amount?: InputMaybe<Scalars['String']>
  bitcashbank_id?: InputMaybe<Scalars['String']>
  buyer?: InputMaybe<Scalars['String']>
  buyer_confirmed_datetime?: InputMaybe<Scalars['timestamptz']>
  buyer_confirmed_payment?: InputMaybe<Scalars['Boolean']>
  buyer_method_details?: InputMaybe<Scalars['String']>
  cancel_approval_datetime?: InputMaybe<Scalars['timestamptz']>
  cancel_request_datetime?: InputMaybe<Scalars['timestamptz']>
  cancellation_approved_by?: InputMaybe<Scalars['String']>
  cancellation_transaction?: InputMaybe<Scalars['String']>
  cancelled?: InputMaybe<Scalars['Boolean']>
  cancelled_by?: InputMaybe<Scalars['String']>
  completed?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  initiator?: InputMaybe<Scalars['String']>
  matched?: InputMaybe<Scalars['Boolean']>
  matched_datetime?: InputMaybe<Scalars['timestamptz']>
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>
  method?: InputMaybe<Scalars['String']>
  original_offer_id?: InputMaybe<Scalars['uuid']>
  regAccountByBuyer?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  regAccountByCancellationApprovedBy?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  regAccountByCancelledBy?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  regAccountByInitiator?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  regAccountBySeller?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  region?: InputMaybe<Scalars['String']>
  reserved_by?: InputMaybe<Scalars['String']>
  sell_put_transaction?: InputMaybe<Scalars['String']>
  sell_settlement_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  seller_confirmed_datetime?: InputMaybe<Scalars['timestamptz']>
  seller_confirmed_payment?: InputMaybe<Scalars['Boolean']>
  seller_method_details?: InputMaybe<Scalars['String']>
  system_notifications?: InputMaybe<System_Notifications_Arr_Rel_Insert_Input>
  type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type P2p_Offers_Max_Fields = {
  amount?: Maybe<Scalars['String']>
  bitcashbank_id?: Maybe<Scalars['String']>
  buyer?: Maybe<Scalars['String']>
  buyer_confirmed_datetime?: Maybe<Scalars['timestamptz']>
  buyer_method_details?: Maybe<Scalars['String']>
  cancel_approval_datetime?: Maybe<Scalars['timestamptz']>
  cancel_request_datetime?: Maybe<Scalars['timestamptz']>
  cancellation_approved_by?: Maybe<Scalars['String']>
  cancellation_transaction?: Maybe<Scalars['String']>
  cancelled_by?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  initiator?: Maybe<Scalars['String']>
  matched_datetime?: Maybe<Scalars['timestamptz']>
  method?: Maybe<Scalars['String']>
  original_offer_id?: Maybe<Scalars['uuid']>
  region?: Maybe<Scalars['String']>
  reserved_by?: Maybe<Scalars['String']>
  sell_put_transaction?: Maybe<Scalars['String']>
  sell_settlement_transaction?: Maybe<Scalars['String']>
  seller?: Maybe<Scalars['String']>
  seller_confirmed_datetime?: Maybe<Scalars['timestamptz']>
  seller_method_details?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "p2p_offers" */
export type P2p_Offers_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  bitcashbank_id?: InputMaybe<Order_By>
  buyer?: InputMaybe<Order_By>
  buyer_confirmed_datetime?: InputMaybe<Order_By>
  buyer_method_details?: InputMaybe<Order_By>
  cancel_approval_datetime?: InputMaybe<Order_By>
  cancel_request_datetime?: InputMaybe<Order_By>
  cancellation_approved_by?: InputMaybe<Order_By>
  cancellation_transaction?: InputMaybe<Order_By>
  cancelled_by?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  initiator?: InputMaybe<Order_By>
  matched_datetime?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  original_offer_id?: InputMaybe<Order_By>
  region?: InputMaybe<Order_By>
  reserved_by?: InputMaybe<Order_By>
  sell_put_transaction?: InputMaybe<Order_By>
  sell_settlement_transaction?: InputMaybe<Order_By>
  seller?: InputMaybe<Order_By>
  seller_confirmed_datetime?: InputMaybe<Order_By>
  seller_method_details?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type P2p_Offers_Min_Fields = {
  amount?: Maybe<Scalars['String']>
  bitcashbank_id?: Maybe<Scalars['String']>
  buyer?: Maybe<Scalars['String']>
  buyer_confirmed_datetime?: Maybe<Scalars['timestamptz']>
  buyer_method_details?: Maybe<Scalars['String']>
  cancel_approval_datetime?: Maybe<Scalars['timestamptz']>
  cancel_request_datetime?: Maybe<Scalars['timestamptz']>
  cancellation_approved_by?: Maybe<Scalars['String']>
  cancellation_transaction?: Maybe<Scalars['String']>
  cancelled_by?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  initiator?: Maybe<Scalars['String']>
  matched_datetime?: Maybe<Scalars['timestamptz']>
  method?: Maybe<Scalars['String']>
  original_offer_id?: Maybe<Scalars['uuid']>
  region?: Maybe<Scalars['String']>
  reserved_by?: Maybe<Scalars['String']>
  sell_put_transaction?: Maybe<Scalars['String']>
  sell_settlement_transaction?: Maybe<Scalars['String']>
  seller?: Maybe<Scalars['String']>
  seller_confirmed_datetime?: Maybe<Scalars['timestamptz']>
  seller_method_details?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "p2p_offers" */
export type P2p_Offers_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  bitcashbank_id?: InputMaybe<Order_By>
  buyer?: InputMaybe<Order_By>
  buyer_confirmed_datetime?: InputMaybe<Order_By>
  buyer_method_details?: InputMaybe<Order_By>
  cancel_approval_datetime?: InputMaybe<Order_By>
  cancel_request_datetime?: InputMaybe<Order_By>
  cancellation_approved_by?: InputMaybe<Order_By>
  cancellation_transaction?: InputMaybe<Order_By>
  cancelled_by?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  initiator?: InputMaybe<Order_By>
  matched_datetime?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  original_offer_id?: InputMaybe<Order_By>
  region?: InputMaybe<Order_By>
  reserved_by?: InputMaybe<Order_By>
  sell_put_transaction?: InputMaybe<Order_By>
  sell_settlement_transaction?: InputMaybe<Order_By>
  seller?: InputMaybe<Order_By>
  seller_confirmed_datetime?: InputMaybe<Order_By>
  seller_method_details?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "p2p_offers" */
export type P2p_Offers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<P2p_Offers>
}

/** input type for inserting object relation for remote table "p2p_offers" */
export type P2p_Offers_Obj_Rel_Insert_Input = {
  data: P2p_Offers_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>
}

/** on_conflict condition type for table "p2p_offers" */
export type P2p_Offers_On_Conflict = {
  constraint: P2p_Offers_Constraint
  update_columns?: Array<P2p_Offers_Update_Column>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** Ordering options when selecting data from "p2p_offers". */
export type P2p_Offers_Order_By = {
  amount?: InputMaybe<Order_By>
  bitcashbank_id?: InputMaybe<Order_By>
  buyer?: InputMaybe<Order_By>
  buyer_confirmed_datetime?: InputMaybe<Order_By>
  buyer_confirmed_payment?: InputMaybe<Order_By>
  buyer_method_details?: InputMaybe<Order_By>
  cancel_approval_datetime?: InputMaybe<Order_By>
  cancel_request_datetime?: InputMaybe<Order_By>
  cancellation_approved_by?: InputMaybe<Order_By>
  cancellation_transaction?: InputMaybe<Order_By>
  cancelled?: InputMaybe<Order_By>
  cancelled_by?: InputMaybe<Order_By>
  completed?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  initiator?: InputMaybe<Order_By>
  matched?: InputMaybe<Order_By>
  matched_datetime?: InputMaybe<Order_By>
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>
  method?: InputMaybe<Order_By>
  original_offer_id?: InputMaybe<Order_By>
  regAccountByBuyer?: InputMaybe<Reg_Accounts_Order_By>
  regAccountByCancellationApprovedBy?: InputMaybe<Reg_Accounts_Order_By>
  regAccountByCancelledBy?: InputMaybe<Reg_Accounts_Order_By>
  regAccountByInitiator?: InputMaybe<Reg_Accounts_Order_By>
  regAccountBySeller?: InputMaybe<Reg_Accounts_Order_By>
  region?: InputMaybe<Order_By>
  reserved_by?: InputMaybe<Order_By>
  sell_put_transaction?: InputMaybe<Order_By>
  sell_settlement_transaction?: InputMaybe<Order_By>
  seller?: InputMaybe<Order_By>
  seller_confirmed_datetime?: InputMaybe<Order_By>
  seller_confirmed_payment?: InputMaybe<Order_By>
  seller_method_details?: InputMaybe<Order_By>
  system_notifications_aggregate?: InputMaybe<System_Notifications_Aggregate_Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: p2p_offers */
export type P2p_Offers_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "p2p_offers" */
export enum P2p_Offers_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BitcashbankId = 'bitcashbank_id',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  BuyerConfirmedDatetime = 'buyer_confirmed_datetime',
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  BuyerMethodDetails = 'buyer_method_details',
  /** column name */
  CancelApprovalDatetime = 'cancel_approval_datetime',
  /** column name */
  CancelRequestDatetime = 'cancel_request_datetime',
  /** column name */
  CancellationApprovedBy = 'cancellation_approved_by',
  /** column name */
  CancellationTransaction = 'cancellation_transaction',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Initiator = 'initiator',
  /** column name */
  Matched = 'matched',
  /** column name */
  MatchedDatetime = 'matched_datetime',
  /** column name */
  Method = 'method',
  /** column name */
  OriginalOfferId = 'original_offer_id',
  /** column name */
  Region = 'region',
  /** column name */
  ReservedBy = 'reserved_by',
  /** column name */
  SellPutTransaction = 'sell_put_transaction',
  /** column name */
  SellSettlementTransaction = 'sell_settlement_transaction',
  /** column name */
  Seller = 'seller',
  /** column name */
  SellerConfirmedDatetime = 'seller_confirmed_datetime',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment',
  /** column name */
  SellerMethodDetails = 'seller_method_details',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "p2p_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "p2p_offers" */
export enum P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  Completed = 'completed',
  /** column name */
  Matched = 'matched',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment',
}

/** select "p2p_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "p2p_offers" */
export enum P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  Completed = 'completed',
  /** column name */
  Matched = 'matched',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment',
}

/** input type for updating data in table "p2p_offers" */
export type P2p_Offers_Set_Input = {
  amount?: InputMaybe<Scalars['String']>
  bitcashbank_id?: InputMaybe<Scalars['String']>
  buyer?: InputMaybe<Scalars['String']>
  buyer_confirmed_datetime?: InputMaybe<Scalars['timestamptz']>
  buyer_confirmed_payment?: InputMaybe<Scalars['Boolean']>
  buyer_method_details?: InputMaybe<Scalars['String']>
  cancel_approval_datetime?: InputMaybe<Scalars['timestamptz']>
  cancel_request_datetime?: InputMaybe<Scalars['timestamptz']>
  cancellation_approved_by?: InputMaybe<Scalars['String']>
  cancellation_transaction?: InputMaybe<Scalars['String']>
  cancelled?: InputMaybe<Scalars['Boolean']>
  cancelled_by?: InputMaybe<Scalars['String']>
  completed?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  initiator?: InputMaybe<Scalars['String']>
  matched?: InputMaybe<Scalars['Boolean']>
  matched_datetime?: InputMaybe<Scalars['timestamptz']>
  method?: InputMaybe<Scalars['String']>
  original_offer_id?: InputMaybe<Scalars['uuid']>
  region?: InputMaybe<Scalars['String']>
  reserved_by?: InputMaybe<Scalars['String']>
  sell_put_transaction?: InputMaybe<Scalars['String']>
  sell_settlement_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  seller_confirmed_datetime?: InputMaybe<Scalars['timestamptz']>
  seller_confirmed_payment?: InputMaybe<Scalars['Boolean']>
  seller_method_details?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "p2p_offers" */
export type P2p_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: P2p_Offers_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type P2p_Offers_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['String']>
  bitcashbank_id?: InputMaybe<Scalars['String']>
  buyer?: InputMaybe<Scalars['String']>
  buyer_confirmed_datetime?: InputMaybe<Scalars['timestamptz']>
  buyer_confirmed_payment?: InputMaybe<Scalars['Boolean']>
  buyer_method_details?: InputMaybe<Scalars['String']>
  cancel_approval_datetime?: InputMaybe<Scalars['timestamptz']>
  cancel_request_datetime?: InputMaybe<Scalars['timestamptz']>
  cancellation_approved_by?: InputMaybe<Scalars['String']>
  cancellation_transaction?: InputMaybe<Scalars['String']>
  cancelled?: InputMaybe<Scalars['Boolean']>
  cancelled_by?: InputMaybe<Scalars['String']>
  completed?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  initiator?: InputMaybe<Scalars['String']>
  matched?: InputMaybe<Scalars['Boolean']>
  matched_datetime?: InputMaybe<Scalars['timestamptz']>
  method?: InputMaybe<Scalars['String']>
  original_offer_id?: InputMaybe<Scalars['uuid']>
  region?: InputMaybe<Scalars['String']>
  reserved_by?: InputMaybe<Scalars['String']>
  sell_put_transaction?: InputMaybe<Scalars['String']>
  sell_settlement_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  seller_confirmed_datetime?: InputMaybe<Scalars['timestamptz']>
  seller_confirmed_payment?: InputMaybe<Scalars['Boolean']>
  seller_method_details?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "p2p_offers" */
export enum P2p_Offers_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BitcashbankId = 'bitcashbank_id',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  BuyerConfirmedDatetime = 'buyer_confirmed_datetime',
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  BuyerMethodDetails = 'buyer_method_details',
  /** column name */
  CancelApprovalDatetime = 'cancel_approval_datetime',
  /** column name */
  CancelRequestDatetime = 'cancel_request_datetime',
  /** column name */
  CancellationApprovedBy = 'cancellation_approved_by',
  /** column name */
  CancellationTransaction = 'cancellation_transaction',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Initiator = 'initiator',
  /** column name */
  Matched = 'matched',
  /** column name */
  MatchedDatetime = 'matched_datetime',
  /** column name */
  Method = 'method',
  /** column name */
  OriginalOfferId = 'original_offer_id',
  /** column name */
  Region = 'region',
  /** column name */
  ReservedBy = 'reserved_by',
  /** column name */
  SellPutTransaction = 'sell_put_transaction',
  /** column name */
  SellSettlementTransaction = 'sell_settlement_transaction',
  /** column name */
  Seller = 'seller',
  /** column name */
  SellerConfirmedDatetime = 'seller_confirmed_datetime',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment',
  /** column name */
  SellerMethodDetails = 'seller_method_details',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type P2p_Offers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<P2p_Offers_Set_Input>
  /** filter the rows which have to be updated */
  where: P2p_Offers_Bool_Exp
}

/** columns and relationships of "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers = {
  amount: Scalars['String']
  bitcashbank_id: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  method: Scalars['String']
  processed: Scalars['Boolean']
  /** An object relationship */
  reg_account: Reg_Accounts
  region: Scalars['String']
  sell_put_transaction: Scalars['String']
  seller: Scalars['String']
  seller_method_details?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Aggregate = {
  aggregate?: Maybe<P2p_Pre_Sell_Offers_Aggregate_Fields>
  nodes: Array<P2p_Pre_Sell_Offers>
}

export type P2p_Pre_Sell_Offers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Count>
}

export type P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_And = {
  arguments: P2p_Pre_Sell_Offers_Select_Column_P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: P2p_Pre_Sell_Offers_Select_Column_P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<P2p_Pre_Sell_Offers_Max_Fields>
  min?: Maybe<P2p_Pre_Sell_Offers_Min_Fields>
}

/** aggregate fields of "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<P2p_Pre_Sell_Offers_Max_Order_By>
  min?: InputMaybe<P2p_Pre_Sell_Offers_Min_Order_By>
}

/** input type for inserting array relation for remote table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Arr_Rel_Insert_Input = {
  data: Array<P2p_Pre_Sell_Offers_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<P2p_Pre_Sell_Offers_On_Conflict>
}

/** Boolean expression to filter rows from the table "p2p_pre_sell_offers". All fields are combined with a logical 'AND'. */
export type P2p_Pre_Sell_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<P2p_Pre_Sell_Offers_Bool_Exp>>
  _not?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
  _or?: InputMaybe<Array<P2p_Pre_Sell_Offers_Bool_Exp>>
  amount?: InputMaybe<String_Comparison_Exp>
  bitcashbank_id?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  method?: InputMaybe<String_Comparison_Exp>
  processed?: InputMaybe<Boolean_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  region?: InputMaybe<String_Comparison_Exp>
  sell_put_transaction?: InputMaybe<String_Comparison_Exp>
  seller?: InputMaybe<String_Comparison_Exp>
  seller_method_details?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "p2p_pre_sell_offers" */
export enum P2p_Pre_Sell_Offers_Constraint {
  /** unique or primary key constraint on columns "id" */
  P2pPreSellOffersPkey = 'p2p_pre_sell_offers_pkey',
  /** unique or primary key constraint on columns "sell_put_transaction" */
  P2pPreSellOffersSellPutTransactionKey = 'p2p_pre_sell_offers_sell_put_transaction_key',
}

/** input type for inserting data into table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Insert_Input = {
  amount?: InputMaybe<Scalars['String']>
  bitcashbank_id?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  method?: InputMaybe<Scalars['String']>
  processed?: InputMaybe<Scalars['Boolean']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  region?: InputMaybe<Scalars['String']>
  sell_put_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  seller_method_details?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type P2p_Pre_Sell_Offers_Max_Fields = {
  amount?: Maybe<Scalars['String']>
  bitcashbank_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  method?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  sell_put_transaction?: Maybe<Scalars['String']>
  seller?: Maybe<Scalars['String']>
  seller_method_details?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  bitcashbank_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  region?: InputMaybe<Order_By>
  sell_put_transaction?: InputMaybe<Order_By>
  seller?: InputMaybe<Order_By>
  seller_method_details?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type P2p_Pre_Sell_Offers_Min_Fields = {
  amount?: Maybe<Scalars['String']>
  bitcashbank_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  method?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  sell_put_transaction?: Maybe<Scalars['String']>
  seller?: Maybe<Scalars['String']>
  seller_method_details?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  bitcashbank_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  region?: InputMaybe<Order_By>
  sell_put_transaction?: InputMaybe<Order_By>
  seller?: InputMaybe<Order_By>
  seller_method_details?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<P2p_Pre_Sell_Offers>
}

/** on_conflict condition type for table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_On_Conflict = {
  constraint: P2p_Pre_Sell_Offers_Constraint
  update_columns?: Array<P2p_Pre_Sell_Offers_Update_Column>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

/** Ordering options when selecting data from "p2p_pre_sell_offers". */
export type P2p_Pre_Sell_Offers_Order_By = {
  amount?: InputMaybe<Order_By>
  bitcashbank_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  processed?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  region?: InputMaybe<Order_By>
  sell_put_transaction?: InputMaybe<Order_By>
  seller?: InputMaybe<Order_By>
  seller_method_details?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: p2p_pre_sell_offers */
export type P2p_Pre_Sell_Offers_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "p2p_pre_sell_offers" */
export enum P2p_Pre_Sell_Offers_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BitcashbankId = 'bitcashbank_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Processed = 'processed',
  /** column name */
  Region = 'region',
  /** column name */
  SellPutTransaction = 'sell_put_transaction',
  /** column name */
  Seller = 'seller',
  /** column name */
  SellerMethodDetails = 'seller_method_details',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "p2p_pre_sell_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "p2p_pre_sell_offers" */
export enum P2p_Pre_Sell_Offers_Select_Column_P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Processed = 'processed',
}

/** select "p2p_pre_sell_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "p2p_pre_sell_offers" */
export enum P2p_Pre_Sell_Offers_Select_Column_P2p_Pre_Sell_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Processed = 'processed',
}

/** input type for updating data in table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Set_Input = {
  amount?: InputMaybe<Scalars['String']>
  bitcashbank_id?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  method?: InputMaybe<Scalars['String']>
  processed?: InputMaybe<Scalars['Boolean']>
  region?: InputMaybe<Scalars['String']>
  sell_put_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  seller_method_details?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "p2p_pre_sell_offers" */
export type P2p_Pre_Sell_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: P2p_Pre_Sell_Offers_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type P2p_Pre_Sell_Offers_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['String']>
  bitcashbank_id?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  method?: InputMaybe<Scalars['String']>
  processed?: InputMaybe<Scalars['Boolean']>
  region?: InputMaybe<Scalars['String']>
  sell_put_transaction?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  seller_method_details?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "p2p_pre_sell_offers" */
export enum P2p_Pre_Sell_Offers_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BitcashbankId = 'bitcashbank_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Processed = 'processed',
  /** column name */
  Region = 'region',
  /** column name */
  SellPutTransaction = 'sell_put_transaction',
  /** column name */
  Seller = 'seller',
  /** column name */
  SellerMethodDetails = 'seller_method_details',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type P2p_Pre_Sell_Offers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<P2p_Pre_Sell_Offers_Set_Input>
  /** filter the rows which have to be updated */
  where: P2p_Pre_Sell_Offers_Bool_Exp
}

export type Photo_Id_Verification_Output = {
  error: Scalars['Boolean']
}

/** columns and relationships of "preferences" */
export type Preferences = {
  account: Scalars['String']
  currency: Scalars['String']
  language: Scalars['String']
  notifications: Scalars['Boolean']
  personalized: Scalars['Boolean']
  region: Scalars['String']
  secondary_currency?: Maybe<Scalars['jsonb']>
  theme: Scalars['String']
}

/** columns and relationships of "preferences" */
export type PreferencesSecondary_CurrencyArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "preferences" */
export type Preferences_Aggregate = {
  aggregate?: Maybe<Preferences_Aggregate_Fields>
  nodes: Array<Preferences>
}

/** aggregate fields of "preferences" */
export type Preferences_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Preferences_Max_Fields>
  min?: Maybe<Preferences_Min_Fields>
}

/** aggregate fields of "preferences" */
export type Preferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Preferences_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Preferences_Append_Input = {
  secondary_currency?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "preferences". All fields are combined with a logical 'AND'. */
export type Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<Preferences_Bool_Exp>>
  _not?: InputMaybe<Preferences_Bool_Exp>
  _or?: InputMaybe<Array<Preferences_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  currency?: InputMaybe<String_Comparison_Exp>
  language?: InputMaybe<String_Comparison_Exp>
  notifications?: InputMaybe<Boolean_Comparison_Exp>
  personalized?: InputMaybe<Boolean_Comparison_Exp>
  region?: InputMaybe<String_Comparison_Exp>
  secondary_currency?: InputMaybe<Jsonb_Comparison_Exp>
  theme?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "preferences" */
export enum Preferences_Constraint {
  /** unique or primary key constraint on columns "account" */
  PreferencesPkey = 'preferences_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Preferences_Delete_At_Path_Input = {
  secondary_currency?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Preferences_Delete_Elem_Input = {
  secondary_currency?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Preferences_Delete_Key_Input = {
  secondary_currency?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "preferences" */
export type Preferences_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  notifications?: InputMaybe<Scalars['Boolean']>
  personalized?: InputMaybe<Scalars['Boolean']>
  region?: InputMaybe<Scalars['String']>
  secondary_currency?: InputMaybe<Scalars['jsonb']>
  theme?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Preferences_Max_Fields = {
  account?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  theme?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Preferences_Min_Fields = {
  account?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  theme?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "preferences" */
export type Preferences_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Preferences>
}

/** on_conflict condition type for table "preferences" */
export type Preferences_On_Conflict = {
  constraint: Preferences_Constraint
  update_columns?: Array<Preferences_Update_Column>
  where?: InputMaybe<Preferences_Bool_Exp>
}

/** Ordering options when selecting data from "preferences". */
export type Preferences_Order_By = {
  account?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  language?: InputMaybe<Order_By>
  notifications?: InputMaybe<Order_By>
  personalized?: InputMaybe<Order_By>
  region?: InputMaybe<Order_By>
  secondary_currency?: InputMaybe<Order_By>
  theme?: InputMaybe<Order_By>
}

/** primary key columns input for table: preferences */
export type Preferences_Pk_Columns_Input = {
  account: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Preferences_Prepend_Input = {
  secondary_currency?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "preferences" */
export enum Preferences_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Currency = 'currency',
  /** column name */
  Language = 'language',
  /** column name */
  Notifications = 'notifications',
  /** column name */
  Personalized = 'personalized',
  /** column name */
  Region = 'region',
  /** column name */
  SecondaryCurrency = 'secondary_currency',
  /** column name */
  Theme = 'theme',
}

/** input type for updating data in table "preferences" */
export type Preferences_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  notifications?: InputMaybe<Scalars['Boolean']>
  personalized?: InputMaybe<Scalars['Boolean']>
  region?: InputMaybe<Scalars['String']>
  secondary_currency?: InputMaybe<Scalars['jsonb']>
  theme?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "preferences" */
export type Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Preferences_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Preferences_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  notifications?: InputMaybe<Scalars['Boolean']>
  personalized?: InputMaybe<Scalars['Boolean']>
  region?: InputMaybe<Scalars['String']>
  secondary_currency?: InputMaybe<Scalars['jsonb']>
  theme?: InputMaybe<Scalars['String']>
}

/** update columns of table "preferences" */
export enum Preferences_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Currency = 'currency',
  /** column name */
  Language = 'language',
  /** column name */
  Notifications = 'notifications',
  /** column name */
  Personalized = 'personalized',
  /** column name */
  Region = 'region',
  /** column name */
  SecondaryCurrency = 'secondary_currency',
  /** column name */
  Theme = 'theme',
}

export type Preferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Preferences_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Preferences_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Preferences_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Preferences_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Preferences_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Preferences_Set_Input>
  /** filter the rows which have to be updated */
  where: Preferences_Bool_Exp
}

export type Query_Root = {
  /** fetch data from the table: "account_statistics" */
  account_statistics: Array<Account_Statistics>
  /** fetch aggregated fields from the table: "account_statistics" */
  account_statistics_aggregate: Account_Statistics_Aggregate
  /** fetch data from the table: "accounts_information" */
  accounts_information: Array<Accounts_Information>
  /** fetch aggregated fields from the table: "accounts_information" */
  accounts_information_aggregate: Accounts_Information_Aggregate
  /** fetch data from the table: "accounts_information" using primary key columns */
  accounts_information_by_pk?: Maybe<Accounts_Information>
  /** fetch data from the table: "accounts_referral" */
  accounts_referral: Array<Accounts_Referral>
  /** fetch aggregated fields from the table: "accounts_referral" */
  accounts_referral_aggregate: Accounts_Referral_Aggregate
  /** fetch data from the table: "accounts_referral" using primary key columns */
  accounts_referral_by_pk?: Maybe<Accounts_Referral>
  /** fetch data from the table: "actions" */
  actions: Array<Actions>
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>
  apollo_auth_health_check?: Maybe<Scalars['String']>
  apollo_health_check?: Maybe<Scalars['Boolean']>
  /** fetch data from the table: "avg_pay_time" */
  avg_pay_time: Array<Avg_Pay_Time>
  /** fetch aggregated fields from the table: "avg_pay_time" */
  avg_pay_time_aggregate: Avg_Pay_Time_Aggregate
  /** fetch data from the table: "avg_release_time" */
  avg_release_time: Array<Avg_Release_Time>
  /** fetch aggregated fields from the table: "avg_release_time" */
  avg_release_time_aggregate: Avg_Release_Time_Aggregate
  /** fetch data from the table: "combined_avg_times" */
  combined_avg_times: Array<Combined_Avg_Times>
  /** fetch aggregated fields from the table: "combined_avg_times" */
  combined_avg_times_aggregate: Combined_Avg_Times_Aggregate
  /** An array relationship */
  devices: Array<Devices>
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>
  /** execute function "get_inactive_accounts" which returns "reg_accounts" */
  get_inactive_accounts: Array<Reg_Accounts>
  /** execute function "get_inactive_accounts" and query aggregates on result of table type "reg_accounts" */
  get_inactive_accounts_aggregate: Reg_Accounts_Aggregate
  /** execute function "get_login_counts" which returns "login_counts" */
  get_login_counts: Array<Login_Counts>
  /** execute function "get_login_counts" and query aggregates on result of table type "login_counts" */
  get_login_counts_aggregate: Login_Counts_Aggregate
  /** execute function "get_p2p_offers_with_old_or_no_messages" which returns "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages: Array<P2p_Offers>
  /** execute function "get_p2p_offers_with_old_or_no_messages" and query aggregates on result of table type "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages_aggregate: P2p_Offers_Aggregate
  get_trust_network?: Maybe<Trust_Network_Output>
  id_verification?: Maybe<Id_Verification_Output>
  /** fetch data from the table: "login_account" */
  login_account: Array<Login_Account>
  /** fetch aggregated fields from the table: "login_account" */
  login_account_aggregate: Login_Account_Aggregate
  /** fetch data from the table: "login_account" using primary key columns */
  login_account_by_pk?: Maybe<Login_Account>
  /** fetch data from the table: "login_counts" */
  login_counts: Array<Login_Counts>
  /** fetch aggregated fields from the table: "login_counts" */
  login_counts_aggregate: Login_Counts_Aggregate
  /** An array relationship */
  messages: Array<Messages>
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>
  /** fetch data from the table: "migrate_device" */
  migrate_device: Array<Migrate_Device>
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate: Migrate_Device_Aggregate
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk?: Maybe<Migrate_Device>
  /** An array relationship */
  notifications: Array<Notifications>
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>
  /** An array relationship */
  p2p_offers: Array<P2p_Offers>
  /** An aggregate relationship */
  p2p_offers_aggregate: P2p_Offers_Aggregate
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk?: Maybe<P2p_Offers>
  /** An array relationship */
  p2p_pre_sell_offers: Array<P2p_Pre_Sell_Offers>
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate: P2p_Pre_Sell_Offers_Aggregate
  /** fetch data from the table: "p2p_pre_sell_offers" using primary key columns */
  p2p_pre_sell_offers_by_pk?: Maybe<P2p_Pre_Sell_Offers>
  photo_id_verification?: Maybe<Photo_Id_Verification_Output>
  /** fetch data from the table: "preferences" */
  preferences: Array<Preferences>
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: Preferences_Aggregate
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: Maybe<Preferences>
  /** fetch data from the table: "reg_accounts" */
  reg_accounts: Array<Reg_Accounts>
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate: Reg_Accounts_Aggregate
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk?: Maybe<Reg_Accounts>
  /** fetch data from the table: "reputation_score" */
  reputation_score: Array<Reputation_Score>
  /** fetch aggregated fields from the table: "reputation_score" */
  reputation_score_aggregate: Reputation_Score_Aggregate
  /** fetch data from the table: "reputation_score" using primary key columns */
  reputation_score_by_pk?: Maybe<Reputation_Score>
  selfie_verification?: Maybe<Selfie_Verification_Output>
  /** fetch data from the table: "signing_requests" */
  signing_requests: Array<Signing_Requests>
  /** fetch aggregated fields from the table: "signing_requests" */
  signing_requests_aggregate: Signing_Requests_Aggregate
  /** fetch data from the table: "signing_requests" using primary key columns */
  signing_requests_by_pk?: Maybe<Signing_Requests>
  /** An array relationship */
  support_sessions: Array<Support_Sessions>
  /** An aggregate relationship */
  support_sessions_aggregate: Support_Sessions_Aggregate
  /** fetch data from the table: "support_sessions" using primary key columns */
  support_sessions_by_pk?: Maybe<Support_Sessions>
  /** fetch data from the table: "swap_assets" */
  swap_assets: Array<Swap_Assets>
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate: Swap_Assets_Aggregate
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk?: Maybe<Swap_Assets>
  /** An array relationship */
  swap_orders: Array<Swap_Orders>
  /** An aggregate relationship */
  swap_orders_aggregate: Swap_Orders_Aggregate
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk?: Maybe<Swap_Orders>
  /** fetch data from the table: "system_notification_read_status" */
  system_notification_read_status: Array<System_Notification_Read_Status>
  /** fetch aggregated fields from the table: "system_notification_read_status" */
  system_notification_read_status_aggregate: System_Notification_Read_Status_Aggregate
  /** fetch data from the table: "system_notification_read_status" using primary key columns */
  system_notification_read_status_by_pk?: Maybe<System_Notification_Read_Status>
  /** An array relationship */
  system_notifications: Array<System_Notifications>
  /** An aggregate relationship */
  system_notifications_aggregate: System_Notifications_Aggregate
  /** fetch data from the table: "system_notifications" using primary key columns */
  system_notifications_by_pk?: Maybe<System_Notifications>
  /** fetch data from the table: "system_type_notification" */
  system_type_notification: Array<System_Type_Notification>
  /** fetch aggregated fields from the table: "system_type_notification" */
  system_type_notification_aggregate: System_Type_Notification_Aggregate
  /** fetch data from the table: "system_type_notification" using primary key columns */
  system_type_notification_by_pk?: Maybe<System_Type_Notification>
  /** fetch data from the table: "table_rows" */
  table_rows: Array<Table_Rows>
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate: Table_Rows_Aggregate
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk?: Maybe<Table_Rows>
  /** fetch data from the table: "trust_network" */
  trust_network: Array<Trust_Network>
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate: Trust_Network_Aggregate
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk?: Maybe<Trust_Network>
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification: Array<Trust_Network_Notification>
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate: Trust_Network_Notification_Aggregate
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>
  /** fetch data from the table: "trust_network_status" */
  trust_network_status: Array<Trust_Network_Status>
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate: Trust_Network_Status_Aggregate
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk?: Maybe<Trust_Network_Status>
  /** fetch data from the table: "tx_statistics" */
  tx_statistics: Array<Tx_Statistics>
  /** fetch aggregated fields from the table: "tx_statistics" */
  tx_statistics_aggregate: Tx_Statistics_Aggregate
  /** fetch data from the table: "volume_statistics" */
  volume_statistics: Array<Volume_Statistics>
  /** fetch aggregated fields from the table: "volume_statistics" */
  volume_statistics_aggregate: Volume_Statistics_Aggregate
  /** fetch data from the table: "web_push" */
  web_push: Array<Web_Push>
  /** fetch aggregated fields from the table: "web_push" */
  web_push_aggregate: Web_Push_Aggregate
  /** fetch data from the table: "web_push" using primary key columns */
  web_push_by_pk?: Maybe<Web_Push>
}

export type Query_RootAccount_StatisticsArgs = {
  distinct_on?: InputMaybe<Array<Account_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Account_Statistics_Order_By>>
  where?: InputMaybe<Account_Statistics_Bool_Exp>
}

export type Query_RootAccount_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Account_Statistics_Order_By>>
  where?: InputMaybe<Account_Statistics_Bool_Exp>
}

export type Query_RootAccounts_InformationArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Information_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Information_Order_By>>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

export type Query_RootAccounts_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Information_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Information_Order_By>>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

export type Query_RootAccounts_Information_By_PkArgs = {
  account: Scalars['String']
  id: Scalars['uuid']
}

export type Query_RootAccounts_ReferralArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Referral_Order_By>>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

export type Query_RootAccounts_Referral_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Referral_Order_By>>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

export type Query_RootAccounts_Referral_By_PkArgs = {
  link: Scalars['String']
}

export type Query_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Query_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Query_RootActions_By_PkArgs = {
  global_sequence: Scalars['String']
}

export type Query_RootAvg_Pay_TimeArgs = {
  distinct_on?: InputMaybe<Array<Avg_Pay_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Pay_Time_Order_By>>
  where?: InputMaybe<Avg_Pay_Time_Bool_Exp>
}

export type Query_RootAvg_Pay_Time_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avg_Pay_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Pay_Time_Order_By>>
  where?: InputMaybe<Avg_Pay_Time_Bool_Exp>
}

export type Query_RootAvg_Release_TimeArgs = {
  distinct_on?: InputMaybe<Array<Avg_Release_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Release_Time_Order_By>>
  where?: InputMaybe<Avg_Release_Time_Bool_Exp>
}

export type Query_RootAvg_Release_Time_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avg_Release_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Release_Time_Order_By>>
  where?: InputMaybe<Avg_Release_Time_Bool_Exp>
}

export type Query_RootCombined_Avg_TimesArgs = {
  distinct_on?: InputMaybe<Array<Combined_Avg_Times_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Combined_Avg_Times_Order_By>>
  where?: InputMaybe<Combined_Avg_Times_Bool_Exp>
}

export type Query_RootCombined_Avg_Times_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Combined_Avg_Times_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Combined_Avg_Times_Order_By>>
  where?: InputMaybe<Combined_Avg_Times_Bool_Exp>
}

export type Query_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Devices_Order_By>>
  where?: InputMaybe<Devices_Bool_Exp>
}

export type Query_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Devices_Order_By>>
  where?: InputMaybe<Devices_Bool_Exp>
}

export type Query_RootDevices_By_PkArgs = {
  cred_id: Scalars['String']
}

export type Query_RootGet_Inactive_AccountsArgs = {
  args: Get_Inactive_Accounts_Args
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Query_RootGet_Inactive_Accounts_AggregateArgs = {
  args: Get_Inactive_Accounts_Args
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Query_RootGet_Login_CountsArgs = {
  args: Get_Login_Counts_Args
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Query_RootGet_Login_Counts_AggregateArgs = {
  args: Get_Login_Counts_Args
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Query_RootGet_P2p_Offers_With_Old_Or_No_MessagesArgs = {
  args: Get_P2p_Offers_With_Old_Or_No_Messages_Args
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Query_RootGet_P2p_Offers_With_Old_Or_No_Messages_AggregateArgs = {
  args: Get_P2p_Offers_With_Old_Or_No_Messages_Args
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Query_RootGet_Trust_NetworkArgs = {
  account?: InputMaybe<Scalars['String']>
}

export type Query_RootId_VerificationArgs = {
  id_data?: InputMaybe<Id_Verification_Input>
}

export type Query_RootLogin_AccountArgs = {
  distinct_on?: InputMaybe<Array<Login_Account_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Account_Order_By>>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

export type Query_RootLogin_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Login_Account_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Account_Order_By>>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

export type Query_RootLogin_Account_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLogin_CountsArgs = {
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Query_RootLogin_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Query_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

export type Query_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

export type Query_RootMessages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMigrate_DeviceArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>
  where?: InputMaybe<Migrate_Device_Bool_Exp>
}

export type Query_RootMigrate_Device_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>
  where?: InputMaybe<Migrate_Device_Bool_Exp>
}

export type Query_RootMigrate_Device_By_PkArgs = {
  cred_id: Scalars['String']
}

export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootP2p_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Query_RootP2p_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Query_RootP2p_Offers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootP2p_Pre_Sell_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Pre_Sell_Offers_Order_By>>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

export type Query_RootP2p_Pre_Sell_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Pre_Sell_Offers_Order_By>>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

export type Query_RootP2p_Pre_Sell_Offers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPhoto_Id_VerificationArgs = {
  photo_id?: InputMaybe<Scalars['String']>
}

export type Query_RootPreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Preferences_Order_By>>
  where?: InputMaybe<Preferences_Bool_Exp>
}

export type Query_RootPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Preferences_Order_By>>
  where?: InputMaybe<Preferences_Bool_Exp>
}

export type Query_RootPreferences_By_PkArgs = {
  account: Scalars['String']
}

export type Query_RootReg_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Query_RootReg_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Query_RootReg_Accounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootReputation_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Reputation_Score_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reputation_Score_Order_By>>
  where?: InputMaybe<Reputation_Score_Bool_Exp>
}

export type Query_RootReputation_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reputation_Score_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reputation_Score_Order_By>>
  where?: InputMaybe<Reputation_Score_Bool_Exp>
}

export type Query_RootReputation_Score_By_PkArgs = {
  account: Scalars['String']
}

export type Query_RootSelfie_VerificationArgs = {
  selfie?: InputMaybe<Scalars['String']>
}

export type Query_RootSigning_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Signing_Requests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Signing_Requests_Order_By>>
  where?: InputMaybe<Signing_Requests_Bool_Exp>
}

export type Query_RootSigning_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Signing_Requests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Signing_Requests_Order_By>>
  where?: InputMaybe<Signing_Requests_Bool_Exp>
}

export type Query_RootSigning_Requests_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSupport_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Support_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Support_Sessions_Order_By>>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

export type Query_RootSupport_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Support_Sessions_Order_By>>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

export type Query_RootSupport_Sessions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSwap_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>
  where?: InputMaybe<Swap_Assets_Bool_Exp>
}

export type Query_RootSwap_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>
  where?: InputMaybe<Swap_Assets_Bool_Exp>
}

export type Query_RootSwap_Assets_By_PkArgs = {
  asset: Scalars['String']
  chain: Scalars['String']
}

export type Query_RootSwap_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

export type Query_RootSwap_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

export type Query_RootSwap_Orders_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSystem_Notification_Read_StatusArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

export type Query_RootSystem_Notification_Read_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

export type Query_RootSystem_Notification_Read_Status_By_PkArgs = {
  notification_id: Scalars['uuid']
  reg_account: Scalars['uuid']
}

export type Query_RootSystem_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

export type Query_RootSystem_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

export type Query_RootSystem_Notifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSystem_Type_NotificationArgs = {
  distinct_on?: InputMaybe<Array<System_Type_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Type_Notification_Order_By>>
  where?: InputMaybe<System_Type_Notification_Bool_Exp>
}

export type Query_RootSystem_Type_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Type_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Type_Notification_Order_By>>
  where?: InputMaybe<System_Type_Notification_Bool_Exp>
}

export type Query_RootSystem_Type_Notification_By_PkArgs = {
  type: Scalars['String']
}

export type Query_RootTable_RowsArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Query_RootTable_Rows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Query_RootTable_Rows_By_PkArgs = {
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

export type Query_RootTrust_NetworkArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

export type Query_RootTrust_Network_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

export type Query_RootTrust_Network_By_PkArgs = {
  account: Scalars['String']
  trust: Scalars['String']
}

export type Query_RootTrust_Network_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

export type Query_RootTrust_Network_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

export type Query_RootTrust_Network_Notification_By_PkArgs = {
  account: Scalars['String']
  trust: Scalars['String']
}

export type Query_RootTrust_Network_StatusArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>
}

export type Query_RootTrust_Network_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>
}

export type Query_RootTrust_Network_Status_By_PkArgs = {
  status: Scalars['String']
}

export type Query_RootTx_StatisticsArgs = {
  distinct_on?: InputMaybe<Array<Tx_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tx_Statistics_Order_By>>
  where?: InputMaybe<Tx_Statistics_Bool_Exp>
}

export type Query_RootTx_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tx_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tx_Statistics_Order_By>>
  where?: InputMaybe<Tx_Statistics_Bool_Exp>
}

export type Query_RootVolume_StatisticsArgs = {
  distinct_on?: InputMaybe<Array<Volume_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Volume_Statistics_Order_By>>
  where?: InputMaybe<Volume_Statistics_Bool_Exp>
}

export type Query_RootVolume_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Volume_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Volume_Statistics_Order_By>>
  where?: InputMaybe<Volume_Statistics_Bool_Exp>
}

export type Query_RootWeb_PushArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Web_Push_Order_By>>
  where?: InputMaybe<Web_Push_Bool_Exp>
}

export type Query_RootWeb_Push_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Web_Push_Order_By>>
  where?: InputMaybe<Web_Push_Bool_Exp>
}

export type Query_RootWeb_Push_By_PkArgs = {
  auth: Scalars['String']
  p256dh: Scalars['String']
}

/** columns and relationships of "reg_accounts" */
export type Reg_Accounts = {
  account: Scalars['String']
  /** An object relationship */
  accounts_information?: Maybe<Accounts_Information>
  /** An array relationship */
  accounts_referrals: Array<Accounts_Referral>
  /** An aggregate relationship */
  accounts_referrals_aggregate: Accounts_Referral_Aggregate
  create_account?: Maybe<Scalars['Boolean']>
  created?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamp']>
  cred_id?: Maybe<Scalars['String']>
  device_name?: Maybe<Scalars['String']>
  /** An array relationship */
  devices: Array<Devices>
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate
  freezed: Scalars['Boolean']
  id: Scalars['uuid']
  is_verified?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  login_accounts: Array<Login_Account>
  /** An aggregate relationship */
  login_accounts_aggregate: Login_Account_Aggregate
  /** An array relationship */
  messages: Array<Messages>
  /** An array relationship */
  messagesByTo: Array<Messages>
  /** An aggregate relationship */
  messagesByTo_aggregate: Messages_Aggregate
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate
  /** An array relationship */
  notifications: Array<Notifications>
  /** An array relationship */
  notificationsByFrom: Array<Notifications>
  /** An aggregate relationship */
  notificationsByFrom_aggregate: Notifications_Aggregate
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate
  onboarded?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  p2p_offers: Array<P2p_Offers>
  /** An aggregate relationship */
  p2p_offers_aggregate: P2p_Offers_Aggregate
  /** An array relationship */
  p2p_offers_buyer: Array<P2p_Offers>
  /** An aggregate relationship */
  p2p_offers_buyer_aggregate: P2p_Offers_Aggregate
  /** An array relationship */
  p2p_offers_initiator: Array<P2p_Offers>
  /** An aggregate relationship */
  p2p_offers_initiator_aggregate: P2p_Offers_Aggregate
  /** An array relationship */
  p2p_offers_seller: Array<P2p_Offers>
  /** An aggregate relationship */
  p2p_offers_seller_aggregate: P2p_Offers_Aggregate
  /** An array relationship */
  p2p_pre_sell_offers: Array<P2p_Pre_Sell_Offers>
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate: P2p_Pre_Sell_Offers_Aggregate
  public_key?: Maybe<Scalars['String']>
  referrer: Scalars['String']
  /** An array relationship */
  regAccountsByCancelledBy: Array<P2p_Offers>
  /** An aggregate relationship */
  regAccountsByCancelledBy_aggregate: P2p_Offers_Aggregate
  /** An array relationship */
  reg_accounts_accounts_information: Array<Accounts_Information>
  /** An aggregate relationship */
  reg_accounts_accounts_information_aggregate: Accounts_Information_Aggregate
  /** An object relationship */
  reputation_score?: Maybe<Reputation_Score>
  role: Scalars['String']
  source?: Maybe<Scalars['String']>
  /** An array relationship */
  support_sessions: Array<Support_Sessions>
  /** An aggregate relationship */
  support_sessions_aggregate: Support_Sessions_Aggregate
  /** An array relationship */
  swap_orders: Array<Swap_Orders>
  /** An aggregate relationship */
  swap_orders_aggregate: Swap_Orders_Aggregate
  /** An array relationship */
  system_notification_read_statuses: Array<System_Notification_Read_Status>
  /** An aggregate relationship */
  system_notification_read_statuses_aggregate: System_Notification_Read_Status_Aggregate
  /** An array relationship */
  trustNetworkNotificationsByTrust: Array<Trust_Network_Notification>
  /** An aggregate relationship */
  trustNetworkNotificationsByTrust_aggregate: Trust_Network_Notification_Aggregate
  /** An array relationship */
  trust_network_notifications: Array<Trust_Network_Notification>
  /** An aggregate relationship */
  trust_network_notifications_aggregate: Trust_Network_Notification_Aggregate
  /** An array relationship */
  trust_networks_by_account: Array<Trust_Network>
  /** An aggregate relationship */
  trust_networks_by_account_aggregate: Trust_Network_Aggregate
  /** An array relationship */
  trust_networks_by_trust: Array<Trust_Network>
  /** An aggregate relationship */
  trust_networks_by_trust_aggregate: Trust_Network_Aggregate
  txid?: Maybe<Scalars['String']>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsAccounts_ReferralsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Referral_Order_By>>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsAccounts_Referrals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Referral_Order_By>>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Devices_Order_By>>
  where?: InputMaybe<Devices_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Devices_Order_By>>
  where?: InputMaybe<Devices_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsLogin_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Login_Account_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Account_Order_By>>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsLogin_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Login_Account_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Account_Order_By>>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessagesByToArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessagesByTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotificationsByFromArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotificationsByFrom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_BuyerArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_Buyer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_InitiatorArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_Initiator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_SellerArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_Seller_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Pre_Sell_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Pre_Sell_Offers_Order_By>>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Pre_Sell_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Pre_Sell_Offers_Order_By>>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsRegAccountsByCancelledByArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsRegAccountsByCancelledBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsReg_Accounts_Accounts_InformationArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Information_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Information_Order_By>>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsReg_Accounts_Accounts_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Information_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Information_Order_By>>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSupport_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Support_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Support_Sessions_Order_By>>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSupport_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Support_Sessions_Order_By>>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSwap_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSwap_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSystem_Notification_Read_StatusesArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSystem_Notification_Read_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrustNetworkNotificationsByTrustArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrustNetworkNotificationsByTrust_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Network_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Network_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_AccountArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_TrustArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_Trust_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

export type Reg_Accounts_Aggregate = {
  aggregate?: Maybe<Reg_Accounts_Aggregate_Fields>
  nodes: Array<Reg_Accounts>
}

/** aggregate fields of "reg_accounts" */
export type Reg_Accounts_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Reg_Accounts_Max_Fields>
  min?: Maybe<Reg_Accounts_Min_Fields>
}

/** aggregate fields of "reg_accounts" */
export type Reg_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "reg_accounts". All fields are combined with a logical 'AND'. */
export type Reg_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Reg_Accounts_Bool_Exp>>
  _not?: InputMaybe<Reg_Accounts_Bool_Exp>
  _or?: InputMaybe<Array<Reg_Accounts_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  accounts_information?: InputMaybe<Accounts_Information_Bool_Exp>
  accounts_referrals?: InputMaybe<Accounts_Referral_Bool_Exp>
  accounts_referrals_aggregate?: InputMaybe<Accounts_Referral_Aggregate_Bool_Exp>
  create_account?: InputMaybe<Boolean_Comparison_Exp>
  created?: InputMaybe<Timestamptz_Comparison_Exp>
  created_at?: InputMaybe<Timestamp_Comparison_Exp>
  cred_id?: InputMaybe<String_Comparison_Exp>
  device_name?: InputMaybe<String_Comparison_Exp>
  devices?: InputMaybe<Devices_Bool_Exp>
  devices_aggregate?: InputMaybe<Devices_Aggregate_Bool_Exp>
  freezed?: InputMaybe<Boolean_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_verified?: InputMaybe<Boolean_Comparison_Exp>
  login_accounts?: InputMaybe<Login_Account_Bool_Exp>
  login_accounts_aggregate?: InputMaybe<Login_Account_Aggregate_Bool_Exp>
  messages?: InputMaybe<Messages_Bool_Exp>
  messagesByTo?: InputMaybe<Messages_Bool_Exp>
  messagesByTo_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>
  notifications?: InputMaybe<Notifications_Bool_Exp>
  notificationsByFrom?: InputMaybe<Notifications_Bool_Exp>
  notificationsByFrom_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>
  onboarded?: InputMaybe<Boolean_Comparison_Exp>
  p2p_offers?: InputMaybe<P2p_Offers_Bool_Exp>
  p2p_offers_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>
  p2p_offers_buyer?: InputMaybe<P2p_Offers_Bool_Exp>
  p2p_offers_buyer_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>
  p2p_offers_initiator?: InputMaybe<P2p_Offers_Bool_Exp>
  p2p_offers_initiator_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>
  p2p_offers_seller?: InputMaybe<P2p_Offers_Bool_Exp>
  p2p_offers_seller_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>
  p2p_pre_sell_offers?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
  p2p_pre_sell_offers_aggregate?: InputMaybe<P2p_Pre_Sell_Offers_Aggregate_Bool_Exp>
  public_key?: InputMaybe<String_Comparison_Exp>
  referrer?: InputMaybe<String_Comparison_Exp>
  regAccountsByCancelledBy?: InputMaybe<P2p_Offers_Bool_Exp>
  regAccountsByCancelledBy_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>
  reg_accounts_accounts_information?: InputMaybe<Accounts_Information_Bool_Exp>
  reg_accounts_accounts_information_aggregate?: InputMaybe<Accounts_Information_Aggregate_Bool_Exp>
  reputation_score?: InputMaybe<Reputation_Score_Bool_Exp>
  role?: InputMaybe<String_Comparison_Exp>
  source?: InputMaybe<String_Comparison_Exp>
  support_sessions?: InputMaybe<Support_Sessions_Bool_Exp>
  support_sessions_aggregate?: InputMaybe<Support_Sessions_Aggregate_Bool_Exp>
  swap_orders?: InputMaybe<Swap_Orders_Bool_Exp>
  swap_orders_aggregate?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp>
  system_notification_read_statuses?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
  system_notification_read_statuses_aggregate?: InputMaybe<System_Notification_Read_Status_Aggregate_Bool_Exp>
  trustNetworkNotificationsByTrust?: InputMaybe<Trust_Network_Notification_Bool_Exp>
  trustNetworkNotificationsByTrust_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Bool_Exp>
  trust_network_notifications?: InputMaybe<Trust_Network_Notification_Bool_Exp>
  trust_network_notifications_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Bool_Exp>
  trust_networks_by_account?: InputMaybe<Trust_Network_Bool_Exp>
  trust_networks_by_account_aggregate?: InputMaybe<Trust_Network_Aggregate_Bool_Exp>
  trust_networks_by_trust?: InputMaybe<Trust_Network_Bool_Exp>
  trust_networks_by_trust_aggregate?: InputMaybe<Trust_Network_Aggregate_Bool_Exp>
  txid?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "reg_accounts" */
export enum Reg_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint on columns "account" */
  RegAccountsAccountKey = 'reg_accounts_account_key',
}

/** input type for inserting data into table "reg_accounts" */
export type Reg_Accounts_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  accounts_information?: InputMaybe<Accounts_Information_Obj_Rel_Insert_Input>
  accounts_referrals?: InputMaybe<Accounts_Referral_Arr_Rel_Insert_Input>
  create_account?: InputMaybe<Scalars['Boolean']>
  created?: InputMaybe<Scalars['timestamptz']>
  created_at?: InputMaybe<Scalars['timestamp']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  devices?: InputMaybe<Devices_Arr_Rel_Insert_Input>
  freezed?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  is_verified?: InputMaybe<Scalars['Boolean']>
  login_accounts?: InputMaybe<Login_Account_Arr_Rel_Insert_Input>
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>
  messagesByTo?: InputMaybe<Messages_Arr_Rel_Insert_Input>
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>
  notificationsByFrom?: InputMaybe<Notifications_Arr_Rel_Insert_Input>
  onboarded?: InputMaybe<Scalars['Boolean']>
  p2p_offers?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>
  p2p_offers_buyer?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>
  p2p_offers_initiator?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>
  p2p_offers_seller?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>
  p2p_pre_sell_offers?: InputMaybe<P2p_Pre_Sell_Offers_Arr_Rel_Insert_Input>
  public_key?: InputMaybe<Scalars['String']>
  referrer?: InputMaybe<Scalars['String']>
  regAccountsByCancelledBy?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>
  reg_accounts_accounts_information?: InputMaybe<Accounts_Information_Arr_Rel_Insert_Input>
  reputation_score?: InputMaybe<Reputation_Score_Obj_Rel_Insert_Input>
  role?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
  support_sessions?: InputMaybe<Support_Sessions_Arr_Rel_Insert_Input>
  swap_orders?: InputMaybe<Swap_Orders_Arr_Rel_Insert_Input>
  system_notification_read_statuses?: InputMaybe<System_Notification_Read_Status_Arr_Rel_Insert_Input>
  trustNetworkNotificationsByTrust?: InputMaybe<Trust_Network_Notification_Arr_Rel_Insert_Input>
  trust_network_notifications?: InputMaybe<Trust_Network_Notification_Arr_Rel_Insert_Input>
  trust_networks_by_account?: InputMaybe<Trust_Network_Arr_Rel_Insert_Input>
  trust_networks_by_trust?: InputMaybe<Trust_Network_Arr_Rel_Insert_Input>
  txid?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Reg_Accounts_Max_Fields = {
  account?: Maybe<Scalars['String']>
  created?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamp']>
  cred_id?: Maybe<Scalars['String']>
  device_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  public_key?: Maybe<Scalars['String']>
  referrer?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  txid?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Reg_Accounts_Min_Fields = {
  account?: Maybe<Scalars['String']>
  created?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamp']>
  cred_id?: Maybe<Scalars['String']>
  device_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  public_key?: Maybe<Scalars['String']>
  referrer?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  txid?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "reg_accounts" */
export type Reg_Accounts_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Reg_Accounts>
}

/** input type for inserting object relation for remote table "reg_accounts" */
export type Reg_Accounts_Obj_Rel_Insert_Input = {
  data: Reg_Accounts_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Reg_Accounts_On_Conflict>
}

/** on_conflict condition type for table "reg_accounts" */
export type Reg_Accounts_On_Conflict = {
  constraint: Reg_Accounts_Constraint
  update_columns?: Array<Reg_Accounts_Update_Column>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

/** Ordering options when selecting data from "reg_accounts". */
export type Reg_Accounts_Order_By = {
  account?: InputMaybe<Order_By>
  accounts_information?: InputMaybe<Accounts_Information_Order_By>
  accounts_referrals_aggregate?: InputMaybe<Accounts_Referral_Aggregate_Order_By>
  create_account?: InputMaybe<Order_By>
  created?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  cred_id?: InputMaybe<Order_By>
  device_name?: InputMaybe<Order_By>
  devices_aggregate?: InputMaybe<Devices_Aggregate_Order_By>
  freezed?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_verified?: InputMaybe<Order_By>
  login_accounts_aggregate?: InputMaybe<Login_Account_Aggregate_Order_By>
  messagesByTo_aggregate?: InputMaybe<Messages_Aggregate_Order_By>
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>
  notificationsByFrom_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>
  onboarded?: InputMaybe<Order_By>
  p2p_offers_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>
  p2p_offers_buyer_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>
  p2p_offers_initiator_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>
  p2p_offers_seller_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>
  p2p_pre_sell_offers_aggregate?: InputMaybe<P2p_Pre_Sell_Offers_Aggregate_Order_By>
  public_key?: InputMaybe<Order_By>
  referrer?: InputMaybe<Order_By>
  regAccountsByCancelledBy_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>
  reg_accounts_accounts_information_aggregate?: InputMaybe<Accounts_Information_Aggregate_Order_By>
  reputation_score?: InputMaybe<Reputation_Score_Order_By>
  role?: InputMaybe<Order_By>
  source?: InputMaybe<Order_By>
  support_sessions_aggregate?: InputMaybe<Support_Sessions_Aggregate_Order_By>
  swap_orders_aggregate?: InputMaybe<Swap_Orders_Aggregate_Order_By>
  system_notification_read_statuses_aggregate?: InputMaybe<System_Notification_Read_Status_Aggregate_Order_By>
  trustNetworkNotificationsByTrust_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Order_By>
  trust_network_notifications_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Order_By>
  trust_networks_by_account_aggregate?: InputMaybe<Trust_Network_Aggregate_Order_By>
  trust_networks_by_trust_aggregate?: InputMaybe<Trust_Network_Aggregate_Order_By>
  txid?: InputMaybe<Order_By>
}

/** primary key columns input for table: reg_accounts */
export type Reg_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "reg_accounts" */
export enum Reg_Accounts_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreateAccount = 'create_account',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  Freezed = 'freezed',
  /** column name */
  Id = 'id',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  Txid = 'txid',
}

/** input type for updating data in table "reg_accounts" */
export type Reg_Accounts_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  create_account?: InputMaybe<Scalars['Boolean']>
  created?: InputMaybe<Scalars['timestamptz']>
  created_at?: InputMaybe<Scalars['timestamp']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  freezed?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  is_verified?: InputMaybe<Scalars['Boolean']>
  onboarded?: InputMaybe<Scalars['Boolean']>
  public_key?: InputMaybe<Scalars['String']>
  referrer?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
  txid?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "reg_accounts" */
export type Reg_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reg_Accounts_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Reg_Accounts_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  create_account?: InputMaybe<Scalars['Boolean']>
  created?: InputMaybe<Scalars['timestamptz']>
  created_at?: InputMaybe<Scalars['timestamp']>
  cred_id?: InputMaybe<Scalars['String']>
  device_name?: InputMaybe<Scalars['String']>
  freezed?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  is_verified?: InputMaybe<Scalars['Boolean']>
  onboarded?: InputMaybe<Scalars['Boolean']>
  public_key?: InputMaybe<Scalars['String']>
  referrer?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
  txid?: InputMaybe<Scalars['String']>
}

/** update columns of table "reg_accounts" */
export enum Reg_Accounts_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreateAccount = 'create_account',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  Freezed = 'freezed',
  /** column name */
  Id = 'id',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  Txid = 'txid',
}

export type Reg_Accounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reg_Accounts_Set_Input>
  /** filter the rows which have to be updated */
  where: Reg_Accounts_Bool_Exp
}

/** columns and relationships of "reputation_score" */
export type Reputation_Score = {
  account: Scalars['String']
  /** An object relationship */
  reg_account: Reg_Accounts
  score: Scalars['numeric']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "reputation_score" */
export type Reputation_Score_Aggregate = {
  aggregate?: Maybe<Reputation_Score_Aggregate_Fields>
  nodes: Array<Reputation_Score>
}

/** aggregate fields of "reputation_score" */
export type Reputation_Score_Aggregate_Fields = {
  avg?: Maybe<Reputation_Score_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Reputation_Score_Max_Fields>
  min?: Maybe<Reputation_Score_Min_Fields>
  stddev?: Maybe<Reputation_Score_Stddev_Fields>
  stddev_pop?: Maybe<Reputation_Score_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Reputation_Score_Stddev_Samp_Fields>
  sum?: Maybe<Reputation_Score_Sum_Fields>
  var_pop?: Maybe<Reputation_Score_Var_Pop_Fields>
  var_samp?: Maybe<Reputation_Score_Var_Samp_Fields>
  variance?: Maybe<Reputation_Score_Variance_Fields>
}

/** aggregate fields of "reputation_score" */
export type Reputation_Score_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reputation_Score_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Reputation_Score_Avg_Fields = {
  score?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "reputation_score". All fields are combined with a logical 'AND'. */
export type Reputation_Score_Bool_Exp = {
  _and?: InputMaybe<Array<Reputation_Score_Bool_Exp>>
  _not?: InputMaybe<Reputation_Score_Bool_Exp>
  _or?: InputMaybe<Array<Reputation_Score_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  score?: InputMaybe<Numeric_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "reputation_score" */
export enum Reputation_Score_Constraint {
  /** unique or primary key constraint on columns "account" */
  ReputationScorePkey = 'reputation_score_pkey',
}

/** input type for incrementing numeric columns in table "reputation_score" */
export type Reputation_Score_Inc_Input = {
  score?: InputMaybe<Scalars['numeric']>
}

/** input type for inserting data into table "reputation_score" */
export type Reputation_Score_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  score?: InputMaybe<Scalars['numeric']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Reputation_Score_Max_Fields = {
  account?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Reputation_Score_Min_Fields = {
  account?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "reputation_score" */
export type Reputation_Score_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Reputation_Score>
}

/** input type for inserting object relation for remote table "reputation_score" */
export type Reputation_Score_Obj_Rel_Insert_Input = {
  data: Reputation_Score_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Reputation_Score_On_Conflict>
}

/** on_conflict condition type for table "reputation_score" */
export type Reputation_Score_On_Conflict = {
  constraint: Reputation_Score_Constraint
  update_columns?: Array<Reputation_Score_Update_Column>
  where?: InputMaybe<Reputation_Score_Bool_Exp>
}

/** Ordering options when selecting data from "reputation_score". */
export type Reputation_Score_Order_By = {
  account?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  score?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: reputation_score */
export type Reputation_Score_Pk_Columns_Input = {
  account: Scalars['String']
}

/** select columns of table "reputation_score" */
export enum Reputation_Score_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "reputation_score" */
export type Reputation_Score_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  score?: InputMaybe<Scalars['numeric']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Reputation_Score_Stddev_Fields = {
  score?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Reputation_Score_Stddev_Pop_Fields = {
  score?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Reputation_Score_Stddev_Samp_Fields = {
  score?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "reputation_score" */
export type Reputation_Score_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reputation_Score_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Reputation_Score_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  score?: InputMaybe<Scalars['numeric']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Reputation_Score_Sum_Fields = {
  score?: Maybe<Scalars['numeric']>
}

/** update columns of table "reputation_score" */
export enum Reputation_Score_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Reputation_Score_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reputation_Score_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reputation_Score_Set_Input>
  /** filter the rows which have to be updated */
  where: Reputation_Score_Bool_Exp
}

/** aggregate var_pop on columns */
export type Reputation_Score_Var_Pop_Fields = {
  score?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Reputation_Score_Var_Samp_Fields = {
  score?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Reputation_Score_Variance_Fields = {
  score?: Maybe<Scalars['Float']>
}

export type Request_New_Account_Input = {
  account: Scalars['String']
  country_id: Scalars['JSON']
  create_account?: InputMaybe<Scalars['Boolean']>
  cred_id: Scalars['String']
  date_of_birth: Scalars['String']
  device_name: Scalars['String']
  email_address: Scalars['String']
  full_name: Scalars['String']
  newsletter_subscription: Scalars['Boolean']
  phone_number: Scalars['String']
  photo_id: Scalars['String']
  public_key: Scalars['String']
  referrer: Scalars['String']
  selfie: Scalars['String']
  source?: InputMaybe<Scalars['String']>
}

export type Request_New_Account_Output = {
  id: Scalars['String']
}

export type Selfie_Verification_Output = {
  error: Scalars['Boolean']
  eye: Scalars['Boolean']
  face: Scalars['Boolean']
}

export type Send_Email_Notification_Input = {
  usersData?: InputMaybe<Array<InputMaybe<UserEmailData>>>
}

export type Send_Email_Notification_Output = {
  success: Scalars['Boolean']
}

export type Short_Link_Input = {
  referrer: Scalars['String']
  website: Scalars['String']
}

/** columns and relationships of "signing_requests" */
export type Signing_Requests = {
  createdAt: Scalars['timestamptz']
  esr: Scalars['String']
  id: Scalars['uuid']
  signature?: Maybe<Scalars['String']>
  signer: Scalars['String']
  status: Scalars['String']
  transactionId?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "signing_requests" */
export type Signing_Requests_Aggregate = {
  aggregate?: Maybe<Signing_Requests_Aggregate_Fields>
  nodes: Array<Signing_Requests>
}

/** aggregate fields of "signing_requests" */
export type Signing_Requests_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Signing_Requests_Max_Fields>
  min?: Maybe<Signing_Requests_Min_Fields>
}

/** aggregate fields of "signing_requests" */
export type Signing_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Signing_Requests_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "signing_requests". All fields are combined with a logical 'AND'. */
export type Signing_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Signing_Requests_Bool_Exp>>
  _not?: InputMaybe<Signing_Requests_Bool_Exp>
  _or?: InputMaybe<Array<Signing_Requests_Bool_Exp>>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  esr?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  signature?: InputMaybe<String_Comparison_Exp>
  signer?: InputMaybe<String_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  transactionId?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "signing_requests" */
export enum Signing_Requests_Constraint {
  /** unique or primary key constraint on columns "id" */
  SigningRequestsPkey = 'signing_requests_pkey',
}

/** input type for inserting data into table "signing_requests" */
export type Signing_Requests_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  esr?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  signature?: InputMaybe<Scalars['String']>
  signer?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  transactionId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Signing_Requests_Max_Fields = {
  createdAt?: Maybe<Scalars['timestamptz']>
  esr?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  signature?: Maybe<Scalars['String']>
  signer?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transactionId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Signing_Requests_Min_Fields = {
  createdAt?: Maybe<Scalars['timestamptz']>
  esr?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  signature?: Maybe<Scalars['String']>
  signer?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transactionId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "signing_requests" */
export type Signing_Requests_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Signing_Requests>
}

/** on_conflict condition type for table "signing_requests" */
export type Signing_Requests_On_Conflict = {
  constraint: Signing_Requests_Constraint
  update_columns?: Array<Signing_Requests_Update_Column>
  where?: InputMaybe<Signing_Requests_Bool_Exp>
}

/** Ordering options when selecting data from "signing_requests". */
export type Signing_Requests_Order_By = {
  createdAt?: InputMaybe<Order_By>
  esr?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  signature?: InputMaybe<Order_By>
  signer?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  transactionId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: signing_requests */
export type Signing_Requests_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "signing_requests" */
export enum Signing_Requests_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Esr = 'esr',
  /** column name */
  Id = 'id',
  /** column name */
  Signature = 'signature',
  /** column name */
  Signer = 'signer',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "signing_requests" */
export type Signing_Requests_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  esr?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  signature?: InputMaybe<Scalars['String']>
  signer?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  transactionId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "signing_requests" */
export type Signing_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Signing_Requests_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Signing_Requests_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  esr?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  signature?: InputMaybe<Scalars['String']>
  signer?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  transactionId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "signing_requests" */
export enum Signing_Requests_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Esr = 'esr',
  /** column name */
  Id = 'id',
  /** column name */
  Signature = 'signature',
  /** column name */
  Signer = 'signer',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Signing_Requests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Signing_Requests_Set_Input>
  /** filter the rows which have to be updated */
  where: Signing_Requests_Bool_Exp
}

export type Store_Ip_Address_Output = {
  success: Scalars['Boolean']
}

export type Subscription_Root = {
  /** fetch data from the table: "account_statistics" */
  account_statistics: Array<Account_Statistics>
  /** fetch aggregated fields from the table: "account_statistics" */
  account_statistics_aggregate: Account_Statistics_Aggregate
  /** fetch data from the table in a streaming manner: "account_statistics" */
  account_statistics_stream: Array<Account_Statistics>
  /** fetch data from the table: "accounts_information" */
  accounts_information: Array<Accounts_Information>
  /** fetch aggregated fields from the table: "accounts_information" */
  accounts_information_aggregate: Accounts_Information_Aggregate
  /** fetch data from the table: "accounts_information" using primary key columns */
  accounts_information_by_pk?: Maybe<Accounts_Information>
  /** fetch data from the table in a streaming manner: "accounts_information" */
  accounts_information_stream: Array<Accounts_Information>
  /** fetch data from the table: "accounts_referral" */
  accounts_referral: Array<Accounts_Referral>
  /** fetch aggregated fields from the table: "accounts_referral" */
  accounts_referral_aggregate: Accounts_Referral_Aggregate
  /** fetch data from the table: "accounts_referral" using primary key columns */
  accounts_referral_by_pk?: Maybe<Accounts_Referral>
  /** fetch data from the table in a streaming manner: "accounts_referral" */
  accounts_referral_stream: Array<Accounts_Referral>
  /** fetch data from the table: "actions" */
  actions: Array<Actions>
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>
  /** fetch data from the table in a streaming manner: "actions" */
  actions_stream: Array<Actions>
  /** fetch data from the table: "avg_pay_time" */
  avg_pay_time: Array<Avg_Pay_Time>
  /** fetch aggregated fields from the table: "avg_pay_time" */
  avg_pay_time_aggregate: Avg_Pay_Time_Aggregate
  /** fetch data from the table in a streaming manner: "avg_pay_time" */
  avg_pay_time_stream: Array<Avg_Pay_Time>
  /** fetch data from the table: "avg_release_time" */
  avg_release_time: Array<Avg_Release_Time>
  /** fetch aggregated fields from the table: "avg_release_time" */
  avg_release_time_aggregate: Avg_Release_Time_Aggregate
  /** fetch data from the table in a streaming manner: "avg_release_time" */
  avg_release_time_stream: Array<Avg_Release_Time>
  /** fetch data from the table: "combined_avg_times" */
  combined_avg_times: Array<Combined_Avg_Times>
  /** fetch aggregated fields from the table: "combined_avg_times" */
  combined_avg_times_aggregate: Combined_Avg_Times_Aggregate
  /** fetch data from the table in a streaming manner: "combined_avg_times" */
  combined_avg_times_stream: Array<Combined_Avg_Times>
  /** An array relationship */
  devices: Array<Devices>
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>
  /** fetch data from the table in a streaming manner: "devices" */
  devices_stream: Array<Devices>
  /** execute function "get_inactive_accounts" which returns "reg_accounts" */
  get_inactive_accounts: Array<Reg_Accounts>
  /** execute function "get_inactive_accounts" and query aggregates on result of table type "reg_accounts" */
  get_inactive_accounts_aggregate: Reg_Accounts_Aggregate
  /** execute function "get_login_counts" which returns "login_counts" */
  get_login_counts: Array<Login_Counts>
  /** execute function "get_login_counts" and query aggregates on result of table type "login_counts" */
  get_login_counts_aggregate: Login_Counts_Aggregate
  /** execute function "get_p2p_offers_with_old_or_no_messages" which returns "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages: Array<P2p_Offers>
  /** execute function "get_p2p_offers_with_old_or_no_messages" and query aggregates on result of table type "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages_aggregate: P2p_Offers_Aggregate
  /** fetch data from the table: "login_account" */
  login_account: Array<Login_Account>
  /** fetch aggregated fields from the table: "login_account" */
  login_account_aggregate: Login_Account_Aggregate
  /** fetch data from the table: "login_account" using primary key columns */
  login_account_by_pk?: Maybe<Login_Account>
  /** fetch data from the table in a streaming manner: "login_account" */
  login_account_stream: Array<Login_Account>
  /** fetch data from the table: "login_counts" */
  login_counts: Array<Login_Counts>
  /** fetch aggregated fields from the table: "login_counts" */
  login_counts_aggregate: Login_Counts_Aggregate
  /** fetch data from the table in a streaming manner: "login_counts" */
  login_counts_stream: Array<Login_Counts>
  /** An array relationship */
  messages: Array<Messages>
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>
  /** fetch data from the table in a streaming manner: "messages" */
  messages_stream: Array<Messages>
  /** fetch data from the table: "migrate_device" */
  migrate_device: Array<Migrate_Device>
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate: Migrate_Device_Aggregate
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk?: Maybe<Migrate_Device>
  /** fetch data from the table in a streaming manner: "migrate_device" */
  migrate_device_stream: Array<Migrate_Device>
  /** An array relationship */
  notifications: Array<Notifications>
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>
  /** An array relationship */
  p2p_offers: Array<P2p_Offers>
  /** An aggregate relationship */
  p2p_offers_aggregate: P2p_Offers_Aggregate
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk?: Maybe<P2p_Offers>
  /** fetch data from the table in a streaming manner: "p2p_offers" */
  p2p_offers_stream: Array<P2p_Offers>
  /** An array relationship */
  p2p_pre_sell_offers: Array<P2p_Pre_Sell_Offers>
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate: P2p_Pre_Sell_Offers_Aggregate
  /** fetch data from the table: "p2p_pre_sell_offers" using primary key columns */
  p2p_pre_sell_offers_by_pk?: Maybe<P2p_Pre_Sell_Offers>
  /** fetch data from the table in a streaming manner: "p2p_pre_sell_offers" */
  p2p_pre_sell_offers_stream: Array<P2p_Pre_Sell_Offers>
  /** fetch data from the table: "preferences" */
  preferences: Array<Preferences>
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: Preferences_Aggregate
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: Maybe<Preferences>
  /** fetch data from the table in a streaming manner: "preferences" */
  preferences_stream: Array<Preferences>
  /** fetch data from the table: "reg_accounts" */
  reg_accounts: Array<Reg_Accounts>
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate: Reg_Accounts_Aggregate
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk?: Maybe<Reg_Accounts>
  /** fetch data from the table in a streaming manner: "reg_accounts" */
  reg_accounts_stream: Array<Reg_Accounts>
  /** fetch data from the table: "reputation_score" */
  reputation_score: Array<Reputation_Score>
  /** fetch aggregated fields from the table: "reputation_score" */
  reputation_score_aggregate: Reputation_Score_Aggregate
  /** fetch data from the table: "reputation_score" using primary key columns */
  reputation_score_by_pk?: Maybe<Reputation_Score>
  /** fetch data from the table in a streaming manner: "reputation_score" */
  reputation_score_stream: Array<Reputation_Score>
  /** fetch data from the table: "signing_requests" */
  signing_requests: Array<Signing_Requests>
  /** fetch aggregated fields from the table: "signing_requests" */
  signing_requests_aggregate: Signing_Requests_Aggregate
  /** fetch data from the table: "signing_requests" using primary key columns */
  signing_requests_by_pk?: Maybe<Signing_Requests>
  /** fetch data from the table in a streaming manner: "signing_requests" */
  signing_requests_stream: Array<Signing_Requests>
  /** An array relationship */
  support_sessions: Array<Support_Sessions>
  /** An aggregate relationship */
  support_sessions_aggregate: Support_Sessions_Aggregate
  /** fetch data from the table: "support_sessions" using primary key columns */
  support_sessions_by_pk?: Maybe<Support_Sessions>
  /** fetch data from the table in a streaming manner: "support_sessions" */
  support_sessions_stream: Array<Support_Sessions>
  /** fetch data from the table: "swap_assets" */
  swap_assets: Array<Swap_Assets>
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate: Swap_Assets_Aggregate
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk?: Maybe<Swap_Assets>
  /** fetch data from the table in a streaming manner: "swap_assets" */
  swap_assets_stream: Array<Swap_Assets>
  /** An array relationship */
  swap_orders: Array<Swap_Orders>
  /** An aggregate relationship */
  swap_orders_aggregate: Swap_Orders_Aggregate
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk?: Maybe<Swap_Orders>
  /** fetch data from the table in a streaming manner: "swap_orders" */
  swap_orders_stream: Array<Swap_Orders>
  /** fetch data from the table: "system_notification_read_status" */
  system_notification_read_status: Array<System_Notification_Read_Status>
  /** fetch aggregated fields from the table: "system_notification_read_status" */
  system_notification_read_status_aggregate: System_Notification_Read_Status_Aggregate
  /** fetch data from the table: "system_notification_read_status" using primary key columns */
  system_notification_read_status_by_pk?: Maybe<System_Notification_Read_Status>
  /** fetch data from the table in a streaming manner: "system_notification_read_status" */
  system_notification_read_status_stream: Array<System_Notification_Read_Status>
  /** An array relationship */
  system_notifications: Array<System_Notifications>
  /** An aggregate relationship */
  system_notifications_aggregate: System_Notifications_Aggregate
  /** fetch data from the table: "system_notifications" using primary key columns */
  system_notifications_by_pk?: Maybe<System_Notifications>
  /** fetch data from the table in a streaming manner: "system_notifications" */
  system_notifications_stream: Array<System_Notifications>
  /** fetch data from the table: "system_type_notification" */
  system_type_notification: Array<System_Type_Notification>
  /** fetch aggregated fields from the table: "system_type_notification" */
  system_type_notification_aggregate: System_Type_Notification_Aggregate
  /** fetch data from the table: "system_type_notification" using primary key columns */
  system_type_notification_by_pk?: Maybe<System_Type_Notification>
  /** fetch data from the table in a streaming manner: "system_type_notification" */
  system_type_notification_stream: Array<System_Type_Notification>
  /** fetch data from the table: "table_rows" */
  table_rows: Array<Table_Rows>
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate: Table_Rows_Aggregate
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk?: Maybe<Table_Rows>
  /** fetch data from the table in a streaming manner: "table_rows" */
  table_rows_stream: Array<Table_Rows>
  /** fetch data from the table: "trust_network" */
  trust_network: Array<Trust_Network>
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate: Trust_Network_Aggregate
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk?: Maybe<Trust_Network>
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification: Array<Trust_Network_Notification>
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate: Trust_Network_Notification_Aggregate
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>
  /** fetch data from the table in a streaming manner: "trust_network_notification" */
  trust_network_notification_stream: Array<Trust_Network_Notification>
  /** fetch data from the table: "trust_network_status" */
  trust_network_status: Array<Trust_Network_Status>
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate: Trust_Network_Status_Aggregate
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk?: Maybe<Trust_Network_Status>
  /** fetch data from the table in a streaming manner: "trust_network_status" */
  trust_network_status_stream: Array<Trust_Network_Status>
  /** fetch data from the table in a streaming manner: "trust_network" */
  trust_network_stream: Array<Trust_Network>
  /** fetch data from the table: "tx_statistics" */
  tx_statistics: Array<Tx_Statistics>
  /** fetch aggregated fields from the table: "tx_statistics" */
  tx_statistics_aggregate: Tx_Statistics_Aggregate
  /** fetch data from the table in a streaming manner: "tx_statistics" */
  tx_statistics_stream: Array<Tx_Statistics>
  /** fetch data from the table: "volume_statistics" */
  volume_statistics: Array<Volume_Statistics>
  /** fetch aggregated fields from the table: "volume_statistics" */
  volume_statistics_aggregate: Volume_Statistics_Aggregate
  /** fetch data from the table in a streaming manner: "volume_statistics" */
  volume_statistics_stream: Array<Volume_Statistics>
  /** fetch data from the table: "web_push" */
  web_push: Array<Web_Push>
  /** fetch aggregated fields from the table: "web_push" */
  web_push_aggregate: Web_Push_Aggregate
  /** fetch data from the table: "web_push" using primary key columns */
  web_push_by_pk?: Maybe<Web_Push>
  /** fetch data from the table in a streaming manner: "web_push" */
  web_push_stream: Array<Web_Push>
}

export type Subscription_RootAccount_StatisticsArgs = {
  distinct_on?: InputMaybe<Array<Account_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Account_Statistics_Order_By>>
  where?: InputMaybe<Account_Statistics_Bool_Exp>
}

export type Subscription_RootAccount_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Account_Statistics_Order_By>>
  where?: InputMaybe<Account_Statistics_Bool_Exp>
}

export type Subscription_RootAccount_Statistics_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Account_Statistics_Stream_Cursor_Input>>
  where?: InputMaybe<Account_Statistics_Bool_Exp>
}

export type Subscription_RootAccounts_InformationArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Information_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Information_Order_By>>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

export type Subscription_RootAccounts_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Information_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Information_Order_By>>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

export type Subscription_RootAccounts_Information_By_PkArgs = {
  account: Scalars['String']
  id: Scalars['uuid']
}

export type Subscription_RootAccounts_Information_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Accounts_Information_Stream_Cursor_Input>>
  where?: InputMaybe<Accounts_Information_Bool_Exp>
}

export type Subscription_RootAccounts_ReferralArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Referral_Order_By>>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

export type Subscription_RootAccounts_Referral_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Referral_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Accounts_Referral_Order_By>>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

export type Subscription_RootAccounts_Referral_By_PkArgs = {
  link: Scalars['String']
}

export type Subscription_RootAccounts_Referral_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Accounts_Referral_Stream_Cursor_Input>>
  where?: InputMaybe<Accounts_Referral_Bool_Exp>
}

export type Subscription_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Subscription_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Subscription_RootActions_By_PkArgs = {
  global_sequence: Scalars['String']
}

export type Subscription_RootActions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Actions_Stream_Cursor_Input>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Subscription_RootAvg_Pay_TimeArgs = {
  distinct_on?: InputMaybe<Array<Avg_Pay_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Pay_Time_Order_By>>
  where?: InputMaybe<Avg_Pay_Time_Bool_Exp>
}

export type Subscription_RootAvg_Pay_Time_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avg_Pay_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Pay_Time_Order_By>>
  where?: InputMaybe<Avg_Pay_Time_Bool_Exp>
}

export type Subscription_RootAvg_Pay_Time_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Avg_Pay_Time_Stream_Cursor_Input>>
  where?: InputMaybe<Avg_Pay_Time_Bool_Exp>
}

export type Subscription_RootAvg_Release_TimeArgs = {
  distinct_on?: InputMaybe<Array<Avg_Release_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Release_Time_Order_By>>
  where?: InputMaybe<Avg_Release_Time_Bool_Exp>
}

export type Subscription_RootAvg_Release_Time_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avg_Release_Time_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Avg_Release_Time_Order_By>>
  where?: InputMaybe<Avg_Release_Time_Bool_Exp>
}

export type Subscription_RootAvg_Release_Time_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Avg_Release_Time_Stream_Cursor_Input>>
  where?: InputMaybe<Avg_Release_Time_Bool_Exp>
}

export type Subscription_RootCombined_Avg_TimesArgs = {
  distinct_on?: InputMaybe<Array<Combined_Avg_Times_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Combined_Avg_Times_Order_By>>
  where?: InputMaybe<Combined_Avg_Times_Bool_Exp>
}

export type Subscription_RootCombined_Avg_Times_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Combined_Avg_Times_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Combined_Avg_Times_Order_By>>
  where?: InputMaybe<Combined_Avg_Times_Bool_Exp>
}

export type Subscription_RootCombined_Avg_Times_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Combined_Avg_Times_Stream_Cursor_Input>>
  where?: InputMaybe<Combined_Avg_Times_Bool_Exp>
}

export type Subscription_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Devices_Order_By>>
  where?: InputMaybe<Devices_Bool_Exp>
}

export type Subscription_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Devices_Order_By>>
  where?: InputMaybe<Devices_Bool_Exp>
}

export type Subscription_RootDevices_By_PkArgs = {
  cred_id: Scalars['String']
}

export type Subscription_RootDevices_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Devices_Stream_Cursor_Input>>
  where?: InputMaybe<Devices_Bool_Exp>
}

export type Subscription_RootGet_Inactive_AccountsArgs = {
  args: Get_Inactive_Accounts_Args
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Subscription_RootGet_Inactive_Accounts_AggregateArgs = {
  args: Get_Inactive_Accounts_Args
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Subscription_RootGet_Login_CountsArgs = {
  args: Get_Login_Counts_Args
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Subscription_RootGet_Login_Counts_AggregateArgs = {
  args: Get_Login_Counts_Args
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Subscription_RootGet_P2p_Offers_With_Old_Or_No_MessagesArgs = {
  args: Get_P2p_Offers_With_Old_Or_No_Messages_Args
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Subscription_RootGet_P2p_Offers_With_Old_Or_No_Messages_AggregateArgs = {
  args: Get_P2p_Offers_With_Old_Or_No_Messages_Args
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Subscription_RootLogin_AccountArgs = {
  distinct_on?: InputMaybe<Array<Login_Account_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Account_Order_By>>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

export type Subscription_RootLogin_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Login_Account_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Account_Order_By>>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

export type Subscription_RootLogin_Account_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLogin_Account_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Login_Account_Stream_Cursor_Input>>
  where?: InputMaybe<Login_Account_Bool_Exp>
}

export type Subscription_RootLogin_CountsArgs = {
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Subscription_RootLogin_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Login_Counts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Login_Counts_Order_By>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Subscription_RootLogin_Counts_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Login_Counts_Stream_Cursor_Input>>
  where?: InputMaybe<Login_Counts_Bool_Exp>
}

export type Subscription_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

export type Subscription_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

export type Subscription_RootMessages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMessages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Messages_Stream_Cursor_Input>>
  where?: InputMaybe<Messages_Bool_Exp>
}

export type Subscription_RootMigrate_DeviceArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>
  where?: InputMaybe<Migrate_Device_Bool_Exp>
}

export type Subscription_RootMigrate_Device_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>
  where?: InputMaybe<Migrate_Device_Bool_Exp>
}

export type Subscription_RootMigrate_Device_By_PkArgs = {
  cred_id: Scalars['String']
}

export type Subscription_RootMigrate_Device_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Migrate_Device_Stream_Cursor_Input>>
  where?: InputMaybe<Migrate_Device_Bool_Exp>
}

export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Notifications_Order_By>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>
  where?: InputMaybe<Notifications_Bool_Exp>
}

export type Subscription_RootP2p_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Subscription_RootP2p_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Subscription_RootP2p_Offers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootP2p_Offers_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<P2p_Offers_Stream_Cursor_Input>>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}

export type Subscription_RootP2p_Pre_Sell_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Pre_Sell_Offers_Order_By>>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

export type Subscription_RootP2p_Pre_Sell_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Pre_Sell_Offers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<P2p_Pre_Sell_Offers_Order_By>>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

export type Subscription_RootP2p_Pre_Sell_Offers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootP2p_Pre_Sell_Offers_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<P2p_Pre_Sell_Offers_Stream_Cursor_Input>>
  where?: InputMaybe<P2p_Pre_Sell_Offers_Bool_Exp>
}

export type Subscription_RootPreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Preferences_Order_By>>
  where?: InputMaybe<Preferences_Bool_Exp>
}

export type Subscription_RootPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Preferences_Order_By>>
  where?: InputMaybe<Preferences_Bool_Exp>
}

export type Subscription_RootPreferences_By_PkArgs = {
  account: Scalars['String']
}

export type Subscription_RootPreferences_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Preferences_Stream_Cursor_Input>>
  where?: InputMaybe<Preferences_Bool_Exp>
}

export type Subscription_RootReg_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Subscription_RootReg_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Subscription_RootReg_Accounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootReg_Accounts_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Reg_Accounts_Stream_Cursor_Input>>
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
}

export type Subscription_RootReputation_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Reputation_Score_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reputation_Score_Order_By>>
  where?: InputMaybe<Reputation_Score_Bool_Exp>
}

export type Subscription_RootReputation_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reputation_Score_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Reputation_Score_Order_By>>
  where?: InputMaybe<Reputation_Score_Bool_Exp>
}

export type Subscription_RootReputation_Score_By_PkArgs = {
  account: Scalars['String']
}

export type Subscription_RootReputation_Score_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Reputation_Score_Stream_Cursor_Input>>
  where?: InputMaybe<Reputation_Score_Bool_Exp>
}

export type Subscription_RootSigning_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Signing_Requests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Signing_Requests_Order_By>>
  where?: InputMaybe<Signing_Requests_Bool_Exp>
}

export type Subscription_RootSigning_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Signing_Requests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Signing_Requests_Order_By>>
  where?: InputMaybe<Signing_Requests_Bool_Exp>
}

export type Subscription_RootSigning_Requests_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSigning_Requests_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Signing_Requests_Stream_Cursor_Input>>
  where?: InputMaybe<Signing_Requests_Bool_Exp>
}

export type Subscription_RootSupport_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Support_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Support_Sessions_Order_By>>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

export type Subscription_RootSupport_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Support_Sessions_Order_By>>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

export type Subscription_RootSupport_Sessions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSupport_Sessions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Support_Sessions_Stream_Cursor_Input>>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

export type Subscription_RootSwap_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>
  where?: InputMaybe<Swap_Assets_Bool_Exp>
}

export type Subscription_RootSwap_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>
  where?: InputMaybe<Swap_Assets_Bool_Exp>
}

export type Subscription_RootSwap_Assets_By_PkArgs = {
  asset: Scalars['String']
  chain: Scalars['String']
}

export type Subscription_RootSwap_Assets_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Swap_Assets_Stream_Cursor_Input>>
  where?: InputMaybe<Swap_Assets_Bool_Exp>
}

export type Subscription_RootSwap_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

export type Subscription_RootSwap_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

export type Subscription_RootSwap_Orders_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSwap_Orders_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Swap_Orders_Stream_Cursor_Input>>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

export type Subscription_RootSystem_Notification_Read_StatusArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

export type Subscription_RootSystem_Notification_Read_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

export type Subscription_RootSystem_Notification_Read_Status_By_PkArgs = {
  notification_id: Scalars['uuid']
  reg_account: Scalars['uuid']
}

export type Subscription_RootSystem_Notification_Read_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<System_Notification_Read_Status_Stream_Cursor_Input>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

export type Subscription_RootSystem_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

export type Subscription_RootSystem_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

export type Subscription_RootSystem_Notifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSystem_Notifications_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<System_Notifications_Stream_Cursor_Input>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

export type Subscription_RootSystem_Type_NotificationArgs = {
  distinct_on?: InputMaybe<Array<System_Type_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Type_Notification_Order_By>>
  where?: InputMaybe<System_Type_Notification_Bool_Exp>
}

export type Subscription_RootSystem_Type_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Type_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Type_Notification_Order_By>>
  where?: InputMaybe<System_Type_Notification_Bool_Exp>
}

export type Subscription_RootSystem_Type_Notification_By_PkArgs = {
  type: Scalars['String']
}

export type Subscription_RootSystem_Type_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<System_Type_Notification_Stream_Cursor_Input>>
  where?: InputMaybe<System_Type_Notification_Bool_Exp>
}

export type Subscription_RootTable_RowsArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Subscription_RootTable_Rows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Subscription_RootTable_Rows_By_PkArgs = {
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

export type Subscription_RootTable_Rows_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Table_Rows_Stream_Cursor_Input>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Subscription_RootTrust_NetworkArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

export type Subscription_RootTrust_Network_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

export type Subscription_RootTrust_Network_By_PkArgs = {
  account: Scalars['String']
  trust: Scalars['String']
}

export type Subscription_RootTrust_Network_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

export type Subscription_RootTrust_Network_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

export type Subscription_RootTrust_Network_Notification_By_PkArgs = {
  account: Scalars['String']
  trust: Scalars['String']
}

export type Subscription_RootTrust_Network_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Trust_Network_Notification_Stream_Cursor_Input>>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

export type Subscription_RootTrust_Network_StatusArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>
}

export type Subscription_RootTrust_Network_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>
}

export type Subscription_RootTrust_Network_Status_By_PkArgs = {
  status: Scalars['String']
}

export type Subscription_RootTrust_Network_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Trust_Network_Status_Stream_Cursor_Input>>
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>
}

export type Subscription_RootTrust_Network_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Trust_Network_Stream_Cursor_Input>>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

export type Subscription_RootTx_StatisticsArgs = {
  distinct_on?: InputMaybe<Array<Tx_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tx_Statistics_Order_By>>
  where?: InputMaybe<Tx_Statistics_Bool_Exp>
}

export type Subscription_RootTx_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tx_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tx_Statistics_Order_By>>
  where?: InputMaybe<Tx_Statistics_Bool_Exp>
}

export type Subscription_RootTx_Statistics_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Tx_Statistics_Stream_Cursor_Input>>
  where?: InputMaybe<Tx_Statistics_Bool_Exp>
}

export type Subscription_RootVolume_StatisticsArgs = {
  distinct_on?: InputMaybe<Array<Volume_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Volume_Statistics_Order_By>>
  where?: InputMaybe<Volume_Statistics_Bool_Exp>
}

export type Subscription_RootVolume_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Volume_Statistics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Volume_Statistics_Order_By>>
  where?: InputMaybe<Volume_Statistics_Bool_Exp>
}

export type Subscription_RootVolume_Statistics_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Volume_Statistics_Stream_Cursor_Input>>
  where?: InputMaybe<Volume_Statistics_Bool_Exp>
}

export type Subscription_RootWeb_PushArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Web_Push_Order_By>>
  where?: InputMaybe<Web_Push_Bool_Exp>
}

export type Subscription_RootWeb_Push_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Web_Push_Order_By>>
  where?: InputMaybe<Web_Push_Bool_Exp>
}

export type Subscription_RootWeb_Push_By_PkArgs = {
  auth: Scalars['String']
  p256dh: Scalars['String']
}

export type Subscription_RootWeb_Push_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Web_Push_Stream_Cursor_Input>>
  where?: InputMaybe<Web_Push_Bool_Exp>
}

/** columns and relationships of "support_sessions" */
export type Support_Sessions = {
  assistant: Scalars['String']
  closed_by: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  reg_account: Reg_Accounts
  session_close_datetime: Scalars['timestamptz']
  session_open_datetime: Scalars['timestamptz']
  status: Scalars['String']
  updated_at: Scalars['timestamptz']
  user: Scalars['String']
}

/** aggregated selection of "support_sessions" */
export type Support_Sessions_Aggregate = {
  aggregate?: Maybe<Support_Sessions_Aggregate_Fields>
  nodes: Array<Support_Sessions>
}

export type Support_Sessions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Support_Sessions_Aggregate_Bool_Exp_Count>
}

export type Support_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Support_Sessions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Support_Sessions_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "support_sessions" */
export type Support_Sessions_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Support_Sessions_Max_Fields>
  min?: Maybe<Support_Sessions_Min_Fields>
}

/** aggregate fields of "support_sessions" */
export type Support_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Sessions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "support_sessions" */
export type Support_Sessions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Support_Sessions_Max_Order_By>
  min?: InputMaybe<Support_Sessions_Min_Order_By>
}

/** input type for inserting array relation for remote table "support_sessions" */
export type Support_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Support_Sessions_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Support_Sessions_On_Conflict>
}

/** Boolean expression to filter rows from the table "support_sessions". All fields are combined with a logical 'AND'. */
export type Support_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Sessions_Bool_Exp>>
  _not?: InputMaybe<Support_Sessions_Bool_Exp>
  _or?: InputMaybe<Array<Support_Sessions_Bool_Exp>>
  assistant?: InputMaybe<String_Comparison_Exp>
  closed_by?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  session_close_datetime?: InputMaybe<Timestamptz_Comparison_Exp>
  session_open_datetime?: InputMaybe<Timestamptz_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "support_sessions" */
export enum Support_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupportSessionsPkey = 'support_sessions_pkey',
}

/** input type for inserting data into table "support_sessions" */
export type Support_Sessions_Insert_Input = {
  assistant?: InputMaybe<Scalars['String']>
  closed_by?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  session_close_datetime?: InputMaybe<Scalars['timestamptz']>
  session_open_datetime?: InputMaybe<Scalars['timestamptz']>
  status?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Support_Sessions_Max_Fields = {
  assistant?: Maybe<Scalars['String']>
  closed_by?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  session_close_datetime?: Maybe<Scalars['timestamptz']>
  session_open_datetime?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "support_sessions" */
export type Support_Sessions_Max_Order_By = {
  assistant?: InputMaybe<Order_By>
  closed_by?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  session_close_datetime?: InputMaybe<Order_By>
  session_open_datetime?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Support_Sessions_Min_Fields = {
  assistant?: Maybe<Scalars['String']>
  closed_by?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  session_close_datetime?: Maybe<Scalars['timestamptz']>
  session_open_datetime?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "support_sessions" */
export type Support_Sessions_Min_Order_By = {
  assistant?: InputMaybe<Order_By>
  closed_by?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  session_close_datetime?: InputMaybe<Order_By>
  session_open_datetime?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<Order_By>
}

/** response of any mutation on the table "support_sessions" */
export type Support_Sessions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Support_Sessions>
}

/** on_conflict condition type for table "support_sessions" */
export type Support_Sessions_On_Conflict = {
  constraint: Support_Sessions_Constraint
  update_columns?: Array<Support_Sessions_Update_Column>
  where?: InputMaybe<Support_Sessions_Bool_Exp>
}

/** Ordering options when selecting data from "support_sessions". */
export type Support_Sessions_Order_By = {
  assistant?: InputMaybe<Order_By>
  closed_by?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  session_close_datetime?: InputMaybe<Order_By>
  session_open_datetime?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<Order_By>
}

/** primary key columns input for table: support_sessions */
export type Support_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "support_sessions" */
export enum Support_Sessions_Select_Column {
  /** column name */
  Assistant = 'assistant',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SessionCloseDatetime = 'session_close_datetime',
  /** column name */
  SessionOpenDatetime = 'session_open_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
}

/** input type for updating data in table "support_sessions" */
export type Support_Sessions_Set_Input = {
  assistant?: InputMaybe<Scalars['String']>
  closed_by?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  session_close_datetime?: InputMaybe<Scalars['timestamptz']>
  session_open_datetime?: InputMaybe<Scalars['timestamptz']>
  status?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "support_sessions" */
export type Support_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Sessions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Support_Sessions_Stream_Cursor_Value_Input = {
  assistant?: InputMaybe<Scalars['String']>
  closed_by?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  session_close_datetime?: InputMaybe<Scalars['timestamptz']>
  session_open_datetime?: InputMaybe<Scalars['timestamptz']>
  status?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<Scalars['String']>
}

/** update columns of table "support_sessions" */
export enum Support_Sessions_Update_Column {
  /** column name */
  Assistant = 'assistant',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SessionCloseDatetime = 'session_close_datetime',
  /** column name */
  SessionOpenDatetime = 'session_open_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
}

export type Support_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Sessions_Set_Input>
  /** filter the rows which have to be updated */
  where: Support_Sessions_Bool_Exp
}

/** columns and relationships of "swap_assets" */
export type Swap_Assets = {
  active_swaps: Scalars['Boolean']
  asset: Scalars['String']
  asset_name: Scalars['String']
  chain: Scalars['String']
  wallet_address: Scalars['String']
}

/** aggregated selection of "swap_assets" */
export type Swap_Assets_Aggregate = {
  aggregate?: Maybe<Swap_Assets_Aggregate_Fields>
  nodes: Array<Swap_Assets>
}

/** aggregate fields of "swap_assets" */
export type Swap_Assets_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Swap_Assets_Max_Fields>
  min?: Maybe<Swap_Assets_Min_Fields>
}

/** aggregate fields of "swap_assets" */
export type Swap_Assets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Swap_Assets_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "swap_assets". All fields are combined with a logical 'AND'. */
export type Swap_Assets_Bool_Exp = {
  _and?: InputMaybe<Array<Swap_Assets_Bool_Exp>>
  _not?: InputMaybe<Swap_Assets_Bool_Exp>
  _or?: InputMaybe<Array<Swap_Assets_Bool_Exp>>
  active_swaps?: InputMaybe<Boolean_Comparison_Exp>
  asset?: InputMaybe<String_Comparison_Exp>
  asset_name?: InputMaybe<String_Comparison_Exp>
  chain?: InputMaybe<String_Comparison_Exp>
  wallet_address?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "swap_assets" */
export enum Swap_Assets_Constraint {
  /** unique or primary key constraint on columns "asset", "chain" */
  SwapAssetsPkey = 'swap_assets_pkey',
}

/** input type for inserting data into table "swap_assets" */
export type Swap_Assets_Insert_Input = {
  active_swaps?: InputMaybe<Scalars['Boolean']>
  asset?: InputMaybe<Scalars['String']>
  asset_name?: InputMaybe<Scalars['String']>
  chain?: InputMaybe<Scalars['String']>
  wallet_address?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Swap_Assets_Max_Fields = {
  asset?: Maybe<Scalars['String']>
  asset_name?: Maybe<Scalars['String']>
  chain?: Maybe<Scalars['String']>
  wallet_address?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Swap_Assets_Min_Fields = {
  asset?: Maybe<Scalars['String']>
  asset_name?: Maybe<Scalars['String']>
  chain?: Maybe<Scalars['String']>
  wallet_address?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "swap_assets" */
export type Swap_Assets_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Swap_Assets>
}

/** on_conflict condition type for table "swap_assets" */
export type Swap_Assets_On_Conflict = {
  constraint: Swap_Assets_Constraint
  update_columns?: Array<Swap_Assets_Update_Column>
  where?: InputMaybe<Swap_Assets_Bool_Exp>
}

/** Ordering options when selecting data from "swap_assets". */
export type Swap_Assets_Order_By = {
  active_swaps?: InputMaybe<Order_By>
  asset?: InputMaybe<Order_By>
  asset_name?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  wallet_address?: InputMaybe<Order_By>
}

/** primary key columns input for table: swap_assets */
export type Swap_Assets_Pk_Columns_Input = {
  asset: Scalars['String']
  chain: Scalars['String']
}

/** select columns of table "swap_assets" */
export enum Swap_Assets_Select_Column {
  /** column name */
  ActiveSwaps = 'active_swaps',
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetName = 'asset_name',
  /** column name */
  Chain = 'chain',
  /** column name */
  WalletAddress = 'wallet_address',
}

/** input type for updating data in table "swap_assets" */
export type Swap_Assets_Set_Input = {
  active_swaps?: InputMaybe<Scalars['Boolean']>
  asset?: InputMaybe<Scalars['String']>
  asset_name?: InputMaybe<Scalars['String']>
  chain?: InputMaybe<Scalars['String']>
  wallet_address?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "swap_assets" */
export type Swap_Assets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Swap_Assets_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Swap_Assets_Stream_Cursor_Value_Input = {
  active_swaps?: InputMaybe<Scalars['Boolean']>
  asset?: InputMaybe<Scalars['String']>
  asset_name?: InputMaybe<Scalars['String']>
  chain?: InputMaybe<Scalars['String']>
  wallet_address?: InputMaybe<Scalars['String']>
}

/** update columns of table "swap_assets" */
export enum Swap_Assets_Update_Column {
  /** column name */
  ActiveSwaps = 'active_swaps',
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetName = 'asset_name',
  /** column name */
  Chain = 'chain',
  /** column name */
  WalletAddress = 'wallet_address',
}

export type Swap_Assets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Swap_Assets_Set_Input>
  /** filter the rows which have to be updated */
  where: Swap_Assets_Bool_Exp
}

/** columns and relationships of "swap_orders" */
export type Swap_Orders = {
  asset: Scalars['String']
  asset_amount: Scalars['float8']
  bitcash_account: Scalars['String']
  bitcash_amount: Scalars['float8']
  bitcash_currency: Scalars['String']
  bitcash_trx?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  gems_id?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  /** An array relationship */
  messages: Array<Messages>
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate
  order_status: Scalars['String']
  order_type: Scalars['String']
  price: Scalars['float8']
  /** An object relationship */
  reg_account: Reg_Accounts
  updated_at: Scalars['timestamptz']
  wallet_address: Scalars['String']
}

/** columns and relationships of "swap_orders" */
export type Swap_OrdersMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** columns and relationships of "swap_orders" */
export type Swap_OrdersMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Messages_Order_By>>
  where?: InputMaybe<Messages_Bool_Exp>
}

/** aggregated selection of "swap_orders" */
export type Swap_Orders_Aggregate = {
  aggregate?: Maybe<Swap_Orders_Aggregate_Fields>
  nodes: Array<Swap_Orders>
}

export type Swap_Orders_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Avg>
  corr?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Corr>
  count?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Count>
  covar_samp?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Covar_Samp>
  max?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Max>
  min?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Min>
  stddev_samp?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp>
  sum?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Sum>
  var_samp?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Var_Samp>
}

export type Swap_Orders_Aggregate_Bool_Exp_Avg = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Avg_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Corr = {
  arguments: Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments_Columns
  Y: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments_Columns
}

export type Swap_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Swap_Orders_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Int_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns
  Y: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns
}

export type Swap_Orders_Aggregate_Bool_Exp_Max = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Max_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Min = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Min_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Sum = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Sum_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

export type Swap_Orders_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Swap_Orders_Bool_Exp>
  predicate: Float8_Comparison_Exp
}

/** aggregate fields of "swap_orders" */
export type Swap_Orders_Aggregate_Fields = {
  avg?: Maybe<Swap_Orders_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Swap_Orders_Max_Fields>
  min?: Maybe<Swap_Orders_Min_Fields>
  stddev?: Maybe<Swap_Orders_Stddev_Fields>
  stddev_pop?: Maybe<Swap_Orders_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Swap_Orders_Stddev_Samp_Fields>
  sum?: Maybe<Swap_Orders_Sum_Fields>
  var_pop?: Maybe<Swap_Orders_Var_Pop_Fields>
  var_samp?: Maybe<Swap_Orders_Var_Samp_Fields>
  variance?: Maybe<Swap_Orders_Variance_Fields>
}

/** aggregate fields of "swap_orders" */
export type Swap_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Swap_Orders_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "swap_orders" */
export type Swap_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Swap_Orders_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Swap_Orders_Max_Order_By>
  min?: InputMaybe<Swap_Orders_Min_Order_By>
  stddev?: InputMaybe<Swap_Orders_Stddev_Order_By>
  stddev_pop?: InputMaybe<Swap_Orders_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Swap_Orders_Stddev_Samp_Order_By>
  sum?: InputMaybe<Swap_Orders_Sum_Order_By>
  var_pop?: InputMaybe<Swap_Orders_Var_Pop_Order_By>
  var_samp?: InputMaybe<Swap_Orders_Var_Samp_Order_By>
  variance?: InputMaybe<Swap_Orders_Variance_Order_By>
}

/** input type for inserting array relation for remote table "swap_orders" */
export type Swap_Orders_Arr_Rel_Insert_Input = {
  data: Array<Swap_Orders_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Swap_Orders_On_Conflict>
}

/** aggregate avg on columns */
export type Swap_Orders_Avg_Fields = {
  asset_amount?: Maybe<Scalars['Float']>
  bitcash_amount?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "swap_orders" */
export type Swap_Orders_Avg_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "swap_orders". All fields are combined with a logical 'AND'. */
export type Swap_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Swap_Orders_Bool_Exp>>
  _not?: InputMaybe<Swap_Orders_Bool_Exp>
  _or?: InputMaybe<Array<Swap_Orders_Bool_Exp>>
  asset?: InputMaybe<String_Comparison_Exp>
  asset_amount?: InputMaybe<Float8_Comparison_Exp>
  bitcash_account?: InputMaybe<String_Comparison_Exp>
  bitcash_amount?: InputMaybe<Float8_Comparison_Exp>
  bitcash_currency?: InputMaybe<String_Comparison_Exp>
  bitcash_trx?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  gems_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  messages?: InputMaybe<Messages_Bool_Exp>
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>
  order_status?: InputMaybe<String_Comparison_Exp>
  order_type?: InputMaybe<String_Comparison_Exp>
  price?: InputMaybe<Float8_Comparison_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  wallet_address?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "swap_orders" */
export enum Swap_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  SwapOrdersPkey = 'swap_orders_pkey',
}

/** input type for incrementing numeric columns in table "swap_orders" */
export type Swap_Orders_Inc_Input = {
  asset_amount?: InputMaybe<Scalars['float8']>
  bitcash_amount?: InputMaybe<Scalars['float8']>
  price?: InputMaybe<Scalars['float8']>
}

/** input type for inserting data into table "swap_orders" */
export type Swap_Orders_Insert_Input = {
  asset?: InputMaybe<Scalars['String']>
  asset_amount?: InputMaybe<Scalars['float8']>
  bitcash_account?: InputMaybe<Scalars['String']>
  bitcash_amount?: InputMaybe<Scalars['float8']>
  bitcash_currency?: InputMaybe<Scalars['String']>
  bitcash_trx?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  gems_id?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>
  order_status?: InputMaybe<Scalars['String']>
  order_type?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['float8']>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  wallet_address?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Swap_Orders_Max_Fields = {
  asset?: Maybe<Scalars['String']>
  asset_amount?: Maybe<Scalars['float8']>
  bitcash_account?: Maybe<Scalars['String']>
  bitcash_amount?: Maybe<Scalars['float8']>
  bitcash_currency?: Maybe<Scalars['String']>
  bitcash_trx?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  gems_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  order_status?: Maybe<Scalars['String']>
  order_type?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['float8']>
  updated_at?: Maybe<Scalars['timestamptz']>
  wallet_address?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "swap_orders" */
export type Swap_Orders_Max_Order_By = {
  asset?: InputMaybe<Order_By>
  asset_amount?: InputMaybe<Order_By>
  bitcash_account?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  bitcash_currency?: InputMaybe<Order_By>
  bitcash_trx?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  gems_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_status?: InputMaybe<Order_By>
  order_type?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  wallet_address?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Swap_Orders_Min_Fields = {
  asset?: Maybe<Scalars['String']>
  asset_amount?: Maybe<Scalars['float8']>
  bitcash_account?: Maybe<Scalars['String']>
  bitcash_amount?: Maybe<Scalars['float8']>
  bitcash_currency?: Maybe<Scalars['String']>
  bitcash_trx?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  gems_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  order_status?: Maybe<Scalars['String']>
  order_type?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['float8']>
  updated_at?: Maybe<Scalars['timestamptz']>
  wallet_address?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "swap_orders" */
export type Swap_Orders_Min_Order_By = {
  asset?: InputMaybe<Order_By>
  asset_amount?: InputMaybe<Order_By>
  bitcash_account?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  bitcash_currency?: InputMaybe<Order_By>
  bitcash_trx?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  gems_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_status?: InputMaybe<Order_By>
  order_type?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  wallet_address?: InputMaybe<Order_By>
}

/** response of any mutation on the table "swap_orders" */
export type Swap_Orders_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Swap_Orders>
}

/** input type for inserting object relation for remote table "swap_orders" */
export type Swap_Orders_Obj_Rel_Insert_Input = {
  data: Swap_Orders_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Swap_Orders_On_Conflict>
}

/** on_conflict condition type for table "swap_orders" */
export type Swap_Orders_On_Conflict = {
  constraint: Swap_Orders_Constraint
  update_columns?: Array<Swap_Orders_Update_Column>
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}

/** Ordering options when selecting data from "swap_orders". */
export type Swap_Orders_Order_By = {
  asset?: InputMaybe<Order_By>
  asset_amount?: InputMaybe<Order_By>
  bitcash_account?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  bitcash_currency?: InputMaybe<Order_By>
  bitcash_trx?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  gems_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>
  order_status?: InputMaybe<Order_By>
  order_type?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  updated_at?: InputMaybe<Order_By>
  wallet_address?: InputMaybe<Order_By>
}

/** primary key columns input for table: swap_orders */
export type Swap_Orders_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "swap_orders" */
export enum Swap_Orders_Select_Column {
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAccount = 'bitcash_account',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  BitcashCurrency = 'bitcash_currency',
  /** column name */
  BitcashTrx = 'bitcash_trx',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GemsId = 'gems_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletAddress = 'wallet_address',
}

/** select "swap_orders_aggregate_bool_exp_avg_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** select "swap_orders_aggregate_bool_exp_corr_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** select "swap_orders_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** select "swap_orders_aggregate_bool_exp_max_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** select "swap_orders_aggregate_bool_exp_min_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** select "swap_orders_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** select "swap_orders_aggregate_bool_exp_sum_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** select "swap_orders_aggregate_bool_exp_var_samp_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price',
}

/** input type for updating data in table "swap_orders" */
export type Swap_Orders_Set_Input = {
  asset?: InputMaybe<Scalars['String']>
  asset_amount?: InputMaybe<Scalars['float8']>
  bitcash_account?: InputMaybe<Scalars['String']>
  bitcash_amount?: InputMaybe<Scalars['float8']>
  bitcash_currency?: InputMaybe<Scalars['String']>
  bitcash_trx?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  gems_id?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  order_status?: InputMaybe<Scalars['String']>
  order_type?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['float8']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  wallet_address?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Swap_Orders_Stddev_Fields = {
  asset_amount?: Maybe<Scalars['Float']>
  bitcash_amount?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "swap_orders" */
export type Swap_Orders_Stddev_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Swap_Orders_Stddev_Pop_Fields = {
  asset_amount?: Maybe<Scalars['Float']>
  bitcash_amount?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "swap_orders" */
export type Swap_Orders_Stddev_Pop_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Swap_Orders_Stddev_Samp_Fields = {
  asset_amount?: Maybe<Scalars['Float']>
  bitcash_amount?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "swap_orders" */
export type Swap_Orders_Stddev_Samp_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "swap_orders" */
export type Swap_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Swap_Orders_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Swap_Orders_Stream_Cursor_Value_Input = {
  asset?: InputMaybe<Scalars['String']>
  asset_amount?: InputMaybe<Scalars['float8']>
  bitcash_account?: InputMaybe<Scalars['String']>
  bitcash_amount?: InputMaybe<Scalars['float8']>
  bitcash_currency?: InputMaybe<Scalars['String']>
  bitcash_trx?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  gems_id?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  order_status?: InputMaybe<Scalars['String']>
  order_type?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['float8']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  wallet_address?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Swap_Orders_Sum_Fields = {
  asset_amount?: Maybe<Scalars['float8']>
  bitcash_amount?: Maybe<Scalars['float8']>
  price?: Maybe<Scalars['float8']>
}

/** order by sum() on columns of table "swap_orders" */
export type Swap_Orders_Sum_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** update columns of table "swap_orders" */
export enum Swap_Orders_Update_Column {
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAccount = 'bitcash_account',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  BitcashCurrency = 'bitcash_currency',
  /** column name */
  BitcashTrx = 'bitcash_trx',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GemsId = 'gems_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletAddress = 'wallet_address',
}

export type Swap_Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Swap_Orders_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Swap_Orders_Set_Input>
  /** filter the rows which have to be updated */
  where: Swap_Orders_Bool_Exp
}

/** aggregate var_pop on columns */
export type Swap_Orders_Var_Pop_Fields = {
  asset_amount?: Maybe<Scalars['Float']>
  bitcash_amount?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "swap_orders" */
export type Swap_Orders_Var_Pop_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Swap_Orders_Var_Samp_Fields = {
  asset_amount?: Maybe<Scalars['Float']>
  bitcash_amount?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "swap_orders" */
export type Swap_Orders_Var_Samp_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Swap_Orders_Variance_Fields = {
  asset_amount?: Maybe<Scalars['Float']>
  bitcash_amount?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "swap_orders" */
export type Swap_Orders_Variance_Order_By = {
  asset_amount?: InputMaybe<Order_By>
  bitcash_amount?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
}

/** columns and relationships of "system_notification_read_status" */
export type System_Notification_Read_Status = {
  created_at: Scalars['timestamptz']
  notification_id: Scalars['uuid']
  /** An object relationship */
  regAccountByRegAccount: Reg_Accounts
  reg_account: Scalars['uuid']
  /** An object relationship */
  system_notification: System_Notifications
}

/** aggregated selection of "system_notification_read_status" */
export type System_Notification_Read_Status_Aggregate = {
  aggregate?: Maybe<System_Notification_Read_Status_Aggregate_Fields>
  nodes: Array<System_Notification_Read_Status>
}

export type System_Notification_Read_Status_Aggregate_Bool_Exp = {
  count?: InputMaybe<System_Notification_Read_Status_Aggregate_Bool_Exp_Count>
}

export type System_Notification_Read_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "system_notification_read_status" */
export type System_Notification_Read_Status_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<System_Notification_Read_Status_Max_Fields>
  min?: Maybe<System_Notification_Read_Status_Min_Fields>
}

/** aggregate fields of "system_notification_read_status" */
export type System_Notification_Read_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "system_notification_read_status" */
export type System_Notification_Read_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<System_Notification_Read_Status_Max_Order_By>
  min?: InputMaybe<System_Notification_Read_Status_Min_Order_By>
}

/** input type for inserting array relation for remote table "system_notification_read_status" */
export type System_Notification_Read_Status_Arr_Rel_Insert_Input = {
  data: Array<System_Notification_Read_Status_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<System_Notification_Read_Status_On_Conflict>
}

/** Boolean expression to filter rows from the table "system_notification_read_status". All fields are combined with a logical 'AND'. */
export type System_Notification_Read_Status_Bool_Exp = {
  _and?: InputMaybe<Array<System_Notification_Read_Status_Bool_Exp>>
  _not?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
  _or?: InputMaybe<Array<System_Notification_Read_Status_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  notification_id?: InputMaybe<Uuid_Comparison_Exp>
  regAccountByRegAccount?: InputMaybe<Reg_Accounts_Bool_Exp>
  reg_account?: InputMaybe<Uuid_Comparison_Exp>
  system_notification?: InputMaybe<System_Notifications_Bool_Exp>
}

/** unique or primary key constraints on table "system_notification_read_status" */
export enum System_Notification_Read_Status_Constraint {
  /** unique or primary key constraint on columns "reg_account", "notification_id" */
  SystemNotificationReadStatusPkey = 'system_notification_read_status_pkey',
}

/** input type for inserting data into table "system_notification_read_status" */
export type System_Notification_Read_Status_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  notification_id?: InputMaybe<Scalars['uuid']>
  regAccountByRegAccount?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  reg_account?: InputMaybe<Scalars['uuid']>
  system_notification?: InputMaybe<System_Notifications_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type System_Notification_Read_Status_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_id?: Maybe<Scalars['uuid']>
  reg_account?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "system_notification_read_status" */
export type System_Notification_Read_Status_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  notification_id?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type System_Notification_Read_Status_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_id?: Maybe<Scalars['uuid']>
  reg_account?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "system_notification_read_status" */
export type System_Notification_Read_Status_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  notification_id?: InputMaybe<Order_By>
  reg_account?: InputMaybe<Order_By>
}

/** response of any mutation on the table "system_notification_read_status" */
export type System_Notification_Read_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<System_Notification_Read_Status>
}

/** on_conflict condition type for table "system_notification_read_status" */
export type System_Notification_Read_Status_On_Conflict = {
  constraint: System_Notification_Read_Status_Constraint
  update_columns?: Array<System_Notification_Read_Status_Update_Column>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

/** Ordering options when selecting data from "system_notification_read_status". */
export type System_Notification_Read_Status_Order_By = {
  created_at?: InputMaybe<Order_By>
  notification_id?: InputMaybe<Order_By>
  regAccountByRegAccount?: InputMaybe<Reg_Accounts_Order_By>
  reg_account?: InputMaybe<Order_By>
  system_notification?: InputMaybe<System_Notifications_Order_By>
}

/** primary key columns input for table: system_notification_read_status */
export type System_Notification_Read_Status_Pk_Columns_Input = {
  notification_id: Scalars['uuid']
  reg_account: Scalars['uuid']
}

/** select columns of table "system_notification_read_status" */
export enum System_Notification_Read_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  RegAccount = 'reg_account',
}

/** input type for updating data in table "system_notification_read_status" */
export type System_Notification_Read_Status_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  notification_id?: InputMaybe<Scalars['uuid']>
  reg_account?: InputMaybe<Scalars['uuid']>
}

/** Streaming cursor of the table "system_notification_read_status" */
export type System_Notification_Read_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: System_Notification_Read_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type System_Notification_Read_Status_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  notification_id?: InputMaybe<Scalars['uuid']>
  reg_account?: InputMaybe<Scalars['uuid']>
}

/** update columns of table "system_notification_read_status" */
export enum System_Notification_Read_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  RegAccount = 'reg_account',
}

export type System_Notification_Read_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<System_Notification_Read_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: System_Notification_Read_Status_Bool_Exp
}

/** columns and relationships of "system_notifications" */
export type System_Notifications = {
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  message?: Maybe<Scalars['String']>
  offer_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  p2p_offer?: Maybe<P2p_Offers>
  /** An array relationship */
  system_notification_read_statuses: Array<System_Notification_Read_Status>
  /** An aggregate relationship */
  system_notification_read_statuses_aggregate: System_Notification_Read_Status_Aggregate
  /** An object relationship */
  system_type_notification: System_Type_Notification
  type: System_Type_Notification_Enum
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "system_notifications" */
export type System_NotificationsSystem_Notification_Read_StatusesArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

/** columns and relationships of "system_notifications" */
export type System_NotificationsSystem_Notification_Read_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notification_Read_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notification_Read_Status_Order_By>>
  where?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
}

/** aggregated selection of "system_notifications" */
export type System_Notifications_Aggregate = {
  aggregate?: Maybe<System_Notifications_Aggregate_Fields>
  nodes: Array<System_Notifications>
}

export type System_Notifications_Aggregate_Bool_Exp = {
  count?: InputMaybe<System_Notifications_Aggregate_Bool_Exp_Count>
}

export type System_Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<System_Notifications_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<System_Notifications_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "system_notifications" */
export type System_Notifications_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<System_Notifications_Max_Fields>
  min?: Maybe<System_Notifications_Min_Fields>
}

/** aggregate fields of "system_notifications" */
export type System_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<System_Notifications_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "system_notifications" */
export type System_Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<System_Notifications_Max_Order_By>
  min?: InputMaybe<System_Notifications_Min_Order_By>
}

/** input type for inserting array relation for remote table "system_notifications" */
export type System_Notifications_Arr_Rel_Insert_Input = {
  data: Array<System_Notifications_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<System_Notifications_On_Conflict>
}

/** Boolean expression to filter rows from the table "system_notifications". All fields are combined with a logical 'AND'. */
export type System_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<System_Notifications_Bool_Exp>>
  _not?: InputMaybe<System_Notifications_Bool_Exp>
  _or?: InputMaybe<Array<System_Notifications_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  message?: InputMaybe<String_Comparison_Exp>
  offer_id?: InputMaybe<Uuid_Comparison_Exp>
  p2p_offer?: InputMaybe<P2p_Offers_Bool_Exp>
  system_notification_read_statuses?: InputMaybe<System_Notification_Read_Status_Bool_Exp>
  system_notification_read_statuses_aggregate?: InputMaybe<System_Notification_Read_Status_Aggregate_Bool_Exp>
  system_type_notification?: InputMaybe<System_Type_Notification_Bool_Exp>
  type?: InputMaybe<System_Type_Notification_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "system_notifications" */
export enum System_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  SystemNotificationsPkey = 'system_notifications_pkey',
}

/** input type for inserting data into table "system_notifications" */
export type System_Notifications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  offer_id?: InputMaybe<Scalars['uuid']>
  p2p_offer?: InputMaybe<P2p_Offers_Obj_Rel_Insert_Input>
  system_notification_read_statuses?: InputMaybe<System_Notification_Read_Status_Arr_Rel_Insert_Input>
  system_type_notification?: InputMaybe<System_Type_Notification_Obj_Rel_Insert_Input>
  type?: InputMaybe<System_Type_Notification_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type System_Notifications_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  offer_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "system_notifications" */
export type System_Notifications_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  offer_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type System_Notifications_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  offer_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "system_notifications" */
export type System_Notifications_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  offer_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "system_notifications" */
export type System_Notifications_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<System_Notifications>
}

/** input type for inserting object relation for remote table "system_notifications" */
export type System_Notifications_Obj_Rel_Insert_Input = {
  data: System_Notifications_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<System_Notifications_On_Conflict>
}

/** on_conflict condition type for table "system_notifications" */
export type System_Notifications_On_Conflict = {
  constraint: System_Notifications_Constraint
  update_columns?: Array<System_Notifications_Update_Column>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

/** Ordering options when selecting data from "system_notifications". */
export type System_Notifications_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  offer_id?: InputMaybe<Order_By>
  p2p_offer?: InputMaybe<P2p_Offers_Order_By>
  system_notification_read_statuses_aggregate?: InputMaybe<System_Notification_Read_Status_Aggregate_Order_By>
  system_type_notification?: InputMaybe<System_Type_Notification_Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: system_notifications */
export type System_Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "system_notifications" */
export enum System_Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "system_notifications" */
export type System_Notifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  offer_id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<System_Type_Notification_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "system_notifications" */
export type System_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: System_Notifications_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type System_Notifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  offer_id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<System_Type_Notification_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "system_notifications" */
export enum System_Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type System_Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<System_Notifications_Set_Input>
  /** filter the rows which have to be updated */
  where: System_Notifications_Bool_Exp
}

/** columns and relationships of "system_type_notification" */
export type System_Type_Notification = {
  /** An array relationship */
  system_notifications: Array<System_Notifications>
  /** An aggregate relationship */
  system_notifications_aggregate: System_Notifications_Aggregate
  type: Scalars['String']
}

/** columns and relationships of "system_type_notification" */
export type System_Type_NotificationSystem_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

/** columns and relationships of "system_type_notification" */
export type System_Type_NotificationSystem_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Notifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<System_Notifications_Order_By>>
  where?: InputMaybe<System_Notifications_Bool_Exp>
}

/** aggregated selection of "system_type_notification" */
export type System_Type_Notification_Aggregate = {
  aggregate?: Maybe<System_Type_Notification_Aggregate_Fields>
  nodes: Array<System_Type_Notification>
}

/** aggregate fields of "system_type_notification" */
export type System_Type_Notification_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<System_Type_Notification_Max_Fields>
  min?: Maybe<System_Type_Notification_Min_Fields>
}

/** aggregate fields of "system_type_notification" */
export type System_Type_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<System_Type_Notification_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "system_type_notification". All fields are combined with a logical 'AND'. */
export type System_Type_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<System_Type_Notification_Bool_Exp>>
  _not?: InputMaybe<System_Type_Notification_Bool_Exp>
  _or?: InputMaybe<Array<System_Type_Notification_Bool_Exp>>
  system_notifications?: InputMaybe<System_Notifications_Bool_Exp>
  system_notifications_aggregate?: InputMaybe<System_Notifications_Aggregate_Bool_Exp>
  type?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "system_type_notification" */
export enum System_Type_Notification_Constraint {
  /** unique or primary key constraint on columns "type" */
  SystemTypeNotificationPkey = 'system_type_notification_pkey',
}

export enum System_Type_Notification_Enum {
  ConfirmedUncompletedOffer = 'CONFIRMED_UNCOMPLETED_OFFER',
  ConfirmedUncompletedOfferError = 'CONFIRMED_UNCOMPLETED_OFFER_ERROR',
  OnChainMatchingPreofferComplete = 'ON_CHAIN_MATCHING_PREOFFER_COMPLETE',
  OnChainMatchingPreofferStart = 'ON_CHAIN_MATCHING_PREOFFER_START',
  OnChainMatchingPreofferTimeoutError = 'ON_CHAIN_MATCHING_PREOFFER_TIMEOUT_ERROR',
  UnconfirmedOffer_12H = 'UNCONFIRMED_OFFER_12H',
  UnconfirmedOffer_12HError = 'UNCONFIRMED_OFFER_12H_ERROR',
  UnexpectedError = 'UNEXPECTED_ERROR',
  UnmatchedOffer_48H = 'UNMATCHED_OFFER_48H',
  UnmatchedOffer_48HError = 'UNMATCHED_OFFER_48H_ERROR',
  UnprocessedIndexedConfirmOffer = 'UNPROCESSED_INDEXED_CONFIRM_OFFER',
  UnprocessedIndexedOffer = 'UNPROCESSED_INDEXED_OFFER',
}

/** Boolean expression to compare columns of type "system_type_notification_enum". All fields are combined with logical 'AND'. */
export type System_Type_Notification_Enum_Comparison_Exp = {
  _eq?: InputMaybe<System_Type_Notification_Enum>
  _in?: InputMaybe<Array<System_Type_Notification_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<System_Type_Notification_Enum>
  _nin?: InputMaybe<Array<System_Type_Notification_Enum>>
}

/** input type for inserting data into table "system_type_notification" */
export type System_Type_Notification_Insert_Input = {
  system_notifications?: InputMaybe<System_Notifications_Arr_Rel_Insert_Input>
  type?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type System_Type_Notification_Max_Fields = {
  type?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type System_Type_Notification_Min_Fields = {
  type?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "system_type_notification" */
export type System_Type_Notification_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<System_Type_Notification>
}

/** input type for inserting object relation for remote table "system_type_notification" */
export type System_Type_Notification_Obj_Rel_Insert_Input = {
  data: System_Type_Notification_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<System_Type_Notification_On_Conflict>
}

/** on_conflict condition type for table "system_type_notification" */
export type System_Type_Notification_On_Conflict = {
  constraint: System_Type_Notification_Constraint
  update_columns?: Array<System_Type_Notification_Update_Column>
  where?: InputMaybe<System_Type_Notification_Bool_Exp>
}

/** Ordering options when selecting data from "system_type_notification". */
export type System_Type_Notification_Order_By = {
  system_notifications_aggregate?: InputMaybe<System_Notifications_Aggregate_Order_By>
  type?: InputMaybe<Order_By>
}

/** primary key columns input for table: system_type_notification */
export type System_Type_Notification_Pk_Columns_Input = {
  type: Scalars['String']
}

/** select columns of table "system_type_notification" */
export enum System_Type_Notification_Select_Column {
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "system_type_notification" */
export type System_Type_Notification_Set_Input = {
  type?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "system_type_notification" */
export type System_Type_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: System_Type_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type System_Type_Notification_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>
}

/** update columns of table "system_type_notification" */
export enum System_Type_Notification_Update_Column {
  /** column name */
  Type = 'type',
}

export type System_Type_Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<System_Type_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: System_Type_Notification_Bool_Exp
}

/** columns and relationships of "table_rows" */
export type Table_Rows = {
  contract: Scalars['String']
  data: Scalars['jsonb']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

/** columns and relationships of "table_rows" */
export type Table_RowsDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "table_rows" */
export type Table_Rows_Aggregate = {
  aggregate?: Maybe<Table_Rows_Aggregate_Fields>
  nodes: Array<Table_Rows>
}

/** aggregate fields of "table_rows" */
export type Table_Rows_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Table_Rows_Max_Fields>
  min?: Maybe<Table_Rows_Min_Fields>
}

/** aggregate fields of "table_rows" */
export type Table_Rows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Table_Rows_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Table_Rows_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "table_rows". All fields are combined with a logical 'AND'. */
export type Table_Rows_Bool_Exp = {
  _and?: InputMaybe<Array<Table_Rows_Bool_Exp>>
  _not?: InputMaybe<Table_Rows_Bool_Exp>
  _or?: InputMaybe<Array<Table_Rows_Bool_Exp>>
  contract?: InputMaybe<String_Comparison_Exp>
  data?: InputMaybe<Jsonb_Comparison_Exp>
  primary_key?: InputMaybe<String_Comparison_Exp>
  scope?: InputMaybe<String_Comparison_Exp>
  table?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "table_rows" */
export enum Table_Rows_Constraint {
  /** unique or primary key constraint on columns "contract", "primary_key", "scope", "table" */
  TableRowsPkey = 'table_rows_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Table_Rows_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Table_Rows_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Table_Rows_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "table_rows" */
export type Table_Rows_Insert_Input = {
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  primary_key?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Table_Rows_Max_Fields = {
  contract?: Maybe<Scalars['String']>
  primary_key?: Maybe<Scalars['String']>
  scope?: Maybe<Scalars['String']>
  table?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Table_Rows_Min_Fields = {
  contract?: Maybe<Scalars['String']>
  primary_key?: Maybe<Scalars['String']>
  scope?: Maybe<Scalars['String']>
  table?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "table_rows" */
export type Table_Rows_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Table_Rows>
}

/** on_conflict condition type for table "table_rows" */
export type Table_Rows_On_Conflict = {
  constraint: Table_Rows_Constraint
  update_columns?: Array<Table_Rows_Update_Column>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

/** Ordering options when selecting data from "table_rows". */
export type Table_Rows_Order_By = {
  contract?: InputMaybe<Order_By>
  data?: InputMaybe<Order_By>
  primary_key?: InputMaybe<Order_By>
  scope?: InputMaybe<Order_By>
  table?: InputMaybe<Order_By>
}

/** primary key columns input for table: table_rows */
export type Table_Rows_Pk_Columns_Input = {
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Table_Rows_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "table_rows" */
export enum Table_Rows_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  PrimaryKey = 'primary_key',
  /** column name */
  Scope = 'scope',
  /** column name */
  Table = 'table',
}

/** input type for updating data in table "table_rows" */
export type Table_Rows_Set_Input = {
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  primary_key?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "table_rows" */
export type Table_Rows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Table_Rows_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Table_Rows_Stream_Cursor_Value_Input = {
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  primary_key?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
}

/** update columns of table "table_rows" */
export enum Table_Rows_Update_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  PrimaryKey = 'primary_key',
  /** column name */
  Scope = 'scope',
  /** column name */
  Table = 'table',
}

export type Table_Rows_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Table_Rows_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Table_Rows_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Table_Rows_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Table_Rows_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Table_Rows_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Table_Rows_Set_Input>
  /** filter the rows which have to be updated */
  where: Table_Rows_Bool_Exp
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>
  _gt?: InputMaybe<Scalars['timestamp']>
  _gte?: InputMaybe<Scalars['timestamp']>
  _in?: InputMaybe<Array<Scalars['timestamp']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamp']>
  _lte?: InputMaybe<Scalars['timestamp']>
  _neq?: InputMaybe<Scalars['timestamp']>
  _nin?: InputMaybe<Array<Scalars['timestamp']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

export type Toggle_Trust_Network_Input = {
  account: Scalars['String']
  reject?: InputMaybe<Scalars['Boolean']>
}

export type Toggle_Trust_Network_Output = {
  success?: Maybe<Scalars['Boolean']>
}

/** columns and relationships of "trust_network" */
export type Trust_Network = {
  account: Scalars['String']
  created_at: Scalars['timestamptz']
  is_mutual: Scalars['Boolean']
  trust: Scalars['String']
  /** An object relationship */
  trust_by: Reg_Accounts
  /** An object relationship */
  trust_network: Reg_Accounts
}

/** aggregated selection of "trust_network" */
export type Trust_Network_Aggregate = {
  aggregate?: Maybe<Trust_Network_Aggregate_Fields>
  nodes: Array<Trust_Network>
}

export type Trust_Network_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Trust_Network_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Trust_Network_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Trust_Network_Aggregate_Bool_Exp_Count>
}

export type Trust_Network_Aggregate_Bool_Exp_Bool_And = {
  arguments: Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Trust_Network_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Trust_Network_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Trust_Network_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Trust_Network_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trust_Network_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Trust_Network_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "trust_network" */
export type Trust_Network_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Trust_Network_Max_Fields>
  min?: Maybe<Trust_Network_Min_Fields>
}

/** aggregate fields of "trust_network" */
export type Trust_Network_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trust_Network_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "trust_network" */
export type Trust_Network_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Trust_Network_Max_Order_By>
  min?: InputMaybe<Trust_Network_Min_Order_By>
}

/** input type for inserting array relation for remote table "trust_network" */
export type Trust_Network_Arr_Rel_Insert_Input = {
  data: Array<Trust_Network_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Trust_Network_On_Conflict>
}

/** Boolean expression to filter rows from the table "trust_network". All fields are combined with a logical 'AND'. */
export type Trust_Network_Bool_Exp = {
  _and?: InputMaybe<Array<Trust_Network_Bool_Exp>>
  _not?: InputMaybe<Trust_Network_Bool_Exp>
  _or?: InputMaybe<Array<Trust_Network_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  is_mutual?: InputMaybe<Boolean_Comparison_Exp>
  trust?: InputMaybe<String_Comparison_Exp>
  trust_by?: InputMaybe<Reg_Accounts_Bool_Exp>
  trust_network?: InputMaybe<Reg_Accounts_Bool_Exp>
}

/** unique or primary key constraints on table "trust_network" */
export enum Trust_Network_Constraint {
  /** unique or primary key constraint on columns "account", "trust" */
  TrustNetworkPkey = 'trust_network_pkey',
}

/** input type for inserting data into table "trust_network" */
export type Trust_Network_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  is_mutual?: InputMaybe<Scalars['Boolean']>
  trust?: InputMaybe<Scalars['String']>
  trust_by?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  trust_network?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Trust_Network_Max_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  trust?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "trust_network" */
export type Trust_Network_Max_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  trust?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Trust_Network_Min_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  trust?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "trust_network" */
export type Trust_Network_Min_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  trust?: InputMaybe<Order_By>
}

/** response of any mutation on the table "trust_network" */
export type Trust_Network_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Trust_Network>
}

/** columns and relationships of "trust_network_notification" */
export type Trust_Network_Notification = {
  account: Scalars['String']
  created_at: Scalars['timestamptz']
  /** An object relationship */
  regAccountByTrust: Reg_Accounts
  /** An object relationship */
  reg_account: Reg_Accounts
  trust: Scalars['String']
}

/** aggregated selection of "trust_network_notification" */
export type Trust_Network_Notification_Aggregate = {
  aggregate?: Maybe<Trust_Network_Notification_Aggregate_Fields>
  nodes: Array<Trust_Network_Notification>
}

export type Trust_Network_Notification_Aggregate_Bool_Exp = {
  count?: InputMaybe<Trust_Network_Notification_Aggregate_Bool_Exp_Count>
}

export type Trust_Network_Notification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Trust_Network_Notification_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "trust_network_notification" */
export type Trust_Network_Notification_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Trust_Network_Notification_Max_Fields>
  min?: Maybe<Trust_Network_Notification_Min_Fields>
}

/** aggregate fields of "trust_network_notification" */
export type Trust_Network_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "trust_network_notification" */
export type Trust_Network_Notification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Trust_Network_Notification_Max_Order_By>
  min?: InputMaybe<Trust_Network_Notification_Min_Order_By>
}

/** input type for inserting array relation for remote table "trust_network_notification" */
export type Trust_Network_Notification_Arr_Rel_Insert_Input = {
  data: Array<Trust_Network_Notification_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Trust_Network_Notification_On_Conflict>
}

/** Boolean expression to filter rows from the table "trust_network_notification". All fields are combined with a logical 'AND'. */
export type Trust_Network_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Trust_Network_Notification_Bool_Exp>>
  _not?: InputMaybe<Trust_Network_Notification_Bool_Exp>
  _or?: InputMaybe<Array<Trust_Network_Notification_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  regAccountByTrust?: InputMaybe<Reg_Accounts_Bool_Exp>
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>
  trust?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "trust_network_notification" */
export enum Trust_Network_Notification_Constraint {
  /** unique or primary key constraint on columns "account", "trust" */
  TrustNetworkNotificationPkey = 'trust_network_notification_pkey',
}

/** input type for inserting data into table "trust_network_notification" */
export type Trust_Network_Notification_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  regAccountByTrust?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>
  trust?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Trust_Network_Notification_Max_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  trust?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "trust_network_notification" */
export type Trust_Network_Notification_Max_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  trust?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Trust_Network_Notification_Min_Fields = {
  account?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  trust?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "trust_network_notification" */
export type Trust_Network_Notification_Min_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  trust?: InputMaybe<Order_By>
}

/** response of any mutation on the table "trust_network_notification" */
export type Trust_Network_Notification_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Trust_Network_Notification>
}

/** on_conflict condition type for table "trust_network_notification" */
export type Trust_Network_Notification_On_Conflict = {
  constraint: Trust_Network_Notification_Constraint
  update_columns?: Array<Trust_Network_Notification_Update_Column>
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>
}

/** Ordering options when selecting data from "trust_network_notification". */
export type Trust_Network_Notification_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  regAccountByTrust?: InputMaybe<Reg_Accounts_Order_By>
  reg_account?: InputMaybe<Reg_Accounts_Order_By>
  trust?: InputMaybe<Order_By>
}

/** primary key columns input for table: trust_network_notification */
export type Trust_Network_Notification_Pk_Columns_Input = {
  account: Scalars['String']
  trust: Scalars['String']
}

/** select columns of table "trust_network_notification" */
export enum Trust_Network_Notification_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Trust = 'trust',
}

/** input type for updating data in table "trust_network_notification" */
export type Trust_Network_Notification_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  trust?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "trust_network_notification" */
export type Trust_Network_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trust_Network_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Trust_Network_Notification_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  trust?: InputMaybe<Scalars['String']>
}

/** update columns of table "trust_network_notification" */
export enum Trust_Network_Notification_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Trust = 'trust',
}

export type Trust_Network_Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trust_Network_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: Trust_Network_Notification_Bool_Exp
}

/** on_conflict condition type for table "trust_network" */
export type Trust_Network_On_Conflict = {
  constraint: Trust_Network_Constraint
  update_columns?: Array<Trust_Network_Update_Column>
  where?: InputMaybe<Trust_Network_Bool_Exp>
}

/** Ordering options when selecting data from "trust_network". */
export type Trust_Network_Order_By = {
  account?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  is_mutual?: InputMaybe<Order_By>
  trust?: InputMaybe<Order_By>
  trust_by?: InputMaybe<Reg_Accounts_Order_By>
  trust_network?: InputMaybe<Reg_Accounts_Order_By>
}

export type Trust_Network_Output = {
  trusted_network?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** primary key columns input for table: trust_network */
export type Trust_Network_Pk_Columns_Input = {
  account: Scalars['String']
  trust: Scalars['String']
}

/** select columns of table "trust_network" */
export enum Trust_Network_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  Trust = 'trust',
}

/** select "trust_network_aggregate_bool_exp_bool_and_arguments_columns" columns of table "trust_network" */
export enum Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsMutual = 'is_mutual',
}

/** select "trust_network_aggregate_bool_exp_bool_or_arguments_columns" columns of table "trust_network" */
export enum Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsMutual = 'is_mutual',
}

/** input type for updating data in table "trust_network" */
export type Trust_Network_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  is_mutual?: InputMaybe<Scalars['Boolean']>
  trust?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "trust_network_status" */
export type Trust_Network_Status = {
  status: Scalars['String']
}

/** aggregated selection of "trust_network_status" */
export type Trust_Network_Status_Aggregate = {
  aggregate?: Maybe<Trust_Network_Status_Aggregate_Fields>
  nodes: Array<Trust_Network_Status>
}

/** aggregate fields of "trust_network_status" */
export type Trust_Network_Status_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Trust_Network_Status_Max_Fields>
  min?: Maybe<Trust_Network_Status_Min_Fields>
}

/** aggregate fields of "trust_network_status" */
export type Trust_Network_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trust_Network_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "trust_network_status". All fields are combined with a logical 'AND'. */
export type Trust_Network_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Trust_Network_Status_Bool_Exp>>
  _not?: InputMaybe<Trust_Network_Status_Bool_Exp>
  _or?: InputMaybe<Array<Trust_Network_Status_Bool_Exp>>
  status?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "trust_network_status" */
export enum Trust_Network_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  TrustedNetworkStatusPkey = 'trusted_network_status_pkey',
}

/** input type for inserting data into table "trust_network_status" */
export type Trust_Network_Status_Insert_Input = {
  status?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Trust_Network_Status_Max_Fields = {
  status?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Trust_Network_Status_Min_Fields = {
  status?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "trust_network_status" */
export type Trust_Network_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Trust_Network_Status>
}

/** on_conflict condition type for table "trust_network_status" */
export type Trust_Network_Status_On_Conflict = {
  constraint: Trust_Network_Status_Constraint
  update_columns?: Array<Trust_Network_Status_Update_Column>
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>
}

/** Ordering options when selecting data from "trust_network_status". */
export type Trust_Network_Status_Order_By = {
  status?: InputMaybe<Order_By>
}

/** primary key columns input for table: trust_network_status */
export type Trust_Network_Status_Pk_Columns_Input = {
  status: Scalars['String']
}

/** select columns of table "trust_network_status" */
export enum Trust_Network_Status_Select_Column {
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "trust_network_status" */
export type Trust_Network_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "trust_network_status" */
export type Trust_Network_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trust_Network_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Trust_Network_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']>
}

/** update columns of table "trust_network_status" */
export enum Trust_Network_Status_Update_Column {
  /** column name */
  Status = 'status',
}

export type Trust_Network_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trust_Network_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Trust_Network_Status_Bool_Exp
}

/** Streaming cursor of the table "trust_network" */
export type Trust_Network_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trust_Network_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Trust_Network_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  is_mutual?: InputMaybe<Scalars['Boolean']>
  trust?: InputMaybe<Scalars['String']>
}

/** update columns of table "trust_network" */
export enum Trust_Network_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  Trust = 'trust',
}

export type Trust_Network_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trust_Network_Set_Input>
  /** filter the rows which have to be updated */
  where: Trust_Network_Bool_Exp
}

/** columns and relationships of "tx_statistics" */
export type Tx_Statistics = {
  currency?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['timestamptz']>
  total_transactions?: Maybe<Scalars['bigint']>
  total_transactions_cancelled?: Maybe<Scalars['bigint']>
  total_transactions_completed?: Maybe<Scalars['bigint']>
}

/** aggregated selection of "tx_statistics" */
export type Tx_Statistics_Aggregate = {
  aggregate?: Maybe<Tx_Statistics_Aggregate_Fields>
  nodes: Array<Tx_Statistics>
}

/** aggregate fields of "tx_statistics" */
export type Tx_Statistics_Aggregate_Fields = {
  avg?: Maybe<Tx_Statistics_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Tx_Statistics_Max_Fields>
  min?: Maybe<Tx_Statistics_Min_Fields>
  stddev?: Maybe<Tx_Statistics_Stddev_Fields>
  stddev_pop?: Maybe<Tx_Statistics_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Tx_Statistics_Stddev_Samp_Fields>
  sum?: Maybe<Tx_Statistics_Sum_Fields>
  var_pop?: Maybe<Tx_Statistics_Var_Pop_Fields>
  var_samp?: Maybe<Tx_Statistics_Var_Samp_Fields>
  variance?: Maybe<Tx_Statistics_Variance_Fields>
}

/** aggregate fields of "tx_statistics" */
export type Tx_Statistics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tx_Statistics_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Tx_Statistics_Avg_Fields = {
  total_transactions?: Maybe<Scalars['Float']>
  total_transactions_cancelled?: Maybe<Scalars['Float']>
  total_transactions_completed?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "tx_statistics". All fields are combined with a logical 'AND'. */
export type Tx_Statistics_Bool_Exp = {
  _and?: InputMaybe<Array<Tx_Statistics_Bool_Exp>>
  _not?: InputMaybe<Tx_Statistics_Bool_Exp>
  _or?: InputMaybe<Array<Tx_Statistics_Bool_Exp>>
  currency?: InputMaybe<String_Comparison_Exp>
  date?: InputMaybe<Timestamptz_Comparison_Exp>
  total_transactions?: InputMaybe<Bigint_Comparison_Exp>
  total_transactions_cancelled?: InputMaybe<Bigint_Comparison_Exp>
  total_transactions_completed?: InputMaybe<Bigint_Comparison_Exp>
}

/** input type for incrementing numeric columns in table "tx_statistics" */
export type Tx_Statistics_Inc_Input = {
  total_transactions?: InputMaybe<Scalars['bigint']>
  total_transactions_cancelled?: InputMaybe<Scalars['bigint']>
  total_transactions_completed?: InputMaybe<Scalars['bigint']>
}

/** input type for inserting data into table "tx_statistics" */
export type Tx_Statistics_Insert_Input = {
  currency?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['timestamptz']>
  total_transactions?: InputMaybe<Scalars['bigint']>
  total_transactions_cancelled?: InputMaybe<Scalars['bigint']>
  total_transactions_completed?: InputMaybe<Scalars['bigint']>
}

/** aggregate max on columns */
export type Tx_Statistics_Max_Fields = {
  currency?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['timestamptz']>
  total_transactions?: Maybe<Scalars['bigint']>
  total_transactions_cancelled?: Maybe<Scalars['bigint']>
  total_transactions_completed?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Tx_Statistics_Min_Fields = {
  currency?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['timestamptz']>
  total_transactions?: Maybe<Scalars['bigint']>
  total_transactions_cancelled?: Maybe<Scalars['bigint']>
  total_transactions_completed?: Maybe<Scalars['bigint']>
}

/** response of any mutation on the table "tx_statistics" */
export type Tx_Statistics_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Tx_Statistics>
}

/** Ordering options when selecting data from "tx_statistics". */
export type Tx_Statistics_Order_By = {
  currency?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  total_transactions?: InputMaybe<Order_By>
  total_transactions_cancelled?: InputMaybe<Order_By>
  total_transactions_completed?: InputMaybe<Order_By>
}

/** select columns of table "tx_statistics" */
export enum Tx_Statistics_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  TotalTransactions = 'total_transactions',
  /** column name */
  TotalTransactionsCancelled = 'total_transactions_cancelled',
  /** column name */
  TotalTransactionsCompleted = 'total_transactions_completed',
}

/** input type for updating data in table "tx_statistics" */
export type Tx_Statistics_Set_Input = {
  currency?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['timestamptz']>
  total_transactions?: InputMaybe<Scalars['bigint']>
  total_transactions_cancelled?: InputMaybe<Scalars['bigint']>
  total_transactions_completed?: InputMaybe<Scalars['bigint']>
}

/** aggregate stddev on columns */
export type Tx_Statistics_Stddev_Fields = {
  total_transactions?: Maybe<Scalars['Float']>
  total_transactions_cancelled?: Maybe<Scalars['Float']>
  total_transactions_completed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Tx_Statistics_Stddev_Pop_Fields = {
  total_transactions?: Maybe<Scalars['Float']>
  total_transactions_cancelled?: Maybe<Scalars['Float']>
  total_transactions_completed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Tx_Statistics_Stddev_Samp_Fields = {
  total_transactions?: Maybe<Scalars['Float']>
  total_transactions_cancelled?: Maybe<Scalars['Float']>
  total_transactions_completed?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "tx_statistics" */
export type Tx_Statistics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tx_Statistics_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Tx_Statistics_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['timestamptz']>
  total_transactions?: InputMaybe<Scalars['bigint']>
  total_transactions_cancelled?: InputMaybe<Scalars['bigint']>
  total_transactions_completed?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Tx_Statistics_Sum_Fields = {
  total_transactions?: Maybe<Scalars['bigint']>
  total_transactions_cancelled?: Maybe<Scalars['bigint']>
  total_transactions_completed?: Maybe<Scalars['bigint']>
}

export type Tx_Statistics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tx_Statistics_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tx_Statistics_Set_Input>
  /** filter the rows which have to be updated */
  where: Tx_Statistics_Bool_Exp
}

/** aggregate var_pop on columns */
export type Tx_Statistics_Var_Pop_Fields = {
  total_transactions?: Maybe<Scalars['Float']>
  total_transactions_cancelled?: Maybe<Scalars['Float']>
  total_transactions_completed?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Tx_Statistics_Var_Samp_Fields = {
  total_transactions?: Maybe<Scalars['Float']>
  total_transactions_cancelled?: Maybe<Scalars['Float']>
  total_transactions_completed?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Tx_Statistics_Variance_Fields = {
  total_transactions?: Maybe<Scalars['Float']>
  total_transactions_cancelled?: Maybe<Scalars['Float']>
  total_transactions_completed?: Maybe<Scalars['Float']>
}

export type Upload_Image_Output = {
  success: Scalars['Boolean']
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type Validate_Short_Link_Input = {
  link: Scalars['String']
}

export type Validate_Short_Link_Output = {
  count: Scalars['Int']
  referrer: Scalars['String']
  website: Scalars['String']
}

/** columns and relationships of "volume_statistics" */
export type Volume_Statistics = {
  currency?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['timestamptz']>
  total_cancelled?: Maybe<Scalars['numeric']>
  total_completed?: Maybe<Scalars['numeric']>
  total_completed_not_cancelled?: Maybe<Scalars['numeric']>
}

/** aggregated selection of "volume_statistics" */
export type Volume_Statistics_Aggregate = {
  aggregate?: Maybe<Volume_Statistics_Aggregate_Fields>
  nodes: Array<Volume_Statistics>
}

/** aggregate fields of "volume_statistics" */
export type Volume_Statistics_Aggregate_Fields = {
  avg?: Maybe<Volume_Statistics_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Volume_Statistics_Max_Fields>
  min?: Maybe<Volume_Statistics_Min_Fields>
  stddev?: Maybe<Volume_Statistics_Stddev_Fields>
  stddev_pop?: Maybe<Volume_Statistics_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Volume_Statistics_Stddev_Samp_Fields>
  sum?: Maybe<Volume_Statistics_Sum_Fields>
  var_pop?: Maybe<Volume_Statistics_Var_Pop_Fields>
  var_samp?: Maybe<Volume_Statistics_Var_Samp_Fields>
  variance?: Maybe<Volume_Statistics_Variance_Fields>
}

/** aggregate fields of "volume_statistics" */
export type Volume_Statistics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Volume_Statistics_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Volume_Statistics_Avg_Fields = {
  total_cancelled?: Maybe<Scalars['Float']>
  total_completed?: Maybe<Scalars['Float']>
  total_completed_not_cancelled?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "volume_statistics". All fields are combined with a logical 'AND'. */
export type Volume_Statistics_Bool_Exp = {
  _and?: InputMaybe<Array<Volume_Statistics_Bool_Exp>>
  _not?: InputMaybe<Volume_Statistics_Bool_Exp>
  _or?: InputMaybe<Array<Volume_Statistics_Bool_Exp>>
  currency?: InputMaybe<String_Comparison_Exp>
  date?: InputMaybe<Timestamptz_Comparison_Exp>
  total_cancelled?: InputMaybe<Numeric_Comparison_Exp>
  total_completed?: InputMaybe<Numeric_Comparison_Exp>
  total_completed_not_cancelled?: InputMaybe<Numeric_Comparison_Exp>
}

/** input type for incrementing numeric columns in table "volume_statistics" */
export type Volume_Statistics_Inc_Input = {
  total_cancelled?: InputMaybe<Scalars['numeric']>
  total_completed?: InputMaybe<Scalars['numeric']>
  total_completed_not_cancelled?: InputMaybe<Scalars['numeric']>
}

/** input type for inserting data into table "volume_statistics" */
export type Volume_Statistics_Insert_Input = {
  currency?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['timestamptz']>
  total_cancelled?: InputMaybe<Scalars['numeric']>
  total_completed?: InputMaybe<Scalars['numeric']>
  total_completed_not_cancelled?: InputMaybe<Scalars['numeric']>
}

/** aggregate max on columns */
export type Volume_Statistics_Max_Fields = {
  currency?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['timestamptz']>
  total_cancelled?: Maybe<Scalars['numeric']>
  total_completed?: Maybe<Scalars['numeric']>
  total_completed_not_cancelled?: Maybe<Scalars['numeric']>
}

/** aggregate min on columns */
export type Volume_Statistics_Min_Fields = {
  currency?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['timestamptz']>
  total_cancelled?: Maybe<Scalars['numeric']>
  total_completed?: Maybe<Scalars['numeric']>
  total_completed_not_cancelled?: Maybe<Scalars['numeric']>
}

/** response of any mutation on the table "volume_statistics" */
export type Volume_Statistics_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Volume_Statistics>
}

/** Ordering options when selecting data from "volume_statistics". */
export type Volume_Statistics_Order_By = {
  currency?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  total_cancelled?: InputMaybe<Order_By>
  total_completed?: InputMaybe<Order_By>
  total_completed_not_cancelled?: InputMaybe<Order_By>
}

/** select columns of table "volume_statistics" */
export enum Volume_Statistics_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  TotalCancelled = 'total_cancelled',
  /** column name */
  TotalCompleted = 'total_completed',
  /** column name */
  TotalCompletedNotCancelled = 'total_completed_not_cancelled',
}

/** input type for updating data in table "volume_statistics" */
export type Volume_Statistics_Set_Input = {
  currency?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['timestamptz']>
  total_cancelled?: InputMaybe<Scalars['numeric']>
  total_completed?: InputMaybe<Scalars['numeric']>
  total_completed_not_cancelled?: InputMaybe<Scalars['numeric']>
}

/** aggregate stddev on columns */
export type Volume_Statistics_Stddev_Fields = {
  total_cancelled?: Maybe<Scalars['Float']>
  total_completed?: Maybe<Scalars['Float']>
  total_completed_not_cancelled?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Volume_Statistics_Stddev_Pop_Fields = {
  total_cancelled?: Maybe<Scalars['Float']>
  total_completed?: Maybe<Scalars['Float']>
  total_completed_not_cancelled?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Volume_Statistics_Stddev_Samp_Fields = {
  total_cancelled?: Maybe<Scalars['Float']>
  total_completed?: Maybe<Scalars['Float']>
  total_completed_not_cancelled?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "volume_statistics" */
export type Volume_Statistics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Volume_Statistics_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Volume_Statistics_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['timestamptz']>
  total_cancelled?: InputMaybe<Scalars['numeric']>
  total_completed?: InputMaybe<Scalars['numeric']>
  total_completed_not_cancelled?: InputMaybe<Scalars['numeric']>
}

/** aggregate sum on columns */
export type Volume_Statistics_Sum_Fields = {
  total_cancelled?: Maybe<Scalars['numeric']>
  total_completed?: Maybe<Scalars['numeric']>
  total_completed_not_cancelled?: Maybe<Scalars['numeric']>
}

export type Volume_Statistics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Volume_Statistics_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Volume_Statistics_Set_Input>
  /** filter the rows which have to be updated */
  where: Volume_Statistics_Bool_Exp
}

/** aggregate var_pop on columns */
export type Volume_Statistics_Var_Pop_Fields = {
  total_cancelled?: Maybe<Scalars['Float']>
  total_completed?: Maybe<Scalars['Float']>
  total_completed_not_cancelled?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Volume_Statistics_Var_Samp_Fields = {
  total_cancelled?: Maybe<Scalars['Float']>
  total_completed?: Maybe<Scalars['Float']>
  total_completed_not_cancelled?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Volume_Statistics_Variance_Fields = {
  total_cancelled?: Maybe<Scalars['Float']>
  total_completed?: Maybe<Scalars['Float']>
  total_completed_not_cancelled?: Maybe<Scalars['Float']>
}

export type Wallet_Request_Output = {
  amount?: Maybe<Scalars['String']>
  bitcashbank_id?: Maybe<Scalars['String']>
  buyer?: Maybe<Scalars['String']>
  buyer_confirmed_payment?: Maybe<Scalars['Boolean']>
  buyer_method_details?: Maybe<Scalars['String']>
  cancel_approval_datetime?: Maybe<Scalars['String']>
  cancel_request_datetime?: Maybe<Scalars['String']>
  cancellation_approved_by?: Maybe<Scalars['String']>
  cancellation_transaction?: Maybe<Scalars['String']>
  cancelled?: Maybe<Scalars['Boolean']>
  cancelled_by?: Maybe<Scalars['String']>
  completed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  initiator?: Maybe<Scalars['String']>
  matched?: Maybe<Scalars['Boolean']>
  matched_datetime?: Maybe<Scalars['String']>
  method?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  sell_put_transaction?: Maybe<Scalars['String']>
  sell_settlement_transaction?: Maybe<Scalars['String']>
  seller?: Maybe<Scalars['String']>
  seller_confirmed_payment?: Maybe<Scalars['Boolean']>
  seller_method_details?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['String']>
}

/** columns and relationships of "web_push" */
export type Web_Push = {
  account: Scalars['String']
  auth: Scalars['String']
  created_at: Scalars['timestamptz']
  endpoint: Scalars['String']
  expiration_time: Scalars['timestamptz']
  p256dh: Scalars['String']
}

/** aggregated selection of "web_push" */
export type Web_Push_Aggregate = {
  aggregate?: Maybe<Web_Push_Aggregate_Fields>
  nodes: Array<Web_Push>
}

/** aggregate fields of "web_push" */
export type Web_Push_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Web_Push_Max_Fields>
  min?: Maybe<Web_Push_Min_Fields>
}

/** aggregate fields of "web_push" */
export type Web_Push_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Web_Push_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "web_push". All fields are combined with a logical 'AND'. */
export type Web_Push_Bool_Exp = {
  _and?: InputMaybe<Array<Web_Push_Bool_Exp>>
  _not?: InputMaybe<Web_Push_Bool_Exp>
  _or?: InputMaybe<Array<Web_Push_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  auth?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  endpoint?: InputMaybe<String_Comparison_Exp>
  expiration_time?: InputMaybe<Timestamptz_Comparison_Exp>
  p256dh?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "web_push" */
export enum Web_Push_Constraint {
  /** unique or primary key constraint on columns "auth", "p256dh" */
  WebPushPkey = 'web_push_pkey',
}

/** input type for inserting data into table "web_push" */
export type Web_Push_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  auth?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  endpoint?: InputMaybe<Scalars['String']>
  expiration_time?: InputMaybe<Scalars['timestamptz']>
  p256dh?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Web_Push_Max_Fields = {
  account?: Maybe<Scalars['String']>
  auth?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  endpoint?: Maybe<Scalars['String']>
  expiration_time?: Maybe<Scalars['timestamptz']>
  p256dh?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Web_Push_Min_Fields = {
  account?: Maybe<Scalars['String']>
  auth?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  endpoint?: Maybe<Scalars['String']>
  expiration_time?: Maybe<Scalars['timestamptz']>
  p256dh?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "web_push" */
export type Web_Push_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Web_Push>
}

/** on_conflict condition type for table "web_push" */
export type Web_Push_On_Conflict = {
  constraint: Web_Push_Constraint
  update_columns?: Array<Web_Push_Update_Column>
  where?: InputMaybe<Web_Push_Bool_Exp>
}

/** Ordering options when selecting data from "web_push". */
export type Web_Push_Order_By = {
  account?: InputMaybe<Order_By>
  auth?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  endpoint?: InputMaybe<Order_By>
  expiration_time?: InputMaybe<Order_By>
  p256dh?: InputMaybe<Order_By>
}

/** primary key columns input for table: web_push */
export type Web_Push_Pk_Columns_Input = {
  auth: Scalars['String']
  p256dh: Scalars['String']
}

/** select columns of table "web_push" */
export enum Web_Push_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Auth = 'auth',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  ExpirationTime = 'expiration_time',
  /** column name */
  P256dh = 'p256dh',
}

/** input type for updating data in table "web_push" */
export type Web_Push_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  auth?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  endpoint?: InputMaybe<Scalars['String']>
  expiration_time?: InputMaybe<Scalars['timestamptz']>
  p256dh?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "web_push" */
export type Web_Push_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Web_Push_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Web_Push_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  auth?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  endpoint?: InputMaybe<Scalars['String']>
  expiration_time?: InputMaybe<Scalars['timestamptz']>
  p256dh?: InputMaybe<Scalars['String']>
}

/** update columns of table "web_push" */
export enum Web_Push_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Auth = 'auth',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  ExpirationTime = 'expiration_time',
  /** column name */
  P256dh = 'p256dh',
}

export type Web_Push_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Web_Push_Set_Input>
  /** filter the rows which have to be updated */
  where: Web_Push_Bool_Exp
}

export type GetRegAccountsQueryVariables = Exact<{
  account: Scalars['String']
}>

export type GetRegAccountsQuery = {
  reg_accounts: Array<{
    id: any
    account: string
    create_account?: boolean | null
    created?: any | null
    cred_id?: string | null
    device_name?: string | null
    public_key?: string | null
    referrer: string
    txid?: string | null
    created_at?: any | null
    onboarded?: boolean | null
    source?: string | null
    trust_by: Array<{ me: string; account: string; is_mutual: boolean }>
    trust_network: Array<{ account: string; me: string; is_mutual: boolean }>
  }>
}

export type GetRegAccountsByPhoneNumberQueryVariables = Exact<{
  phone: Scalars['String']
}>

export type GetRegAccountsByPhoneNumberQuery = {
  reg_accounts: Array<{
    id: any
    account: string
    create_account?: boolean | null
    created?: any | null
    cred_id?: string | null
    device_name?: string | null
    public_key?: string | null
    referrer: string
    txid?: string | null
    created_at?: any | null
    onboarded?: boolean | null
    source?: string | null
    trust_by: Array<{ me: string; account: string; is_mutual: boolean }>
    trust_network: Array<{ account: string; me: string; is_mutual: boolean }>
  }>
}

export type IdVerificationQueryVariables = Exact<{
  id_data: Id_Verification_Input
}>

export type IdVerificationQuery = { id_verification?: { existingRecord: boolean } | null }

export type SelfieVerificationQueryVariables = Exact<{
  selfie: Scalars['String']
}>

export type SelfieVerificationQuery = {
  selfie_verification?: { error: boolean; face: boolean; eye: boolean } | null
}

export type UploadImageMutationVariables = Exact<{
  image: Scalars['String']
  account: Scalars['String']
  is_selfie: Scalars['Boolean']
}>

export type UploadImageMutation = { upload_image?: { success: boolean } | null }

export type StoreIpAddressMutationVariables = Exact<{
  account: Scalars['String']
}>

export type StoreIpAddressMutation = { store_ip_address?: { success: boolean } | null }

export type InsertDevicesOneMutationVariables = Exact<{
  object: Devices_Insert_Input
}>

export type InsertDevicesOneMutation = {
  insert_devices_one?: {
    account: string
    cred_id: string
    device_name: string
    public_key: string
  } | null
}

export type InsertMigrateDeviceMutationVariables = Exact<{
  objects: Array<Migrate_Device_Insert_Input> | Migrate_Device_Insert_Input
}>

export type InsertMigrateDeviceMutation = {
  insert_migrate_device?: { affected_rows: number } | null
}

export type DeleteDevicesByPkMutationVariables = Exact<{
  cred_id: Scalars['String']
}>

export type DeleteDevicesByPkMutation = {
  delete_devices_by_pk?: {
    account: string
    cred_id: string
    device_name: string
    public_key: string
  } | null
}

export type P2POffersSubscriptionVariables = Exact<{
  order_by?: InputMaybe<Array<P2p_Offers_Order_By> | P2p_Offers_Order_By>
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}>

export type P2POffersSubscription = {
  p2p_offers: Array<{
    id: any
    bitcashbank_id?: string | null
    type: string
    amount: string
    method: string
    buyer_method_details?: string | null
    seller_method_details?: string | null
    region?: string | null
    initiator: string
    created_at: any
    updated_at: any
    matched: boolean
    buyer?: string | null
    buyer_confirmed_payment: boolean
    seller?: string | null
    seller_confirmed_payment: boolean
    completed: boolean
    cancelled: boolean
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: any | null
    cancel_approval_datetime?: any | null
    matched_datetime?: any | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
  }>
}

export type MessagesSubscriptionVariables = Exact<{
  where?: InputMaybe<Messages_Bool_Exp>
  order_by?: InputMaybe<Array<Messages_Order_By> | Messages_Order_By>
  limit?: InputMaybe<Scalars['Int']>
}>

export type MessagesSubscription = {
  messages: Array<{
    from: string
    id: any
    message: string
    p2p_id?: any | null
    support_id?: any | null
    swap_id?: any | null
    timestamp: any
    to: string
  }>
}

export type PreferencesSubscriptionVariables = Exact<{
  account: Scalars['String']
}>

export type PreferencesSubscription = {
  preferences_by_pk?: { language: string; currency: string; region: string; theme: string } | null
}

export type BitcashSwapNotificationsDataQueryVariables = Exact<{
  where?: InputMaybe<Swap_Orders_Bool_Exp>
}>

export type BitcashSwapNotificationsDataQuery = {
  swap_orders: Array<{ id: any; asset: string; order_status: string; order_type: string }>
}

export type BitcashP2POffersNotificationsDataQueryVariables = Exact<{
  where?: InputMaybe<P2p_Offers_Bool_Exp>
}>

export type BitcashP2POffersNotificationsDataQuery = {
  p2p_offers: Array<{
    id: any
    type: string
    completed: boolean
    seller?: string | null
    buyer?: string | null
    cancelled_by?: string | null
    cancelled: boolean
  }>
}

export type SwapOrdersSubscriptionVariables = Exact<{
  where?: InputMaybe<Swap_Orders_Bool_Exp>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Swap_Orders_Order_By> | Swap_Orders_Order_By>
}>

export type SwapOrdersSubscription = {
  swap_orders: Array<{
    gems_id?: string | null
    asset: string
    asset_amount: any
    bitcash_account: string
    bitcash_amount: any
    bitcash_currency: string
    bitcash_trx?: string | null
    created_at: any
    id: any
    order_status: string
    order_type: string
    price: any
    updated_at: any
    wallet_address: string
  }>
}

export type SystemNotificationsSubscriptionVariables = Exact<{ [key: string]: never }>

export type SystemNotificationsSubscription = {
  system_notifications: Array<{
    type: System_Type_Notification_Enum
    updated_at: any
    created_at: any
    id: any
    message?: string | null
    offer_id?: any | null
    p2p_offer?: {
      buyer?: string | null
      seller?: string | null
      initiator: string
      amount: string
      buyer_confirmed_payment: boolean
      seller_confirmed_payment: boolean
      matched_datetime?: any | null
      updated_at: any
      messages: Array<{ from: string; to: string; p2p_id?: any | null }>
    } | null
    system_notification_read_statuses: Array<{
      notification_id: any
      created_at: any
      reg_account: any
    }>
    system_type_notification: { type: string }
  }>
}

export type RegisterAccountsQueryVariables = Exact<{
  where?: InputMaybe<Reg_Accounts_Bool_Exp>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type RegisterAccountsQuery = {
  accounts: {
    aggregate?: { count: number } | null
    nodes: Array<{
      id: any
      account: string
      referrer: string
      create_account?: boolean | null
      created?: any | null
      cred_id?: string | null
      created_at?: any | null
      source?: string | null
      is_verified?: boolean | null
      followers: Array<{ me: string; account: string; is_mutual: boolean }>
      following: Array<{ account: string; me: string; is_mutual: boolean }>
      profile?: {
        id: any
        account: string
        email: string
        newsletter_subscription?: boolean | null
        phone: string
        full_name: string
        selfie?: string | null
        country_id: any
        registration_ip: string
        date_of_birth: any
        photo_id?: string | null
      } | null
    }>
  }
}

export type RegisterAccountSubscriptionVariables = Exact<{
  account: Scalars['String']
}>

export type RegisterAccountSubscription = {
  accounts: Array<{
    id: any
    account: string
    referrer: string
    create_account?: boolean | null
    created?: any | null
    cred_id?: string | null
    source?: string | null
    is_verified?: boolean | null
    created_at?: any | null
    trust_notifications: Array<{ me: string; account: string }>
    followers: Array<{
      me: string
      account: string
      is_mutual: boolean
      trust_by: {
        as_seller: { aggregate?: { count: number } | null }
        as_buyer: { aggregate?: { count: number } | null }
      }
    }>
    following: Array<{
      account: string
      me: string
      is_mutual: boolean
      trust_network: {
        as_seller: { aggregate?: { count: number } | null }
        as_buyer: { aggregate?: { count: number } | null }
      }
    }>
    profile?: {
      id: any
      account: string
      email: string
      newsletter_subscription?: boolean | null
      phone: string
      full_name: string
      selfie?: string | null
      photo_id?: string | null
      country_id: any
      registration_ip: string
      date_of_birth: any
    } | null
  }>
}

export type BitcashRegisterNotificationsSubscriptionVariables = Exact<{
  where?: InputMaybe<Notifications_Bool_Exp>
  order_by?: InputMaybe<Array<Notifications_Order_By> | Notifications_Order_By>
}>

export type BitcashRegisterNotificationsSubscription = {
  notifications: Array<{
    id: any
    content_id?: any | null
    from: string
    to: string
    type: string
    read: boolean
    created_at: any
  }>
}

export type BitcashInsertNotificationMutationVariables = Exact<{
  object: Notifications_Insert_Input
}>

export type BitcashInsertNotificationMutation = {
  insert_notifications_one?: {
    content_id?: any | null
    created_at: any
    from: string
    id: any
    to: string
    type: string
    read: boolean
  } | null
}

export type BitcashNotificationReadMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type BitcashNotificationReadMutation = {
  update_notifications_by_pk?: {
    id: any
    read: boolean
    from: string
    created_at: any
    content_id?: any | null
    to: string
    type: string
  } | null
}

export type BitcashNotificationDeleteMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type BitcashNotificationDeleteMutation = { delete_notifications_by_pk?: { id: any } | null }

export type InsertSystemNotificationReadStatusMutationVariables = Exact<{
  object: System_Notification_Read_Status_Insert_Input
}>

export type InsertSystemNotificationReadStatusMutation = {
  insert_system_notification_read_status_one?: {
    notification_id: any
    reg_account: any
    created_at: any
  } | null
}

export type CreateNewShareLinkMutationVariables = Exact<{
  _set: Short_Link_Input
}>

export type CreateNewShareLinkMutation = { generate_short_link: string }

export type ValidateShareLinkMutationVariables = Exact<{
  _set: Validate_Short_Link_Input
}>

export type ValidateShareLinkMutation = {
  validate_short_link?: { count: number; website: string; referrer: string } | null
}

export type UpdateAccountReferralRegisterCountMutationVariables = Exact<{
  link: Scalars['String']
}>

export type UpdateAccountReferralRegisterCountMutation = {
  update_accounts_referral?: { returning: Array<{ clicks: number; registrations: number }> } | null
}

export type UpdatePreferencesMutationVariables = Exact<{
  account: Scalars['String']
  _set: Preferences_Set_Input
}>

export type UpdatePreferencesMutation = {
  update_preferences_by_pk?: {
    account: string
    currency: string
    language: string
    region: string
    theme: string
  } | null
}

export type UpdateAccountInfoMutationVariables = Exact<{
  pk_columns: Accounts_Information_Pk_Columns_Input
  _set?: InputMaybe<Accounts_Information_Set_Input>
}>

export type UpdateAccountInfoMutation = {
  update_accounts_information_by_pk?: {
    full_name: string
    account: string
    email: string
    id: any
    newsletter_subscription?: boolean | null
    phone: string
    date_of_birth: any
  } | null
}

export type InsertAccountInformationMutationVariables = Exact<{
  input: Accounts_Information_Insert_Input
}>

export type InsertAccountInformationMutation = {
  insert_accounts_information_one?: { id: any } | null
}

export type CreatePreferencesMutationVariables = Exact<{
  object: Preferences_Insert_Input
}>

export type CreatePreferencesMutation = {
  insert_preferences_one?: {
    account: string
    currency: string
    language: string
    region: string
    theme: string
  } | null
}

export type MakeP2PBuyOfferMutationVariables = Exact<{
  amount: Scalars['String']
  buyer: Scalars['String']
  method: Scalars['String']
  region: Scalars['String']
  buyer_method_details?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
}>

export type MakeP2PBuyOfferMutation = {
  make_p2p_buy_offer?: {
    amount?: string | null
    bitcashbank_id?: string | null
    buyer?: string | null
    buyer_confirmed_payment?: boolean | null
    cancelled?: boolean | null
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: string | null
    cancel_approval_datetime?: string | null
    matched_datetime?: string | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
    completed?: boolean | null
    created_at?: string | null
    id?: string | null
    initiator?: string | null
    matched?: boolean | null
    method?: string | null
    buyer_method_details?: string | null
    seller_method_details?: string | null
    region?: string | null
    seller?: string | null
    seller_confirmed_payment?: boolean | null
    type?: string | null
    updated_at?: string | null
  } | null
}

export type MakeP2PSellOfferMutationVariables = Exact<{
  amount: Scalars['String']
  bitcashbank_id: Scalars['String']
  seller: Scalars['String']
  method: Scalars['String']
  region: Scalars['String']
  sell_put_transaction: Scalars['String']
  seller_method_details?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
}>

export type MakeP2PSellOfferMutation = {
  make_p2p_sell_offer?: {
    amount?: string | null
    bitcashbank_id?: string | null
    buyer?: string | null
    buyer_confirmed_payment?: boolean | null
    cancelled?: boolean | null
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: string | null
    cancel_approval_datetime?: string | null
    matched_datetime?: string | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
    completed?: boolean | null
    created_at?: string | null
    id?: string | null
    initiator?: string | null
    matched?: boolean | null
    method?: string | null
    buyer_method_details?: string | null
    seller_method_details?: string | null
    region?: string | null
    seller?: string | null
    seller_confirmed_payment?: boolean | null
    type?: string | null
    updated_at?: string | null
  } | null
}

export type MakeP2PPreSellOfferMutationVariables = Exact<{
  object: P2p_Pre_Sell_Offers_Insert_Input
}>

export type MakeP2PPreSellOfferMutation = {
  insert_p2p_pre_sell_offers_one?: {
    id: any
    amount: string
    bitcashbank_id: string
    created_at: any
    method: string
    region: string
    sell_put_transaction: string
    seller: string
    seller_method_details?: string | null
    updated_at: any
  } | null
}

export type FetchP2PSaleOfferSubscriptionVariables = Exact<{
  sell_put_transaction: Scalars['String']
}>

export type FetchP2PSaleOfferSubscription = {
  p2p_offers: Array<{
    amount: string
    bitcashbank_id?: string | null
    buyer?: string | null
    buyer_confirmed_payment: boolean
    cancelled: boolean
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: any | null
    cancel_approval_datetime?: any | null
    matched_datetime?: any | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
    completed: boolean
    created_at: any
    id: any
    initiator: string
    matched: boolean
    method: string
    buyer_method_details?: string | null
    seller_method_details?: string | null
    region?: string | null
    seller?: string | null
    seller_confirmed_payment: boolean
    type: string
    updated_at: any
  }>
}

export type BitcashConfirmP2PPaymentMutationVariables = Exact<{
  id: Scalars['String']
  buyer?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sell_settlement_transaction?: InputMaybe<Scalars['String']>
}>

export type BitcashConfirmP2PPaymentMutation = {
  confirm_p2p_payment?: {
    amount?: string | null
    bitcashbank_id?: string | null
    buyer?: string | null
    buyer_confirmed_payment?: boolean | null
    cancelled?: boolean | null
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: string | null
    cancel_approval_datetime?: string | null
    matched_datetime?: string | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
    completed?: boolean | null
    created_at?: string | null
    id?: string | null
    initiator?: string | null
    matched?: boolean | null
    method?: string | null
    region?: string | null
    seller?: string | null
    seller_confirmed_payment?: boolean | null
    type?: string | null
    updated_at?: string | null
  } | null
}

export type AutoCancelP2POfferMutationVariables = Exact<{
  cancelled_by: Scalars['String']
  p2p_id: Scalars['String']
}>

export type AutoCancelP2POfferMutation = {
  auto_cancel_p2p_offer?: {
    amount?: string | null
    bitcashbank_id?: string | null
    buyer?: string | null
    buyer_confirmed_payment?: boolean | null
    cancelled?: boolean | null
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: string | null
    cancel_approval_datetime?: string | null
    matched_datetime?: string | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
    completed?: boolean | null
    created_at?: string | null
    id?: string | null
    initiator?: string | null
    matched?: boolean | null
    method?: string | null
    region?: string | null
    seller?: string | null
    seller_confirmed_payment?: boolean | null
    type?: string | null
    updated_at?: string | null
  } | null
}

export type CancelP2POfferMutationVariables = Exact<{
  cancelled_by: Scalars['String']
  p2p_id: Scalars['String']
}>

export type CancelP2POfferMutation = {
  cancel_p2p_offer?: {
    amount?: string | null
    bitcashbank_id?: string | null
    buyer?: string | null
    buyer_confirmed_payment?: boolean | null
    cancelled?: boolean | null
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: string | null
    cancel_approval_datetime?: string | null
    matched_datetime?: string | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
    completed?: boolean | null
    created_at?: string | null
    id?: string | null
    initiator?: string | null
    matched?: boolean | null
    method?: string | null
    region?: string | null
    seller?: string | null
    seller_confirmed_payment?: boolean | null
    type?: string | null
    updated_at?: string | null
  } | null
}

export type CancelApproveP2POfferMutationVariables = Exact<{
  cancel_p2p_approval: Cancel_P2p_Approval_Input
}>

export type CancelApproveP2POfferMutation = {
  cancel_p2p_approval?: {
    amount?: string | null
    bitcashbank_id?: string | null
    buyer?: string | null
    buyer_confirmed_payment?: boolean | null
    cancelled?: boolean | null
    cancelled_by?: string | null
    cancellation_approved_by?: string | null
    cancellation_transaction?: string | null
    cancel_request_datetime?: string | null
    cancel_approval_datetime?: string | null
    matched_datetime?: string | null
    sell_put_transaction?: string | null
    sell_settlement_transaction?: string | null
    completed?: boolean | null
    created_at?: string | null
    id?: string | null
    initiator?: string | null
    matched?: boolean | null
    method?: string | null
    region?: string | null
    seller?: string | null
    seller_confirmed_payment?: boolean | null
    type?: string | null
    updated_at?: string | null
  } | null
}

export type BitcashSendMessageMutationVariables = Exact<{
  message: Scalars['String']
  p2p_id?: InputMaybe<Scalars['uuid']>
  support_id?: InputMaybe<Scalars['uuid']>
  swap_id?: InputMaybe<Scalars['uuid']>
  to: Scalars['String']
  from: Scalars['String']
  timestamp: Scalars['timestamptz']
}>

export type BitcashSendMessageMutation = {
  insert_messages?: {
    returning: Array<{
      from: string
      id: any
      message: string
      p2p_id?: any | null
      support_id?: any | null
      swap_id?: any | null
      timestamp: any
      to: string
    }>
  } | null
}

export type BitcashSwapAssetsQueryVariables = Exact<{
  where?: InputMaybe<Swap_Assets_Bool_Exp>
  order_by?: InputMaybe<Array<Swap_Assets_Order_By> | Swap_Assets_Order_By>
}>

export type BitcashSwapAssetsQuery = {
  swap_assets: Array<{
    active_swaps: boolean
    asset: string
    asset_name: string
    chain: string
    wallet_address: string
  }>
}

export type DevicesSubscriptionVariables = Exact<{
  where?: InputMaybe<Devices_Bool_Exp>
  order_by?: InputMaybe<Array<Devices_Order_By> | Devices_Order_By>
}>

export type DevicesSubscription = {
  devices: Array<{ account: string; cred_id: string; device_name: string; public_key: string }>
}

export type CreateSwapMutationVariables = Exact<{
  object: Swap_Orders_Insert_Input
}>

export type CreateSwapMutation = { insert_swap_orders_one?: { id: any } | null }

export type UpdateSwapOrderStatusMutationVariables = Exact<{
  id: Scalars['uuid']
  order_status?: InputMaybe<Scalars['String']>
}>

export type UpdateSwapOrderStatusMutation = {
  update_swap_orders_by_pk?: {
    asset: string
    asset_amount: any
    bitcash_account: string
    bitcash_amount: any
    bitcash_currency: string
    bitcash_trx?: string | null
    created_at: any
    id: any
    order_status: string
    order_type: string
    price: any
    updated_at: any
    wallet_address: string
  } | null
}

export type BitcashRequestNewAccountMutationVariables = Exact<{
  account_data: Request_New_Account_Input
}>

export type BitcashRequestNewAccountMutation = { request_new_account?: { id: string } | null }

export type BitcashToggleTrustNetworkMutationVariables = Exact<{
  input?: InputMaybe<Toggle_Trust_Network_Input>
}>

export type BitcashToggleTrustNetworkMutation = {
  toggle_trust_network?: { success?: boolean | null } | null
}

export type BitcashUpdateOnboardedUserMutationVariables = Exact<{
  account: Scalars['String']
}>

export type BitcashUpdateOnboardedUserMutation = {
  update_reg_accounts?: { returning: Array<{ onboarded?: boolean | null }> } | null
}

export type BitcashPresellOffersQueryVariables = Exact<{
  account: Scalars['String']
}>

export type BitcashPresellOffersQuery = {
  p2p_pre_sell_offers: Array<{
    id: any
    amount: string
    bitcashbank_id: string
    created_at: any
    method: string
    region: string
    sell_put_transaction: string
    seller: string
    seller_method_details?: string | null
    updated_at: any
  }>
}

export type AccountDeviceListQueryVariables = Exact<{
  account: Scalars['String']
}>

export type AccountDeviceListQuery = { devices: Array<{ account: string }> }

export const GetRegAccountsDocument = gql`
  query GetRegAccounts($account: String!) {
    reg_accounts(where: { account: { _eq: $account } }) {
      id
      account
      create_account
      created
      cred_id
      device_name
      public_key
      referrer
      txid
      created_at
      trust_by: trust_networks_by_trust {
        me: trust
        account: account
        is_mutual: is_mutual
      }
      trust_network: trust_networks_by_account {
        account: trust
        me: account
        is_mutual: is_mutual
      }
      onboarded
      source
    }
  }
`

/**
 * __useGetRegAccountsQuery__
 *
 * To run a query within a React component, call `useGetRegAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegAccountsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetRegAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRegAccountsQuery, GetRegAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRegAccountsQuery, GetRegAccountsQueryVariables>(
    GetRegAccountsDocument,
    options,
  )
}
export function useGetRegAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRegAccountsQuery, GetRegAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRegAccountsQuery, GetRegAccountsQueryVariables>(
    GetRegAccountsDocument,
    options,
  )
}
export type GetRegAccountsQueryHookResult = ReturnType<typeof useGetRegAccountsQuery>
export type GetRegAccountsLazyQueryHookResult = ReturnType<typeof useGetRegAccountsLazyQuery>
export type GetRegAccountsQueryResult = Apollo.QueryResult<
  GetRegAccountsQuery,
  GetRegAccountsQueryVariables
>
export const GetRegAccountsByPhoneNumberDocument = gql`
  query GetRegAccountsByPhoneNumber($phone: String!) {
    reg_accounts(where: { accounts_information: { phone: { _eq: $phone } } }) {
      id
      account
      create_account
      created
      cred_id
      device_name
      public_key
      referrer
      txid
      created_at
      trust_by: trust_networks_by_trust {
        me: trust
        account: account
        is_mutual: is_mutual
      }
      trust_network: trust_networks_by_account {
        account: trust
        me: account
        is_mutual: is_mutual
      }
      onboarded
      source
    }
  }
`

/**
 * __useGetRegAccountsByPhoneNumberQuery__
 *
 * To run a query within a React component, call `useGetRegAccountsByPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegAccountsByPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegAccountsByPhoneNumberQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useGetRegAccountsByPhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegAccountsByPhoneNumberQuery,
    GetRegAccountsByPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRegAccountsByPhoneNumberQuery,
    GetRegAccountsByPhoneNumberQueryVariables
  >(GetRegAccountsByPhoneNumberDocument, options)
}
export function useGetRegAccountsByPhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegAccountsByPhoneNumberQuery,
    GetRegAccountsByPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRegAccountsByPhoneNumberQuery,
    GetRegAccountsByPhoneNumberQueryVariables
  >(GetRegAccountsByPhoneNumberDocument, options)
}
export type GetRegAccountsByPhoneNumberQueryHookResult = ReturnType<
  typeof useGetRegAccountsByPhoneNumberQuery
>
export type GetRegAccountsByPhoneNumberLazyQueryHookResult = ReturnType<
  typeof useGetRegAccountsByPhoneNumberLazyQuery
>
export type GetRegAccountsByPhoneNumberQueryResult = Apollo.QueryResult<
  GetRegAccountsByPhoneNumberQuery,
  GetRegAccountsByPhoneNumberQueryVariables
>
export const IdVerificationDocument = gql`
  query IdVerification($id_data: id_verification_input!) {
    id_verification(id_data: $id_data) {
      existingRecord
    }
  }
`

/**
 * __useIdVerificationQuery__
 *
 * To run a query within a React component, call `useIdVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdVerificationQuery({
 *   variables: {
 *      id_data: // value for 'id_data'
 *   },
 * });
 */
export function useIdVerificationQuery(
  baseOptions: Apollo.QueryHookOptions<IdVerificationQuery, IdVerificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IdVerificationQuery, IdVerificationQueryVariables>(
    IdVerificationDocument,
    options,
  )
}
export function useIdVerificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IdVerificationQuery, IdVerificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IdVerificationQuery, IdVerificationQueryVariables>(
    IdVerificationDocument,
    options,
  )
}
export type IdVerificationQueryHookResult = ReturnType<typeof useIdVerificationQuery>
export type IdVerificationLazyQueryHookResult = ReturnType<typeof useIdVerificationLazyQuery>
export type IdVerificationQueryResult = Apollo.QueryResult<
  IdVerificationQuery,
  IdVerificationQueryVariables
>
export const SelfieVerificationDocument = gql`
  query SelfieVerification($selfie: String!) {
    selfie_verification(selfie: $selfie) {
      error
      face
      eye
    }
  }
`

/**
 * __useSelfieVerificationQuery__
 *
 * To run a query within a React component, call `useSelfieVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfieVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfieVerificationQuery({
 *   variables: {
 *      selfie: // value for 'selfie'
 *   },
 * });
 */
export function useSelfieVerificationQuery(
  baseOptions: Apollo.QueryHookOptions<SelfieVerificationQuery, SelfieVerificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SelfieVerificationQuery, SelfieVerificationQueryVariables>(
    SelfieVerificationDocument,
    options,
  )
}
export function useSelfieVerificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfieVerificationQuery,
    SelfieVerificationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SelfieVerificationQuery, SelfieVerificationQueryVariables>(
    SelfieVerificationDocument,
    options,
  )
}
export type SelfieVerificationQueryHookResult = ReturnType<typeof useSelfieVerificationQuery>
export type SelfieVerificationLazyQueryHookResult = ReturnType<
  typeof useSelfieVerificationLazyQuery
>
export type SelfieVerificationQueryResult = Apollo.QueryResult<
  SelfieVerificationQuery,
  SelfieVerificationQueryVariables
>
export const UploadImageDocument = gql`
  mutation UploadImage($image: String!, $account: String!, $is_selfie: Boolean!) {
    upload_image(image: $image, account: $account, selfie: $is_selfie) {
      success
    }
  }
`
export type UploadImageMutationFn = Apollo.MutationFunction<
  UploadImageMutation,
  UploadImageMutationVariables
>

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      account: // value for 'account'
 *      is_selfie: // value for 'is_selfie'
 *   },
 * });
 */
export function useUploadImageMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(
    UploadImageDocument,
    options,
  )
}
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<
  UploadImageMutation,
  UploadImageMutationVariables
>
export const StoreIpAddressDocument = gql`
  mutation StoreIPAddress($account: String!) {
    store_ip_address(account: $account) {
      success
    }
  }
`
export type StoreIpAddressMutationFn = Apollo.MutationFunction<
  StoreIpAddressMutation,
  StoreIpAddressMutationVariables
>

/**
 * __useStoreIpAddressMutation__
 *
 * To run a mutation, you first call `useStoreIpAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreIpAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeIpAddressMutation, { data, loading, error }] = useStoreIpAddressMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useStoreIpAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<StoreIpAddressMutation, StoreIpAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StoreIpAddressMutation, StoreIpAddressMutationVariables>(
    StoreIpAddressDocument,
    options,
  )
}
export type StoreIpAddressMutationHookResult = ReturnType<typeof useStoreIpAddressMutation>
export type StoreIpAddressMutationResult = Apollo.MutationResult<StoreIpAddressMutation>
export type StoreIpAddressMutationOptions = Apollo.BaseMutationOptions<
  StoreIpAddressMutation,
  StoreIpAddressMutationVariables
>
export const InsertDevicesOneDocument = gql`
  mutation InsertDevicesOne($object: devices_insert_input!) {
    insert_devices_one(object: $object) {
      account
      cred_id
      device_name
      public_key
    }
  }
`
export type InsertDevicesOneMutationFn = Apollo.MutationFunction<
  InsertDevicesOneMutation,
  InsertDevicesOneMutationVariables
>

/**
 * __useInsertDevicesOneMutation__
 *
 * To run a mutation, you first call `useInsertDevicesOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDevicesOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDevicesOneMutation, { data, loading, error }] = useInsertDevicesOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertDevicesOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertDevicesOneMutation,
    InsertDevicesOneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InsertDevicesOneMutation, InsertDevicesOneMutationVariables>(
    InsertDevicesOneDocument,
    options,
  )
}
export type InsertDevicesOneMutationHookResult = ReturnType<typeof useInsertDevicesOneMutation>
export type InsertDevicesOneMutationResult = Apollo.MutationResult<InsertDevicesOneMutation>
export type InsertDevicesOneMutationOptions = Apollo.BaseMutationOptions<
  InsertDevicesOneMutation,
  InsertDevicesOneMutationVariables
>
export const InsertMigrateDeviceDocument = gql`
  mutation InsertMigrateDevice($objects: [migrate_device_insert_input!]!) {
    insert_migrate_device(objects: $objects) {
      affected_rows
    }
  }
`
export type InsertMigrateDeviceMutationFn = Apollo.MutationFunction<
  InsertMigrateDeviceMutation,
  InsertMigrateDeviceMutationVariables
>

/**
 * __useInsertMigrateDeviceMutation__
 *
 * To run a mutation, you first call `useInsertMigrateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMigrateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMigrateDeviceMutation, { data, loading, error }] = useInsertMigrateDeviceMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertMigrateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertMigrateDeviceMutation,
    InsertMigrateDeviceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InsertMigrateDeviceMutation, InsertMigrateDeviceMutationVariables>(
    InsertMigrateDeviceDocument,
    options,
  )
}
export type InsertMigrateDeviceMutationHookResult = ReturnType<
  typeof useInsertMigrateDeviceMutation
>
export type InsertMigrateDeviceMutationResult = Apollo.MutationResult<InsertMigrateDeviceMutation>
export type InsertMigrateDeviceMutationOptions = Apollo.BaseMutationOptions<
  InsertMigrateDeviceMutation,
  InsertMigrateDeviceMutationVariables
>
export const DeleteDevicesByPkDocument = gql`
  mutation DeleteDevicesByPK($cred_id: String!) {
    delete_devices_by_pk(cred_id: $cred_id) {
      account
      cred_id
      device_name
      public_key
    }
  }
`
export type DeleteDevicesByPkMutationFn = Apollo.MutationFunction<
  DeleteDevicesByPkMutation,
  DeleteDevicesByPkMutationVariables
>

/**
 * __useDeleteDevicesByPkMutation__
 *
 * To run a mutation, you first call `useDeleteDevicesByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDevicesByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDevicesByPkMutation, { data, loading, error }] = useDeleteDevicesByPkMutation({
 *   variables: {
 *      cred_id: // value for 'cred_id'
 *   },
 * });
 */
export function useDeleteDevicesByPkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDevicesByPkMutation,
    DeleteDevicesByPkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteDevicesByPkMutation, DeleteDevicesByPkMutationVariables>(
    DeleteDevicesByPkDocument,
    options,
  )
}
export type DeleteDevicesByPkMutationHookResult = ReturnType<typeof useDeleteDevicesByPkMutation>
export type DeleteDevicesByPkMutationResult = Apollo.MutationResult<DeleteDevicesByPkMutation>
export type DeleteDevicesByPkMutationOptions = Apollo.BaseMutationOptions<
  DeleteDevicesByPkMutation,
  DeleteDevicesByPkMutationVariables
>
export const P2POffersDocument = gql`
  subscription P2POffers($order_by: [p2p_offers_order_by!] = {}, $where: p2p_offers_bool_exp = {}) {
    p2p_offers(order_by: $order_by, where: $where) {
      id
      bitcashbank_id
      type
      amount
      method
      buyer_method_details
      seller_method_details
      region
      initiator
      created_at
      updated_at
      matched
      buyer
      buyer_confirmed_payment
      seller
      seller_confirmed_payment
      completed
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
    }
  }
`

/**
 * __useP2POffersSubscription__
 *
 * To run a query within a React component, call `useP2POffersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useP2POffersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useP2POffersSubscription({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useP2POffersSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    P2POffersSubscription,
    P2POffersSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<P2POffersSubscription, P2POffersSubscriptionVariables>(
    P2POffersDocument,
    options,
  )
}
export type P2POffersSubscriptionHookResult = ReturnType<typeof useP2POffersSubscription>
export type P2POffersSubscriptionResult = Apollo.SubscriptionResult<P2POffersSubscription>
export const MessagesDocument = gql`
  subscription Messages(
    $where: messages_bool_exp = {}
    $order_by: [messages_order_by!] = {}
    $limit: Int = 500
  ) {
    messages(where: $where, order_by: $order_by, limit: $limit) {
      from
      id
      message
      p2p_id
      support_id
      swap_id
      timestamp
      to
    }
  }
`

/**
 * __useMessagesSubscription__
 *
 * To run a query within a React component, call `useMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMessagesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<MessagesSubscription, MessagesSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<MessagesSubscription, MessagesSubscriptionVariables>(
    MessagesDocument,
    options,
  )
}
export type MessagesSubscriptionHookResult = ReturnType<typeof useMessagesSubscription>
export type MessagesSubscriptionResult = Apollo.SubscriptionResult<MessagesSubscription>
export const PreferencesDocument = gql`
  subscription Preferences($account: String!) {
    preferences_by_pk(account: $account) {
      language
      currency
      region
      theme
    }
  }
`

/**
 * __usePreferencesSubscription__
 *
 * To run a query within a React component, call `usePreferencesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesSubscription({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function usePreferencesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PreferencesSubscription,
    PreferencesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<PreferencesSubscription, PreferencesSubscriptionVariables>(
    PreferencesDocument,
    options,
  )
}
export type PreferencesSubscriptionHookResult = ReturnType<typeof usePreferencesSubscription>
export type PreferencesSubscriptionResult = Apollo.SubscriptionResult<PreferencesSubscription>
export const BitcashSwapNotificationsDataDocument = gql`
  query BitcashSwapNotificationsData($where: swap_orders_bool_exp = {}) {
    swap_orders(where: $where) {
      id
      asset
      order_status
      order_type
    }
  }
`

/**
 * __useBitcashSwapNotificationsDataQuery__
 *
 * To run a query within a React component, call `useBitcashSwapNotificationsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitcashSwapNotificationsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashSwapNotificationsDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBitcashSwapNotificationsDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BitcashSwapNotificationsDataQuery,
    BitcashSwapNotificationsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BitcashSwapNotificationsDataQuery,
    BitcashSwapNotificationsDataQueryVariables
  >(BitcashSwapNotificationsDataDocument, options)
}
export function useBitcashSwapNotificationsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BitcashSwapNotificationsDataQuery,
    BitcashSwapNotificationsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BitcashSwapNotificationsDataQuery,
    BitcashSwapNotificationsDataQueryVariables
  >(BitcashSwapNotificationsDataDocument, options)
}
export type BitcashSwapNotificationsDataQueryHookResult = ReturnType<
  typeof useBitcashSwapNotificationsDataQuery
>
export type BitcashSwapNotificationsDataLazyQueryHookResult = ReturnType<
  typeof useBitcashSwapNotificationsDataLazyQuery
>
export type BitcashSwapNotificationsDataQueryResult = Apollo.QueryResult<
  BitcashSwapNotificationsDataQuery,
  BitcashSwapNotificationsDataQueryVariables
>
export const BitcashP2POffersNotificationsDataDocument = gql`
  query BitcashP2POffersNotificationsData($where: p2p_offers_bool_exp = {}) {
    p2p_offers(where: $where) {
      id
      type
      completed
      seller
      buyer
      cancelled_by
      cancelled
    }
  }
`

/**
 * __useBitcashP2POffersNotificationsDataQuery__
 *
 * To run a query within a React component, call `useBitcashP2POffersNotificationsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitcashP2POffersNotificationsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashP2POffersNotificationsDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBitcashP2POffersNotificationsDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BitcashP2POffersNotificationsDataQuery,
    BitcashP2POffersNotificationsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BitcashP2POffersNotificationsDataQuery,
    BitcashP2POffersNotificationsDataQueryVariables
  >(BitcashP2POffersNotificationsDataDocument, options)
}
export function useBitcashP2POffersNotificationsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BitcashP2POffersNotificationsDataQuery,
    BitcashP2POffersNotificationsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BitcashP2POffersNotificationsDataQuery,
    BitcashP2POffersNotificationsDataQueryVariables
  >(BitcashP2POffersNotificationsDataDocument, options)
}
export type BitcashP2POffersNotificationsDataQueryHookResult = ReturnType<
  typeof useBitcashP2POffersNotificationsDataQuery
>
export type BitcashP2POffersNotificationsDataLazyQueryHookResult = ReturnType<
  typeof useBitcashP2POffersNotificationsDataLazyQuery
>
export type BitcashP2POffersNotificationsDataQueryResult = Apollo.QueryResult<
  BitcashP2POffersNotificationsDataQuery,
  BitcashP2POffersNotificationsDataQueryVariables
>
export const SwapOrdersDocument = gql`
  subscription SwapOrders(
    $where: swap_orders_bool_exp = {}
    $limit: Int = 30
    $offset: Int
    $order_by: [swap_orders_order_by!] = {}
  ) {
    swap_orders(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      gems_id
      asset
      asset_amount
      bitcash_account
      bitcash_amount
      bitcash_currency
      bitcash_trx
      created_at
      id
      order_status
      order_type
      price
      updated_at
      wallet_address
    }
  }
`

/**
 * __useSwapOrdersSubscription__
 *
 * To run a query within a React component, call `useSwapOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSwapOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwapOrdersSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSwapOrdersSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SwapOrdersSubscription,
    SwapOrdersSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<SwapOrdersSubscription, SwapOrdersSubscriptionVariables>(
    SwapOrdersDocument,
    options,
  )
}
export type SwapOrdersSubscriptionHookResult = ReturnType<typeof useSwapOrdersSubscription>
export type SwapOrdersSubscriptionResult = Apollo.SubscriptionResult<SwapOrdersSubscription>
export const SystemNotificationsDocument = gql`
  subscription SystemNotifications {
    system_notifications(order_by: { created_at: desc }) {
      type
      updated_at
      created_at
      id
      message
      offer_id
      p2p_offer {
        buyer
        seller
        initiator
        amount
        buyer_confirmed_payment
        seller_confirmed_payment
        matched_datetime
        updated_at
        messages {
          from
          to
          p2p_id
        }
      }
      system_notification_read_statuses {
        notification_id
        created_at
        reg_account
      }
      system_type_notification {
        type
      }
    }
  }
`

/**
 * __useSystemNotificationsSubscription__
 *
 * To run a query within a React component, call `useSystemNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSystemNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSystemNotificationsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SystemNotificationsSubscription,
    SystemNotificationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SystemNotificationsSubscription,
    SystemNotificationsSubscriptionVariables
  >(SystemNotificationsDocument, options)
}
export type SystemNotificationsSubscriptionHookResult = ReturnType<
  typeof useSystemNotificationsSubscription
>
export type SystemNotificationsSubscriptionResult =
  Apollo.SubscriptionResult<SystemNotificationsSubscription>
export const RegisterAccountsDocument = gql`
  query RegisterAccounts($where: reg_accounts_bool_exp, $limit: Int, $offset: Int) {
    accounts: reg_accounts_aggregate(where: $where) {
      aggregate {
        count
      }
      nodes {
        id
        account
        referrer
        create_account
        created
        cred_id
        created_at
        source
        is_verified
        followers: trust_networks_by_trust {
          me: trust
          account: account
          is_mutual: is_mutual
        }
        following: trust_networks_by_account {
          account: trust
          me: account
          is_mutual: is_mutual
        }
        profile: accounts_information {
          id
          account
          email
          newsletter_subscription
          phone
          full_name
          selfie
          country_id
          registration_ip
          date_of_birth
          photo_id
        }
      }
    }
  }
`

/**
 * __useRegisterAccountsQuery__
 *
 * To run a query within a React component, call `useRegisterAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterAccountsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useRegisterAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<RegisterAccountsQuery, RegisterAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RegisterAccountsQuery, RegisterAccountsQueryVariables>(
    RegisterAccountsDocument,
    options,
  )
}
export function useRegisterAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegisterAccountsQuery, RegisterAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RegisterAccountsQuery, RegisterAccountsQueryVariables>(
    RegisterAccountsDocument,
    options,
  )
}
export type RegisterAccountsQueryHookResult = ReturnType<typeof useRegisterAccountsQuery>
export type RegisterAccountsLazyQueryHookResult = ReturnType<typeof useRegisterAccountsLazyQuery>
export type RegisterAccountsQueryResult = Apollo.QueryResult<
  RegisterAccountsQuery,
  RegisterAccountsQueryVariables
>
export const RegisterAccountDocument = gql`
  subscription RegisterAccount($account: String!) {
    accounts: reg_accounts(where: { account: { _eq: $account } }) {
      id
      account
      referrer
      create_account
      created
      cred_id
      source
      is_verified
      created_at
      trust_notifications: trust_network_notifications {
        me: account
        account: trust
      }
      followers: trust_networks_by_trust {
        me: trust
        account: account
        is_mutual: is_mutual
        trust_by {
          as_seller: p2p_offers_seller_aggregate(
            where: {
              type: { _in: ["buy", "sell"] }
              _or: [{ completed: { _eq: true } }, { cancelled: { _eq: true } }]
            }
          ) {
            aggregate {
              count
            }
          }
          as_buyer: p2p_offers_buyer_aggregate(
            where: {
              type: { _in: ["buy", "sell"] }
              _or: [{ completed: { _eq: true } }, { cancelled: { _eq: true } }]
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
      following: trust_networks_by_account {
        account: trust
        me: account
        is_mutual: is_mutual
        trust_network {
          as_seller: p2p_offers_seller_aggregate(
            where: {
              type: { _in: ["buy", "sell"] }
              _or: [{ completed: { _eq: true } }, { cancelled: { _eq: true } }]
            }
          ) {
            aggregate {
              count
            }
          }
          as_buyer: p2p_offers_buyer_aggregate(
            where: {
              type: { _in: ["buy", "sell"] }
              _or: [{ completed: { _eq: true } }, { cancelled: { _eq: true } }]
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
      profile: accounts_information {
        id
        account
        email
        newsletter_subscription
        phone
        full_name
        selfie
        photo_id
        country_id
        registration_ip
        date_of_birth
      }
    }
  }
`

/**
 * __useRegisterAccountSubscription__
 *
 * To run a query within a React component, call `useRegisterAccountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterAccountSubscription({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useRegisterAccountSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RegisterAccountSubscription,
    RegisterAccountSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<RegisterAccountSubscription, RegisterAccountSubscriptionVariables>(
    RegisterAccountDocument,
    options,
  )
}
export type RegisterAccountSubscriptionHookResult = ReturnType<
  typeof useRegisterAccountSubscription
>
export type RegisterAccountSubscriptionResult =
  Apollo.SubscriptionResult<RegisterAccountSubscription>
export const BitcashRegisterNotificationsDocument = gql`
  subscription BitcashRegisterNotifications(
    $where: notifications_bool_exp = {}
    $order_by: [notifications_order_by!] = {}
  ) {
    notifications(where: $where, order_by: $order_by) {
      id
      content_id
      from
      to
      type
      read
      created_at
    }
  }
`

/**
 * __useBitcashRegisterNotificationsSubscription__
 *
 * To run a query within a React component, call `useBitcashRegisterNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBitcashRegisterNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashRegisterNotificationsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useBitcashRegisterNotificationsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    BitcashRegisterNotificationsSubscription,
    BitcashRegisterNotificationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    BitcashRegisterNotificationsSubscription,
    BitcashRegisterNotificationsSubscriptionVariables
  >(BitcashRegisterNotificationsDocument, options)
}
export type BitcashRegisterNotificationsSubscriptionHookResult = ReturnType<
  typeof useBitcashRegisterNotificationsSubscription
>
export type BitcashRegisterNotificationsSubscriptionResult =
  Apollo.SubscriptionResult<BitcashRegisterNotificationsSubscription>
export const BitcashInsertNotificationDocument = gql`
  mutation BitcashInsertNotification($object: notifications_insert_input!) {
    insert_notifications_one(object: $object) {
      content_id
      created_at
      from
      id
      to
      type
      read
    }
  }
`
export type BitcashInsertNotificationMutationFn = Apollo.MutationFunction<
  BitcashInsertNotificationMutation,
  BitcashInsertNotificationMutationVariables
>

/**
 * __useBitcashInsertNotificationMutation__
 *
 * To run a mutation, you first call `useBitcashInsertNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashInsertNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashInsertNotificationMutation, { data, loading, error }] = useBitcashInsertNotificationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useBitcashInsertNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashInsertNotificationMutation,
    BitcashInsertNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BitcashInsertNotificationMutation,
    BitcashInsertNotificationMutationVariables
  >(BitcashInsertNotificationDocument, options)
}
export type BitcashInsertNotificationMutationHookResult = ReturnType<
  typeof useBitcashInsertNotificationMutation
>
export type BitcashInsertNotificationMutationResult =
  Apollo.MutationResult<BitcashInsertNotificationMutation>
export type BitcashInsertNotificationMutationOptions = Apollo.BaseMutationOptions<
  BitcashInsertNotificationMutation,
  BitcashInsertNotificationMutationVariables
>
export const BitcashNotificationReadDocument = gql`
  mutation BitcashNotificationRead($id: uuid!) {
    update_notifications_by_pk(pk_columns: { id: $id }, _set: { read: true }) {
      id
      read
      from
      created_at
      content_id
      to
      type
    }
  }
`
export type BitcashNotificationReadMutationFn = Apollo.MutationFunction<
  BitcashNotificationReadMutation,
  BitcashNotificationReadMutationVariables
>

/**
 * __useBitcashNotificationReadMutation__
 *
 * To run a mutation, you first call `useBitcashNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashNotificationReadMutation, { data, loading, error }] = useBitcashNotificationReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBitcashNotificationReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashNotificationReadMutation,
    BitcashNotificationReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BitcashNotificationReadMutation,
    BitcashNotificationReadMutationVariables
  >(BitcashNotificationReadDocument, options)
}
export type BitcashNotificationReadMutationHookResult = ReturnType<
  typeof useBitcashNotificationReadMutation
>
export type BitcashNotificationReadMutationResult =
  Apollo.MutationResult<BitcashNotificationReadMutation>
export type BitcashNotificationReadMutationOptions = Apollo.BaseMutationOptions<
  BitcashNotificationReadMutation,
  BitcashNotificationReadMutationVariables
>
export const BitcashNotificationDeleteDocument = gql`
  mutation BitcashNotificationDelete($id: uuid!) {
    delete_notifications_by_pk(id: $id) {
      id
    }
  }
`
export type BitcashNotificationDeleteMutationFn = Apollo.MutationFunction<
  BitcashNotificationDeleteMutation,
  BitcashNotificationDeleteMutationVariables
>

/**
 * __useBitcashNotificationDeleteMutation__
 *
 * To run a mutation, you first call `useBitcashNotificationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashNotificationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashNotificationDeleteMutation, { data, loading, error }] = useBitcashNotificationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBitcashNotificationDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashNotificationDeleteMutation,
    BitcashNotificationDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BitcashNotificationDeleteMutation,
    BitcashNotificationDeleteMutationVariables
  >(BitcashNotificationDeleteDocument, options)
}
export type BitcashNotificationDeleteMutationHookResult = ReturnType<
  typeof useBitcashNotificationDeleteMutation
>
export type BitcashNotificationDeleteMutationResult =
  Apollo.MutationResult<BitcashNotificationDeleteMutation>
export type BitcashNotificationDeleteMutationOptions = Apollo.BaseMutationOptions<
  BitcashNotificationDeleteMutation,
  BitcashNotificationDeleteMutationVariables
>
export const InsertSystemNotificationReadStatusDocument = gql`
  mutation InsertSystemNotificationReadStatus(
    $object: system_notification_read_status_insert_input!
  ) {
    insert_system_notification_read_status_one(object: $object) {
      notification_id
      reg_account
      created_at
    }
  }
`
export type InsertSystemNotificationReadStatusMutationFn = Apollo.MutationFunction<
  InsertSystemNotificationReadStatusMutation,
  InsertSystemNotificationReadStatusMutationVariables
>

/**
 * __useInsertSystemNotificationReadStatusMutation__
 *
 * To run a mutation, you first call `useInsertSystemNotificationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSystemNotificationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSystemNotificationReadStatusMutation, { data, loading, error }] = useInsertSystemNotificationReadStatusMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSystemNotificationReadStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSystemNotificationReadStatusMutation,
    InsertSystemNotificationReadStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertSystemNotificationReadStatusMutation,
    InsertSystemNotificationReadStatusMutationVariables
  >(InsertSystemNotificationReadStatusDocument, options)
}
export type InsertSystemNotificationReadStatusMutationHookResult = ReturnType<
  typeof useInsertSystemNotificationReadStatusMutation
>
export type InsertSystemNotificationReadStatusMutationResult =
  Apollo.MutationResult<InsertSystemNotificationReadStatusMutation>
export type InsertSystemNotificationReadStatusMutationOptions = Apollo.BaseMutationOptions<
  InsertSystemNotificationReadStatusMutation,
  InsertSystemNotificationReadStatusMutationVariables
>
export const CreateNewShareLinkDocument = gql`
  mutation CreateNewShareLink($_set: short_link_input!) {
    generate_short_link(short_link_input: $_set)
  }
`
export type CreateNewShareLinkMutationFn = Apollo.MutationFunction<
  CreateNewShareLinkMutation,
  CreateNewShareLinkMutationVariables
>

/**
 * __useCreateNewShareLinkMutation__
 *
 * To run a mutation, you first call `useCreateNewShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewShareLinkMutation, { data, loading, error }] = useCreateNewShareLinkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useCreateNewShareLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewShareLinkMutation,
    CreateNewShareLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateNewShareLinkMutation, CreateNewShareLinkMutationVariables>(
    CreateNewShareLinkDocument,
    options,
  )
}
export type CreateNewShareLinkMutationHookResult = ReturnType<typeof useCreateNewShareLinkMutation>
export type CreateNewShareLinkMutationResult = Apollo.MutationResult<CreateNewShareLinkMutation>
export type CreateNewShareLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateNewShareLinkMutation,
  CreateNewShareLinkMutationVariables
>
export const ValidateShareLinkDocument = gql`
  mutation ValidateShareLink($_set: validate_short_link_input!) {
    validate_short_link(validate_short_link_input: $_set) {
      count
      website
      referrer
    }
  }
`
export type ValidateShareLinkMutationFn = Apollo.MutationFunction<
  ValidateShareLinkMutation,
  ValidateShareLinkMutationVariables
>

/**
 * __useValidateShareLinkMutation__
 *
 * To run a mutation, you first call `useValidateShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateShareLinkMutation, { data, loading, error }] = useValidateShareLinkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useValidateShareLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateShareLinkMutation,
    ValidateShareLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ValidateShareLinkMutation, ValidateShareLinkMutationVariables>(
    ValidateShareLinkDocument,
    options,
  )
}
export type ValidateShareLinkMutationHookResult = ReturnType<typeof useValidateShareLinkMutation>
export type ValidateShareLinkMutationResult = Apollo.MutationResult<ValidateShareLinkMutation>
export type ValidateShareLinkMutationOptions = Apollo.BaseMutationOptions<
  ValidateShareLinkMutation,
  ValidateShareLinkMutationVariables
>
export const UpdateAccountReferralRegisterCountDocument = gql`
  mutation UpdateAccountReferralRegisterCount($link: String!) {
    update_accounts_referral(where: { link: { _eq: $link } }, _inc: { registrations: 1 }) {
      returning {
        clicks
        registrations
      }
    }
  }
`
export type UpdateAccountReferralRegisterCountMutationFn = Apollo.MutationFunction<
  UpdateAccountReferralRegisterCountMutation,
  UpdateAccountReferralRegisterCountMutationVariables
>

/**
 * __useUpdateAccountReferralRegisterCountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountReferralRegisterCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountReferralRegisterCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountReferralRegisterCountMutation, { data, loading, error }] = useUpdateAccountReferralRegisterCountMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateAccountReferralRegisterCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountReferralRegisterCountMutation,
    UpdateAccountReferralRegisterCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAccountReferralRegisterCountMutation,
    UpdateAccountReferralRegisterCountMutationVariables
  >(UpdateAccountReferralRegisterCountDocument, options)
}
export type UpdateAccountReferralRegisterCountMutationHookResult = ReturnType<
  typeof useUpdateAccountReferralRegisterCountMutation
>
export type UpdateAccountReferralRegisterCountMutationResult =
  Apollo.MutationResult<UpdateAccountReferralRegisterCountMutation>
export type UpdateAccountReferralRegisterCountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountReferralRegisterCountMutation,
  UpdateAccountReferralRegisterCountMutationVariables
>
export const UpdatePreferencesDocument = gql`
  mutation UpdatePreferences($account: String!, $_set: preferences_set_input!) {
    update_preferences_by_pk(pk_columns: { account: $account }, _set: $_set) {
      account
      currency
      language
      region
      theme
    }
  }
`
export type UpdatePreferencesMutationFn = Apollo.MutationFunction<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferencesMutation, { data, loading, error }] = useUpdatePreferencesMutation({
 *   variables: {
 *      account: // value for 'account'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreferencesMutation,
    UpdatePreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(
    UpdatePreferencesDocument,
    options,
  )
}
export type UpdatePreferencesMutationHookResult = ReturnType<typeof useUpdatePreferencesMutation>
export type UpdatePreferencesMutationResult = Apollo.MutationResult<UpdatePreferencesMutation>
export type UpdatePreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>
export const UpdateAccountInfoDocument = gql`
  mutation UpdateAccountInfo(
    $pk_columns: accounts_information_pk_columns_input!
    $_set: accounts_information_set_input
  ) {
    update_accounts_information_by_pk(pk_columns: $pk_columns, _set: $_set) {
      full_name
      account
      email
      id
      newsletter_subscription
      phone
      date_of_birth
    }
  }
`
export type UpdateAccountInfoMutationFn = Apollo.MutationFunction<
  UpdateAccountInfoMutation,
  UpdateAccountInfoMutationVariables
>

/**
 * __useUpdateAccountInfoMutation__
 *
 * To run a mutation, you first call `useUpdateAccountInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountInfoMutation, { data, loading, error }] = useUpdateAccountInfoMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateAccountInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountInfoMutation,
    UpdateAccountInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAccountInfoMutation, UpdateAccountInfoMutationVariables>(
    UpdateAccountInfoDocument,
    options,
  )
}
export type UpdateAccountInfoMutationHookResult = ReturnType<typeof useUpdateAccountInfoMutation>
export type UpdateAccountInfoMutationResult = Apollo.MutationResult<UpdateAccountInfoMutation>
export type UpdateAccountInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountInfoMutation,
  UpdateAccountInfoMutationVariables
>
export const InsertAccountInformationDocument = gql`
  mutation InsertAccountInformation($input: accounts_information_insert_input!) {
    insert_accounts_information_one(object: $input) {
      id
    }
  }
`
export type InsertAccountInformationMutationFn = Apollo.MutationFunction<
  InsertAccountInformationMutation,
  InsertAccountInformationMutationVariables
>

/**
 * __useInsertAccountInformationMutation__
 *
 * To run a mutation, you first call `useInsertAccountInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountInformationMutation, { data, loading, error }] = useInsertAccountInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertAccountInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertAccountInformationMutation,
    InsertAccountInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertAccountInformationMutation,
    InsertAccountInformationMutationVariables
  >(InsertAccountInformationDocument, options)
}
export type InsertAccountInformationMutationHookResult = ReturnType<
  typeof useInsertAccountInformationMutation
>
export type InsertAccountInformationMutationResult =
  Apollo.MutationResult<InsertAccountInformationMutation>
export type InsertAccountInformationMutationOptions = Apollo.BaseMutationOptions<
  InsertAccountInformationMutation,
  InsertAccountInformationMutationVariables
>
export const CreatePreferencesDocument = gql`
  mutation CreatePreferences($object: preferences_insert_input!) {
    insert_preferences_one(object: $object) {
      account
      currency
      language
      region
      theme
    }
  }
`
export type CreatePreferencesMutationFn = Apollo.MutationFunction<
  CreatePreferencesMutation,
  CreatePreferencesMutationVariables
>

/**
 * __useCreatePreferencesMutation__
 *
 * To run a mutation, you first call `useCreatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreferencesMutation, { data, loading, error }] = useCreatePreferencesMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreatePreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePreferencesMutation,
    CreatePreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePreferencesMutation, CreatePreferencesMutationVariables>(
    CreatePreferencesDocument,
    options,
  )
}
export type CreatePreferencesMutationHookResult = ReturnType<typeof useCreatePreferencesMutation>
export type CreatePreferencesMutationResult = Apollo.MutationResult<CreatePreferencesMutation>
export type CreatePreferencesMutationOptions = Apollo.BaseMutationOptions<
  CreatePreferencesMutation,
  CreatePreferencesMutationVariables
>
export const MakeP2PBuyOfferDocument = gql`
  mutation MakeP2PBuyOffer(
    $amount: String!
    $buyer: String!
    $method: String!
    $region: String!
    $buyer_method_details: String
    $id: String
  ) {
    make_p2p_buy_offer(
      offer: {
        amount: $amount
        buyer: $buyer
        method: $method
        region: $region
        buyer_method_details: $buyer_method_details
        id: $id
      }
    ) {
      amount
      bitcashbank_id
      buyer
      buyer_confirmed_payment
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
      completed
      created_at
      id
      initiator
      matched
      method
      buyer_method_details
      seller_method_details
      region
      seller
      seller_confirmed_payment
      type
      updated_at
    }
  }
`
export type MakeP2PBuyOfferMutationFn = Apollo.MutationFunction<
  MakeP2PBuyOfferMutation,
  MakeP2PBuyOfferMutationVariables
>

/**
 * __useMakeP2PBuyOfferMutation__
 *
 * To run a mutation, you first call `useMakeP2PBuyOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeP2PBuyOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeP2PBuyOfferMutation, { data, loading, error }] = useMakeP2PBuyOfferMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      buyer: // value for 'buyer'
 *      method: // value for 'method'
 *      region: // value for 'region'
 *      buyer_method_details: // value for 'buyer_method_details'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeP2PBuyOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeP2PBuyOfferMutation,
    MakeP2PBuyOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MakeP2PBuyOfferMutation, MakeP2PBuyOfferMutationVariables>(
    MakeP2PBuyOfferDocument,
    options,
  )
}
export type MakeP2PBuyOfferMutationHookResult = ReturnType<typeof useMakeP2PBuyOfferMutation>
export type MakeP2PBuyOfferMutationResult = Apollo.MutationResult<MakeP2PBuyOfferMutation>
export type MakeP2PBuyOfferMutationOptions = Apollo.BaseMutationOptions<
  MakeP2PBuyOfferMutation,
  MakeP2PBuyOfferMutationVariables
>
export const MakeP2PSellOfferDocument = gql`
  mutation MakeP2PSellOffer(
    $amount: String!
    $bitcashbank_id: String!
    $seller: String!
    $method: String!
    $region: String!
    $sell_put_transaction: String!
    $seller_method_details: String
    $id: String
  ) {
    make_p2p_sell_offer(
      offer: {
        amount: $amount
        bitcashbank_id: $bitcashbank_id
        method: $method
        seller: $seller
        region: $region
        sell_put_transaction: $sell_put_transaction
        seller_method_details: $seller_method_details
        id: $id
      }
    ) {
      amount
      bitcashbank_id
      buyer
      buyer_confirmed_payment
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
      completed
      created_at
      id
      initiator
      matched
      method
      buyer_method_details
      seller_method_details
      region
      seller
      seller_confirmed_payment
      type
      updated_at
    }
  }
`
export type MakeP2PSellOfferMutationFn = Apollo.MutationFunction<
  MakeP2PSellOfferMutation,
  MakeP2PSellOfferMutationVariables
>

/**
 * __useMakeP2PSellOfferMutation__
 *
 * To run a mutation, you first call `useMakeP2PSellOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeP2PSellOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeP2PSellOfferMutation, { data, loading, error }] = useMakeP2PSellOfferMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      bitcashbank_id: // value for 'bitcashbank_id'
 *      seller: // value for 'seller'
 *      method: // value for 'method'
 *      region: // value for 'region'
 *      sell_put_transaction: // value for 'sell_put_transaction'
 *      seller_method_details: // value for 'seller_method_details'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeP2PSellOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeP2PSellOfferMutation,
    MakeP2PSellOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MakeP2PSellOfferMutation, MakeP2PSellOfferMutationVariables>(
    MakeP2PSellOfferDocument,
    options,
  )
}
export type MakeP2PSellOfferMutationHookResult = ReturnType<typeof useMakeP2PSellOfferMutation>
export type MakeP2PSellOfferMutationResult = Apollo.MutationResult<MakeP2PSellOfferMutation>
export type MakeP2PSellOfferMutationOptions = Apollo.BaseMutationOptions<
  MakeP2PSellOfferMutation,
  MakeP2PSellOfferMutationVariables
>
export const MakeP2PPreSellOfferDocument = gql`
  mutation MakeP2PPreSellOffer($object: p2p_pre_sell_offers_insert_input!) {
    insert_p2p_pre_sell_offers_one(object: $object) {
      id
      amount
      bitcashbank_id
      created_at
      method
      region
      sell_put_transaction
      seller
      seller_method_details
      updated_at
    }
  }
`
export type MakeP2PPreSellOfferMutationFn = Apollo.MutationFunction<
  MakeP2PPreSellOfferMutation,
  MakeP2PPreSellOfferMutationVariables
>

/**
 * __useMakeP2PPreSellOfferMutation__
 *
 * To run a mutation, you first call `useMakeP2PPreSellOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeP2PPreSellOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeP2PPreSellOfferMutation, { data, loading, error }] = useMakeP2PPreSellOfferMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useMakeP2PPreSellOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeP2PPreSellOfferMutation,
    MakeP2PPreSellOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MakeP2PPreSellOfferMutation, MakeP2PPreSellOfferMutationVariables>(
    MakeP2PPreSellOfferDocument,
    options,
  )
}
export type MakeP2PPreSellOfferMutationHookResult = ReturnType<
  typeof useMakeP2PPreSellOfferMutation
>
export type MakeP2PPreSellOfferMutationResult = Apollo.MutationResult<MakeP2PPreSellOfferMutation>
export type MakeP2PPreSellOfferMutationOptions = Apollo.BaseMutationOptions<
  MakeP2PPreSellOfferMutation,
  MakeP2PPreSellOfferMutationVariables
>
export const FetchP2PSaleOfferDocument = gql`
  subscription FetchP2PSaleOffer($sell_put_transaction: String!) {
    p2p_offers(
      where: { sell_put_transaction: { _eq: $sell_put_transaction } }
      order_by: { updated_at: desc_nulls_last }
    ) {
      amount
      bitcashbank_id
      buyer
      buyer_confirmed_payment
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
      completed
      created_at
      id
      initiator
      matched
      method
      buyer_method_details
      seller_method_details
      region
      seller
      seller_confirmed_payment
      type
      updated_at
    }
  }
`

/**
 * __useFetchP2PSaleOfferSubscription__
 *
 * To run a query within a React component, call `useFetchP2PSaleOfferSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFetchP2PSaleOfferSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchP2PSaleOfferSubscription({
 *   variables: {
 *      sell_put_transaction: // value for 'sell_put_transaction'
 *   },
 * });
 */
export function useFetchP2PSaleOfferSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FetchP2PSaleOfferSubscription,
    FetchP2PSaleOfferSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FetchP2PSaleOfferSubscription,
    FetchP2PSaleOfferSubscriptionVariables
  >(FetchP2PSaleOfferDocument, options)
}
export type FetchP2PSaleOfferSubscriptionHookResult = ReturnType<
  typeof useFetchP2PSaleOfferSubscription
>
export type FetchP2PSaleOfferSubscriptionResult =
  Apollo.SubscriptionResult<FetchP2PSaleOfferSubscription>
export const BitcashConfirmP2PPaymentDocument = gql`
  mutation BitcashConfirmP2PPayment(
    $id: String!
    $buyer: String
    $seller: String
    $sell_settlement_transaction: String
  ) {
    confirm_p2p_payment(
      p2p_data: {
        id: $id
        buyer: $buyer
        seller: $seller
        sell_settlement_transaction: $sell_settlement_transaction
      }
    ) {
      amount
      bitcashbank_id
      buyer
      buyer_confirmed_payment
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
      completed
      created_at
      id
      initiator
      matched
      method
      region
      seller
      seller_confirmed_payment
      type
      updated_at
    }
  }
`
export type BitcashConfirmP2PPaymentMutationFn = Apollo.MutationFunction<
  BitcashConfirmP2PPaymentMutation,
  BitcashConfirmP2PPaymentMutationVariables
>

/**
 * __useBitcashConfirmP2PPaymentMutation__
 *
 * To run a mutation, you first call `useBitcashConfirmP2PPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashConfirmP2PPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashConfirmP2PPaymentMutation, { data, loading, error }] = useBitcashConfirmP2PPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      buyer: // value for 'buyer'
 *      seller: // value for 'seller'
 *      sell_settlement_transaction: // value for 'sell_settlement_transaction'
 *   },
 * });
 */
export function useBitcashConfirmP2PPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashConfirmP2PPaymentMutation,
    BitcashConfirmP2PPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BitcashConfirmP2PPaymentMutation,
    BitcashConfirmP2PPaymentMutationVariables
  >(BitcashConfirmP2PPaymentDocument, options)
}
export type BitcashConfirmP2PPaymentMutationHookResult = ReturnType<
  typeof useBitcashConfirmP2PPaymentMutation
>
export type BitcashConfirmP2PPaymentMutationResult =
  Apollo.MutationResult<BitcashConfirmP2PPaymentMutation>
export type BitcashConfirmP2PPaymentMutationOptions = Apollo.BaseMutationOptions<
  BitcashConfirmP2PPaymentMutation,
  BitcashConfirmP2PPaymentMutationVariables
>
export const AutoCancelP2POfferDocument = gql`
  mutation AutoCancelP2POffer($cancelled_by: String!, $p2p_id: String!) {
    auto_cancel_p2p_offer(p2p_data: { cancelled_by: $cancelled_by, id: $p2p_id }) {
      amount
      bitcashbank_id
      buyer
      buyer_confirmed_payment
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
      completed
      created_at
      id
      initiator
      matched
      method
      region
      seller
      seller_confirmed_payment
      type
      updated_at
    }
  }
`
export type AutoCancelP2POfferMutationFn = Apollo.MutationFunction<
  AutoCancelP2POfferMutation,
  AutoCancelP2POfferMutationVariables
>

/**
 * __useAutoCancelP2POfferMutation__
 *
 * To run a mutation, you first call `useAutoCancelP2POfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoCancelP2POfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoCancelP2POfferMutation, { data, loading, error }] = useAutoCancelP2POfferMutation({
 *   variables: {
 *      cancelled_by: // value for 'cancelled_by'
 *      p2p_id: // value for 'p2p_id'
 *   },
 * });
 */
export function useAutoCancelP2POfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AutoCancelP2POfferMutation,
    AutoCancelP2POfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AutoCancelP2POfferMutation, AutoCancelP2POfferMutationVariables>(
    AutoCancelP2POfferDocument,
    options,
  )
}
export type AutoCancelP2POfferMutationHookResult = ReturnType<typeof useAutoCancelP2POfferMutation>
export type AutoCancelP2POfferMutationResult = Apollo.MutationResult<AutoCancelP2POfferMutation>
export type AutoCancelP2POfferMutationOptions = Apollo.BaseMutationOptions<
  AutoCancelP2POfferMutation,
  AutoCancelP2POfferMutationVariables
>
export const CancelP2POfferDocument = gql`
  mutation CancelP2POffer($cancelled_by: String!, $p2p_id: String!) {
    cancel_p2p_offer(p2p_data: { cancelled_by: $cancelled_by, id: $p2p_id }) {
      amount
      bitcashbank_id
      buyer
      buyer_confirmed_payment
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
      completed
      created_at
      id
      initiator
      matched
      method
      region
      seller
      seller_confirmed_payment
      type
      updated_at
    }
  }
`
export type CancelP2POfferMutationFn = Apollo.MutationFunction<
  CancelP2POfferMutation,
  CancelP2POfferMutationVariables
>

/**
 * __useCancelP2POfferMutation__
 *
 * To run a mutation, you first call `useCancelP2POfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelP2POfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelP2POfferMutation, { data, loading, error }] = useCancelP2POfferMutation({
 *   variables: {
 *      cancelled_by: // value for 'cancelled_by'
 *      p2p_id: // value for 'p2p_id'
 *   },
 * });
 */
export function useCancelP2POfferMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelP2POfferMutation, CancelP2POfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelP2POfferMutation, CancelP2POfferMutationVariables>(
    CancelP2POfferDocument,
    options,
  )
}
export type CancelP2POfferMutationHookResult = ReturnType<typeof useCancelP2POfferMutation>
export type CancelP2POfferMutationResult = Apollo.MutationResult<CancelP2POfferMutation>
export type CancelP2POfferMutationOptions = Apollo.BaseMutationOptions<
  CancelP2POfferMutation,
  CancelP2POfferMutationVariables
>
export const CancelApproveP2POfferDocument = gql`
  mutation CancelApproveP2POffer($cancel_p2p_approval: cancel_p2p_approval_input!) {
    cancel_p2p_approval(p2p_data: $cancel_p2p_approval) {
      amount
      bitcashbank_id
      buyer
      buyer_confirmed_payment
      cancelled
      cancelled_by
      cancellation_approved_by
      cancellation_transaction
      cancel_request_datetime
      cancel_approval_datetime
      matched_datetime
      sell_put_transaction
      sell_settlement_transaction
      completed
      created_at
      id
      initiator
      matched
      method
      region
      seller
      seller_confirmed_payment
      type
      updated_at
    }
  }
`
export type CancelApproveP2POfferMutationFn = Apollo.MutationFunction<
  CancelApproveP2POfferMutation,
  CancelApproveP2POfferMutationVariables
>

/**
 * __useCancelApproveP2POfferMutation__
 *
 * To run a mutation, you first call `useCancelApproveP2POfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelApproveP2POfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelApproveP2POfferMutation, { data, loading, error }] = useCancelApproveP2POfferMutation({
 *   variables: {
 *      cancel_p2p_approval: // value for 'cancel_p2p_approval'
 *   },
 * });
 */
export function useCancelApproveP2POfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelApproveP2POfferMutation,
    CancelApproveP2POfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelApproveP2POfferMutation, CancelApproveP2POfferMutationVariables>(
    CancelApproveP2POfferDocument,
    options,
  )
}
export type CancelApproveP2POfferMutationHookResult = ReturnType<
  typeof useCancelApproveP2POfferMutation
>
export type CancelApproveP2POfferMutationResult =
  Apollo.MutationResult<CancelApproveP2POfferMutation>
export type CancelApproveP2POfferMutationOptions = Apollo.BaseMutationOptions<
  CancelApproveP2POfferMutation,
  CancelApproveP2POfferMutationVariables
>
export const BitcashSendMessageDocument = gql`
  mutation BitcashSendMessage(
    $message: String!
    $p2p_id: uuid
    $support_id: uuid
    $swap_id: uuid
    $to: String!
    $from: String!
    $timestamp: timestamptz!
  ) {
    insert_messages(
      objects: {
        message: $message
        from: $from
        p2p_id: $p2p_id
        support_id: $support_id
        swap_id: $swap_id
        timestamp: $timestamp
        to: $to
      }
    ) {
      returning {
        from
        id
        message
        p2p_id
        support_id
        swap_id
        timestamp
        to
      }
    }
  }
`
export type BitcashSendMessageMutationFn = Apollo.MutationFunction<
  BitcashSendMessageMutation,
  BitcashSendMessageMutationVariables
>

/**
 * __useBitcashSendMessageMutation__
 *
 * To run a mutation, you first call `useBitcashSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashSendMessageMutation, { data, loading, error }] = useBitcashSendMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      p2p_id: // value for 'p2p_id'
 *      support_id: // value for 'support_id'
 *      swap_id: // value for 'swap_id'
 *      to: // value for 'to'
 *      from: // value for 'from'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useBitcashSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashSendMessageMutation,
    BitcashSendMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BitcashSendMessageMutation, BitcashSendMessageMutationVariables>(
    BitcashSendMessageDocument,
    options,
  )
}
export type BitcashSendMessageMutationHookResult = ReturnType<typeof useBitcashSendMessageMutation>
export type BitcashSendMessageMutationResult = Apollo.MutationResult<BitcashSendMessageMutation>
export type BitcashSendMessageMutationOptions = Apollo.BaseMutationOptions<
  BitcashSendMessageMutation,
  BitcashSendMessageMutationVariables
>
export const BitcashSwapAssetsDocument = gql`
  query BitcashSwapAssets(
    $where: swap_assets_bool_exp = {}
    $order_by: [swap_assets_order_by!] = {}
  ) {
    swap_assets(where: $where, order_by: $order_by) {
      active_swaps
      asset
      asset_name
      chain
      wallet_address
    }
  }
`

/**
 * __useBitcashSwapAssetsQuery__
 *
 * To run a query within a React component, call `useBitcashSwapAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitcashSwapAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashSwapAssetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useBitcashSwapAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>(
    BitcashSwapAssetsDocument,
    options,
  )
}
export function useBitcashSwapAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BitcashSwapAssetsQuery,
    BitcashSwapAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>(
    BitcashSwapAssetsDocument,
    options,
  )
}
export type BitcashSwapAssetsQueryHookResult = ReturnType<typeof useBitcashSwapAssetsQuery>
export type BitcashSwapAssetsLazyQueryHookResult = ReturnType<typeof useBitcashSwapAssetsLazyQuery>
export type BitcashSwapAssetsQueryResult = Apollo.QueryResult<
  BitcashSwapAssetsQuery,
  BitcashSwapAssetsQueryVariables
>
export const DevicesDocument = gql`
  subscription Devices($where: devices_bool_exp = {}, $order_by: [devices_order_by!] = {}) {
    devices(where: $where, order_by: $order_by) {
      account
      cred_id
      device_name
      public_key
    }
  }
`

/**
 * __useDevicesSubscription__
 *
 * To run a query within a React component, call `useDevicesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDevicesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useDevicesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<DevicesSubscription, DevicesSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<DevicesSubscription, DevicesSubscriptionVariables>(
    DevicesDocument,
    options,
  )
}
export type DevicesSubscriptionHookResult = ReturnType<typeof useDevicesSubscription>
export type DevicesSubscriptionResult = Apollo.SubscriptionResult<DevicesSubscription>
export const CreateSwapDocument = gql`
  mutation CreateSwap($object: swap_orders_insert_input!) {
    insert_swap_orders_one(object: $object) {
      id
    }
  }
`
export type CreateSwapMutationFn = Apollo.MutationFunction<
  CreateSwapMutation,
  CreateSwapMutationVariables
>

/**
 * __useCreateSwapMutation__
 *
 * To run a mutation, you first call `useCreateSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSwapMutation, { data, loading, error }] = useCreateSwapMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateSwapMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSwapMutation, CreateSwapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSwapMutation, CreateSwapMutationVariables>(
    CreateSwapDocument,
    options,
  )
}
export type CreateSwapMutationHookResult = ReturnType<typeof useCreateSwapMutation>
export type CreateSwapMutationResult = Apollo.MutationResult<CreateSwapMutation>
export type CreateSwapMutationOptions = Apollo.BaseMutationOptions<
  CreateSwapMutation,
  CreateSwapMutationVariables
>
export const UpdateSwapOrderStatusDocument = gql`
  mutation UpdateSwapOrderStatus($id: uuid!, $order_status: String) {
    update_swap_orders_by_pk(pk_columns: { id: $id }, _set: { order_status: $order_status }) {
      asset
      asset_amount
      bitcash_account
      bitcash_amount
      bitcash_currency
      bitcash_trx
      created_at
      id
      order_status
      order_type
      price
      updated_at
      wallet_address
    }
  }
`
export type UpdateSwapOrderStatusMutationFn = Apollo.MutationFunction<
  UpdateSwapOrderStatusMutation,
  UpdateSwapOrderStatusMutationVariables
>

/**
 * __useUpdateSwapOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSwapOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSwapOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSwapOrderStatusMutation, { data, loading, error }] = useUpdateSwapOrderStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order_status: // value for 'order_status'
 *   },
 * });
 */
export function useUpdateSwapOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSwapOrderStatusMutation,
    UpdateSwapOrderStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSwapOrderStatusMutation, UpdateSwapOrderStatusMutationVariables>(
    UpdateSwapOrderStatusDocument,
    options,
  )
}
export type UpdateSwapOrderStatusMutationHookResult = ReturnType<
  typeof useUpdateSwapOrderStatusMutation
>
export type UpdateSwapOrderStatusMutationResult =
  Apollo.MutationResult<UpdateSwapOrderStatusMutation>
export type UpdateSwapOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateSwapOrderStatusMutation,
  UpdateSwapOrderStatusMutationVariables
>
export const BitcashRequestNewAccountDocument = gql`
  mutation BitcashRequestNewAccount($account_data: request_new_account_input!) {
    request_new_account(account_data: $account_data) {
      id
    }
  }
`
export type BitcashRequestNewAccountMutationFn = Apollo.MutationFunction<
  BitcashRequestNewAccountMutation,
  BitcashRequestNewAccountMutationVariables
>

/**
 * __useBitcashRequestNewAccountMutation__
 *
 * To run a mutation, you first call `useBitcashRequestNewAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashRequestNewAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashRequestNewAccountMutation, { data, loading, error }] = useBitcashRequestNewAccountMutation({
 *   variables: {
 *      account_data: // value for 'account_data'
 *   },
 * });
 */
export function useBitcashRequestNewAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashRequestNewAccountMutation,
    BitcashRequestNewAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BitcashRequestNewAccountMutation,
    BitcashRequestNewAccountMutationVariables
  >(BitcashRequestNewAccountDocument, options)
}
export type BitcashRequestNewAccountMutationHookResult = ReturnType<
  typeof useBitcashRequestNewAccountMutation
>
export type BitcashRequestNewAccountMutationResult =
  Apollo.MutationResult<BitcashRequestNewAccountMutation>
export type BitcashRequestNewAccountMutationOptions = Apollo.BaseMutationOptions<
  BitcashRequestNewAccountMutation,
  BitcashRequestNewAccountMutationVariables
>
export const BitcashToggleTrustNetworkDocument = gql`
  mutation BitcashToggleTrustNetwork($input: toggle_trust_network_input) {
    toggle_trust_network(input: $input) {
      success
    }
  }
`
export type BitcashToggleTrustNetworkMutationFn = Apollo.MutationFunction<
  BitcashToggleTrustNetworkMutation,
  BitcashToggleTrustNetworkMutationVariables
>

/**
 * __useBitcashToggleTrustNetworkMutation__
 *
 * To run a mutation, you first call `useBitcashToggleTrustNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashToggleTrustNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashToggleTrustNetworkMutation, { data, loading, error }] = useBitcashToggleTrustNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBitcashToggleTrustNetworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashToggleTrustNetworkMutation,
    BitcashToggleTrustNetworkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BitcashToggleTrustNetworkMutation,
    BitcashToggleTrustNetworkMutationVariables
  >(BitcashToggleTrustNetworkDocument, options)
}
export type BitcashToggleTrustNetworkMutationHookResult = ReturnType<
  typeof useBitcashToggleTrustNetworkMutation
>
export type BitcashToggleTrustNetworkMutationResult =
  Apollo.MutationResult<BitcashToggleTrustNetworkMutation>
export type BitcashToggleTrustNetworkMutationOptions = Apollo.BaseMutationOptions<
  BitcashToggleTrustNetworkMutation,
  BitcashToggleTrustNetworkMutationVariables
>
export const BitcashUpdateOnboardedUserDocument = gql`
  mutation BitcashUpdateOnboardedUser($account: String!) {
    update_reg_accounts(where: { account: { _eq: $account } }, _set: { onboarded: true }) {
      returning {
        onboarded
      }
    }
  }
`
export type BitcashUpdateOnboardedUserMutationFn = Apollo.MutationFunction<
  BitcashUpdateOnboardedUserMutation,
  BitcashUpdateOnboardedUserMutationVariables
>

/**
 * __useBitcashUpdateOnboardedUserMutation__
 *
 * To run a mutation, you first call `useBitcashUpdateOnboardedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashUpdateOnboardedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashUpdateOnboardedUserMutation, { data, loading, error }] = useBitcashUpdateOnboardedUserMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useBitcashUpdateOnboardedUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BitcashUpdateOnboardedUserMutation,
    BitcashUpdateOnboardedUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BitcashUpdateOnboardedUserMutation,
    BitcashUpdateOnboardedUserMutationVariables
  >(BitcashUpdateOnboardedUserDocument, options)
}
export type BitcashUpdateOnboardedUserMutationHookResult = ReturnType<
  typeof useBitcashUpdateOnboardedUserMutation
>
export type BitcashUpdateOnboardedUserMutationResult =
  Apollo.MutationResult<BitcashUpdateOnboardedUserMutation>
export type BitcashUpdateOnboardedUserMutationOptions = Apollo.BaseMutationOptions<
  BitcashUpdateOnboardedUserMutation,
  BitcashUpdateOnboardedUserMutationVariables
>
export const BitcashPresellOffersDocument = gql`
  query BitcashPresellOffers($account: String!) {
    p2p_pre_sell_offers(
      where: { seller: { _eq: $account }, processed: { _eq: false } }
      order_by: { updated_at: desc_nulls_last }
    ) {
      id
      amount
      bitcashbank_id
      created_at
      method
      region
      sell_put_transaction
      seller
      seller_method_details
      updated_at
    }
  }
`

/**
 * __useBitcashPresellOffersQuery__
 *
 * To run a query within a React component, call `useBitcashPresellOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitcashPresellOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashPresellOffersQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useBitcashPresellOffersQuery(
  baseOptions: Apollo.QueryHookOptions<
    BitcashPresellOffersQuery,
    BitcashPresellOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BitcashPresellOffersQuery, BitcashPresellOffersQueryVariables>(
    BitcashPresellOffersDocument,
    options,
  )
}
export function useBitcashPresellOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BitcashPresellOffersQuery,
    BitcashPresellOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BitcashPresellOffersQuery, BitcashPresellOffersQueryVariables>(
    BitcashPresellOffersDocument,
    options,
  )
}
export type BitcashPresellOffersQueryHookResult = ReturnType<typeof useBitcashPresellOffersQuery>
export type BitcashPresellOffersLazyQueryHookResult = ReturnType<
  typeof useBitcashPresellOffersLazyQuery
>
export type BitcashPresellOffersQueryResult = Apollo.QueryResult<
  BitcashPresellOffersQuery,
  BitcashPresellOffersQueryVariables
>
export const AccountDeviceListDocument = gql`
  query AccountDeviceList($account: String!) {
    devices(where: { account: { _eq: $account } }) {
      account
    }
  }
`

/**
 * __useAccountDeviceListQuery__
 *
 * To run a query within a React component, call `useAccountDeviceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDeviceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDeviceListQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useAccountDeviceListQuery(
  baseOptions: Apollo.QueryHookOptions<AccountDeviceListQuery, AccountDeviceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AccountDeviceListQuery, AccountDeviceListQueryVariables>(
    AccountDeviceListDocument,
    options,
  )
}
export function useAccountDeviceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountDeviceListQuery,
    AccountDeviceListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AccountDeviceListQuery, AccountDeviceListQueryVariables>(
    AccountDeviceListDocument,
    options,
  )
}
export type AccountDeviceListQueryHookResult = ReturnType<typeof useAccountDeviceListQuery>
export type AccountDeviceListLazyQueryHookResult = ReturnType<typeof useAccountDeviceListLazyQuery>
export type AccountDeviceListQueryResult = Apollo.QueryResult<
  AccountDeviceListQuery,
  AccountDeviceListQueryVariables
>
