import React, { useMemo } from 'react'
// TODO: Replace them. Package will be rm
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { WalletBalanceBox } from 'app-view/components/BalanceBox'
import { CardPosition } from 'app-view/components/CardPosition'
import { useTranslation } from 'react-i18next'
import { BigBlackButton, BigButton2, ButtonsGrid } from 'app-view/components/buttons'
import {
  MainContentContainer,
  ScrollableFlexBoxArea,
  HeaderContainer,
  OnramperGrid,
} from 'app-view/components/Styled'
import { config } from 'app-config'
import { Wizard } from '../../app-view/components/Wizard/index'
import { useWallet, WalletProvider } from 'pages/WalletView/hooks/useWallet'
import { WizardProvider, useWizard } from 'app-view/components/Wizard'
import { OnramperIcon } from 'app-view/components/icons'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { useStore } from 'app-engine/store'

const WalletViewComponent = () => {
  const { t } = useTranslation(['wallet'])
  const { account, wallet_balances, wallet_total } = useStore()
  const [{ error }, { resetError, startNewAction }] = useWallet()
  const [, globalModalActions] = useGlobalModal()
  const [, { start }] = useWizard()

  const newActionHandler = (type: 'deposit' | 'send') => {
    const new_steps = startNewAction(type)

    start(new_steps)
  }

  const openOnramper = () => {
    globalModalActions.open({
      content: () => (
        <iframe
          src={`https://buy.onramper.com?apiKey=${config.onramperKey}&mode=buy&defaultCrypto=usdt_eosio&onlyCryptos=btc,eos_eosio,usdt_eosio,usdt_ethereum,usdt_ton,usdt_tron,usdt_tezos,usdt_near,usdt_polygon,usdt_solana,usdt_algorand,usdt_arbitrum,usdt_bsc,usdt_bbc,usdt_avaxc,usdt_bitcoin,usdt_optimism&onlyCryptoNetworks=eosio,ethereum,bitcoin&wallets=eos_eosio:${account}`}
          title="Bitcash App Onramper"
          style={{
            border: 0,
            height: 650,
            width: '100%',
          }}
          allow="accelerometer; autoplay; camera; gyroscope; payment"
        />
      ),
      showClose: true,
      autoHide: false,
    })
  }

  return (
    <ScrollableFlexBoxArea>
      <WalletBalanceBox amount={wallet_total} />

      <HeaderContainer>
        <ButtonsGrid>
          <BigBlackButton onClick={() => newActionHandler('deposit')}>
            {t('deposit')}
          </BigBlackButton>
          <BigBlackButton onClick={() => newActionHandler('send')}>{t('send')}</BigBlackButton>
        </ButtonsGrid>
        <OnramperGrid>
          <BigButton2 variant="green" onClick={openOnramper}>
            <OnramperIcon />
          </BigButton2>
        </OnramperGrid>
      </HeaderContainer>

      <MainContentContainer>
        <HeaderContainer pt={24}>
          {wallet_balances.map((position) => (
            <CardPosition position={position} tab="crypto" key={position.symbol_code} />
          ))}
        </HeaderContainer>
      </MainContentContainer>

      {config.environment !== 'production' ? (
        <Button id="faucet-button" as={Link} to="/faucet" fluid primary>
          BITCASH FAUCET
        </Button>
      ) : null}
      <Wizard error={error} resetError={resetError} />
    </ScrollableFlexBoxArea>
  )
}

export const WalletView = () => (
  // @ts-expect-error
  <WizardProvider>
    {/* @ts-ignore */}
    <WalletProvider>
      <WalletViewComponent />
    </WalletProvider>
  </WizardProvider>
)
