import { createContextHook } from '@blockmatic/hooks-utils'
import { EncodeResult, encryptMessage } from '@newchat/new.chat-sdk'
import { TransactResult } from 'anchor-link'
import { config } from 'app-config'
import {
  useInsertAccountInformationMutation,
  useUpdateAccountInfoMutation,
} from 'app-engine/graphql/generated/bitcash'
import { useStore } from 'app-engine/store'
import { SendTransaction2Response } from 'app-engine/store/eos-slice'
import {
  KeyRecoveryPartner,
  KeyRecoveryProfile,
  KeyRecoveryTypeId,
} from 'app-engine/store/key-recovery-slice'
import { randomBytes } from 'crypto'
import { useSetState } from 'react-use'

export interface UseKeyRecoveryActions {
  storeTypeData: (value: KeyRecoveryTypeId) => void
  storeProfileData: (value: KeyRecoveryProfile) => void
  storePartners: (value: KeyRecoveryPartner) => void
  setState: (value: Partial<UseKeyRecoveryState>) => void
  pushTransactionKeyRecovery: () => Promise<{
    success: boolean
    transactionResponse: TransactResult | SendTransaction2Response | Error
  }>
  submitAccount: () => Promise<any>
}

export type UseKeyRecoveryState = {
  loading: boolean
  error: string
  salt: string
  recoveryKey: EncodeResult
}
const initialState: UseKeyRecoveryState = {
  loading: false,
  error: '',
  salt: '',
  recoveryKey: {
    iv: '',
    ephemPubKey: '',
    cipherText: '',
    mac: '',
  },
}

export const useKeyRecoveryFn = (): [UseKeyRecoveryState, UseKeyRecoveryActions] => {
  const [updateAccountInfoMutation] = useUpdateAccountInfoMutation()
  const [insertAccountInformationMutation] = useInsertAccountInformationMutation()

  const {
    pub_key,
    account,
    registerAccount,
    keyRecoveryData,
    setKeyRecoveryProfile,
    setKeyRecoveryType,
    setKeyRecoveryPartners,
    pushTransaction,
  } = useStore()

  const [state, setState] = useSetState(initialState)

  const createEncryptMessage = () => {
    const { type, country, typeValue } = keyRecoveryData.typeId!
    const { phoneNumber, emailAddress, surName, firstName, middleName } = keyRecoveryData.profile!
    const salt = randomBytes(32).toString('base64')
    const fullName = `${firstName}_${middleName}_${surName}`
    const message = encryptMessage(
      `${fullName}:${phoneNumber}:${emailAddress}:${type}_${country}_${typeValue}:${salt}`,
      pub_key!,
    )

    setState({ salt })

    return message
  }

  const pushTransactionKeyRecovery = async () => {
    try {
      const recoveryKey = createEncryptMessage()
      const transactionResponse = await pushTransaction({
        actions: [
          {
            account: config.contracts.bitcashAccounts,
            name: 'setrcvrid',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data: {
              account,
              recovery_id: recoveryKey.mac,
            },
          },
        ],
      })

      setState({ recoveryKey })

      return {
        success: true,
        transactionResponse,
      }
    } catch (error) {
      console.error('useKeyRecovery.ts:pushTransactionKeyRecovery ~ error', { error })

      return {
        success: false,
        transactionResponse: error as Error,
      }
    }
  }

  const submitAccount = async () => {
    const { phoneNumber, emailAddress, surName, firstName, middleName } = keyRecoveryData.profile!
    const { type, country, typeValue } = keyRecoveryData.typeId!
    const { profile } = registerAccount!
    const fullName = `${firstName}_${middleName}_${surName}`

    if (!registerAccount?.profile?.id) {
      return insertAccountInformationMutation({
        variables: {
          input: {
            account,
            phone: phoneNumber,
            email: emailAddress,
            full_name: fullName,
            recovery_partners: {
              salt: state.salt,
              recoveryKey: state.recoveryKey,
              accounts: keyRecoveryData.partnersDao?.partners,
              accountId: { idType: type, country, idNumber: typeValue },
            },
          },
        },
      })
    }
    return updateAccountInfoMutation({
      variables: {
        pk_columns: { account, id: profile?.id },
        _set: {
          phone: phoneNumber,
          email: emailAddress,
          full_name: fullName,
          recovery_partners: {
            salt: state.salt,
            recoveryKey: state.recoveryKey,
            accounts: keyRecoveryData.partnersDao?.partners,
            accountId: { idType: type, country, idNumber: typeValue },
          },
        },
      },
    })
  }

  const storeProfileData = (data: KeyRecoveryProfile) => {
    setKeyRecoveryProfile(data)
  }

  const storeTypeData = (data: KeyRecoveryTypeId) => {
    setKeyRecoveryType(data)
  }

  const storePartners = (data: KeyRecoveryPartner) => {
    setKeyRecoveryPartners(data)
  }

  return [
    state,
    {
      storePartners,
      storeTypeData,
      storeProfileData,
      setState,
      pushTransactionKeyRecovery,
      submitAccount,
    },
  ]
}

export const [useKeyRecovery, KeyRecoveryProvider] = createContextHook(
  useKeyRecoveryFn,
  'You must wrap your application with <KeyRecoveryProvider /> in order to useKeyRecovery().',
)
