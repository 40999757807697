import { StoreSlice } from 'app-engine/store'

export type KeyRecoveryProfile = {
  surName: string
  firstName: string
  middleName: string
  emailAddress: string
  phoneNumber: string
}

export type KeyRecoveryTypeId = {
  type: string
  typeValue: string
  country: string
}

export type KeyRecoveryPartner = {
  partners: string[]
  dao: boolean
}

export type KeyRecoverySliceState = {
  keyRecoveryData: {
    profile?: KeyRecoveryProfile
    typeId?: KeyRecoveryTypeId
    partnersDao?: KeyRecoveryPartner
  }
}

type KeyRecoverySliceActions = {
  setKeyRecoveryProfile: (value: KeyRecoveryProfile) => void
  setKeyRecoveryType: (value: KeyRecoveryTypeId) => void
  setKeyRecoveryPartners: (value: KeyRecoveryPartner) => void
  reset: () => void
}

export type KeyRecoverySlice = KeyRecoverySliceState & KeyRecoverySliceActions

const defaultKeyRecoveryState = {
  keyRecoveryData: {},
}

export const createKeyRecoverySlice: StoreSlice<KeyRecoverySlice> = (set, get) => ({
  ...defaultKeyRecoveryState,
  reset: () => {
    set(defaultKeyRecoveryState)
  },
  setKeyRecoveryProfile: (value: KeyRecoveryProfile) => {
    set({
      keyRecoveryData: {
        ...get().keyRecoveryData,
        profile: { ...value },
      },
    })
  },
  setKeyRecoveryType: (value: KeyRecoveryTypeId) => {
    set({
      keyRecoveryData: {
        ...get().keyRecoveryData,
        typeId: { ...value },
      },
    })
  },
  setKeyRecoveryPartners: (value: KeyRecoveryPartner) => {
    set({
      keyRecoveryData: {
        ...get().keyRecoveryData,
        partnersDao: { ...value },
      },
    })
  },
})
