import { useStore } from 'app-engine/store'
import { RegisterAccount } from 'app-engine/store/p2p-slice'
import { Wizard, WizardProvider } from 'app-view/components/Wizard'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized'
import { AdminStatus } from '..'
import { AdminActionsProvider, useAdminActions } from '../hooks/useAdminActions'
import { AccountItem } from './AccountItem'
import { AdminSkeleton } from './AdminSkeleton'
import { Input, InputSection } from 'app-view/components/InputField'
import Spinner from 'app-view/components/Spinner'

export interface AdminAccountsProps {
  status: AdminStatus
}
export interface AccountStateProps {
  data: any
  loading: boolean
  error: null | string
}

function compareAccounts(accountA, accountB) {
  const isMissingInfoA = isMissingInfo(accountA)
  const isMissingInfoB = isMissingInfo(accountB)

  if (!accountA.is_verified && !accountB.is_verified) {
    if ((isMissingInfoA && isMissingInfoB) || (!isMissingInfoA && !isMissingInfoB)) return 0
    else if (!isMissingInfoA && isMissingInfoB) return -1
    else if (isMissingInfoA && !isMissingInfoB) return 1
  } else if (accountA.is_verified && !accountB.is_verified) {
    return 1
  } else if (!accountA.is_verified && accountB.is_verified) {
    return -1
  }
  return 0
}

function isMissingInfo(account) {
  const profile = account.profile
  return (
    !profile?.account ||
    !profile?.country_id ||
    !profile?.email ||
    !profile?.full_name ||
    !profile?.phone ||
    !profile?.photo_id ||
    !profile?.selfie
  )
}
let timeout

export function AdminAccounts({ status }: AdminAccountsProps) {
  const [accounts, setAccounts] = useState<RegisterAccount[]>([])
  const [filteredAccounts, setFilteredAccounts] = useState<RegisterAccount[]>([])
  const [keyword, setKeyword] = useState<string>('')
  const { t } = useTranslation(['account', 'global'])
  const {
    registerAccounts,
    isRegisterAccountsLoading: loading,
    subscribeRegisterAccounts,
    unsubscribeSubscribeRegisterAccounts,
  } = useStore()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offset, setOffset] = useState(0)
  const limit = 35
  const isRowLoaded = ({ index }) => !!registerAccounts[index]

  useEffect(() => {
    const loadAndSubscribeAccounts = async () => {
      await subscribeRegisterAccounts(offset, limit)
    }
    loadAndSubscribeAccounts()

    return () => {
      unsubscribeSubscribeRegisterAccounts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, offset, limit])

  useEffect(() => {
    const dataAccounts = Object.values(registerAccounts)
    const isPendingAccounts = status === 'pending_accounts'
    const sortedAndFilteredAccounts = dataAccounts
      .sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      })
      .sort((a, b) => {
        return compareAccounts(a, b)
      })
      .filter((account) => {
        return isPendingAccounts ? !account.created : account.created
      })

    setAccounts(sortedAndFilteredAccounts)
  }, [status, registerAccounts])

  const loadMoreRows = ({ startIndex, stopIndex }) => {
    const limit = stopIndex - startIndex + 1
    return new Promise<void>((resolve) => {
      setOffset((currentOffset) => currentOffset + limit)
      resolve()
    })
  }

  useEffect(() => {
    setKeyword('')
    setOffset(0)
  }, [status])

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setKeyword(value)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (value) {
        setFilteredAccounts(
          accounts.filter(
            (account) => account.account.toLowerCase().indexOf(value.toLowerCase()) > -1,
          ),
        )
      } else setFilteredAccounts(accounts)
    }, 1150)
  }

  useEffect(() => {
    if (keyword) {
      setFilteredAccounts(
        accounts.filter(
          (account) => account.account.toLowerCase().indexOf(keyword.toLowerCase()) > -1,
        ),
      )
    } else setFilteredAccounts(accounts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts])

  return (
    <>
      {/* @ts-expect-error */}
      <WizardProvider>
        {/* @ts-expect-error */}
        <AdminActionsProvider>
          <br />
          <b>
            Total {status}: {filteredAccounts.length}
          </b>
          <InputSection inputSize="sm" border="grey" style={{ margin: '10px 0' }}>
            <Input
              onChange={handleChangeSearch}
              placeholder="Search Users"
              fullradius={1}
              fontWeight="500"
              type="text"
              value={keyword}
            />
            {loading && <Spinner boxSize={20} />}
          </InputSection>
          {filteredAccounts.length === 0 && !loading && <div>{t('global:no_data')}</div>}
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={filteredAccounts.length}
                minimumBatchSize={35}
                threshold={15}
              >
                {({ onRowsRendered, registerChild }) => (
                  <List
                    ref={registerChild}
                    width={width}
                    height={height}
                    rowCount={filteredAccounts.length}
                    rowHeight={130}
                    rowRenderer={(props) => (
                      <RowRenderer
                        filteredAccounts={filteredAccounts}
                        status={status}
                        loading={loading}
                        {...props}
                      />
                    )}
                    onRowsRendered={onRowsRendered}
                  />
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
          <AdminAccountsWizard />
        </AdminActionsProvider>
      </WizardProvider>
    </>
  )
}

function AdminAccountsWizard() {
  const [{ error, account }, { setError }] = useAdminActions()

  return (
    <Wizard
      title={`${account?.account ?? ''}, account info.`}
      iconType="NOICON-BGGREEN"
      error={error}
      resetError={() => {
        setError('')
      }}
    />
  )
}

function RowRenderer({
  key,
  index,
  style,
  filteredAccounts,
  loading,
  status,
}: {
  key: string
  index: number
  style: any
  filteredAccounts: RegisterAccount[]
  loading: boolean
  status: AdminStatus
}) {
  const account = filteredAccounts[index]
  if (!account) {
    return loading ? (
      <div key={key} style={style}>
        <AdminSkeleton />
      </div>
    ) : null
  }
  return (
    <div key={`account-${account.id}`} style={style}>
      <AccountItem accountInfo={account} status={status} />
    </div>
  )
}
