export default {
  scalars: [
    0, 2, 3, 5, 8, 20, 41, 56, 57, 58, 62, 74, 85, 97, 110, 122, 126, 136, 154, 164, 178, 193, 202,
    212, 216, 218, 227, 238, 248, 252, 265, 289, 299, 303, 309, 317, 321, 333, 343, 344, 345, 349,
    351, 353, 365, 376, 377, 378, 382, 394, 404, 405, 406, 410, 418, 430, 434, 440, 449, 453, 460,
    470, 478, 493, 501, 505, 516, 526, 530, 536, 544, 548, 570, 582, 583, 584, 585, 586, 587, 588,
    589, 590, 602, 618, 628, 632, 642, 653, 657, 663, 664, 674, 678, 685, 697, 701, 703, 705, 719,
    734, 744, 748, 754, 755, 756, 762, 770, 774, 778, 791, 804, 819, 836, 844, 848,
  ],
  types: {
    Boolean: {},
    Boolean_comparison_exp: {
      _eq: [0],
      _gt: [0],
      _gte: [0],
      _in: [0],
      _is_null: [0],
      _lt: [0],
      _lte: [0],
      _neq: [0],
      _nin: [0],
      __typename: [8],
    },
    Float: {},
    Int: {},
    Int_comparison_exp: {
      _eq: [3],
      _gt: [3],
      _gte: [3],
      _in: [3],
      _is_null: [0],
      _lt: [3],
      _lte: [3],
      _neq: [3],
      _nin: [3],
      __typename: [8],
    },
    JSON: {},
    PushSubscriptionInput: {
      account: [8],
      endpoint: [8],
      expirationTime: [8],
      keys: [7],
      __typename: [8],
    },
    PushSubscriptionKeysInput: {
      auth: [8],
      p256dh: [8],
      __typename: [8],
    },
    String: {},
    String_array_comparison_exp: {
      _contained_in: [8],
      _contains: [8],
      _eq: [8],
      _gt: [8],
      _gte: [8],
      _in: [8],
      _is_null: [0],
      _lt: [8],
      _lte: [8],
      _neq: [8],
      _nin: [8],
      __typename: [8],
    },
    String_comparison_exp: {
      _eq: [8],
      _gt: [8],
      _gte: [8],
      _ilike: [8],
      _in: [8],
      _iregex: [8],
      _is_null: [0],
      _like: [8],
      _lt: [8],
      _lte: [8],
      _neq: [8],
      _nilike: [8],
      _nin: [8],
      _niregex: [8],
      _nlike: [8],
      _nregex: [8],
      _nsimilar: [8],
      _regex: [8],
      _similar: [8],
      __typename: [8],
    },
    UserEmailData: {
      account: [8],
      email: [8],
      __typename: [8],
    },
    account_statistics: {
      orders_cancelled: [164],
      orders_completed: [164],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_aggregate: {
      aggregate: [14],
      nodes: [12],
      __typename: [8],
    },
    account_statistics_aggregate_fields: {
      avg: [15],
      count: [
        3,
        {
          columns: [20, '[account_statistics_select_column!]'],
          distinct: [0],
        },
      ],
      max: [17],
      min: [18],
      stddev: [21],
      stddev_pop: [22],
      stddev_samp: [23],
      sum: [26],
      var_pop: [27],
      var_samp: [28],
      variance: [29],
      __typename: [8],
    },
    account_statistics_avg_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_bool_exp: {
      _and: [16],
      _not: [16],
      _or: [16],
      orders_cancelled: [165],
      orders_completed: [165],
      user_id: [10],
      __typename: [8],
    },
    account_statistics_max_fields: {
      orders_cancelled: [164],
      orders_completed: [164],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_min_fields: {
      orders_cancelled: [164],
      orders_completed: [164],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_order_by: {
      orders_cancelled: [353],
      orders_completed: [353],
      user_id: [353],
      __typename: [8],
    },
    account_statistics_select_column: {},
    account_statistics_stddev_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_stddev_pop_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_stddev_samp_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_stream_cursor_input: {
      initial_value: [25],
      ordering: [193],
      __typename: [8],
    },
    account_statistics_stream_cursor_value_input: {
      orders_cancelled: [164],
      orders_completed: [164],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_sum_fields: {
      orders_cancelled: [164],
      orders_completed: [164],
      __typename: [8],
    },
    account_statistics_var_pop_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_var_samp_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_variance_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    accounts_information: {
      account: [8],
      country_id: [
        227,
        {
          path: [8],
        },
      ],
      date_of_birth: [705],
      email: [8],
      full_name: [8],
      id: [804],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [
        227,
        {
          path: [8],
        },
      ],
      reg_account: [436],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_aggregate: {
      aggregate: [36],
      nodes: [30],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp: {
      bool_and: [33],
      bool_or: [34],
      count: [35],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp_bool_and: {
      arguments: [57],
      distinct: [0],
      filter: [40],
      predicate: [1],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp_bool_or: {
      arguments: [58],
      distinct: [0],
      filter: [40],
      predicate: [1],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp_count: {
      arguments: [56],
      distinct: [0],
      filter: [40],
      predicate: [4],
      __typename: [8],
    },
    accounts_information_aggregate_fields: {
      count: [
        3,
        {
          columns: [56, '[accounts_information_select_column!]'],
          distinct: [0],
        },
      ],
      max: [46],
      min: [48],
      __typename: [8],
    },
    accounts_information_aggregate_order_by: {
      count: [353],
      max: [47],
      min: [49],
      __typename: [8],
    },
    accounts_information_append_input: {
      country_id: [227],
      recovery_partners: [227],
      __typename: [8],
    },
    accounts_information_arr_rel_insert_input: {
      data: [45],
      on_conflict: [52],
      __typename: [8],
    },
    accounts_information_bool_exp: {
      _and: [40],
      _not: [40],
      _or: [40],
      account: [10],
      country_id: [229],
      date_of_birth: [706],
      email: [10],
      full_name: [10],
      id: [805],
      newsletter_subscription: [1],
      phone: [10],
      photo_id: [10],
      recovery_partners: [229],
      reg_account: [439],
      registration_ip: [10],
      selfie: [10],
      __typename: [8],
    },
    accounts_information_constraint: {},
    accounts_information_delete_at_path_input: {
      country_id: [8],
      recovery_partners: [8],
      __typename: [8],
    },
    accounts_information_delete_elem_input: {
      country_id: [3],
      recovery_partners: [3],
      __typename: [8],
    },
    accounts_information_delete_key_input: {
      country_id: [8],
      recovery_partners: [8],
      __typename: [8],
    },
    accounts_information_insert_input: {
      account: [8],
      country_id: [227],
      date_of_birth: [705],
      email: [8],
      full_name: [8],
      id: [804],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [227],
      reg_account: [445],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_max_fields: {
      account: [8],
      date_of_birth: [705],
      email: [8],
      full_name: [8],
      id: [804],
      phone: [8],
      photo_id: [8],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_max_order_by: {
      account: [353],
      date_of_birth: [353],
      email: [353],
      full_name: [353],
      id: [353],
      phone: [353],
      photo_id: [353],
      registration_ip: [353],
      selfie: [353],
      __typename: [8],
    },
    accounts_information_min_fields: {
      account: [8],
      date_of_birth: [705],
      email: [8],
      full_name: [8],
      id: [804],
      phone: [8],
      photo_id: [8],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_min_order_by: {
      account: [353],
      date_of_birth: [353],
      email: [353],
      full_name: [353],
      id: [353],
      phone: [353],
      photo_id: [353],
      registration_ip: [353],
      selfie: [353],
      __typename: [8],
    },
    accounts_information_mutation_response: {
      affected_rows: [3],
      returning: [30],
      __typename: [8],
    },
    accounts_information_obj_rel_insert_input: {
      data: [45],
      on_conflict: [52],
      __typename: [8],
    },
    accounts_information_on_conflict: {
      constraint: [41],
      update_columns: [62],
      where: [40],
      __typename: [8],
    },
    accounts_information_order_by: {
      account: [353],
      country_id: [353],
      date_of_birth: [353],
      email: [353],
      full_name: [353],
      id: [353],
      newsletter_subscription: [353],
      phone: [353],
      photo_id: [353],
      recovery_partners: [353],
      reg_account: [447],
      registration_ip: [353],
      selfie: [353],
      __typename: [8],
    },
    accounts_information_pk_columns_input: {
      account: [8],
      id: [804],
      __typename: [8],
    },
    accounts_information_prepend_input: {
      country_id: [227],
      recovery_partners: [227],
      __typename: [8],
    },
    accounts_information_select_column: {},
    accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_and_arguments_columns:
      {},
    accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_or_arguments_columns:
      {},
    accounts_information_set_input: {
      account: [8],
      country_id: [227],
      date_of_birth: [705],
      email: [8],
      full_name: [8],
      id: [804],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [227],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_stream_cursor_input: {
      initial_value: [61],
      ordering: [193],
      __typename: [8],
    },
    accounts_information_stream_cursor_value_input: {
      account: [8],
      country_id: [227],
      date_of_birth: [705],
      email: [8],
      full_name: [8],
      id: [804],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [227],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_update_column: {},
    accounts_information_updates: {
      _append: [38],
      _delete_at_path: [42],
      _delete_elem: [43],
      _delete_key: [44],
      _prepend: [55],
      _set: [59],
      where: [40],
      __typename: [8],
    },
    accounts_referral: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      reg_account: [436],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_aggregate: {
      aggregate: [68],
      nodes: [64],
      __typename: [8],
    },
    accounts_referral_aggregate_bool_exp: {
      count: [67],
      __typename: [8],
    },
    accounts_referral_aggregate_bool_exp_count: {
      arguments: [85],
      distinct: [0],
      filter: [73],
      predicate: [4],
      __typename: [8],
    },
    accounts_referral_aggregate_fields: {
      avg: [71],
      count: [
        3,
        {
          columns: [85, '[accounts_referral_select_column!]'],
          distinct: [0],
        },
      ],
      max: [77],
      min: [79],
      stddev: [87],
      stddev_pop: [89],
      stddev_samp: [91],
      sum: [95],
      var_pop: [99],
      var_samp: [101],
      variance: [103],
      __typename: [8],
    },
    accounts_referral_aggregate_order_by: {
      avg: [72],
      count: [353],
      max: [78],
      min: [80],
      stddev: [88],
      stddev_pop: [90],
      stddev_samp: [92],
      sum: [96],
      var_pop: [100],
      var_samp: [102],
      variance: [104],
      __typename: [8],
    },
    accounts_referral_arr_rel_insert_input: {
      data: [76],
      on_conflict: [82],
      __typename: [8],
    },
    accounts_referral_avg_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_avg_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    accounts_referral_bool_exp: {
      _and: [73],
      _not: [73],
      _or: [73],
      account: [10],
      clicks: [4],
      link: [10],
      referrer: [10],
      reg_account: [439],
      registrations: [4],
      website: [10],
      __typename: [8],
    },
    accounts_referral_constraint: {},
    accounts_referral_inc_input: {
      clicks: [3],
      registrations: [3],
      __typename: [8],
    },
    accounts_referral_insert_input: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      reg_account: [445],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_max_fields: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_max_order_by: {
      account: [353],
      clicks: [353],
      link: [353],
      referrer: [353],
      registrations: [353],
      website: [353],
      __typename: [8],
    },
    accounts_referral_min_fields: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_min_order_by: {
      account: [353],
      clicks: [353],
      link: [353],
      referrer: [353],
      registrations: [353],
      website: [353],
      __typename: [8],
    },
    accounts_referral_mutation_response: {
      affected_rows: [3],
      returning: [64],
      __typename: [8],
    },
    accounts_referral_on_conflict: {
      constraint: [74],
      update_columns: [97],
      where: [73],
      __typename: [8],
    },
    accounts_referral_order_by: {
      account: [353],
      clicks: [353],
      link: [353],
      referrer: [353],
      reg_account: [447],
      registrations: [353],
      website: [353],
      __typename: [8],
    },
    accounts_referral_pk_columns_input: {
      link: [8],
      __typename: [8],
    },
    accounts_referral_select_column: {},
    accounts_referral_set_input: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_stddev_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_stddev_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    accounts_referral_stddev_pop_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_stddev_pop_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    accounts_referral_stddev_samp_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_stddev_samp_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    accounts_referral_stream_cursor_input: {
      initial_value: [94],
      ordering: [193],
      __typename: [8],
    },
    accounts_referral_stream_cursor_value_input: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_sum_fields: {
      clicks: [3],
      registrations: [3],
      __typename: [8],
    },
    accounts_referral_sum_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    accounts_referral_update_column: {},
    accounts_referral_updates: {
      _inc: [75],
      _set: [86],
      where: [73],
      __typename: [8],
    },
    accounts_referral_var_pop_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_var_pop_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    accounts_referral_var_samp_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_var_samp_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    accounts_referral_variance_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_variance_order_by: {
      clicks: [353],
      registrations: [353],
      __typename: [8],
    },
    actions: {
      action: [8],
      auth: [
        227,
        {
          path: [8],
        },
      ],
      contract: [8],
      data: [
        227,
        {
          path: [8],
        },
      ],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_aggregate: {
      aggregate: [107],
      nodes: [105],
      __typename: [8],
    },
    actions_aggregate_fields: {
      count: [
        3,
        {
          columns: [122, '[actions_select_column!]'],
          distinct: [0],
        },
      ],
      max: [115],
      min: [116],
      __typename: [8],
    },
    actions_append_input: {
      auth: [227],
      data: [227],
      __typename: [8],
    },
    actions_bool_exp: {
      _and: [109],
      _not: [109],
      _or: [109],
      action: [10],
      auth: [229],
      contract: [10],
      data: [229],
      global_sequence: [10],
      transaction_id: [10],
      __typename: [8],
    },
    actions_constraint: {},
    actions_delete_at_path_input: {
      auth: [8],
      data: [8],
      __typename: [8],
    },
    actions_delete_elem_input: {
      auth: [3],
      data: [3],
      __typename: [8],
    },
    actions_delete_key_input: {
      auth: [8],
      data: [8],
      __typename: [8],
    },
    actions_insert_input: {
      action: [8],
      auth: [227],
      contract: [8],
      data: [227],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_max_fields: {
      action: [8],
      contract: [8],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_min_fields: {
      action: [8],
      contract: [8],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_mutation_response: {
      affected_rows: [3],
      returning: [105],
      __typename: [8],
    },
    actions_on_conflict: {
      constraint: [110],
      update_columns: [126],
      where: [109],
      __typename: [8],
    },
    actions_order_by: {
      action: [353],
      auth: [353],
      contract: [353],
      data: [353],
      global_sequence: [353],
      transaction_id: [353],
      __typename: [8],
    },
    actions_pk_columns_input: {
      global_sequence: [8],
      __typename: [8],
    },
    actions_prepend_input: {
      auth: [227],
      data: [227],
      __typename: [8],
    },
    actions_select_column: {},
    actions_set_input: {
      action: [8],
      auth: [227],
      contract: [8],
      data: [227],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_stream_cursor_input: {
      initial_value: [125],
      ordering: [193],
      __typename: [8],
    },
    actions_stream_cursor_value_input: {
      action: [8],
      auth: [227],
      contract: [8],
      data: [227],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_update_column: {},
    actions_updates: {
      _append: [108],
      _delete_at_path: [111],
      _delete_elem: [112],
      _delete_key: [113],
      _prepend: [121],
      _set: [123],
      where: [109],
      __typename: [8],
    },
    avg_pay_time: {
      avg_pay_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_aggregate: {
      aggregate: [130],
      nodes: [128],
      __typename: [8],
    },
    avg_pay_time_aggregate_fields: {
      avg: [131],
      count: [
        3,
        {
          columns: [136, '[avg_pay_time_select_column!]'],
          distinct: [0],
        },
      ],
      max: [133],
      min: [134],
      stddev: [137],
      stddev_pop: [138],
      stddev_samp: [139],
      sum: [142],
      var_pop: [143],
      var_samp: [144],
      variance: [145],
      __typename: [8],
    },
    avg_pay_time_avg_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_bool_exp: {
      _and: [132],
      _not: [132],
      _or: [132],
      avg_pay_time_seconds: [352],
      user_id: [10],
      __typename: [8],
    },
    avg_pay_time_max_fields: {
      avg_pay_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_min_fields: {
      avg_pay_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_order_by: {
      avg_pay_time_seconds: [353],
      user_id: [353],
      __typename: [8],
    },
    avg_pay_time_select_column: {},
    avg_pay_time_stddev_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_stddev_pop_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_stddev_samp_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_stream_cursor_input: {
      initial_value: [141],
      ordering: [193],
      __typename: [8],
    },
    avg_pay_time_stream_cursor_value_input: {
      avg_pay_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_sum_fields: {
      avg_pay_time_seconds: [351],
      __typename: [8],
    },
    avg_pay_time_var_pop_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_var_samp_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_variance_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time: {
      avg_release_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_aggregate: {
      aggregate: [148],
      nodes: [146],
      __typename: [8],
    },
    avg_release_time_aggregate_fields: {
      avg: [149],
      count: [
        3,
        {
          columns: [154, '[avg_release_time_select_column!]'],
          distinct: [0],
        },
      ],
      max: [151],
      min: [152],
      stddev: [155],
      stddev_pop: [156],
      stddev_samp: [157],
      sum: [160],
      var_pop: [161],
      var_samp: [162],
      variance: [163],
      __typename: [8],
    },
    avg_release_time_avg_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_bool_exp: {
      _and: [150],
      _not: [150],
      _or: [150],
      avg_release_time_seconds: [352],
      user_id: [10],
      __typename: [8],
    },
    avg_release_time_max_fields: {
      avg_release_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_min_fields: {
      avg_release_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_order_by: {
      avg_release_time_seconds: [353],
      user_id: [353],
      __typename: [8],
    },
    avg_release_time_select_column: {},
    avg_release_time_stddev_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_stddev_pop_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_stddev_samp_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_stream_cursor_input: {
      initial_value: [159],
      ordering: [193],
      __typename: [8],
    },
    avg_release_time_stream_cursor_value_input: {
      avg_release_time_seconds: [351],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_sum_fields: {
      avg_release_time_seconds: [351],
      __typename: [8],
    },
    avg_release_time_var_pop_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_var_samp_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_variance_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    bigint: {},
    bigint_comparison_exp: {
      _eq: [164],
      _gt: [164],
      _gte: [164],
      _in: [164],
      _is_null: [0],
      _lt: [164],
      _lte: [164],
      _neq: [164],
      _nin: [164],
      __typename: [8],
    },
    cancel_p2p_approval_input: {
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      id: [8],
      __typename: [8],
    },
    cancel_p2p_offer_input: {
      cancelled_by: [8],
      id: [8],
      __typename: [8],
    },
    cancel_wallet_request_approval_input: {
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      id: [8],
      __typename: [8],
    },
    cancel_wallet_request_input: {
      cancelled_by: [8],
      id: [8],
      __typename: [8],
    },
    combined_avg_times: {
      avg_pay_time_minutes: [351],
      avg_release_time_minutes: [351],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_aggregate: {
      aggregate: [172],
      nodes: [170],
      __typename: [8],
    },
    combined_avg_times_aggregate_fields: {
      avg: [173],
      count: [
        3,
        {
          columns: [178, '[combined_avg_times_select_column!]'],
          distinct: [0],
        },
      ],
      max: [175],
      min: [176],
      stddev: [179],
      stddev_pop: [180],
      stddev_samp: [181],
      sum: [184],
      var_pop: [185],
      var_samp: [186],
      variance: [187],
      __typename: [8],
    },
    combined_avg_times_avg_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_bool_exp: {
      _and: [174],
      _not: [174],
      _or: [174],
      avg_pay_time_minutes: [352],
      avg_release_time_minutes: [352],
      user_id: [10],
      __typename: [8],
    },
    combined_avg_times_max_fields: {
      avg_pay_time_minutes: [351],
      avg_release_time_minutes: [351],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_min_fields: {
      avg_pay_time_minutes: [351],
      avg_release_time_minutes: [351],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_order_by: {
      avg_pay_time_minutes: [353],
      avg_release_time_minutes: [353],
      user_id: [353],
      __typename: [8],
    },
    combined_avg_times_select_column: {},
    combined_avg_times_stddev_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_stddev_pop_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_stddev_samp_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_stream_cursor_input: {
      initial_value: [183],
      ordering: [193],
      __typename: [8],
    },
    combined_avg_times_stream_cursor_value_input: {
      avg_pay_time_minutes: [351],
      avg_release_time_minutes: [351],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_sum_fields: {
      avg_pay_time_minutes: [351],
      avg_release_time_minutes: [351],
      __typename: [8],
    },
    combined_avg_times_var_pop_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_var_samp_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_variance_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    confirm_p2p_offer_input: {
      buyer: [8],
      id: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      __typename: [8],
    },
    confirm_wallet_request_input: {
      buyer: [8],
      id: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      __typename: [8],
    },
    create_add_bk_account_input: {
      account: [8],
      keys: [191],
      __typename: [8],
    },
    create_add_bk_account_keys_input: {
      private_key: [8],
      public_key: [8],
      __typename: [8],
    },
    create_add_bk_account_output: {
      data: [5],
      error: [8],
      success: [0],
      __typename: [8],
    },
    cursor_ordering: {},
    devices: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      reg_account: [436],
      __typename: [8],
    },
    devices_aggregate: {
      aggregate: [198],
      nodes: [194],
      __typename: [8],
    },
    devices_aggregate_bool_exp: {
      count: [197],
      __typename: [8],
    },
    devices_aggregate_bool_exp_count: {
      arguments: [212],
      distinct: [0],
      filter: [201],
      predicate: [4],
      __typename: [8],
    },
    devices_aggregate_fields: {
      count: [
        3,
        {
          columns: [212, '[devices_select_column!]'],
          distinct: [0],
        },
      ],
      max: [204],
      min: [206],
      __typename: [8],
    },
    devices_aggregate_order_by: {
      count: [353],
      max: [205],
      min: [207],
      __typename: [8],
    },
    devices_arr_rel_insert_input: {
      data: [203],
      on_conflict: [209],
      __typename: [8],
    },
    devices_bool_exp: {
      _and: [201],
      _not: [201],
      _or: [201],
      account: [10],
      created_at: [706],
      cred_id: [10],
      device_name: [10],
      public_key: [10],
      reg_account: [439],
      __typename: [8],
    },
    devices_constraint: {},
    devices_insert_input: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      reg_account: [445],
      __typename: [8],
    },
    devices_max_fields: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_max_order_by: {
      account: [353],
      created_at: [353],
      cred_id: [353],
      device_name: [353],
      public_key: [353],
      __typename: [8],
    },
    devices_min_fields: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_min_order_by: {
      account: [353],
      created_at: [353],
      cred_id: [353],
      device_name: [353],
      public_key: [353],
      __typename: [8],
    },
    devices_mutation_response: {
      affected_rows: [3],
      returning: [194],
      __typename: [8],
    },
    devices_on_conflict: {
      constraint: [202],
      update_columns: [216],
      where: [201],
      __typename: [8],
    },
    devices_order_by: {
      account: [353],
      created_at: [353],
      cred_id: [353],
      device_name: [353],
      public_key: [353],
      reg_account: [447],
      __typename: [8],
    },
    devices_pk_columns_input: {
      cred_id: [8],
      __typename: [8],
    },
    devices_select_column: {},
    devices_set_input: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_stream_cursor_input: {
      initial_value: [215],
      ordering: [193],
      __typename: [8],
    },
    devices_stream_cursor_value_input: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_update_column: {},
    devices_updates: {
      _set: [213],
      where: [201],
      __typename: [8],
    },
    float8: {},
    float8_comparison_exp: {
      _eq: [218],
      _gt: [218],
      _gte: [218],
      _in: [218],
      _is_null: [0],
      _lt: [218],
      _lte: [218],
      _neq: [218],
      _nin: [218],
      __typename: [8],
    },
    get_inactive_accounts_args: {
      p_days_ago: [3],
      __typename: [8],
    },
    get_login_counts_args: {
      p_interval: [8],
      __typename: [8],
    },
    get_p2p_offers_with_old_or_no_messages_args: {
      completed_status: [0],
      hours: [3],
      matched_status: [0],
      __typename: [8],
    },
    get_tx_statistics_args: {
      p_interval: [8],
      __typename: [8],
    },
    get_volume_statistics_args: {
      p_interval: [8],
      __typename: [8],
    },
    id_verification_input: {
      country: [8],
      id: [8],
      type: [8],
      __typename: [8],
    },
    id_verification_output: {
      existingRecord: [0],
      __typename: [8],
    },
    jsonb: {},
    jsonb_cast_exp: {
      String: [10],
      __typename: [8],
    },
    jsonb_comparison_exp: {
      _cast: [228],
      _contained_in: [227],
      _contains: [227],
      _eq: [227],
      _gt: [227],
      _gte: [227],
      _has_key: [8],
      _has_keys_all: [8],
      _has_keys_any: [8],
      _in: [227],
      _is_null: [0],
      _lt: [227],
      _lte: [227],
      _neq: [227],
      _nin: [227],
      __typename: [8],
    },
    login_account: {
      account: [8],
      created_at: [705],
      id: [804],
      reg_account: [436],
      __typename: [8],
    },
    login_account_aggregate: {
      aggregate: [234],
      nodes: [230],
      __typename: [8],
    },
    login_account_aggregate_bool_exp: {
      count: [233],
      __typename: [8],
    },
    login_account_aggregate_bool_exp_count: {
      arguments: [248],
      distinct: [0],
      filter: [237],
      predicate: [4],
      __typename: [8],
    },
    login_account_aggregate_fields: {
      count: [
        3,
        {
          columns: [248, '[login_account_select_column!]'],
          distinct: [0],
        },
      ],
      max: [240],
      min: [242],
      __typename: [8],
    },
    login_account_aggregate_order_by: {
      count: [353],
      max: [241],
      min: [243],
      __typename: [8],
    },
    login_account_arr_rel_insert_input: {
      data: [239],
      on_conflict: [245],
      __typename: [8],
    },
    login_account_bool_exp: {
      _and: [237],
      _not: [237],
      _or: [237],
      account: [10],
      created_at: [706],
      id: [805],
      reg_account: [439],
      __typename: [8],
    },
    login_account_constraint: {},
    login_account_insert_input: {
      account: [8],
      created_at: [705],
      id: [804],
      reg_account: [445],
      __typename: [8],
    },
    login_account_max_fields: {
      account: [8],
      created_at: [705],
      id: [804],
      __typename: [8],
    },
    login_account_max_order_by: {
      account: [353],
      created_at: [353],
      id: [353],
      __typename: [8],
    },
    login_account_min_fields: {
      account: [8],
      created_at: [705],
      id: [804],
      __typename: [8],
    },
    login_account_min_order_by: {
      account: [353],
      created_at: [353],
      id: [353],
      __typename: [8],
    },
    login_account_mutation_response: {
      affected_rows: [3],
      returning: [230],
      __typename: [8],
    },
    login_account_on_conflict: {
      constraint: [238],
      update_columns: [252],
      where: [237],
      __typename: [8],
    },
    login_account_order_by: {
      account: [353],
      created_at: [353],
      id: [353],
      reg_account: [447],
      __typename: [8],
    },
    login_account_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    login_account_select_column: {},
    login_account_set_input: {
      account: [8],
      created_at: [705],
      id: [804],
      __typename: [8],
    },
    login_account_stream_cursor_input: {
      initial_value: [251],
      ordering: [193],
      __typename: [8],
    },
    login_account_stream_cursor_value_input: {
      account: [8],
      created_at: [705],
      id: [804],
      __typename: [8],
    },
    login_account_update_column: {},
    login_account_updates: {
      _set: [249],
      where: [237],
      __typename: [8],
    },
    login_counts: {
      accounts: [8],
      date: [705],
      login_count: [164],
      __typename: [8],
    },
    login_counts_aggregate: {
      aggregate: [256],
      nodes: [254],
      __typename: [8],
    },
    login_counts_aggregate_fields: {
      avg: [257],
      count: [
        3,
        {
          columns: [265, '[login_counts_select_column!]'],
          distinct: [0],
        },
      ],
      max: [261],
      min: [262],
      stddev: [267],
      stddev_pop: [268],
      stddev_samp: [269],
      sum: [272],
      var_pop: [274],
      var_samp: [275],
      variance: [276],
      __typename: [8],
    },
    login_counts_avg_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_bool_exp: {
      _and: [258],
      _not: [258],
      _or: [258],
      accounts: [9],
      date: [706],
      login_count: [165],
      __typename: [8],
    },
    login_counts_inc_input: {
      login_count: [164],
      __typename: [8],
    },
    login_counts_insert_input: {
      accounts: [8],
      date: [705],
      login_count: [164],
      __typename: [8],
    },
    login_counts_max_fields: {
      accounts: [8],
      date: [705],
      login_count: [164],
      __typename: [8],
    },
    login_counts_min_fields: {
      accounts: [8],
      date: [705],
      login_count: [164],
      __typename: [8],
    },
    login_counts_mutation_response: {
      affected_rows: [3],
      returning: [254],
      __typename: [8],
    },
    login_counts_order_by: {
      accounts: [353],
      date: [353],
      login_count: [353],
      __typename: [8],
    },
    login_counts_select_column: {},
    login_counts_set_input: {
      accounts: [8],
      date: [705],
      login_count: [164],
      __typename: [8],
    },
    login_counts_stddev_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_stddev_pop_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_stddev_samp_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_stream_cursor_input: {
      initial_value: [271],
      ordering: [193],
      __typename: [8],
    },
    login_counts_stream_cursor_value_input: {
      accounts: [8],
      date: [705],
      login_count: [164],
      __typename: [8],
    },
    login_counts_sum_fields: {
      login_count: [164],
      __typename: [8],
    },
    login_counts_updates: {
      _inc: [259],
      _set: [266],
      where: [258],
      __typename: [8],
    },
    login_counts_var_pop_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_var_samp_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_variance_fields: {
      login_count: [2],
      __typename: [8],
    },
    make_p2p_buy_offer_input: {
      amount: [8],
      buyer: [8],
      buyer_method_details: [8],
      id: [8],
      method: [8],
      region: [8],
      __typename: [8],
    },
    make_p2p_sell_offer_input: {
      amount: [8],
      bitcashbank_id: [8],
      id: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      __typename: [8],
    },
    make_wallet_deposit_request_input: {
      amount: [8],
      buyer: [8],
      buyer_method_details: [8],
      id: [8],
      method: [8],
      region: [8],
      __typename: [8],
    },
    make_wallet_withdraw_request_input: {
      amount: [8],
      bitcashbank_id: [8],
      id: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      __typename: [8],
    },
    messages: {
      from: [8],
      id: [804],
      message: [8],
      p2p_id: [804],
      p2p_offer: [355],
      regAccountByTo: [436],
      reg_account: [436],
      support_id: [804],
      swap_id: [804],
      swap_order: [550],
      timestamp: [705],
      to: [8],
      __typename: [8],
    },
    messages_aggregate: {
      aggregate: [285],
      nodes: [281],
      __typename: [8],
    },
    messages_aggregate_bool_exp: {
      count: [284],
      __typename: [8],
    },
    messages_aggregate_bool_exp_count: {
      arguments: [299],
      distinct: [0],
      filter: [288],
      predicate: [4],
      __typename: [8],
    },
    messages_aggregate_fields: {
      count: [
        3,
        {
          columns: [299, '[messages_select_column!]'],
          distinct: [0],
        },
      ],
      max: [291],
      min: [293],
      __typename: [8],
    },
    messages_aggregate_order_by: {
      count: [353],
      max: [292],
      min: [294],
      __typename: [8],
    },
    messages_arr_rel_insert_input: {
      data: [290],
      on_conflict: [296],
      __typename: [8],
    },
    messages_bool_exp: {
      _and: [288],
      _not: [288],
      _or: [288],
      from: [10],
      id: [805],
      message: [10],
      p2p_id: [805],
      p2p_offer: [364],
      regAccountByTo: [439],
      reg_account: [439],
      support_id: [805],
      swap_id: [805],
      swap_order: [569],
      timestamp: [706],
      to: [10],
      __typename: [8],
    },
    messages_constraint: {},
    messages_insert_input: {
      from: [8],
      id: [804],
      message: [8],
      p2p_id: [804],
      p2p_offer: [372],
      regAccountByTo: [445],
      reg_account: [445],
      support_id: [804],
      swap_id: [804],
      swap_order: [578],
      timestamp: [705],
      to: [8],
      __typename: [8],
    },
    messages_max_fields: {
      from: [8],
      id: [804],
      message: [8],
      p2p_id: [804],
      support_id: [804],
      swap_id: [804],
      timestamp: [705],
      to: [8],
      __typename: [8],
    },
    messages_max_order_by: {
      from: [353],
      id: [353],
      message: [353],
      p2p_id: [353],
      support_id: [353],
      swap_id: [353],
      timestamp: [353],
      to: [353],
      __typename: [8],
    },
    messages_min_fields: {
      from: [8],
      id: [804],
      message: [8],
      p2p_id: [804],
      support_id: [804],
      swap_id: [804],
      timestamp: [705],
      to: [8],
      __typename: [8],
    },
    messages_min_order_by: {
      from: [353],
      id: [353],
      message: [353],
      p2p_id: [353],
      support_id: [353],
      swap_id: [353],
      timestamp: [353],
      to: [353],
      __typename: [8],
    },
    messages_mutation_response: {
      affected_rows: [3],
      returning: [281],
      __typename: [8],
    },
    messages_on_conflict: {
      constraint: [289],
      update_columns: [303],
      where: [288],
      __typename: [8],
    },
    messages_order_by: {
      from: [353],
      id: [353],
      message: [353],
      p2p_id: [353],
      p2p_offer: [374],
      regAccountByTo: [447],
      reg_account: [447],
      support_id: [353],
      swap_id: [353],
      swap_order: [580],
      timestamp: [353],
      to: [353],
      __typename: [8],
    },
    messages_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    messages_select_column: {},
    messages_set_input: {
      from: [8],
      id: [804],
      message: [8],
      p2p_id: [804],
      support_id: [804],
      swap_id: [804],
      timestamp: [705],
      to: [8],
      __typename: [8],
    },
    messages_stream_cursor_input: {
      initial_value: [302],
      ordering: [193],
      __typename: [8],
    },
    messages_stream_cursor_value_input: {
      from: [8],
      id: [804],
      message: [8],
      p2p_id: [804],
      support_id: [804],
      swap_id: [804],
      timestamp: [705],
      to: [8],
      __typename: [8],
    },
    messages_update_column: {},
    messages_updates: {
      _set: [300],
      where: [288],
      __typename: [8],
    },
    migrate_device: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_aggregate: {
      aggregate: [307],
      nodes: [305],
      __typename: [8],
    },
    migrate_device_aggregate_fields: {
      count: [
        3,
        {
          columns: [317, '[migrate_device_select_column!]'],
          distinct: [0],
        },
      ],
      max: [311],
      min: [312],
      __typename: [8],
    },
    migrate_device_bool_exp: {
      _and: [308],
      _not: [308],
      _or: [308],
      account: [10],
      created_at: [706],
      cred_id: [10],
      device_name: [10],
      public_key: [10],
      __typename: [8],
    },
    migrate_device_constraint: {},
    migrate_device_insert_input: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_max_fields: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_min_fields: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_mutation_response: {
      affected_rows: [3],
      returning: [305],
      __typename: [8],
    },
    migrate_device_on_conflict: {
      constraint: [309],
      update_columns: [321],
      where: [308],
      __typename: [8],
    },
    migrate_device_order_by: {
      account: [353],
      created_at: [353],
      cred_id: [353],
      device_name: [353],
      public_key: [353],
      __typename: [8],
    },
    migrate_device_pk_columns_input: {
      cred_id: [8],
      __typename: [8],
    },
    migrate_device_select_column: {},
    migrate_device_set_input: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_stream_cursor_input: {
      initial_value: [320],
      ordering: [193],
      __typename: [8],
    },
    migrate_device_stream_cursor_value_input: {
      account: [8],
      created_at: [705],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_update_column: {},
    migrate_device_updates: {
      _set: [318],
      where: [308],
      __typename: [8],
    },
    notifications: {
      content_id: [804],
      created_at: [705],
      from: [8],
      id: [804],
      notifications_reg_account: [436],
      read: [0],
      reg_account: [436],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_aggregate: {
      aggregate: [329],
      nodes: [323],
      __typename: [8],
    },
    notifications_aggregate_bool_exp: {
      bool_and: [326],
      bool_or: [327],
      count: [328],
      __typename: [8],
    },
    notifications_aggregate_bool_exp_bool_and: {
      arguments: [344],
      distinct: [0],
      filter: [332],
      predicate: [1],
      __typename: [8],
    },
    notifications_aggregate_bool_exp_bool_or: {
      arguments: [345],
      distinct: [0],
      filter: [332],
      predicate: [1],
      __typename: [8],
    },
    notifications_aggregate_bool_exp_count: {
      arguments: [343],
      distinct: [0],
      filter: [332],
      predicate: [4],
      __typename: [8],
    },
    notifications_aggregate_fields: {
      count: [
        3,
        {
          columns: [343, '[notifications_select_column!]'],
          distinct: [0],
        },
      ],
      max: [335],
      min: [337],
      __typename: [8],
    },
    notifications_aggregate_order_by: {
      count: [353],
      max: [336],
      min: [338],
      __typename: [8],
    },
    notifications_arr_rel_insert_input: {
      data: [334],
      on_conflict: [340],
      __typename: [8],
    },
    notifications_bool_exp: {
      _and: [332],
      _not: [332],
      _or: [332],
      content_id: [805],
      created_at: [706],
      from: [10],
      id: [805],
      notifications_reg_account: [439],
      read: [1],
      reg_account: [439],
      to: [10],
      type: [10],
      __typename: [8],
    },
    notifications_constraint: {},
    notifications_insert_input: {
      content_id: [804],
      created_at: [705],
      from: [8],
      id: [804],
      notifications_reg_account: [445],
      read: [0],
      reg_account: [445],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_max_fields: {
      content_id: [804],
      created_at: [705],
      from: [8],
      id: [804],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_max_order_by: {
      content_id: [353],
      created_at: [353],
      from: [353],
      id: [353],
      to: [353],
      type: [353],
      __typename: [8],
    },
    notifications_min_fields: {
      content_id: [804],
      created_at: [705],
      from: [8],
      id: [804],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_min_order_by: {
      content_id: [353],
      created_at: [353],
      from: [353],
      id: [353],
      to: [353],
      type: [353],
      __typename: [8],
    },
    notifications_mutation_response: {
      affected_rows: [3],
      returning: [323],
      __typename: [8],
    },
    notifications_on_conflict: {
      constraint: [333],
      update_columns: [349],
      where: [332],
      __typename: [8],
    },
    notifications_order_by: {
      content_id: [353],
      created_at: [353],
      from: [353],
      id: [353],
      notifications_reg_account: [447],
      read: [353],
      reg_account: [447],
      to: [353],
      type: [353],
      __typename: [8],
    },
    notifications_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    notifications_select_column: {},
    notifications_select_column_notifications_aggregate_bool_exp_bool_and_arguments_columns: {},
    notifications_select_column_notifications_aggregate_bool_exp_bool_or_arguments_columns: {},
    notifications_set_input: {
      content_id: [804],
      created_at: [705],
      from: [8],
      id: [804],
      read: [0],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_stream_cursor_input: {
      initial_value: [348],
      ordering: [193],
      __typename: [8],
    },
    notifications_stream_cursor_value_input: {
      content_id: [804],
      created_at: [705],
      from: [8],
      id: [804],
      read: [0],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_update_column: {},
    notifications_updates: {
      _set: [346],
      where: [332],
      __typename: [8],
    },
    numeric: {},
    numeric_comparison_exp: {
      _eq: [351],
      _gt: [351],
      _gte: [351],
      _in: [351],
      _is_null: [0],
      _lt: [351],
      _lte: [351],
      _neq: [351],
      _nin: [351],
      __typename: [8],
    },
    order_by: {},
    p2p_offer_output: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [8],
      cancel_request_datetime: [8],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [8],
      id: [8],
      initiator: [8],
      matched: [0],
      matched_datetime: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [8],
      __typename: [8],
    },
    p2p_offers: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [705],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [705],
      cancel_request_datetime: [705],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [705],
      id: [804],
      initiator: [8],
      matched: [0],
      matched_datetime: [705],
      messages: [
        281,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messages_aggregate: [
        282,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      method: [8],
      original_offer_id: [804],
      regAccountByBuyer: [436],
      regAccountByCancellationApprovedBy: [436],
      regAccountByCancelledBy: [436],
      regAccountByInitiator: [436],
      regAccountBySeller: [436],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [705],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      system_notifications: [
        634,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      system_notifications_aggregate: [
        635,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      type: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_offers_aggregate: {
      aggregate: [361],
      nodes: [355],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp: {
      bool_and: [358],
      bool_or: [359],
      count: [360],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp_bool_and: {
      arguments: [377],
      distinct: [0],
      filter: [364],
      predicate: [1],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp_bool_or: {
      arguments: [378],
      distinct: [0],
      filter: [364],
      predicate: [1],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp_count: {
      arguments: [376],
      distinct: [0],
      filter: [364],
      predicate: [4],
      __typename: [8],
    },
    p2p_offers_aggregate_fields: {
      count: [
        3,
        {
          columns: [376, '[p2p_offers_select_column!]'],
          distinct: [0],
        },
      ],
      max: [367],
      min: [369],
      __typename: [8],
    },
    p2p_offers_aggregate_order_by: {
      count: [353],
      max: [368],
      min: [370],
      __typename: [8],
    },
    p2p_offers_arr_rel_insert_input: {
      data: [366],
      on_conflict: [373],
      __typename: [8],
    },
    p2p_offers_bool_exp: {
      _and: [364],
      _not: [364],
      _or: [364],
      amount: [10],
      bitcashbank_id: [10],
      buyer: [10],
      buyer_confirmed_datetime: [706],
      buyer_confirmed_payment: [1],
      buyer_method_details: [10],
      cancel_approval_datetime: [706],
      cancel_request_datetime: [706],
      cancellation_approved_by: [10],
      cancellation_transaction: [10],
      cancelled: [1],
      cancelled_by: [10],
      completed: [1],
      created_at: [706],
      id: [805],
      initiator: [10],
      matched: [1],
      matched_datetime: [706],
      messages: [288],
      messages_aggregate: [283],
      method: [10],
      original_offer_id: [805],
      regAccountByBuyer: [439],
      regAccountByCancellationApprovedBy: [439],
      regAccountByCancelledBy: [439],
      regAccountByInitiator: [439],
      regAccountBySeller: [439],
      region: [10],
      reserved_by: [10],
      sell_put_transaction: [10],
      sell_settlement_transaction: [10],
      seller: [10],
      seller_confirmed_datetime: [706],
      seller_confirmed_payment: [1],
      seller_method_details: [10],
      system_notifications: [641],
      system_notifications_aggregate: [636],
      type: [10],
      updated_at: [706],
      __typename: [8],
    },
    p2p_offers_constraint: {},
    p2p_offers_insert_input: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [705],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [705],
      cancel_request_datetime: [705],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [705],
      id: [804],
      initiator: [8],
      matched: [0],
      matched_datetime: [705],
      messages: [287],
      method: [8],
      original_offer_id: [804],
      regAccountByBuyer: [445],
      regAccountByCancellationApprovedBy: [445],
      regAccountByCancelledBy: [445],
      regAccountByInitiator: [445],
      regAccountBySeller: [445],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [705],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      system_notifications: [640],
      type: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_offers_max_fields: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [705],
      buyer_method_details: [8],
      cancel_approval_datetime: [705],
      cancel_request_datetime: [705],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled_by: [8],
      created_at: [705],
      id: [804],
      initiator: [8],
      matched_datetime: [705],
      method: [8],
      original_offer_id: [804],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [705],
      seller_method_details: [8],
      type: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_offers_max_order_by: {
      amount: [353],
      bitcashbank_id: [353],
      buyer: [353],
      buyer_confirmed_datetime: [353],
      buyer_method_details: [353],
      cancel_approval_datetime: [353],
      cancel_request_datetime: [353],
      cancellation_approved_by: [353],
      cancellation_transaction: [353],
      cancelled_by: [353],
      created_at: [353],
      id: [353],
      initiator: [353],
      matched_datetime: [353],
      method: [353],
      original_offer_id: [353],
      region: [353],
      reserved_by: [353],
      sell_put_transaction: [353],
      sell_settlement_transaction: [353],
      seller: [353],
      seller_confirmed_datetime: [353],
      seller_method_details: [353],
      type: [353],
      updated_at: [353],
      __typename: [8],
    },
    p2p_offers_min_fields: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [705],
      buyer_method_details: [8],
      cancel_approval_datetime: [705],
      cancel_request_datetime: [705],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled_by: [8],
      created_at: [705],
      id: [804],
      initiator: [8],
      matched_datetime: [705],
      method: [8],
      original_offer_id: [804],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [705],
      seller_method_details: [8],
      type: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_offers_min_order_by: {
      amount: [353],
      bitcashbank_id: [353],
      buyer: [353],
      buyer_confirmed_datetime: [353],
      buyer_method_details: [353],
      cancel_approval_datetime: [353],
      cancel_request_datetime: [353],
      cancellation_approved_by: [353],
      cancellation_transaction: [353],
      cancelled_by: [353],
      created_at: [353],
      id: [353],
      initiator: [353],
      matched_datetime: [353],
      method: [353],
      original_offer_id: [353],
      region: [353],
      reserved_by: [353],
      sell_put_transaction: [353],
      sell_settlement_transaction: [353],
      seller: [353],
      seller_confirmed_datetime: [353],
      seller_method_details: [353],
      type: [353],
      updated_at: [353],
      __typename: [8],
    },
    p2p_offers_mutation_response: {
      affected_rows: [3],
      returning: [355],
      __typename: [8],
    },
    p2p_offers_obj_rel_insert_input: {
      data: [366],
      on_conflict: [373],
      __typename: [8],
    },
    p2p_offers_on_conflict: {
      constraint: [365],
      update_columns: [382],
      where: [364],
      __typename: [8],
    },
    p2p_offers_order_by: {
      amount: [353],
      bitcashbank_id: [353],
      buyer: [353],
      buyer_confirmed_datetime: [353],
      buyer_confirmed_payment: [353],
      buyer_method_details: [353],
      cancel_approval_datetime: [353],
      cancel_request_datetime: [353],
      cancellation_approved_by: [353],
      cancellation_transaction: [353],
      cancelled: [353],
      cancelled_by: [353],
      completed: [353],
      created_at: [353],
      id: [353],
      initiator: [353],
      matched: [353],
      matched_datetime: [353],
      messages_aggregate: [286],
      method: [353],
      original_offer_id: [353],
      regAccountByBuyer: [447],
      regAccountByCancellationApprovedBy: [447],
      regAccountByCancelledBy: [447],
      regAccountByInitiator: [447],
      regAccountBySeller: [447],
      region: [353],
      reserved_by: [353],
      sell_put_transaction: [353],
      sell_settlement_transaction: [353],
      seller: [353],
      seller_confirmed_datetime: [353],
      seller_confirmed_payment: [353],
      seller_method_details: [353],
      system_notifications_aggregate: [639],
      type: [353],
      updated_at: [353],
      __typename: [8],
    },
    p2p_offers_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    p2p_offers_select_column: {},
    p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_and_arguments_columns: {},
    p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_or_arguments_columns: {},
    p2p_offers_set_input: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [705],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [705],
      cancel_request_datetime: [705],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [705],
      id: [804],
      initiator: [8],
      matched: [0],
      matched_datetime: [705],
      method: [8],
      original_offer_id: [804],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [705],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_offers_stream_cursor_input: {
      initial_value: [381],
      ordering: [193],
      __typename: [8],
    },
    p2p_offers_stream_cursor_value_input: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [705],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [705],
      cancel_request_datetime: [705],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [705],
      id: [804],
      initiator: [8],
      matched: [0],
      matched_datetime: [705],
      method: [8],
      original_offer_id: [804],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [705],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_offers_update_column: {},
    p2p_offers_updates: {
      _set: [379],
      where: [364],
      __typename: [8],
    },
    p2p_pre_sell_offers: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [705],
      id: [804],
      method: [8],
      processed: [0],
      reg_account: [436],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate: {
      aggregate: [390],
      nodes: [384],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp: {
      bool_and: [387],
      bool_or: [388],
      count: [389],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp_bool_and: {
      arguments: [405],
      distinct: [0],
      filter: [393],
      predicate: [1],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp_bool_or: {
      arguments: [406],
      distinct: [0],
      filter: [393],
      predicate: [1],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp_count: {
      arguments: [404],
      distinct: [0],
      filter: [393],
      predicate: [4],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_fields: {
      count: [
        3,
        {
          columns: [404, '[p2p_pre_sell_offers_select_column!]'],
          distinct: [0],
        },
      ],
      max: [396],
      min: [398],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_order_by: {
      count: [353],
      max: [397],
      min: [399],
      __typename: [8],
    },
    p2p_pre_sell_offers_arr_rel_insert_input: {
      data: [395],
      on_conflict: [401],
      __typename: [8],
    },
    p2p_pre_sell_offers_bool_exp: {
      _and: [393],
      _not: [393],
      _or: [393],
      amount: [10],
      bitcashbank_id: [10],
      created_at: [706],
      id: [805],
      method: [10],
      processed: [1],
      reg_account: [439],
      region: [10],
      sell_put_transaction: [10],
      seller: [10],
      seller_method_details: [10],
      updated_at: [706],
      __typename: [8],
    },
    p2p_pre_sell_offers_constraint: {},
    p2p_pre_sell_offers_insert_input: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [705],
      id: [804],
      method: [8],
      processed: [0],
      reg_account: [445],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_pre_sell_offers_max_fields: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [705],
      id: [804],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_pre_sell_offers_max_order_by: {
      amount: [353],
      bitcashbank_id: [353],
      created_at: [353],
      id: [353],
      method: [353],
      region: [353],
      sell_put_transaction: [353],
      seller: [353],
      seller_method_details: [353],
      updated_at: [353],
      __typename: [8],
    },
    p2p_pre_sell_offers_min_fields: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [705],
      id: [804],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_pre_sell_offers_min_order_by: {
      amount: [353],
      bitcashbank_id: [353],
      created_at: [353],
      id: [353],
      method: [353],
      region: [353],
      sell_put_transaction: [353],
      seller: [353],
      seller_method_details: [353],
      updated_at: [353],
      __typename: [8],
    },
    p2p_pre_sell_offers_mutation_response: {
      affected_rows: [3],
      returning: [384],
      __typename: [8],
    },
    p2p_pre_sell_offers_on_conflict: {
      constraint: [394],
      update_columns: [410],
      where: [393],
      __typename: [8],
    },
    p2p_pre_sell_offers_order_by: {
      amount: [353],
      bitcashbank_id: [353],
      created_at: [353],
      id: [353],
      method: [353],
      processed: [353],
      reg_account: [447],
      region: [353],
      sell_put_transaction: [353],
      seller: [353],
      seller_method_details: [353],
      updated_at: [353],
      __typename: [8],
    },
    p2p_pre_sell_offers_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    p2p_pre_sell_offers_select_column: {},
    p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_and_arguments_columns:
      {},
    p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_or_arguments_columns:
      {},
    p2p_pre_sell_offers_set_input: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [705],
      id: [804],
      method: [8],
      processed: [0],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_pre_sell_offers_stream_cursor_input: {
      initial_value: [409],
      ordering: [193],
      __typename: [8],
    },
    p2p_pre_sell_offers_stream_cursor_value_input: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [705],
      id: [804],
      method: [8],
      processed: [0],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [705],
      __typename: [8],
    },
    p2p_pre_sell_offers_update_column: {},
    p2p_pre_sell_offers_updates: {
      _set: [407],
      where: [393],
      __typename: [8],
    },
    photo_id_verification_output: {
      error: [0],
      __typename: [8],
    },
    preferences: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [
        227,
        {
          path: [8],
        },
      ],
      theme: [8],
      __typename: [8],
    },
    preferences_aggregate: {
      aggregate: [415],
      nodes: [413],
      __typename: [8],
    },
    preferences_aggregate_fields: {
      count: [
        3,
        {
          columns: [430, '[preferences_select_column!]'],
          distinct: [0],
        },
      ],
      max: [423],
      min: [424],
      __typename: [8],
    },
    preferences_append_input: {
      secondary_currency: [227],
      __typename: [8],
    },
    preferences_bool_exp: {
      _and: [417],
      _not: [417],
      _or: [417],
      account: [10],
      currency: [10],
      language: [10],
      notifications: [1],
      personalized: [1],
      region: [10],
      secondary_currency: [229],
      theme: [10],
      __typename: [8],
    },
    preferences_constraint: {},
    preferences_delete_at_path_input: {
      secondary_currency: [8],
      __typename: [8],
    },
    preferences_delete_elem_input: {
      secondary_currency: [3],
      __typename: [8],
    },
    preferences_delete_key_input: {
      secondary_currency: [8],
      __typename: [8],
    },
    preferences_insert_input: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [227],
      theme: [8],
      __typename: [8],
    },
    preferences_max_fields: {
      account: [8],
      currency: [8],
      language: [8],
      region: [8],
      theme: [8],
      __typename: [8],
    },
    preferences_min_fields: {
      account: [8],
      currency: [8],
      language: [8],
      region: [8],
      theme: [8],
      __typename: [8],
    },
    preferences_mutation_response: {
      affected_rows: [3],
      returning: [413],
      __typename: [8],
    },
    preferences_on_conflict: {
      constraint: [418],
      update_columns: [434],
      where: [417],
      __typename: [8],
    },
    preferences_order_by: {
      account: [353],
      currency: [353],
      language: [353],
      notifications: [353],
      personalized: [353],
      region: [353],
      secondary_currency: [353],
      theme: [353],
      __typename: [8],
    },
    preferences_pk_columns_input: {
      account: [8],
      __typename: [8],
    },
    preferences_prepend_input: {
      secondary_currency: [227],
      __typename: [8],
    },
    preferences_select_column: {},
    preferences_set_input: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [227],
      theme: [8],
      __typename: [8],
    },
    preferences_stream_cursor_input: {
      initial_value: [433],
      ordering: [193],
      __typename: [8],
    },
    preferences_stream_cursor_value_input: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [227],
      theme: [8],
      __typename: [8],
    },
    preferences_update_column: {},
    preferences_updates: {
      _append: [416],
      _delete_at_path: [419],
      _delete_elem: [420],
      _delete_key: [421],
      _prepend: [429],
      _set: [431],
      where: [417],
      __typename: [8],
    },
    reg_accounts: {
      account: [8],
      accounts_information: [30],
      accounts_referrals: [
        64,
        {
          distinct_on: [85, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [83, '[accounts_referral_order_by!]'],
          where: [73],
        },
      ],
      accounts_referrals_aggregate: [
        65,
        {
          distinct_on: [85, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [83, '[accounts_referral_order_by!]'],
          where: [73],
        },
      ],
      create_account: [0],
      created: [705],
      created_at: [703],
      cred_id: [8],
      device_name: [8],
      devices: [
        194,
        {
          distinct_on: [212, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [210, '[devices_order_by!]'],
          where: [201],
        },
      ],
      devices_aggregate: [
        195,
        {
          distinct_on: [212, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [210, '[devices_order_by!]'],
          where: [201],
        },
      ],
      freezed: [0],
      id: [804],
      is_verified: [0],
      login_accounts: [
        230,
        {
          distinct_on: [248, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [246, '[login_account_order_by!]'],
          where: [237],
        },
      ],
      login_accounts_aggregate: [
        231,
        {
          distinct_on: [248, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [246, '[login_account_order_by!]'],
          where: [237],
        },
      ],
      messages: [
        281,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messagesByTo: [
        281,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messagesByTo_aggregate: [
        282,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messages_aggregate: [
        282,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      notifications: [
        323,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      notificationsByFrom: [
        323,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      notificationsByFrom_aggregate: [
        324,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      notifications_aggregate: [
        324,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      onboarded: [0],
      p2p_offers: [
        355,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_aggregate: [
        356,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_buyer: [
        355,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_buyer_aggregate: [
        356,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_initiator: [
        355,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_initiator_aggregate: [
        356,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_seller: [
        355,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_seller_aggregate: [
        356,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_pre_sell_offers: [
        384,
        {
          distinct_on: [404, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [402, '[p2p_pre_sell_offers_order_by!]'],
          where: [393],
        },
      ],
      p2p_pre_sell_offers_aggregate: [
        385,
        {
          distinct_on: [404, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [402, '[p2p_pre_sell_offers_order_by!]'],
          where: [393],
        },
      ],
      public_key: [8],
      referrer: [8],
      regAccountsByCancelledBy: [
        355,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      regAccountsByCancelledBy_aggregate: [
        356,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      reg_accounts_accounts_information: [
        30,
        {
          distinct_on: [56, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [53, '[accounts_information_order_by!]'],
          where: [40],
        },
      ],
      reg_accounts_accounts_information_aggregate: [
        31,
        {
          distinct_on: [56, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [53, '[accounts_information_order_by!]'],
          where: [40],
        },
      ],
      reputation_score: [455],
      role: [8],
      source: [8],
      support_sessions: [
        508,
        {
          distinct_on: [526, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [524, '[support_sessions_order_by!]'],
          where: [515],
        },
      ],
      support_sessions_aggregate: [
        509,
        {
          distinct_on: [526, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [524, '[support_sessions_order_by!]'],
          where: [515],
        },
      ],
      swap_orders: [
        550,
        {
          distinct_on: [582, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [580, '[swap_orders_order_by!]'],
          where: [569],
        },
      ],
      swap_orders_aggregate: [
        551,
        {
          distinct_on: [582, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [580, '[swap_orders_order_by!]'],
          where: [569],
        },
      ],
      system_notification_read_statuses: [
        610,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      system_notification_read_statuses_aggregate: [
        611,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      trustNetworkNotificationsByTrust: [
        726,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trustNetworkNotificationsByTrust_aggregate: [
        727,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trust_network_notifications: [
        726,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trust_network_notifications_aggregate: [
        727,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trust_networks_by_account: [
        709,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      trust_networks_by_account_aggregate: [
        710,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      trust_networks_by_trust: [
        709,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      trust_networks_by_trust_aggregate: [
        710,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_aggregate: {
      aggregate: [438],
      nodes: [436],
      __typename: [8],
    },
    reg_accounts_aggregate_fields: {
      count: [
        3,
        {
          columns: [449, '[reg_accounts_select_column!]'],
          distinct: [0],
        },
      ],
      max: [442],
      min: [443],
      __typename: [8],
    },
    reg_accounts_bool_exp: {
      _and: [439],
      _not: [439],
      _or: [439],
      account: [10],
      accounts_information: [40],
      accounts_referrals: [73],
      accounts_referrals_aggregate: [66],
      create_account: [1],
      created: [706],
      created_at: [704],
      cred_id: [10],
      device_name: [10],
      devices: [201],
      devices_aggregate: [196],
      freezed: [1],
      id: [805],
      is_verified: [1],
      login_accounts: [237],
      login_accounts_aggregate: [232],
      messages: [288],
      messagesByTo: [288],
      messagesByTo_aggregate: [283],
      messages_aggregate: [283],
      notifications: [332],
      notificationsByFrom: [332],
      notificationsByFrom_aggregate: [325],
      notifications_aggregate: [325],
      onboarded: [1],
      p2p_offers: [364],
      p2p_offers_aggregate: [357],
      p2p_offers_buyer: [364],
      p2p_offers_buyer_aggregate: [357],
      p2p_offers_initiator: [364],
      p2p_offers_initiator_aggregate: [357],
      p2p_offers_seller: [364],
      p2p_offers_seller_aggregate: [357],
      p2p_pre_sell_offers: [393],
      p2p_pre_sell_offers_aggregate: [386],
      public_key: [10],
      referrer: [10],
      regAccountsByCancelledBy: [364],
      regAccountsByCancelledBy_aggregate: [357],
      reg_accounts_accounts_information: [40],
      reg_accounts_accounts_information_aggregate: [32],
      reputation_score: [459],
      role: [10],
      source: [10],
      support_sessions: [515],
      support_sessions_aggregate: [510],
      swap_orders: [569],
      swap_orders_aggregate: [552],
      system_notification_read_statuses: [617],
      system_notification_read_statuses_aggregate: [612],
      trustNetworkNotificationsByTrust: [733],
      trustNetworkNotificationsByTrust_aggregate: [728],
      trust_network_notifications: [733],
      trust_network_notifications_aggregate: [728],
      trust_networks_by_account: [718],
      trust_networks_by_account_aggregate: [711],
      trust_networks_by_trust: [718],
      trust_networks_by_trust_aggregate: [711],
      txid: [10],
      __typename: [8],
    },
    reg_accounts_constraint: {},
    reg_accounts_insert_input: {
      account: [8],
      accounts_information: [51],
      accounts_referrals: [70],
      create_account: [0],
      created: [705],
      created_at: [703],
      cred_id: [8],
      device_name: [8],
      devices: [200],
      freezed: [0],
      id: [804],
      is_verified: [0],
      login_accounts: [236],
      messages: [287],
      messagesByTo: [287],
      notifications: [331],
      notificationsByFrom: [331],
      onboarded: [0],
      p2p_offers: [363],
      p2p_offers_buyer: [363],
      p2p_offers_initiator: [363],
      p2p_offers_seller: [363],
      p2p_pre_sell_offers: [392],
      public_key: [8],
      referrer: [8],
      regAccountsByCancelledBy: [363],
      reg_accounts_accounts_information: [39],
      reputation_score: [466],
      role: [8],
      source: [8],
      support_sessions: [514],
      swap_orders: [566],
      system_notification_read_statuses: [616],
      trustNetworkNotificationsByTrust: [732],
      trust_network_notifications: [732],
      trust_networks_by_account: [717],
      trust_networks_by_trust: [717],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_max_fields: {
      account: [8],
      created: [705],
      created_at: [703],
      cred_id: [8],
      device_name: [8],
      id: [804],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_min_fields: {
      account: [8],
      created: [705],
      created_at: [703],
      cred_id: [8],
      device_name: [8],
      id: [804],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_mutation_response: {
      affected_rows: [3],
      returning: [436],
      __typename: [8],
    },
    reg_accounts_obj_rel_insert_input: {
      data: [441],
      on_conflict: [446],
      __typename: [8],
    },
    reg_accounts_on_conflict: {
      constraint: [440],
      update_columns: [453],
      where: [439],
      __typename: [8],
    },
    reg_accounts_order_by: {
      account: [353],
      accounts_information: [53],
      accounts_referrals_aggregate: [69],
      create_account: [353],
      created: [353],
      created_at: [353],
      cred_id: [353],
      device_name: [353],
      devices_aggregate: [199],
      freezed: [353],
      id: [353],
      is_verified: [353],
      login_accounts_aggregate: [235],
      messagesByTo_aggregate: [286],
      messages_aggregate: [286],
      notificationsByFrom_aggregate: [330],
      notifications_aggregate: [330],
      onboarded: [353],
      p2p_offers_aggregate: [362],
      p2p_offers_buyer_aggregate: [362],
      p2p_offers_initiator_aggregate: [362],
      p2p_offers_seller_aggregate: [362],
      p2p_pre_sell_offers_aggregate: [391],
      public_key: [353],
      referrer: [353],
      regAccountsByCancelledBy_aggregate: [362],
      reg_accounts_accounts_information_aggregate: [37],
      reputation_score: [468],
      role: [353],
      source: [353],
      support_sessions_aggregate: [513],
      swap_orders_aggregate: [565],
      system_notification_read_statuses_aggregate: [615],
      trustNetworkNotificationsByTrust_aggregate: [731],
      trust_network_notifications_aggregate: [731],
      trust_networks_by_account_aggregate: [716],
      trust_networks_by_trust_aggregate: [716],
      txid: [353],
      __typename: [8],
    },
    reg_accounts_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    reg_accounts_select_column: {},
    reg_accounts_set_input: {
      account: [8],
      create_account: [0],
      created: [705],
      created_at: [703],
      cred_id: [8],
      device_name: [8],
      freezed: [0],
      id: [804],
      is_verified: [0],
      onboarded: [0],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_stream_cursor_input: {
      initial_value: [452],
      ordering: [193],
      __typename: [8],
    },
    reg_accounts_stream_cursor_value_input: {
      account: [8],
      create_account: [0],
      created: [705],
      created_at: [703],
      cred_id: [8],
      device_name: [8],
      freezed: [0],
      id: [804],
      is_verified: [0],
      onboarded: [0],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_update_column: {},
    reg_accounts_updates: {
      _set: [450],
      where: [439],
      __typename: [8],
    },
    reputation_score: {
      account: [8],
      reg_account: [436],
      score: [351],
      updated_at: [705],
      __typename: [8],
    },
    reputation_score_aggregate: {
      aggregate: [457],
      nodes: [455],
      __typename: [8],
    },
    reputation_score_aggregate_fields: {
      avg: [458],
      count: [
        3,
        {
          columns: [470, '[reputation_score_select_column!]'],
          distinct: [0],
        },
      ],
      max: [463],
      min: [464],
      stddev: [472],
      stddev_pop: [473],
      stddev_samp: [474],
      sum: [477],
      var_pop: [480],
      var_samp: [481],
      variance: [482],
      __typename: [8],
    },
    reputation_score_avg_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_bool_exp: {
      _and: [459],
      _not: [459],
      _or: [459],
      account: [10],
      reg_account: [439],
      score: [352],
      updated_at: [706],
      __typename: [8],
    },
    reputation_score_constraint: {},
    reputation_score_inc_input: {
      score: [351],
      __typename: [8],
    },
    reputation_score_insert_input: {
      account: [8],
      reg_account: [445],
      score: [351],
      updated_at: [705],
      __typename: [8],
    },
    reputation_score_max_fields: {
      account: [8],
      score: [351],
      updated_at: [705],
      __typename: [8],
    },
    reputation_score_min_fields: {
      account: [8],
      score: [351],
      updated_at: [705],
      __typename: [8],
    },
    reputation_score_mutation_response: {
      affected_rows: [3],
      returning: [455],
      __typename: [8],
    },
    reputation_score_obj_rel_insert_input: {
      data: [462],
      on_conflict: [467],
      __typename: [8],
    },
    reputation_score_on_conflict: {
      constraint: [460],
      update_columns: [478],
      where: [459],
      __typename: [8],
    },
    reputation_score_order_by: {
      account: [353],
      reg_account: [447],
      score: [353],
      updated_at: [353],
      __typename: [8],
    },
    reputation_score_pk_columns_input: {
      account: [8],
      __typename: [8],
    },
    reputation_score_select_column: {},
    reputation_score_set_input: {
      account: [8],
      score: [351],
      updated_at: [705],
      __typename: [8],
    },
    reputation_score_stddev_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_stddev_pop_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_stddev_samp_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_stream_cursor_input: {
      initial_value: [476],
      ordering: [193],
      __typename: [8],
    },
    reputation_score_stream_cursor_value_input: {
      account: [8],
      score: [351],
      updated_at: [705],
      __typename: [8],
    },
    reputation_score_sum_fields: {
      score: [351],
      __typename: [8],
    },
    reputation_score_update_column: {},
    reputation_score_updates: {
      _inc: [461],
      _set: [471],
      where: [459],
      __typename: [8],
    },
    reputation_score_var_pop_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_var_samp_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_variance_fields: {
      score: [2],
      __typename: [8],
    },
    request_new_account_input: {
      account: [8],
      country_id: [5],
      create_account: [0],
      cred_id: [8],
      date_of_birth: [8],
      device_name: [8],
      email_address: [8],
      full_name: [8],
      newsletter_subscription: [0],
      phone_number: [8],
      photo_id: [8],
      public_key: [8],
      referrer: [8],
      selfie: [8],
      source: [8],
      __typename: [8],
    },
    request_new_account_output: {
      id: [8],
      __typename: [8],
    },
    selfie_verification_output: {
      error: [0],
      eye: [0],
      face: [0],
      __typename: [8],
    },
    send_email_notification_input: {
      usersData: [11],
      __typename: [8],
    },
    send_email_notification_output: {
      success: [0],
      __typename: [8],
    },
    short_link_input: {
      referrer: [8],
      website: [8],
      __typename: [8],
    },
    signing_requests: {
      createdAt: [705],
      esr: [8],
      id: [804],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [705],
      __typename: [8],
    },
    signing_requests_aggregate: {
      aggregate: [491],
      nodes: [489],
      __typename: [8],
    },
    signing_requests_aggregate_fields: {
      count: [
        3,
        {
          columns: [501, '[signing_requests_select_column!]'],
          distinct: [0],
        },
      ],
      max: [495],
      min: [496],
      __typename: [8],
    },
    signing_requests_bool_exp: {
      _and: [492],
      _not: [492],
      _or: [492],
      createdAt: [706],
      esr: [10],
      id: [805],
      signature: [10],
      signer: [10],
      status: [10],
      transactionId: [10],
      updatedAt: [706],
      __typename: [8],
    },
    signing_requests_constraint: {},
    signing_requests_insert_input: {
      createdAt: [705],
      esr: [8],
      id: [804],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [705],
      __typename: [8],
    },
    signing_requests_max_fields: {
      createdAt: [705],
      esr: [8],
      id: [804],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [705],
      __typename: [8],
    },
    signing_requests_min_fields: {
      createdAt: [705],
      esr: [8],
      id: [804],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [705],
      __typename: [8],
    },
    signing_requests_mutation_response: {
      affected_rows: [3],
      returning: [489],
      __typename: [8],
    },
    signing_requests_on_conflict: {
      constraint: [493],
      update_columns: [505],
      where: [492],
      __typename: [8],
    },
    signing_requests_order_by: {
      createdAt: [353],
      esr: [353],
      id: [353],
      signature: [353],
      signer: [353],
      status: [353],
      transactionId: [353],
      updatedAt: [353],
      __typename: [8],
    },
    signing_requests_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    signing_requests_select_column: {},
    signing_requests_set_input: {
      createdAt: [705],
      esr: [8],
      id: [804],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [705],
      __typename: [8],
    },
    signing_requests_stream_cursor_input: {
      initial_value: [504],
      ordering: [193],
      __typename: [8],
    },
    signing_requests_stream_cursor_value_input: {
      createdAt: [705],
      esr: [8],
      id: [804],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [705],
      __typename: [8],
    },
    signing_requests_update_column: {},
    signing_requests_updates: {
      _set: [502],
      where: [492],
      __typename: [8],
    },
    store_ip_address_output: {
      success: [0],
      __typename: [8],
    },
    support_sessions: {
      assistant: [8],
      closed_by: [8],
      created_at: [705],
      id: [804],
      reg_account: [436],
      session_close_datetime: [705],
      session_open_datetime: [705],
      status: [8],
      updated_at: [705],
      user: [8],
      __typename: [8],
    },
    support_sessions_aggregate: {
      aggregate: [512],
      nodes: [508],
      __typename: [8],
    },
    support_sessions_aggregate_bool_exp: {
      count: [511],
      __typename: [8],
    },
    support_sessions_aggregate_bool_exp_count: {
      arguments: [526],
      distinct: [0],
      filter: [515],
      predicate: [4],
      __typename: [8],
    },
    support_sessions_aggregate_fields: {
      count: [
        3,
        {
          columns: [526, '[support_sessions_select_column!]'],
          distinct: [0],
        },
      ],
      max: [518],
      min: [520],
      __typename: [8],
    },
    support_sessions_aggregate_order_by: {
      count: [353],
      max: [519],
      min: [521],
      __typename: [8],
    },
    support_sessions_arr_rel_insert_input: {
      data: [517],
      on_conflict: [523],
      __typename: [8],
    },
    support_sessions_bool_exp: {
      _and: [515],
      _not: [515],
      _or: [515],
      assistant: [10],
      closed_by: [10],
      created_at: [706],
      id: [805],
      reg_account: [439],
      session_close_datetime: [706],
      session_open_datetime: [706],
      status: [10],
      updated_at: [706],
      user: [10],
      __typename: [8],
    },
    support_sessions_constraint: {},
    support_sessions_insert_input: {
      assistant: [8],
      closed_by: [8],
      created_at: [705],
      id: [804],
      reg_account: [445],
      session_close_datetime: [705],
      session_open_datetime: [705],
      status: [8],
      updated_at: [705],
      user: [8],
      __typename: [8],
    },
    support_sessions_max_fields: {
      assistant: [8],
      closed_by: [8],
      created_at: [705],
      id: [804],
      session_close_datetime: [705],
      session_open_datetime: [705],
      status: [8],
      updated_at: [705],
      user: [8],
      __typename: [8],
    },
    support_sessions_max_order_by: {
      assistant: [353],
      closed_by: [353],
      created_at: [353],
      id: [353],
      session_close_datetime: [353],
      session_open_datetime: [353],
      status: [353],
      updated_at: [353],
      user: [353],
      __typename: [8],
    },
    support_sessions_min_fields: {
      assistant: [8],
      closed_by: [8],
      created_at: [705],
      id: [804],
      session_close_datetime: [705],
      session_open_datetime: [705],
      status: [8],
      updated_at: [705],
      user: [8],
      __typename: [8],
    },
    support_sessions_min_order_by: {
      assistant: [353],
      closed_by: [353],
      created_at: [353],
      id: [353],
      session_close_datetime: [353],
      session_open_datetime: [353],
      status: [353],
      updated_at: [353],
      user: [353],
      __typename: [8],
    },
    support_sessions_mutation_response: {
      affected_rows: [3],
      returning: [508],
      __typename: [8],
    },
    support_sessions_on_conflict: {
      constraint: [516],
      update_columns: [530],
      where: [515],
      __typename: [8],
    },
    support_sessions_order_by: {
      assistant: [353],
      closed_by: [353],
      created_at: [353],
      id: [353],
      reg_account: [447],
      session_close_datetime: [353],
      session_open_datetime: [353],
      status: [353],
      updated_at: [353],
      user: [353],
      __typename: [8],
    },
    support_sessions_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    support_sessions_select_column: {},
    support_sessions_set_input: {
      assistant: [8],
      closed_by: [8],
      created_at: [705],
      id: [804],
      session_close_datetime: [705],
      session_open_datetime: [705],
      status: [8],
      updated_at: [705],
      user: [8],
      __typename: [8],
    },
    support_sessions_stream_cursor_input: {
      initial_value: [529],
      ordering: [193],
      __typename: [8],
    },
    support_sessions_stream_cursor_value_input: {
      assistant: [8],
      closed_by: [8],
      created_at: [705],
      id: [804],
      session_close_datetime: [705],
      session_open_datetime: [705],
      status: [8],
      updated_at: [705],
      user: [8],
      __typename: [8],
    },
    support_sessions_update_column: {},
    support_sessions_updates: {
      _set: [527],
      where: [515],
      __typename: [8],
    },
    swap_assets: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_aggregate: {
      aggregate: [534],
      nodes: [532],
      __typename: [8],
    },
    swap_assets_aggregate_fields: {
      count: [
        3,
        {
          columns: [544, '[swap_assets_select_column!]'],
          distinct: [0],
        },
      ],
      max: [538],
      min: [539],
      __typename: [8],
    },
    swap_assets_bool_exp: {
      _and: [535],
      _not: [535],
      _or: [535],
      active_swaps: [1],
      asset: [10],
      asset_name: [10],
      chain: [10],
      wallet_address: [10],
      __typename: [8],
    },
    swap_assets_constraint: {},
    swap_assets_insert_input: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_max_fields: {
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_min_fields: {
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_mutation_response: {
      affected_rows: [3],
      returning: [532],
      __typename: [8],
    },
    swap_assets_on_conflict: {
      constraint: [536],
      update_columns: [548],
      where: [535],
      __typename: [8],
    },
    swap_assets_order_by: {
      active_swaps: [353],
      asset: [353],
      asset_name: [353],
      chain: [353],
      wallet_address: [353],
      __typename: [8],
    },
    swap_assets_pk_columns_input: {
      asset: [8],
      chain: [8],
      __typename: [8],
    },
    swap_assets_select_column: {},
    swap_assets_set_input: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_stream_cursor_input: {
      initial_value: [547],
      ordering: [193],
      __typename: [8],
    },
    swap_assets_stream_cursor_value_input: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_update_column: {},
    swap_assets_updates: {
      _set: [545],
      where: [535],
      __typename: [8],
    },
    swap_orders: {
      asset: [8],
      asset_amount: [218],
      bitcash_account: [8],
      bitcash_amount: [218],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [705],
      gems_id: [8],
      id: [804],
      messages: [
        281,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messages_aggregate: [
        282,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      order_status: [8],
      order_type: [8],
      price: [218],
      reg_account: [436],
      updated_at: [705],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_aggregate: {
      aggregate: [564],
      nodes: [550],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp: {
      avg: [553],
      corr: [554],
      count: [556],
      covar_samp: [557],
      max: [559],
      min: [560],
      stddev_samp: [561],
      sum: [562],
      var_samp: [563],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_avg: {
      arguments: [583],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_corr: {
      arguments: [555],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_corr_arguments: {
      X: [584],
      Y: [584],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_count: {
      arguments: [582],
      distinct: [0],
      filter: [569],
      predicate: [4],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_covar_samp: {
      arguments: [558],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_covar_samp_arguments: {
      X: [585],
      Y: [585],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_max: {
      arguments: [586],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_min: {
      arguments: [587],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_stddev_samp: {
      arguments: [588],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_sum: {
      arguments: [589],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_var_samp: {
      arguments: [590],
      distinct: [0],
      filter: [569],
      predicate: [219],
      __typename: [8],
    },
    swap_orders_aggregate_fields: {
      avg: [567],
      count: [
        3,
        {
          columns: [582, '[swap_orders_select_column!]'],
          distinct: [0],
        },
      ],
      max: [573],
      min: [575],
      stddev: [592],
      stddev_pop: [594],
      stddev_samp: [596],
      sum: [600],
      var_pop: [604],
      var_samp: [606],
      variance: [608],
      __typename: [8],
    },
    swap_orders_aggregate_order_by: {
      avg: [568],
      count: [353],
      max: [574],
      min: [576],
      stddev: [593],
      stddev_pop: [595],
      stddev_samp: [597],
      sum: [601],
      var_pop: [605],
      var_samp: [607],
      variance: [609],
      __typename: [8],
    },
    swap_orders_arr_rel_insert_input: {
      data: [572],
      on_conflict: [579],
      __typename: [8],
    },
    swap_orders_avg_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_avg_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    swap_orders_bool_exp: {
      _and: [569],
      _not: [569],
      _or: [569],
      asset: [10],
      asset_amount: [219],
      bitcash_account: [10],
      bitcash_amount: [219],
      bitcash_currency: [10],
      bitcash_trx: [10],
      created_at: [706],
      gems_id: [10],
      id: [805],
      messages: [288],
      messages_aggregate: [283],
      order_status: [10],
      order_type: [10],
      price: [219],
      reg_account: [439],
      updated_at: [706],
      wallet_address: [10],
      __typename: [8],
    },
    swap_orders_constraint: {},
    swap_orders_inc_input: {
      asset_amount: [218],
      bitcash_amount: [218],
      price: [218],
      __typename: [8],
    },
    swap_orders_insert_input: {
      asset: [8],
      asset_amount: [218],
      bitcash_account: [8],
      bitcash_amount: [218],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [705],
      gems_id: [8],
      id: [804],
      messages: [287],
      order_status: [8],
      order_type: [8],
      price: [218],
      reg_account: [445],
      updated_at: [705],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_max_fields: {
      asset: [8],
      asset_amount: [218],
      bitcash_account: [8],
      bitcash_amount: [218],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [705],
      gems_id: [8],
      id: [804],
      order_status: [8],
      order_type: [8],
      price: [218],
      updated_at: [705],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_max_order_by: {
      asset: [353],
      asset_amount: [353],
      bitcash_account: [353],
      bitcash_amount: [353],
      bitcash_currency: [353],
      bitcash_trx: [353],
      created_at: [353],
      gems_id: [353],
      id: [353],
      order_status: [353],
      order_type: [353],
      price: [353],
      updated_at: [353],
      wallet_address: [353],
      __typename: [8],
    },
    swap_orders_min_fields: {
      asset: [8],
      asset_amount: [218],
      bitcash_account: [8],
      bitcash_amount: [218],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [705],
      gems_id: [8],
      id: [804],
      order_status: [8],
      order_type: [8],
      price: [218],
      updated_at: [705],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_min_order_by: {
      asset: [353],
      asset_amount: [353],
      bitcash_account: [353],
      bitcash_amount: [353],
      bitcash_currency: [353],
      bitcash_trx: [353],
      created_at: [353],
      gems_id: [353],
      id: [353],
      order_status: [353],
      order_type: [353],
      price: [353],
      updated_at: [353],
      wallet_address: [353],
      __typename: [8],
    },
    swap_orders_mutation_response: {
      affected_rows: [3],
      returning: [550],
      __typename: [8],
    },
    swap_orders_obj_rel_insert_input: {
      data: [572],
      on_conflict: [579],
      __typename: [8],
    },
    swap_orders_on_conflict: {
      constraint: [570],
      update_columns: [602],
      where: [569],
      __typename: [8],
    },
    swap_orders_order_by: {
      asset: [353],
      asset_amount: [353],
      bitcash_account: [353],
      bitcash_amount: [353],
      bitcash_currency: [353],
      bitcash_trx: [353],
      created_at: [353],
      gems_id: [353],
      id: [353],
      messages_aggregate: [286],
      order_status: [353],
      order_type: [353],
      price: [353],
      reg_account: [447],
      updated_at: [353],
      wallet_address: [353],
      __typename: [8],
    },
    swap_orders_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    swap_orders_select_column: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_avg_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_corr_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_covar_samp_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_max_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_min_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_stddev_samp_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_sum_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_var_samp_arguments_columns: {},
    swap_orders_set_input: {
      asset: [8],
      asset_amount: [218],
      bitcash_account: [8],
      bitcash_amount: [218],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [705],
      gems_id: [8],
      id: [804],
      order_status: [8],
      order_type: [8],
      price: [218],
      updated_at: [705],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_stddev_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_stddev_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    swap_orders_stddev_pop_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_stddev_pop_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    swap_orders_stddev_samp_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_stddev_samp_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    swap_orders_stream_cursor_input: {
      initial_value: [599],
      ordering: [193],
      __typename: [8],
    },
    swap_orders_stream_cursor_value_input: {
      asset: [8],
      asset_amount: [218],
      bitcash_account: [8],
      bitcash_amount: [218],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [705],
      gems_id: [8],
      id: [804],
      order_status: [8],
      order_type: [8],
      price: [218],
      updated_at: [705],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_sum_fields: {
      asset_amount: [218],
      bitcash_amount: [218],
      price: [218],
      __typename: [8],
    },
    swap_orders_sum_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    swap_orders_update_column: {},
    swap_orders_updates: {
      _inc: [571],
      _set: [591],
      where: [569],
      __typename: [8],
    },
    swap_orders_var_pop_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_var_pop_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    swap_orders_var_samp_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_var_samp_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    swap_orders_variance_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_variance_order_by: {
      asset_amount: [353],
      bitcash_amount: [353],
      price: [353],
      __typename: [8],
    },
    system_notification_read_status: {
      created_at: [705],
      notification_id: [804],
      regAccountByRegAccount: [436],
      reg_account: [804],
      system_notification: [634],
      __typename: [8],
    },
    system_notification_read_status_aggregate: {
      aggregate: [614],
      nodes: [610],
      __typename: [8],
    },
    system_notification_read_status_aggregate_bool_exp: {
      count: [613],
      __typename: [8],
    },
    system_notification_read_status_aggregate_bool_exp_count: {
      arguments: [628],
      distinct: [0],
      filter: [617],
      predicate: [4],
      __typename: [8],
    },
    system_notification_read_status_aggregate_fields: {
      count: [
        3,
        {
          columns: [628, '[system_notification_read_status_select_column!]'],
          distinct: [0],
        },
      ],
      max: [620],
      min: [622],
      __typename: [8],
    },
    system_notification_read_status_aggregate_order_by: {
      count: [353],
      max: [621],
      min: [623],
      __typename: [8],
    },
    system_notification_read_status_arr_rel_insert_input: {
      data: [619],
      on_conflict: [625],
      __typename: [8],
    },
    system_notification_read_status_bool_exp: {
      _and: [617],
      _not: [617],
      _or: [617],
      created_at: [706],
      notification_id: [805],
      regAccountByRegAccount: [439],
      reg_account: [805],
      system_notification: [641],
      __typename: [8],
    },
    system_notification_read_status_constraint: {},
    system_notification_read_status_insert_input: {
      created_at: [705],
      notification_id: [804],
      regAccountByRegAccount: [445],
      reg_account: [804],
      system_notification: [649],
      __typename: [8],
    },
    system_notification_read_status_max_fields: {
      created_at: [705],
      notification_id: [804],
      reg_account: [804],
      __typename: [8],
    },
    system_notification_read_status_max_order_by: {
      created_at: [353],
      notification_id: [353],
      reg_account: [353],
      __typename: [8],
    },
    system_notification_read_status_min_fields: {
      created_at: [705],
      notification_id: [804],
      reg_account: [804],
      __typename: [8],
    },
    system_notification_read_status_min_order_by: {
      created_at: [353],
      notification_id: [353],
      reg_account: [353],
      __typename: [8],
    },
    system_notification_read_status_mutation_response: {
      affected_rows: [3],
      returning: [610],
      __typename: [8],
    },
    system_notification_read_status_on_conflict: {
      constraint: [618],
      update_columns: [632],
      where: [617],
      __typename: [8],
    },
    system_notification_read_status_order_by: {
      created_at: [353],
      notification_id: [353],
      regAccountByRegAccount: [447],
      reg_account: [353],
      system_notification: [651],
      __typename: [8],
    },
    system_notification_read_status_pk_columns_input: {
      notification_id: [804],
      reg_account: [804],
      __typename: [8],
    },
    system_notification_read_status_select_column: {},
    system_notification_read_status_set_input: {
      created_at: [705],
      notification_id: [804],
      reg_account: [804],
      __typename: [8],
    },
    system_notification_read_status_stream_cursor_input: {
      initial_value: [631],
      ordering: [193],
      __typename: [8],
    },
    system_notification_read_status_stream_cursor_value_input: {
      created_at: [705],
      notification_id: [804],
      reg_account: [804],
      __typename: [8],
    },
    system_notification_read_status_update_column: {},
    system_notification_read_status_updates: {
      _set: [629],
      where: [617],
      __typename: [8],
    },
    system_notifications: {
      created_at: [705],
      id: [804],
      message: [8],
      offer_id: [804],
      p2p_offer: [355],
      system_notification_read_statuses: [
        610,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      system_notification_read_statuses_aggregate: [
        611,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      system_type_notification: [659],
      type: [664],
      updated_at: [705],
      __typename: [8],
    },
    system_notifications_aggregate: {
      aggregate: [638],
      nodes: [634],
      __typename: [8],
    },
    system_notifications_aggregate_bool_exp: {
      count: [637],
      __typename: [8],
    },
    system_notifications_aggregate_bool_exp_count: {
      arguments: [653],
      distinct: [0],
      filter: [641],
      predicate: [4],
      __typename: [8],
    },
    system_notifications_aggregate_fields: {
      count: [
        3,
        {
          columns: [653, '[system_notifications_select_column!]'],
          distinct: [0],
        },
      ],
      max: [644],
      min: [646],
      __typename: [8],
    },
    system_notifications_aggregate_order_by: {
      count: [353],
      max: [645],
      min: [647],
      __typename: [8],
    },
    system_notifications_arr_rel_insert_input: {
      data: [643],
      on_conflict: [650],
      __typename: [8],
    },
    system_notifications_bool_exp: {
      _and: [641],
      _not: [641],
      _or: [641],
      created_at: [706],
      id: [805],
      message: [10],
      offer_id: [805],
      p2p_offer: [364],
      system_notification_read_statuses: [617],
      system_notification_read_statuses_aggregate: [612],
      system_type_notification: [662],
      type: [665],
      updated_at: [706],
      __typename: [8],
    },
    system_notifications_constraint: {},
    system_notifications_insert_input: {
      created_at: [705],
      id: [804],
      message: [8],
      offer_id: [804],
      p2p_offer: [372],
      system_notification_read_statuses: [616],
      system_type_notification: [670],
      type: [664],
      updated_at: [705],
      __typename: [8],
    },
    system_notifications_max_fields: {
      created_at: [705],
      id: [804],
      message: [8],
      offer_id: [804],
      updated_at: [705],
      __typename: [8],
    },
    system_notifications_max_order_by: {
      created_at: [353],
      id: [353],
      message: [353],
      offer_id: [353],
      updated_at: [353],
      __typename: [8],
    },
    system_notifications_min_fields: {
      created_at: [705],
      id: [804],
      message: [8],
      offer_id: [804],
      updated_at: [705],
      __typename: [8],
    },
    system_notifications_min_order_by: {
      created_at: [353],
      id: [353],
      message: [353],
      offer_id: [353],
      updated_at: [353],
      __typename: [8],
    },
    system_notifications_mutation_response: {
      affected_rows: [3],
      returning: [634],
      __typename: [8],
    },
    system_notifications_obj_rel_insert_input: {
      data: [643],
      on_conflict: [650],
      __typename: [8],
    },
    system_notifications_on_conflict: {
      constraint: [642],
      update_columns: [657],
      where: [641],
      __typename: [8],
    },
    system_notifications_order_by: {
      created_at: [353],
      id: [353],
      message: [353],
      offer_id: [353],
      p2p_offer: [374],
      system_notification_read_statuses_aggregate: [615],
      system_type_notification: [672],
      type: [353],
      updated_at: [353],
      __typename: [8],
    },
    system_notifications_pk_columns_input: {
      id: [804],
      __typename: [8],
    },
    system_notifications_select_column: {},
    system_notifications_set_input: {
      created_at: [705],
      id: [804],
      message: [8],
      offer_id: [804],
      type: [664],
      updated_at: [705],
      __typename: [8],
    },
    system_notifications_stream_cursor_input: {
      initial_value: [656],
      ordering: [193],
      __typename: [8],
    },
    system_notifications_stream_cursor_value_input: {
      created_at: [705],
      id: [804],
      message: [8],
      offer_id: [804],
      type: [664],
      updated_at: [705],
      __typename: [8],
    },
    system_notifications_update_column: {},
    system_notifications_updates: {
      _set: [654],
      where: [641],
      __typename: [8],
    },
    system_type_notification: {
      system_notifications: [
        634,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      system_notifications_aggregate: [
        635,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      type: [8],
      __typename: [8],
    },
    system_type_notification_aggregate: {
      aggregate: [661],
      nodes: [659],
      __typename: [8],
    },
    system_type_notification_aggregate_fields: {
      count: [
        3,
        {
          columns: [674, '[system_type_notification_select_column!]'],
          distinct: [0],
        },
      ],
      max: [667],
      min: [668],
      __typename: [8],
    },
    system_type_notification_bool_exp: {
      _and: [662],
      _not: [662],
      _or: [662],
      system_notifications: [641],
      system_notifications_aggregate: [636],
      type: [10],
      __typename: [8],
    },
    system_type_notification_constraint: {},
    system_type_notification_enum: {},
    system_type_notification_enum_comparison_exp: {
      _eq: [664],
      _in: [664],
      _is_null: [0],
      _neq: [664],
      _nin: [664],
      __typename: [8],
    },
    system_type_notification_insert_input: {
      system_notifications: [640],
      type: [8],
      __typename: [8],
    },
    system_type_notification_max_fields: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_min_fields: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_mutation_response: {
      affected_rows: [3],
      returning: [659],
      __typename: [8],
    },
    system_type_notification_obj_rel_insert_input: {
      data: [666],
      on_conflict: [671],
      __typename: [8],
    },
    system_type_notification_on_conflict: {
      constraint: [663],
      update_columns: [678],
      where: [662],
      __typename: [8],
    },
    system_type_notification_order_by: {
      system_notifications_aggregate: [639],
      type: [353],
      __typename: [8],
    },
    system_type_notification_pk_columns_input: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_select_column: {},
    system_type_notification_set_input: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_stream_cursor_input: {
      initial_value: [677],
      ordering: [193],
      __typename: [8],
    },
    system_type_notification_stream_cursor_value_input: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_update_column: {},
    system_type_notification_updates: {
      _set: [675],
      where: [662],
      __typename: [8],
    },
    table_rows: {
      contract: [8],
      data: [
        227,
        {
          path: [8],
        },
      ],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_aggregate: {
      aggregate: [682],
      nodes: [680],
      __typename: [8],
    },
    table_rows_aggregate_fields: {
      count: [
        3,
        {
          columns: [697, '[table_rows_select_column!]'],
          distinct: [0],
        },
      ],
      max: [690],
      min: [691],
      __typename: [8],
    },
    table_rows_append_input: {
      data: [227],
      __typename: [8],
    },
    table_rows_bool_exp: {
      _and: [684],
      _not: [684],
      _or: [684],
      contract: [10],
      data: [229],
      primary_key: [10],
      scope: [10],
      table: [10],
      __typename: [8],
    },
    table_rows_constraint: {},
    table_rows_delete_at_path_input: {
      data: [8],
      __typename: [8],
    },
    table_rows_delete_elem_input: {
      data: [3],
      __typename: [8],
    },
    table_rows_delete_key_input: {
      data: [8],
      __typename: [8],
    },
    table_rows_insert_input: {
      contract: [8],
      data: [227],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_max_fields: {
      contract: [8],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_min_fields: {
      contract: [8],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_mutation_response: {
      affected_rows: [3],
      returning: [680],
      __typename: [8],
    },
    table_rows_on_conflict: {
      constraint: [685],
      update_columns: [701],
      where: [684],
      __typename: [8],
    },
    table_rows_order_by: {
      contract: [353],
      data: [353],
      primary_key: [353],
      scope: [353],
      table: [353],
      __typename: [8],
    },
    table_rows_pk_columns_input: {
      contract: [8],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_prepend_input: {
      data: [227],
      __typename: [8],
    },
    table_rows_select_column: {},
    table_rows_set_input: {
      contract: [8],
      data: [227],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_stream_cursor_input: {
      initial_value: [700],
      ordering: [193],
      __typename: [8],
    },
    table_rows_stream_cursor_value_input: {
      contract: [8],
      data: [227],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_update_column: {},
    table_rows_updates: {
      _append: [683],
      _delete_at_path: [686],
      _delete_elem: [687],
      _delete_key: [688],
      _prepend: [696],
      _set: [698],
      where: [684],
      __typename: [8],
    },
    timestamp: {},
    timestamp_comparison_exp: {
      _eq: [703],
      _gt: [703],
      _gte: [703],
      _in: [703],
      _is_null: [0],
      _lt: [703],
      _lte: [703],
      _neq: [703],
      _nin: [703],
      __typename: [8],
    },
    timestamptz: {},
    timestamptz_comparison_exp: {
      _eq: [705],
      _gt: [705],
      _gte: [705],
      _in: [705],
      _is_null: [0],
      _lt: [705],
      _lte: [705],
      _neq: [705],
      _nin: [705],
      __typename: [8],
    },
    toggle_trust_network_input: {
      account: [8],
      reject: [0],
      __typename: [8],
    },
    toggle_trust_network_output: {
      success: [0],
      __typename: [8],
    },
    trust_network: {
      account: [8],
      created_at: [705],
      is_mutual: [0],
      trust: [8],
      trust_by: [436],
      trust_network: [436],
      __typename: [8],
    },
    trust_network_aggregate: {
      aggregate: [715],
      nodes: [709],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp: {
      bool_and: [712],
      bool_or: [713],
      count: [714],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp_bool_and: {
      arguments: [755],
      distinct: [0],
      filter: [718],
      predicate: [1],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp_bool_or: {
      arguments: [756],
      distinct: [0],
      filter: [718],
      predicate: [1],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp_count: {
      arguments: [754],
      distinct: [0],
      filter: [718],
      predicate: [4],
      __typename: [8],
    },
    trust_network_aggregate_fields: {
      count: [
        3,
        {
          columns: [754, '[trust_network_select_column!]'],
          distinct: [0],
        },
      ],
      max: [721],
      min: [723],
      __typename: [8],
    },
    trust_network_aggregate_order_by: {
      count: [353],
      max: [722],
      min: [724],
      __typename: [8],
    },
    trust_network_arr_rel_insert_input: {
      data: [720],
      on_conflict: [750],
      __typename: [8],
    },
    trust_network_bool_exp: {
      _and: [718],
      _not: [718],
      _or: [718],
      account: [10],
      created_at: [706],
      is_mutual: [1],
      trust: [10],
      trust_by: [439],
      trust_network: [439],
      __typename: [8],
    },
    trust_network_constraint: {},
    trust_network_insert_input: {
      account: [8],
      created_at: [705],
      is_mutual: [0],
      trust: [8],
      trust_by: [445],
      trust_network: [445],
      __typename: [8],
    },
    trust_network_max_fields: {
      account: [8],
      created_at: [705],
      trust: [8],
      __typename: [8],
    },
    trust_network_max_order_by: {
      account: [353],
      created_at: [353],
      trust: [353],
      __typename: [8],
    },
    trust_network_min_fields: {
      account: [8],
      created_at: [705],
      trust: [8],
      __typename: [8],
    },
    trust_network_min_order_by: {
      account: [353],
      created_at: [353],
      trust: [353],
      __typename: [8],
    },
    trust_network_mutation_response: {
      affected_rows: [3],
      returning: [709],
      __typename: [8],
    },
    trust_network_notification: {
      account: [8],
      created_at: [705],
      regAccountByTrust: [436],
      reg_account: [436],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_aggregate: {
      aggregate: [730],
      nodes: [726],
      __typename: [8],
    },
    trust_network_notification_aggregate_bool_exp: {
      count: [729],
      __typename: [8],
    },
    trust_network_notification_aggregate_bool_exp_count: {
      arguments: [744],
      distinct: [0],
      filter: [733],
      predicate: [4],
      __typename: [8],
    },
    trust_network_notification_aggregate_fields: {
      count: [
        3,
        {
          columns: [744, '[trust_network_notification_select_column!]'],
          distinct: [0],
        },
      ],
      max: [736],
      min: [738],
      __typename: [8],
    },
    trust_network_notification_aggregate_order_by: {
      count: [353],
      max: [737],
      min: [739],
      __typename: [8],
    },
    trust_network_notification_arr_rel_insert_input: {
      data: [735],
      on_conflict: [741],
      __typename: [8],
    },
    trust_network_notification_bool_exp: {
      _and: [733],
      _not: [733],
      _or: [733],
      account: [10],
      created_at: [706],
      regAccountByTrust: [439],
      reg_account: [439],
      trust: [10],
      __typename: [8],
    },
    trust_network_notification_constraint: {},
    trust_network_notification_insert_input: {
      account: [8],
      created_at: [705],
      regAccountByTrust: [445],
      reg_account: [445],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_max_fields: {
      account: [8],
      created_at: [705],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_max_order_by: {
      account: [353],
      created_at: [353],
      trust: [353],
      __typename: [8],
    },
    trust_network_notification_min_fields: {
      account: [8],
      created_at: [705],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_min_order_by: {
      account: [353],
      created_at: [353],
      trust: [353],
      __typename: [8],
    },
    trust_network_notification_mutation_response: {
      affected_rows: [3],
      returning: [726],
      __typename: [8],
    },
    trust_network_notification_on_conflict: {
      constraint: [734],
      update_columns: [748],
      where: [733],
      __typename: [8],
    },
    trust_network_notification_order_by: {
      account: [353],
      created_at: [353],
      regAccountByTrust: [447],
      reg_account: [447],
      trust: [353],
      __typename: [8],
    },
    trust_network_notification_pk_columns_input: {
      account: [8],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_select_column: {},
    trust_network_notification_set_input: {
      account: [8],
      created_at: [705],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_stream_cursor_input: {
      initial_value: [747],
      ordering: [193],
      __typename: [8],
    },
    trust_network_notification_stream_cursor_value_input: {
      account: [8],
      created_at: [705],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_update_column: {},
    trust_network_notification_updates: {
      _set: [745],
      where: [733],
      __typename: [8],
    },
    trust_network_on_conflict: {
      constraint: [719],
      update_columns: [778],
      where: [718],
      __typename: [8],
    },
    trust_network_order_by: {
      account: [353],
      created_at: [353],
      is_mutual: [353],
      trust: [353],
      trust_by: [447],
      trust_network: [447],
      __typename: [8],
    },
    trust_network_output: {
      trusted_network: [8],
      __typename: [8],
    },
    trust_network_pk_columns_input: {
      account: [8],
      trust: [8],
      __typename: [8],
    },
    trust_network_select_column: {},
    trust_network_select_column_trust_network_aggregate_bool_exp_bool_and_arguments_columns: {},
    trust_network_select_column_trust_network_aggregate_bool_exp_bool_or_arguments_columns: {},
    trust_network_set_input: {
      account: [8],
      created_at: [705],
      is_mutual: [0],
      trust: [8],
      __typename: [8],
    },
    trust_network_status: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_aggregate: {
      aggregate: [760],
      nodes: [758],
      __typename: [8],
    },
    trust_network_status_aggregate_fields: {
      count: [
        3,
        {
          columns: [770, '[trust_network_status_select_column!]'],
          distinct: [0],
        },
      ],
      max: [764],
      min: [765],
      __typename: [8],
    },
    trust_network_status_bool_exp: {
      _and: [761],
      _not: [761],
      _or: [761],
      status: [10],
      __typename: [8],
    },
    trust_network_status_constraint: {},
    trust_network_status_insert_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_max_fields: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_min_fields: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_mutation_response: {
      affected_rows: [3],
      returning: [758],
      __typename: [8],
    },
    trust_network_status_on_conflict: {
      constraint: [762],
      update_columns: [774],
      where: [761],
      __typename: [8],
    },
    trust_network_status_order_by: {
      status: [353],
      __typename: [8],
    },
    trust_network_status_pk_columns_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_select_column: {},
    trust_network_status_set_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_stream_cursor_input: {
      initial_value: [773],
      ordering: [193],
      __typename: [8],
    },
    trust_network_status_stream_cursor_value_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_update_column: {},
    trust_network_status_updates: {
      _set: [771],
      where: [761],
      __typename: [8],
    },
    trust_network_stream_cursor_input: {
      initial_value: [777],
      ordering: [193],
      __typename: [8],
    },
    trust_network_stream_cursor_value_input: {
      account: [8],
      created_at: [705],
      is_mutual: [0],
      trust: [8],
      __typename: [8],
    },
    trust_network_update_column: {},
    trust_network_updates: {
      _set: [757],
      where: [718],
      __typename: [8],
    },
    tx_statistics: {
      currency: [8],
      date: [705],
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_aggregate: {
      aggregate: [782],
      nodes: [780],
      __typename: [8],
    },
    tx_statistics_aggregate_fields: {
      avg: [783],
      count: [
        3,
        {
          columns: [791, '[tx_statistics_select_column!]'],
          distinct: [0],
        },
      ],
      max: [787],
      min: [788],
      stddev: [793],
      stddev_pop: [794],
      stddev_samp: [795],
      sum: [798],
      var_pop: [800],
      var_samp: [801],
      variance: [802],
      __typename: [8],
    },
    tx_statistics_avg_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_bool_exp: {
      _and: [784],
      _not: [784],
      _or: [784],
      currency: [10],
      date: [706],
      total_transactions: [165],
      total_transactions_cancelled: [165],
      total_transactions_completed: [165],
      __typename: [8],
    },
    tx_statistics_inc_input: {
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_insert_input: {
      currency: [8],
      date: [705],
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_max_fields: {
      currency: [8],
      date: [705],
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_min_fields: {
      currency: [8],
      date: [705],
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_mutation_response: {
      affected_rows: [3],
      returning: [780],
      __typename: [8],
    },
    tx_statistics_order_by: {
      currency: [353],
      date: [353],
      total_transactions: [353],
      total_transactions_cancelled: [353],
      total_transactions_completed: [353],
      __typename: [8],
    },
    tx_statistics_select_column: {},
    tx_statistics_set_input: {
      currency: [8],
      date: [705],
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_stddev_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_stddev_pop_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_stddev_samp_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_stream_cursor_input: {
      initial_value: [797],
      ordering: [193],
      __typename: [8],
    },
    tx_statistics_stream_cursor_value_input: {
      currency: [8],
      date: [705],
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_sum_fields: {
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_updates: {
      _inc: [785],
      _set: [792],
      where: [784],
      __typename: [8],
    },
    tx_statistics_var_pop_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_var_samp_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_variance_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    upload_image_output: {
      success: [0],
      __typename: [8],
    },
    uuid: {},
    uuid_comparison_exp: {
      _eq: [804],
      _gt: [804],
      _gte: [804],
      _in: [804],
      _is_null: [0],
      _lt: [804],
      _lte: [804],
      _neq: [804],
      _nin: [804],
      __typename: [8],
    },
    validate_short_link_input: {
      link: [8],
      __typename: [8],
    },
    validate_short_link_output: {
      count: [3],
      referrer: [8],
      website: [8],
      __typename: [8],
    },
    volume_statistics: {
      currency: [8],
      date: [705],
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_aggregate: {
      aggregate: [810],
      nodes: [808],
      __typename: [8],
    },
    volume_statistics_aggregate_fields: {
      avg: [811],
      count: [
        3,
        {
          columns: [819, '[volume_statistics_select_column!]'],
          distinct: [0],
        },
      ],
      max: [815],
      min: [816],
      stddev: [821],
      stddev_pop: [822],
      stddev_samp: [823],
      sum: [826],
      var_pop: [828],
      var_samp: [829],
      variance: [830],
      __typename: [8],
    },
    volume_statistics_avg_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_bool_exp: {
      _and: [812],
      _not: [812],
      _or: [812],
      currency: [10],
      date: [706],
      total_cancelled: [352],
      total_completed: [352],
      total_completed_not_cancelled: [352],
      __typename: [8],
    },
    volume_statistics_inc_input: {
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_insert_input: {
      currency: [8],
      date: [705],
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_max_fields: {
      currency: [8],
      date: [705],
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_min_fields: {
      currency: [8],
      date: [705],
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_mutation_response: {
      affected_rows: [3],
      returning: [808],
      __typename: [8],
    },
    volume_statistics_order_by: {
      currency: [353],
      date: [353],
      total_cancelled: [353],
      total_completed: [353],
      total_completed_not_cancelled: [353],
      __typename: [8],
    },
    volume_statistics_select_column: {},
    volume_statistics_set_input: {
      currency: [8],
      date: [705],
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_stddev_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_stddev_pop_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_stddev_samp_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_stream_cursor_input: {
      initial_value: [825],
      ordering: [193],
      __typename: [8],
    },
    volume_statistics_stream_cursor_value_input: {
      currency: [8],
      date: [705],
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_sum_fields: {
      total_cancelled: [351],
      total_completed: [351],
      total_completed_not_cancelled: [351],
      __typename: [8],
    },
    volume_statistics_updates: {
      _inc: [813],
      _set: [820],
      where: [812],
      __typename: [8],
    },
    volume_statistics_var_pop_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_var_samp_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_variance_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    wallet_request_output: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [8],
      cancel_request_datetime: [8],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [8],
      id: [8],
      initiator: [8],
      matched: [0],
      matched_datetime: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [8],
      __typename: [8],
    },
    web_push: {
      account: [8],
      auth: [8],
      created_at: [705],
      endpoint: [8],
      expiration_time: [705],
      p256dh: [8],
      __typename: [8],
    },
    web_push_aggregate: {
      aggregate: [834],
      nodes: [832],
      __typename: [8],
    },
    web_push_aggregate_fields: {
      count: [
        3,
        {
          columns: [844, '[web_push_select_column!]'],
          distinct: [0],
        },
      ],
      max: [838],
      min: [839],
      __typename: [8],
    },
    web_push_bool_exp: {
      _and: [835],
      _not: [835],
      _or: [835],
      account: [10],
      auth: [10],
      created_at: [706],
      endpoint: [10],
      expiration_time: [706],
      p256dh: [10],
      __typename: [8],
    },
    web_push_constraint: {},
    web_push_insert_input: {
      account: [8],
      auth: [8],
      created_at: [705],
      endpoint: [8],
      expiration_time: [705],
      p256dh: [8],
      __typename: [8],
    },
    web_push_max_fields: {
      account: [8],
      auth: [8],
      created_at: [705],
      endpoint: [8],
      expiration_time: [705],
      p256dh: [8],
      __typename: [8],
    },
    web_push_min_fields: {
      account: [8],
      auth: [8],
      created_at: [705],
      endpoint: [8],
      expiration_time: [705],
      p256dh: [8],
      __typename: [8],
    },
    web_push_mutation_response: {
      affected_rows: [3],
      returning: [832],
      __typename: [8],
    },
    web_push_on_conflict: {
      constraint: [836],
      update_columns: [848],
      where: [835],
      __typename: [8],
    },
    web_push_order_by: {
      account: [353],
      auth: [353],
      created_at: [353],
      endpoint: [353],
      expiration_time: [353],
      p256dh: [353],
      __typename: [8],
    },
    web_push_pk_columns_input: {
      auth: [8],
      p256dh: [8],
      __typename: [8],
    },
    web_push_select_column: {},
    web_push_set_input: {
      account: [8],
      auth: [8],
      created_at: [705],
      endpoint: [8],
      expiration_time: [705],
      p256dh: [8],
      __typename: [8],
    },
    web_push_stream_cursor_input: {
      initial_value: [847],
      ordering: [193],
      __typename: [8],
    },
    web_push_stream_cursor_value_input: {
      account: [8],
      auth: [8],
      created_at: [705],
      endpoint: [8],
      expiration_time: [705],
      p256dh: [8],
      __typename: [8],
    },
    web_push_update_column: {},
    web_push_updates: {
      _set: [845],
      where: [835],
      __typename: [8],
    },
    Query: {
      account_statistics: [
        12,
        {
          distinct_on: [20, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [19, '[account_statistics_order_by!]'],
          where: [16],
        },
      ],
      account_statistics_aggregate: [
        13,
        {
          distinct_on: [20, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [19, '[account_statistics_order_by!]'],
          where: [16],
        },
      ],
      accounts_information: [
        30,
        {
          distinct_on: [56, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [53, '[accounts_information_order_by!]'],
          where: [40],
        },
      ],
      accounts_information_aggregate: [
        31,
        {
          distinct_on: [56, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [53, '[accounts_information_order_by!]'],
          where: [40],
        },
      ],
      accounts_information_by_pk: [
        30,
        {
          account: [8, 'String!'],
          id: [804, 'uuid!'],
        },
      ],
      accounts_referral: [
        64,
        {
          distinct_on: [85, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [83, '[accounts_referral_order_by!]'],
          where: [73],
        },
      ],
      accounts_referral_aggregate: [
        65,
        {
          distinct_on: [85, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [83, '[accounts_referral_order_by!]'],
          where: [73],
        },
      ],
      accounts_referral_by_pk: [
        64,
        {
          link: [8, 'String!'],
        },
      ],
      actions: [
        105,
        {
          distinct_on: [122, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [119, '[actions_order_by!]'],
          where: [109],
        },
      ],
      actions_aggregate: [
        106,
        {
          distinct_on: [122, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [119, '[actions_order_by!]'],
          where: [109],
        },
      ],
      actions_by_pk: [
        105,
        {
          global_sequence: [8, 'String!'],
        },
      ],
      apollo_auth_health_check: [8],
      apollo_health_check: [0],
      avg_pay_time: [
        128,
        {
          distinct_on: [136, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [135, '[avg_pay_time_order_by!]'],
          where: [132],
        },
      ],
      avg_pay_time_aggregate: [
        129,
        {
          distinct_on: [136, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [135, '[avg_pay_time_order_by!]'],
          where: [132],
        },
      ],
      avg_release_time: [
        146,
        {
          distinct_on: [154, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [153, '[avg_release_time_order_by!]'],
          where: [150],
        },
      ],
      avg_release_time_aggregate: [
        147,
        {
          distinct_on: [154, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [153, '[avg_release_time_order_by!]'],
          where: [150],
        },
      ],
      combined_avg_times: [
        170,
        {
          distinct_on: [178, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [177, '[combined_avg_times_order_by!]'],
          where: [174],
        },
      ],
      combined_avg_times_aggregate: [
        171,
        {
          distinct_on: [178, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [177, '[combined_avg_times_order_by!]'],
          where: [174],
        },
      ],
      devices: [
        194,
        {
          distinct_on: [212, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [210, '[devices_order_by!]'],
          where: [201],
        },
      ],
      devices_aggregate: [
        195,
        {
          distinct_on: [212, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [210, '[devices_order_by!]'],
          where: [201],
        },
      ],
      devices_by_pk: [
        194,
        {
          cred_id: [8, 'String!'],
        },
      ],
      get_inactive_accounts: [
        436,
        {
          args: [220, 'get_inactive_accounts_args!'],
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      get_inactive_accounts_aggregate: [
        437,
        {
          args: [220, 'get_inactive_accounts_args!'],
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      get_login_counts: [
        254,
        {
          args: [221, 'get_login_counts_args!'],
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      get_login_counts_aggregate: [
        255,
        {
          args: [221, 'get_login_counts_args!'],
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      get_p2p_offers_with_old_or_no_messages: [
        355,
        {
          args: [222, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      get_p2p_offers_with_old_or_no_messages_aggregate: [
        356,
        {
          args: [222, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      get_trust_network: [
        752,
        {
          account: [8],
        },
      ],
      id_verification: [
        226,
        {
          id_data: [225],
        },
      ],
      login_account: [
        230,
        {
          distinct_on: [248, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [246, '[login_account_order_by!]'],
          where: [237],
        },
      ],
      login_account_aggregate: [
        231,
        {
          distinct_on: [248, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [246, '[login_account_order_by!]'],
          where: [237],
        },
      ],
      login_account_by_pk: [
        230,
        {
          id: [804, 'uuid!'],
        },
      ],
      login_counts: [
        254,
        {
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      login_counts_aggregate: [
        255,
        {
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      messages: [
        281,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messages_aggregate: [
        282,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messages_by_pk: [
        281,
        {
          id: [804, 'uuid!'],
        },
      ],
      migrate_device: [
        305,
        {
          distinct_on: [317, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [315, '[migrate_device_order_by!]'],
          where: [308],
        },
      ],
      migrate_device_aggregate: [
        306,
        {
          distinct_on: [317, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [315, '[migrate_device_order_by!]'],
          where: [308],
        },
      ],
      migrate_device_by_pk: [
        305,
        {
          cred_id: [8, 'String!'],
        },
      ],
      notifications: [
        323,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      notifications_aggregate: [
        324,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      notifications_by_pk: [
        323,
        {
          id: [804, 'uuid!'],
        },
      ],
      p2p_offers: [
        355,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_aggregate: [
        356,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_by_pk: [
        355,
        {
          id: [804, 'uuid!'],
        },
      ],
      p2p_pre_sell_offers: [
        384,
        {
          distinct_on: [404, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [402, '[p2p_pre_sell_offers_order_by!]'],
          where: [393],
        },
      ],
      p2p_pre_sell_offers_aggregate: [
        385,
        {
          distinct_on: [404, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [402, '[p2p_pre_sell_offers_order_by!]'],
          where: [393],
        },
      ],
      p2p_pre_sell_offers_by_pk: [
        384,
        {
          id: [804, 'uuid!'],
        },
      ],
      photo_id_verification: [
        412,
        {
          photo_id: [8],
        },
      ],
      preferences: [
        413,
        {
          distinct_on: [430, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [427, '[preferences_order_by!]'],
          where: [417],
        },
      ],
      preferences_aggregate: [
        414,
        {
          distinct_on: [430, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [427, '[preferences_order_by!]'],
          where: [417],
        },
      ],
      preferences_by_pk: [
        413,
        {
          account: [8, 'String!'],
        },
      ],
      reg_accounts: [
        436,
        {
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      reg_accounts_aggregate: [
        437,
        {
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      reg_accounts_by_pk: [
        436,
        {
          id: [804, 'uuid!'],
        },
      ],
      reputation_score: [
        455,
        {
          distinct_on: [470, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [468, '[reputation_score_order_by!]'],
          where: [459],
        },
      ],
      reputation_score_aggregate: [
        456,
        {
          distinct_on: [470, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [468, '[reputation_score_order_by!]'],
          where: [459],
        },
      ],
      reputation_score_by_pk: [
        455,
        {
          account: [8, 'String!'],
        },
      ],
      selfie_verification: [
        485,
        {
          selfie: [8],
        },
      ],
      signing_requests: [
        489,
        {
          distinct_on: [501, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [499, '[signing_requests_order_by!]'],
          where: [492],
        },
      ],
      signing_requests_aggregate: [
        490,
        {
          distinct_on: [501, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [499, '[signing_requests_order_by!]'],
          where: [492],
        },
      ],
      signing_requests_by_pk: [
        489,
        {
          id: [804, 'uuid!'],
        },
      ],
      support_sessions: [
        508,
        {
          distinct_on: [526, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [524, '[support_sessions_order_by!]'],
          where: [515],
        },
      ],
      support_sessions_aggregate: [
        509,
        {
          distinct_on: [526, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [524, '[support_sessions_order_by!]'],
          where: [515],
        },
      ],
      support_sessions_by_pk: [
        508,
        {
          id: [804, 'uuid!'],
        },
      ],
      swap_assets: [
        532,
        {
          distinct_on: [544, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [542, '[swap_assets_order_by!]'],
          where: [535],
        },
      ],
      swap_assets_aggregate: [
        533,
        {
          distinct_on: [544, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [542, '[swap_assets_order_by!]'],
          where: [535],
        },
      ],
      swap_assets_by_pk: [
        532,
        {
          asset: [8, 'String!'],
          chain: [8, 'String!'],
        },
      ],
      swap_orders: [
        550,
        {
          distinct_on: [582, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [580, '[swap_orders_order_by!]'],
          where: [569],
        },
      ],
      swap_orders_aggregate: [
        551,
        {
          distinct_on: [582, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [580, '[swap_orders_order_by!]'],
          where: [569],
        },
      ],
      swap_orders_by_pk: [
        550,
        {
          id: [804, 'uuid!'],
        },
      ],
      system_notification_read_status: [
        610,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      system_notification_read_status_aggregate: [
        611,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      system_notification_read_status_by_pk: [
        610,
        {
          notification_id: [804, 'uuid!'],
          reg_account: [804, 'uuid!'],
        },
      ],
      system_notifications: [
        634,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      system_notifications_aggregate: [
        635,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      system_notifications_by_pk: [
        634,
        {
          id: [804, 'uuid!'],
        },
      ],
      system_type_notification: [
        659,
        {
          distinct_on: [674, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [672, '[system_type_notification_order_by!]'],
          where: [662],
        },
      ],
      system_type_notification_aggregate: [
        660,
        {
          distinct_on: [674, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [672, '[system_type_notification_order_by!]'],
          where: [662],
        },
      ],
      system_type_notification_by_pk: [
        659,
        {
          type: [8, 'String!'],
        },
      ],
      table_rows: [
        680,
        {
          distinct_on: [697, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [694, '[table_rows_order_by!]'],
          where: [684],
        },
      ],
      table_rows_aggregate: [
        681,
        {
          distinct_on: [697, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [694, '[table_rows_order_by!]'],
          where: [684],
        },
      ],
      table_rows_by_pk: [
        680,
        {
          contract: [8, 'String!'],
          primary_key: [8, 'String!'],
          scope: [8, 'String!'],
          table: [8, 'String!'],
        },
      ],
      trust_network: [
        709,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      trust_network_aggregate: [
        710,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      trust_network_by_pk: [
        709,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_notification: [
        726,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trust_network_notification_aggregate: [
        727,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trust_network_notification_by_pk: [
        726,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_status: [
        758,
        {
          distinct_on: [770, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [768, '[trust_network_status_order_by!]'],
          where: [761],
        },
      ],
      trust_network_status_aggregate: [
        759,
        {
          distinct_on: [770, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [768, '[trust_network_status_order_by!]'],
          where: [761],
        },
      ],
      trust_network_status_by_pk: [
        758,
        {
          status: [8, 'String!'],
        },
      ],
      tx_statistics: [
        780,
        {
          distinct_on: [791, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [790, '[tx_statistics_order_by!]'],
          where: [784],
        },
      ],
      tx_statistics_aggregate: [
        781,
        {
          distinct_on: [791, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [790, '[tx_statistics_order_by!]'],
          where: [784],
        },
      ],
      volume_statistics: [
        808,
        {
          distinct_on: [819, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [818, '[volume_statistics_order_by!]'],
          where: [812],
        },
      ],
      volume_statistics_aggregate: [
        809,
        {
          distinct_on: [819, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [818, '[volume_statistics_order_by!]'],
          where: [812],
        },
      ],
      web_push: [
        832,
        {
          distinct_on: [844, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [842, '[web_push_order_by!]'],
          where: [835],
        },
      ],
      web_push_aggregate: [
        833,
        {
          distinct_on: [844, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [842, '[web_push_order_by!]'],
          where: [835],
        },
      ],
      web_push_by_pk: [
        832,
        {
          auth: [8, 'String!'],
          p256dh: [8, 'String!'],
        },
      ],
      __typename: [8],
    },
    Mutation: {
      approve_new_account: [
        8,
        {
          is_verified: [0],
          request_id: [8],
        },
      ],
      auto_cancel_p2p_offer: [
        354,
        {
          p2p_data: [167],
        },
      ],
      cancel_p2p_approval: [
        354,
        {
          p2p_data: [166],
        },
      ],
      cancel_p2p_offer: [
        354,
        {
          p2p_data: [167],
        },
      ],
      cancel_wallet_request: [
        831,
        {
          p2p_data: [169],
        },
      ],
      cancel_wallet_request_approval: [
        831,
        {
          p2p_data: [168],
        },
      ],
      confirm_p2p_payment: [
        354,
        {
          p2p_data: [188],
        },
      ],
      confirm_wallet_request_payment: [
        831,
        {
          p2p_data: [189],
        },
      ],
      create_add_bk_account: [
        192,
        {
          create_add_bk_account_input: [190, 'create_add_bk_account_input!'],
        },
      ],
      delete_accounts_information: [
        50,
        {
          where: [40, 'accounts_information_bool_exp!'],
        },
      ],
      delete_accounts_information_by_pk: [
        30,
        {
          account: [8, 'String!'],
          id: [804, 'uuid!'],
        },
      ],
      delete_accounts_referral: [
        81,
        {
          where: [73, 'accounts_referral_bool_exp!'],
        },
      ],
      delete_accounts_referral_by_pk: [
        64,
        {
          link: [8, 'String!'],
        },
      ],
      delete_actions: [
        117,
        {
          where: [109, 'actions_bool_exp!'],
        },
      ],
      delete_actions_by_pk: [
        105,
        {
          global_sequence: [8, 'String!'],
        },
      ],
      delete_devices: [
        208,
        {
          where: [201, 'devices_bool_exp!'],
        },
      ],
      delete_devices_by_pk: [
        194,
        {
          cred_id: [8, 'String!'],
        },
      ],
      delete_login_account: [
        244,
        {
          where: [237, 'login_account_bool_exp!'],
        },
      ],
      delete_login_account_by_pk: [
        230,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_login_counts: [
        263,
        {
          where: [258, 'login_counts_bool_exp!'],
        },
      ],
      delete_messages: [
        295,
        {
          where: [288, 'messages_bool_exp!'],
        },
      ],
      delete_messages_by_pk: [
        281,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_migrate_device: [
        313,
        {
          where: [308, 'migrate_device_bool_exp!'],
        },
      ],
      delete_migrate_device_by_pk: [
        305,
        {
          cred_id: [8, 'String!'],
        },
      ],
      delete_notifications: [
        339,
        {
          where: [332, 'notifications_bool_exp!'],
        },
      ],
      delete_notifications_by_pk: [
        323,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_p2p_offers: [
        371,
        {
          where: [364, 'p2p_offers_bool_exp!'],
        },
      ],
      delete_p2p_offers_by_pk: [
        355,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_p2p_pre_sell_offers: [
        400,
        {
          where: [393, 'p2p_pre_sell_offers_bool_exp!'],
        },
      ],
      delete_p2p_pre_sell_offers_by_pk: [
        384,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_preferences: [
        425,
        {
          where: [417, 'preferences_bool_exp!'],
        },
      ],
      delete_preferences_by_pk: [
        413,
        {
          account: [8, 'String!'],
        },
      ],
      delete_reg_accounts: [
        444,
        {
          where: [439, 'reg_accounts_bool_exp!'],
        },
      ],
      delete_reg_accounts_by_pk: [
        436,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_reputation_score: [
        465,
        {
          where: [459, 'reputation_score_bool_exp!'],
        },
      ],
      delete_reputation_score_by_pk: [
        455,
        {
          account: [8, 'String!'],
        },
      ],
      delete_signing_requests: [
        497,
        {
          where: [492, 'signing_requests_bool_exp!'],
        },
      ],
      delete_signing_requests_by_pk: [
        489,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_support_sessions: [
        522,
        {
          where: [515, 'support_sessions_bool_exp!'],
        },
      ],
      delete_support_sessions_by_pk: [
        508,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_swap_assets: [
        540,
        {
          where: [535, 'swap_assets_bool_exp!'],
        },
      ],
      delete_swap_assets_by_pk: [
        532,
        {
          asset: [8, 'String!'],
          chain: [8, 'String!'],
        },
      ],
      delete_swap_orders: [
        577,
        {
          where: [569, 'swap_orders_bool_exp!'],
        },
      ],
      delete_swap_orders_by_pk: [
        550,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_system_notification_read_status: [
        624,
        {
          where: [617, 'system_notification_read_status_bool_exp!'],
        },
      ],
      delete_system_notification_read_status_by_pk: [
        610,
        {
          notification_id: [804, 'uuid!'],
          reg_account: [804, 'uuid!'],
        },
      ],
      delete_system_notifications: [
        648,
        {
          where: [641, 'system_notifications_bool_exp!'],
        },
      ],
      delete_system_notifications_by_pk: [
        634,
        {
          id: [804, 'uuid!'],
        },
      ],
      delete_system_type_notification: [
        669,
        {
          where: [662, 'system_type_notification_bool_exp!'],
        },
      ],
      delete_system_type_notification_by_pk: [
        659,
        {
          type: [8, 'String!'],
        },
      ],
      delete_table_rows: [
        692,
        {
          where: [684, 'table_rows_bool_exp!'],
        },
      ],
      delete_table_rows_by_pk: [
        680,
        {
          contract: [8, 'String!'],
          primary_key: [8, 'String!'],
          scope: [8, 'String!'],
          table: [8, 'String!'],
        },
      ],
      delete_trust_network: [
        725,
        {
          where: [718, 'trust_network_bool_exp!'],
        },
      ],
      delete_trust_network_by_pk: [
        709,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      delete_trust_network_notification: [
        740,
        {
          where: [733, 'trust_network_notification_bool_exp!'],
        },
      ],
      delete_trust_network_notification_by_pk: [
        726,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      delete_trust_network_status: [
        766,
        {
          where: [761, 'trust_network_status_bool_exp!'],
        },
      ],
      delete_trust_network_status_by_pk: [
        758,
        {
          status: [8, 'String!'],
        },
      ],
      delete_tx_statistics: [
        789,
        {
          where: [784, 'tx_statistics_bool_exp!'],
        },
      ],
      delete_volume_statistics: [
        817,
        {
          where: [812, 'volume_statistics_bool_exp!'],
        },
      ],
      delete_web_push: [
        840,
        {
          where: [835, 'web_push_bool_exp!'],
        },
      ],
      delete_web_push_by_pk: [
        832,
        {
          auth: [8, 'String!'],
          p256dh: [8, 'String!'],
        },
      ],
      generate_short_link: [
        8,
        {
          short_link_input: [488, 'short_link_input!'],
        },
      ],
      get_tx_statistics: [
        780,
        {
          args: [223, 'get_tx_statistics_args!'],
          distinct_on: [791, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [790, '[tx_statistics_order_by!]'],
          where: [784],
        },
      ],
      get_volume_statistics: [
        808,
        {
          args: [224, 'get_volume_statistics_args!'],
          distinct_on: [819, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [818, '[volume_statistics_order_by!]'],
          where: [812],
        },
      ],
      insert_accounts_information: [
        50,
        {
          objects: [45, '[accounts_information_insert_input!]!'],
          on_conflict: [52],
        },
      ],
      insert_accounts_information_one: [
        30,
        {
          object: [45, 'accounts_information_insert_input!'],
          on_conflict: [52],
        },
      ],
      insert_accounts_referral: [
        81,
        {
          objects: [76, '[accounts_referral_insert_input!]!'],
          on_conflict: [82],
        },
      ],
      insert_accounts_referral_one: [
        64,
        {
          object: [76, 'accounts_referral_insert_input!'],
          on_conflict: [82],
        },
      ],
      insert_actions: [
        117,
        {
          objects: [114, '[actions_insert_input!]!'],
          on_conflict: [118],
        },
      ],
      insert_actions_one: [
        105,
        {
          object: [114, 'actions_insert_input!'],
          on_conflict: [118],
        },
      ],
      insert_devices: [
        208,
        {
          objects: [203, '[devices_insert_input!]!'],
          on_conflict: [209],
        },
      ],
      insert_devices_one: [
        194,
        {
          object: [203, 'devices_insert_input!'],
          on_conflict: [209],
        },
      ],
      insert_login_account: [
        244,
        {
          objects: [239, '[login_account_insert_input!]!'],
          on_conflict: [245],
        },
      ],
      insert_login_account_one: [
        230,
        {
          object: [239, 'login_account_insert_input!'],
          on_conflict: [245],
        },
      ],
      insert_login_counts: [
        263,
        {
          objects: [260, '[login_counts_insert_input!]!'],
        },
      ],
      insert_login_counts_one: [
        254,
        {
          object: [260, 'login_counts_insert_input!'],
        },
      ],
      insert_messages: [
        295,
        {
          objects: [290, '[messages_insert_input!]!'],
          on_conflict: [296],
        },
      ],
      insert_messages_one: [
        281,
        {
          object: [290, 'messages_insert_input!'],
          on_conflict: [296],
        },
      ],
      insert_migrate_device: [
        313,
        {
          objects: [310, '[migrate_device_insert_input!]!'],
          on_conflict: [314],
        },
      ],
      insert_migrate_device_one: [
        305,
        {
          object: [310, 'migrate_device_insert_input!'],
          on_conflict: [314],
        },
      ],
      insert_notifications: [
        339,
        {
          objects: [334, '[notifications_insert_input!]!'],
          on_conflict: [340],
        },
      ],
      insert_notifications_one: [
        323,
        {
          object: [334, 'notifications_insert_input!'],
          on_conflict: [340],
        },
      ],
      insert_p2p_offers: [
        371,
        {
          objects: [366, '[p2p_offers_insert_input!]!'],
          on_conflict: [373],
        },
      ],
      insert_p2p_offers_one: [
        355,
        {
          object: [366, 'p2p_offers_insert_input!'],
          on_conflict: [373],
        },
      ],
      insert_p2p_pre_sell_offers: [
        400,
        {
          objects: [395, '[p2p_pre_sell_offers_insert_input!]!'],
          on_conflict: [401],
        },
      ],
      insert_p2p_pre_sell_offers_one: [
        384,
        {
          object: [395, 'p2p_pre_sell_offers_insert_input!'],
          on_conflict: [401],
        },
      ],
      insert_preferences: [
        425,
        {
          objects: [422, '[preferences_insert_input!]!'],
          on_conflict: [426],
        },
      ],
      insert_preferences_one: [
        413,
        {
          object: [422, 'preferences_insert_input!'],
          on_conflict: [426],
        },
      ],
      insert_reg_accounts: [
        444,
        {
          objects: [441, '[reg_accounts_insert_input!]!'],
          on_conflict: [446],
        },
      ],
      insert_reg_accounts_one: [
        436,
        {
          object: [441, 'reg_accounts_insert_input!'],
          on_conflict: [446],
        },
      ],
      insert_reputation_score: [
        465,
        {
          objects: [462, '[reputation_score_insert_input!]!'],
          on_conflict: [467],
        },
      ],
      insert_reputation_score_one: [
        455,
        {
          object: [462, 'reputation_score_insert_input!'],
          on_conflict: [467],
        },
      ],
      insert_signing_requests: [
        497,
        {
          objects: [494, '[signing_requests_insert_input!]!'],
          on_conflict: [498],
        },
      ],
      insert_signing_requests_one: [
        489,
        {
          object: [494, 'signing_requests_insert_input!'],
          on_conflict: [498],
        },
      ],
      insert_support_sessions: [
        522,
        {
          objects: [517, '[support_sessions_insert_input!]!'],
          on_conflict: [523],
        },
      ],
      insert_support_sessions_one: [
        508,
        {
          object: [517, 'support_sessions_insert_input!'],
          on_conflict: [523],
        },
      ],
      insert_swap_assets: [
        540,
        {
          objects: [537, '[swap_assets_insert_input!]!'],
          on_conflict: [541],
        },
      ],
      insert_swap_assets_one: [
        532,
        {
          object: [537, 'swap_assets_insert_input!'],
          on_conflict: [541],
        },
      ],
      insert_swap_orders: [
        577,
        {
          objects: [572, '[swap_orders_insert_input!]!'],
          on_conflict: [579],
        },
      ],
      insert_swap_orders_one: [
        550,
        {
          object: [572, 'swap_orders_insert_input!'],
          on_conflict: [579],
        },
      ],
      insert_system_notification_read_status: [
        624,
        {
          objects: [619, '[system_notification_read_status_insert_input!]!'],
          on_conflict: [625],
        },
      ],
      insert_system_notification_read_status_one: [
        610,
        {
          object: [619, 'system_notification_read_status_insert_input!'],
          on_conflict: [625],
        },
      ],
      insert_system_notifications: [
        648,
        {
          objects: [643, '[system_notifications_insert_input!]!'],
          on_conflict: [650],
        },
      ],
      insert_system_notifications_one: [
        634,
        {
          object: [643, 'system_notifications_insert_input!'],
          on_conflict: [650],
        },
      ],
      insert_system_type_notification: [
        669,
        {
          objects: [666, '[system_type_notification_insert_input!]!'],
          on_conflict: [671],
        },
      ],
      insert_system_type_notification_one: [
        659,
        {
          object: [666, 'system_type_notification_insert_input!'],
          on_conflict: [671],
        },
      ],
      insert_table_rows: [
        692,
        {
          objects: [689, '[table_rows_insert_input!]!'],
          on_conflict: [693],
        },
      ],
      insert_table_rows_one: [
        680,
        {
          object: [689, 'table_rows_insert_input!'],
          on_conflict: [693],
        },
      ],
      insert_trust_network: [
        725,
        {
          objects: [720, '[trust_network_insert_input!]!'],
          on_conflict: [750],
        },
      ],
      insert_trust_network_notification: [
        740,
        {
          objects: [735, '[trust_network_notification_insert_input!]!'],
          on_conflict: [741],
        },
      ],
      insert_trust_network_notification_one: [
        726,
        {
          object: [735, 'trust_network_notification_insert_input!'],
          on_conflict: [741],
        },
      ],
      insert_trust_network_one: [
        709,
        {
          object: [720, 'trust_network_insert_input!'],
          on_conflict: [750],
        },
      ],
      insert_trust_network_status: [
        766,
        {
          objects: [763, '[trust_network_status_insert_input!]!'],
          on_conflict: [767],
        },
      ],
      insert_trust_network_status_one: [
        758,
        {
          object: [763, 'trust_network_status_insert_input!'],
          on_conflict: [767],
        },
      ],
      insert_tx_statistics: [
        789,
        {
          objects: [786, '[tx_statistics_insert_input!]!'],
        },
      ],
      insert_tx_statistics_one: [
        780,
        {
          object: [786, 'tx_statistics_insert_input!'],
        },
      ],
      insert_volume_statistics: [
        817,
        {
          objects: [814, '[volume_statistics_insert_input!]!'],
        },
      ],
      insert_volume_statistics_one: [
        808,
        {
          object: [814, 'volume_statistics_insert_input!'],
        },
      ],
      insert_web_push: [
        840,
        {
          objects: [837, '[web_push_insert_input!]!'],
          on_conflict: [841],
        },
      ],
      insert_web_push_one: [
        832,
        {
          object: [837, 'web_push_insert_input!'],
          on_conflict: [841],
        },
      ],
      make_p2p_buy_offer: [
        354,
        {
          offer: [277],
        },
      ],
      make_p2p_sell_offer: [
        354,
        {
          offer: [278],
        },
      ],
      make_wallet_deposit_request: [
        831,
        {
          offer: [279],
        },
      ],
      make_wallet_withdraw_request: [
        831,
        {
          offer: [280],
        },
      ],
      reject_new_account: [
        8,
        {
          request_id: [8, 'String!'],
        },
      ],
      request_new_account: [
        484,
        {
          account_data: [483],
        },
      ],
      reserve_offer: [
        0,
        {
          offerId: [8, 'String!'],
        },
      ],
      retry_account_kyc: [
        0,
        {
          request_id: [8, 'String!'],
        },
      ],
      send_email_notification: [
        487,
        {
          send_email_notification_input: [486, 'send_email_notification_input!'],
        },
      ],
      send_push_notification: [0],
      store_ip_address: [
        507,
        {
          account: [8],
        },
      ],
      subscribe_push_notification: [
        0,
        {
          subscription: [6, 'PushSubscriptionInput!'],
        },
      ],
      toggle_trust_network: [
        708,
        {
          input: [707],
        },
      ],
      unreserve_offer: [
        0,
        {
          offerId: [8, 'String!'],
        },
      ],
      update_accounts_information: [
        50,
        {
          _append: [38],
          _delete_at_path: [42],
          _delete_elem: [43],
          _delete_key: [44],
          _prepend: [55],
          _set: [59],
          where: [40, 'accounts_information_bool_exp!'],
        },
      ],
      update_accounts_information_by_pk: [
        30,
        {
          _append: [38],
          _delete_at_path: [42],
          _delete_elem: [43],
          _delete_key: [44],
          _prepend: [55],
          _set: [59],
          pk_columns: [54, 'accounts_information_pk_columns_input!'],
        },
      ],
      update_accounts_information_many: [
        50,
        {
          updates: [63, '[accounts_information_updates!]!'],
        },
      ],
      update_accounts_referral: [
        81,
        {
          _inc: [75],
          _set: [86],
          where: [73, 'accounts_referral_bool_exp!'],
        },
      ],
      update_accounts_referral_by_pk: [
        64,
        {
          _inc: [75],
          _set: [86],
          pk_columns: [84, 'accounts_referral_pk_columns_input!'],
        },
      ],
      update_accounts_referral_many: [
        81,
        {
          updates: [98, '[accounts_referral_updates!]!'],
        },
      ],
      update_actions: [
        117,
        {
          _append: [108],
          _delete_at_path: [111],
          _delete_elem: [112],
          _delete_key: [113],
          _prepend: [121],
          _set: [123],
          where: [109, 'actions_bool_exp!'],
        },
      ],
      update_actions_by_pk: [
        105,
        {
          _append: [108],
          _delete_at_path: [111],
          _delete_elem: [112],
          _delete_key: [113],
          _prepend: [121],
          _set: [123],
          pk_columns: [120, 'actions_pk_columns_input!'],
        },
      ],
      update_actions_many: [
        117,
        {
          updates: [127, '[actions_updates!]!'],
        },
      ],
      update_devices: [
        208,
        {
          _set: [213],
          where: [201, 'devices_bool_exp!'],
        },
      ],
      update_devices_by_pk: [
        194,
        {
          _set: [213],
          pk_columns: [211, 'devices_pk_columns_input!'],
        },
      ],
      update_devices_many: [
        208,
        {
          updates: [217, '[devices_updates!]!'],
        },
      ],
      update_login_account: [
        244,
        {
          _set: [249],
          where: [237, 'login_account_bool_exp!'],
        },
      ],
      update_login_account_by_pk: [
        230,
        {
          _set: [249],
          pk_columns: [247, 'login_account_pk_columns_input!'],
        },
      ],
      update_login_account_many: [
        244,
        {
          updates: [253, '[login_account_updates!]!'],
        },
      ],
      update_login_counts: [
        263,
        {
          _inc: [259],
          _set: [266],
          where: [258, 'login_counts_bool_exp!'],
        },
      ],
      update_login_counts_many: [
        263,
        {
          updates: [273, '[login_counts_updates!]!'],
        },
      ],
      update_messages: [
        295,
        {
          _set: [300],
          where: [288, 'messages_bool_exp!'],
        },
      ],
      update_messages_by_pk: [
        281,
        {
          _set: [300],
          pk_columns: [298, 'messages_pk_columns_input!'],
        },
      ],
      update_messages_many: [
        295,
        {
          updates: [304, '[messages_updates!]!'],
        },
      ],
      update_migrate_device: [
        313,
        {
          _set: [318],
          where: [308, 'migrate_device_bool_exp!'],
        },
      ],
      update_migrate_device_by_pk: [
        305,
        {
          _set: [318],
          pk_columns: [316, 'migrate_device_pk_columns_input!'],
        },
      ],
      update_migrate_device_many: [
        313,
        {
          updates: [322, '[migrate_device_updates!]!'],
        },
      ],
      update_notifications: [
        339,
        {
          _set: [346],
          where: [332, 'notifications_bool_exp!'],
        },
      ],
      update_notifications_by_pk: [
        323,
        {
          _set: [346],
          pk_columns: [342, 'notifications_pk_columns_input!'],
        },
      ],
      update_notifications_many: [
        339,
        {
          updates: [350, '[notifications_updates!]!'],
        },
      ],
      update_p2p_offers: [
        371,
        {
          _set: [379],
          where: [364, 'p2p_offers_bool_exp!'],
        },
      ],
      update_p2p_offers_by_pk: [
        355,
        {
          _set: [379],
          pk_columns: [375, 'p2p_offers_pk_columns_input!'],
        },
      ],
      update_p2p_offers_many: [
        371,
        {
          updates: [383, '[p2p_offers_updates!]!'],
        },
      ],
      update_p2p_pre_sell_offers: [
        400,
        {
          _set: [407],
          where: [393, 'p2p_pre_sell_offers_bool_exp!'],
        },
      ],
      update_p2p_pre_sell_offers_by_pk: [
        384,
        {
          _set: [407],
          pk_columns: [403, 'p2p_pre_sell_offers_pk_columns_input!'],
        },
      ],
      update_p2p_pre_sell_offers_many: [
        400,
        {
          updates: [411, '[p2p_pre_sell_offers_updates!]!'],
        },
      ],
      update_preferences: [
        425,
        {
          _append: [416],
          _delete_at_path: [419],
          _delete_elem: [420],
          _delete_key: [421],
          _prepend: [429],
          _set: [431],
          where: [417, 'preferences_bool_exp!'],
        },
      ],
      update_preferences_by_pk: [
        413,
        {
          _append: [416],
          _delete_at_path: [419],
          _delete_elem: [420],
          _delete_key: [421],
          _prepend: [429],
          _set: [431],
          pk_columns: [428, 'preferences_pk_columns_input!'],
        },
      ],
      update_preferences_many: [
        425,
        {
          updates: [435, '[preferences_updates!]!'],
        },
      ],
      update_reg_accounts: [
        444,
        {
          _set: [450],
          where: [439, 'reg_accounts_bool_exp!'],
        },
      ],
      update_reg_accounts_by_pk: [
        436,
        {
          _set: [450],
          pk_columns: [448, 'reg_accounts_pk_columns_input!'],
        },
      ],
      update_reg_accounts_many: [
        444,
        {
          updates: [454, '[reg_accounts_updates!]!'],
        },
      ],
      update_reputation_score: [
        465,
        {
          _inc: [461],
          _set: [471],
          where: [459, 'reputation_score_bool_exp!'],
        },
      ],
      update_reputation_score_by_pk: [
        455,
        {
          _inc: [461],
          _set: [471],
          pk_columns: [469, 'reputation_score_pk_columns_input!'],
        },
      ],
      update_reputation_score_many: [
        465,
        {
          updates: [479, '[reputation_score_updates!]!'],
        },
      ],
      update_signing_requests: [
        497,
        {
          _set: [502],
          where: [492, 'signing_requests_bool_exp!'],
        },
      ],
      update_signing_requests_by_pk: [
        489,
        {
          _set: [502],
          pk_columns: [500, 'signing_requests_pk_columns_input!'],
        },
      ],
      update_signing_requests_many: [
        497,
        {
          updates: [506, '[signing_requests_updates!]!'],
        },
      ],
      update_support_sessions: [
        522,
        {
          _set: [527],
          where: [515, 'support_sessions_bool_exp!'],
        },
      ],
      update_support_sessions_by_pk: [
        508,
        {
          _set: [527],
          pk_columns: [525, 'support_sessions_pk_columns_input!'],
        },
      ],
      update_support_sessions_many: [
        522,
        {
          updates: [531, '[support_sessions_updates!]!'],
        },
      ],
      update_swap_assets: [
        540,
        {
          _set: [545],
          where: [535, 'swap_assets_bool_exp!'],
        },
      ],
      update_swap_assets_by_pk: [
        532,
        {
          _set: [545],
          pk_columns: [543, 'swap_assets_pk_columns_input!'],
        },
      ],
      update_swap_assets_many: [
        540,
        {
          updates: [549, '[swap_assets_updates!]!'],
        },
      ],
      update_swap_orders: [
        577,
        {
          _inc: [571],
          _set: [591],
          where: [569, 'swap_orders_bool_exp!'],
        },
      ],
      update_swap_orders_by_pk: [
        550,
        {
          _inc: [571],
          _set: [591],
          pk_columns: [581, 'swap_orders_pk_columns_input!'],
        },
      ],
      update_swap_orders_many: [
        577,
        {
          updates: [603, '[swap_orders_updates!]!'],
        },
      ],
      update_system_notification_read_status: [
        624,
        {
          _set: [629],
          where: [617, 'system_notification_read_status_bool_exp!'],
        },
      ],
      update_system_notification_read_status_by_pk: [
        610,
        {
          _set: [629],
          pk_columns: [627, 'system_notification_read_status_pk_columns_input!'],
        },
      ],
      update_system_notification_read_status_many: [
        624,
        {
          updates: [633, '[system_notification_read_status_updates!]!'],
        },
      ],
      update_system_notifications: [
        648,
        {
          _set: [654],
          where: [641, 'system_notifications_bool_exp!'],
        },
      ],
      update_system_notifications_by_pk: [
        634,
        {
          _set: [654],
          pk_columns: [652, 'system_notifications_pk_columns_input!'],
        },
      ],
      update_system_notifications_many: [
        648,
        {
          updates: [658, '[system_notifications_updates!]!'],
        },
      ],
      update_system_type_notification: [
        669,
        {
          _set: [675],
          where: [662, 'system_type_notification_bool_exp!'],
        },
      ],
      update_system_type_notification_by_pk: [
        659,
        {
          _set: [675],
          pk_columns: [673, 'system_type_notification_pk_columns_input!'],
        },
      ],
      update_system_type_notification_many: [
        669,
        {
          updates: [679, '[system_type_notification_updates!]!'],
        },
      ],
      update_table_rows: [
        692,
        {
          _append: [683],
          _delete_at_path: [686],
          _delete_elem: [687],
          _delete_key: [688],
          _prepend: [696],
          _set: [698],
          where: [684, 'table_rows_bool_exp!'],
        },
      ],
      update_table_rows_by_pk: [
        680,
        {
          _append: [683],
          _delete_at_path: [686],
          _delete_elem: [687],
          _delete_key: [688],
          _prepend: [696],
          _set: [698],
          pk_columns: [695, 'table_rows_pk_columns_input!'],
        },
      ],
      update_table_rows_many: [
        692,
        {
          updates: [702, '[table_rows_updates!]!'],
        },
      ],
      update_trust_network: [
        725,
        {
          _set: [757],
          where: [718, 'trust_network_bool_exp!'],
        },
      ],
      update_trust_network_by_pk: [
        709,
        {
          _set: [757],
          pk_columns: [753, 'trust_network_pk_columns_input!'],
        },
      ],
      update_trust_network_many: [
        725,
        {
          updates: [779, '[trust_network_updates!]!'],
        },
      ],
      update_trust_network_notification: [
        740,
        {
          _set: [745],
          where: [733, 'trust_network_notification_bool_exp!'],
        },
      ],
      update_trust_network_notification_by_pk: [
        726,
        {
          _set: [745],
          pk_columns: [743, 'trust_network_notification_pk_columns_input!'],
        },
      ],
      update_trust_network_notification_many: [
        740,
        {
          updates: [749, '[trust_network_notification_updates!]!'],
        },
      ],
      update_trust_network_status: [
        766,
        {
          _set: [771],
          where: [761, 'trust_network_status_bool_exp!'],
        },
      ],
      update_trust_network_status_by_pk: [
        758,
        {
          _set: [771],
          pk_columns: [769, 'trust_network_status_pk_columns_input!'],
        },
      ],
      update_trust_network_status_many: [
        766,
        {
          updates: [775, '[trust_network_status_updates!]!'],
        },
      ],
      update_tx_statistics: [
        789,
        {
          _inc: [785],
          _set: [792],
          where: [784, 'tx_statistics_bool_exp!'],
        },
      ],
      update_tx_statistics_many: [
        789,
        {
          updates: [799, '[tx_statistics_updates!]!'],
        },
      ],
      update_volume_statistics: [
        817,
        {
          _inc: [813],
          _set: [820],
          where: [812, 'volume_statistics_bool_exp!'],
        },
      ],
      update_volume_statistics_many: [
        817,
        {
          updates: [827, '[volume_statistics_updates!]!'],
        },
      ],
      update_web_push: [
        840,
        {
          _set: [845],
          where: [835, 'web_push_bool_exp!'],
        },
      ],
      update_web_push_by_pk: [
        832,
        {
          _set: [845],
          pk_columns: [843, 'web_push_pk_columns_input!'],
        },
      ],
      update_web_push_many: [
        840,
        {
          updates: [849, '[web_push_updates!]!'],
        },
      ],
      upload_image: [
        803,
        {
          account: [8, 'String!'],
          image: [8, 'String!'],
          selfie: [0],
        },
      ],
      validate_short_link: [
        807,
        {
          validate_short_link_input: [806, 'validate_short_link_input!'],
        },
      ],
      __typename: [8],
    },
    Subscription: {
      account_statistics: [
        12,
        {
          distinct_on: [20, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [19, '[account_statistics_order_by!]'],
          where: [16],
        },
      ],
      account_statistics_aggregate: [
        13,
        {
          distinct_on: [20, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [19, '[account_statistics_order_by!]'],
          where: [16],
        },
      ],
      account_statistics_stream: [
        12,
        {
          batch_size: [3, 'Int!'],
          cursor: [24, '[account_statistics_stream_cursor_input]!'],
          where: [16],
        },
      ],
      accounts_information: [
        30,
        {
          distinct_on: [56, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [53, '[accounts_information_order_by!]'],
          where: [40],
        },
      ],
      accounts_information_aggregate: [
        31,
        {
          distinct_on: [56, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [53, '[accounts_information_order_by!]'],
          where: [40],
        },
      ],
      accounts_information_by_pk: [
        30,
        {
          account: [8, 'String!'],
          id: [804, 'uuid!'],
        },
      ],
      accounts_information_stream: [
        30,
        {
          batch_size: [3, 'Int!'],
          cursor: [60, '[accounts_information_stream_cursor_input]!'],
          where: [40],
        },
      ],
      accounts_referral: [
        64,
        {
          distinct_on: [85, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [83, '[accounts_referral_order_by!]'],
          where: [73],
        },
      ],
      accounts_referral_aggregate: [
        65,
        {
          distinct_on: [85, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [83, '[accounts_referral_order_by!]'],
          where: [73],
        },
      ],
      accounts_referral_by_pk: [
        64,
        {
          link: [8, 'String!'],
        },
      ],
      accounts_referral_stream: [
        64,
        {
          batch_size: [3, 'Int!'],
          cursor: [93, '[accounts_referral_stream_cursor_input]!'],
          where: [73],
        },
      ],
      actions: [
        105,
        {
          distinct_on: [122, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [119, '[actions_order_by!]'],
          where: [109],
        },
      ],
      actions_aggregate: [
        106,
        {
          distinct_on: [122, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [119, '[actions_order_by!]'],
          where: [109],
        },
      ],
      actions_by_pk: [
        105,
        {
          global_sequence: [8, 'String!'],
        },
      ],
      actions_stream: [
        105,
        {
          batch_size: [3, 'Int!'],
          cursor: [124, '[actions_stream_cursor_input]!'],
          where: [109],
        },
      ],
      avg_pay_time: [
        128,
        {
          distinct_on: [136, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [135, '[avg_pay_time_order_by!]'],
          where: [132],
        },
      ],
      avg_pay_time_aggregate: [
        129,
        {
          distinct_on: [136, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [135, '[avg_pay_time_order_by!]'],
          where: [132],
        },
      ],
      avg_pay_time_stream: [
        128,
        {
          batch_size: [3, 'Int!'],
          cursor: [140, '[avg_pay_time_stream_cursor_input]!'],
          where: [132],
        },
      ],
      avg_release_time: [
        146,
        {
          distinct_on: [154, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [153, '[avg_release_time_order_by!]'],
          where: [150],
        },
      ],
      avg_release_time_aggregate: [
        147,
        {
          distinct_on: [154, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [153, '[avg_release_time_order_by!]'],
          where: [150],
        },
      ],
      avg_release_time_stream: [
        146,
        {
          batch_size: [3, 'Int!'],
          cursor: [158, '[avg_release_time_stream_cursor_input]!'],
          where: [150],
        },
      ],
      combined_avg_times: [
        170,
        {
          distinct_on: [178, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [177, '[combined_avg_times_order_by!]'],
          where: [174],
        },
      ],
      combined_avg_times_aggregate: [
        171,
        {
          distinct_on: [178, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [177, '[combined_avg_times_order_by!]'],
          where: [174],
        },
      ],
      combined_avg_times_stream: [
        170,
        {
          batch_size: [3, 'Int!'],
          cursor: [182, '[combined_avg_times_stream_cursor_input]!'],
          where: [174],
        },
      ],
      devices: [
        194,
        {
          distinct_on: [212, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [210, '[devices_order_by!]'],
          where: [201],
        },
      ],
      devices_aggregate: [
        195,
        {
          distinct_on: [212, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [210, '[devices_order_by!]'],
          where: [201],
        },
      ],
      devices_by_pk: [
        194,
        {
          cred_id: [8, 'String!'],
        },
      ],
      devices_stream: [
        194,
        {
          batch_size: [3, 'Int!'],
          cursor: [214, '[devices_stream_cursor_input]!'],
          where: [201],
        },
      ],
      get_inactive_accounts: [
        436,
        {
          args: [220, 'get_inactive_accounts_args!'],
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      get_inactive_accounts_aggregate: [
        437,
        {
          args: [220, 'get_inactive_accounts_args!'],
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      get_login_counts: [
        254,
        {
          args: [221, 'get_login_counts_args!'],
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      get_login_counts_aggregate: [
        255,
        {
          args: [221, 'get_login_counts_args!'],
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      get_p2p_offers_with_old_or_no_messages: [
        355,
        {
          args: [222, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      get_p2p_offers_with_old_or_no_messages_aggregate: [
        356,
        {
          args: [222, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      login_account: [
        230,
        {
          distinct_on: [248, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [246, '[login_account_order_by!]'],
          where: [237],
        },
      ],
      login_account_aggregate: [
        231,
        {
          distinct_on: [248, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [246, '[login_account_order_by!]'],
          where: [237],
        },
      ],
      login_account_by_pk: [
        230,
        {
          id: [804, 'uuid!'],
        },
      ],
      login_account_stream: [
        230,
        {
          batch_size: [3, 'Int!'],
          cursor: [250, '[login_account_stream_cursor_input]!'],
          where: [237],
        },
      ],
      login_counts: [
        254,
        {
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      login_counts_aggregate: [
        255,
        {
          distinct_on: [265, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [264, '[login_counts_order_by!]'],
          where: [258],
        },
      ],
      login_counts_stream: [
        254,
        {
          batch_size: [3, 'Int!'],
          cursor: [270, '[login_counts_stream_cursor_input]!'],
          where: [258],
        },
      ],
      messages: [
        281,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messages_aggregate: [
        282,
        {
          distinct_on: [299, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [297, '[messages_order_by!]'],
          where: [288],
        },
      ],
      messages_by_pk: [
        281,
        {
          id: [804, 'uuid!'],
        },
      ],
      messages_stream: [
        281,
        {
          batch_size: [3, 'Int!'],
          cursor: [301, '[messages_stream_cursor_input]!'],
          where: [288],
        },
      ],
      migrate_device: [
        305,
        {
          distinct_on: [317, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [315, '[migrate_device_order_by!]'],
          where: [308],
        },
      ],
      migrate_device_aggregate: [
        306,
        {
          distinct_on: [317, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [315, '[migrate_device_order_by!]'],
          where: [308],
        },
      ],
      migrate_device_by_pk: [
        305,
        {
          cred_id: [8, 'String!'],
        },
      ],
      migrate_device_stream: [
        305,
        {
          batch_size: [3, 'Int!'],
          cursor: [319, '[migrate_device_stream_cursor_input]!'],
          where: [308],
        },
      ],
      notifications: [
        323,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      notifications_aggregate: [
        324,
        {
          distinct_on: [343, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [341, '[notifications_order_by!]'],
          where: [332],
        },
      ],
      notifications_by_pk: [
        323,
        {
          id: [804, 'uuid!'],
        },
      ],
      notifications_stream: [
        323,
        {
          batch_size: [3, 'Int!'],
          cursor: [347, '[notifications_stream_cursor_input]!'],
          where: [332],
        },
      ],
      p2p_offers: [
        355,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_aggregate: [
        356,
        {
          distinct_on: [376, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [374, '[p2p_offers_order_by!]'],
          where: [364],
        },
      ],
      p2p_offers_by_pk: [
        355,
        {
          id: [804, 'uuid!'],
        },
      ],
      p2p_offers_stream: [
        355,
        {
          batch_size: [3, 'Int!'],
          cursor: [380, '[p2p_offers_stream_cursor_input]!'],
          where: [364],
        },
      ],
      p2p_pre_sell_offers: [
        384,
        {
          distinct_on: [404, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [402, '[p2p_pre_sell_offers_order_by!]'],
          where: [393],
        },
      ],
      p2p_pre_sell_offers_aggregate: [
        385,
        {
          distinct_on: [404, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [402, '[p2p_pre_sell_offers_order_by!]'],
          where: [393],
        },
      ],
      p2p_pre_sell_offers_by_pk: [
        384,
        {
          id: [804, 'uuid!'],
        },
      ],
      p2p_pre_sell_offers_stream: [
        384,
        {
          batch_size: [3, 'Int!'],
          cursor: [408, '[p2p_pre_sell_offers_stream_cursor_input]!'],
          where: [393],
        },
      ],
      preferences: [
        413,
        {
          distinct_on: [430, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [427, '[preferences_order_by!]'],
          where: [417],
        },
      ],
      preferences_aggregate: [
        414,
        {
          distinct_on: [430, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [427, '[preferences_order_by!]'],
          where: [417],
        },
      ],
      preferences_by_pk: [
        413,
        {
          account: [8, 'String!'],
        },
      ],
      preferences_stream: [
        413,
        {
          batch_size: [3, 'Int!'],
          cursor: [432, '[preferences_stream_cursor_input]!'],
          where: [417],
        },
      ],
      reg_accounts: [
        436,
        {
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      reg_accounts_aggregate: [
        437,
        {
          distinct_on: [449, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [447, '[reg_accounts_order_by!]'],
          where: [439],
        },
      ],
      reg_accounts_by_pk: [
        436,
        {
          id: [804, 'uuid!'],
        },
      ],
      reg_accounts_stream: [
        436,
        {
          batch_size: [3, 'Int!'],
          cursor: [451, '[reg_accounts_stream_cursor_input]!'],
          where: [439],
        },
      ],
      reputation_score: [
        455,
        {
          distinct_on: [470, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [468, '[reputation_score_order_by!]'],
          where: [459],
        },
      ],
      reputation_score_aggregate: [
        456,
        {
          distinct_on: [470, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [468, '[reputation_score_order_by!]'],
          where: [459],
        },
      ],
      reputation_score_by_pk: [
        455,
        {
          account: [8, 'String!'],
        },
      ],
      reputation_score_stream: [
        455,
        {
          batch_size: [3, 'Int!'],
          cursor: [475, '[reputation_score_stream_cursor_input]!'],
          where: [459],
        },
      ],
      signing_requests: [
        489,
        {
          distinct_on: [501, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [499, '[signing_requests_order_by!]'],
          where: [492],
        },
      ],
      signing_requests_aggregate: [
        490,
        {
          distinct_on: [501, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [499, '[signing_requests_order_by!]'],
          where: [492],
        },
      ],
      signing_requests_by_pk: [
        489,
        {
          id: [804, 'uuid!'],
        },
      ],
      signing_requests_stream: [
        489,
        {
          batch_size: [3, 'Int!'],
          cursor: [503, '[signing_requests_stream_cursor_input]!'],
          where: [492],
        },
      ],
      support_sessions: [
        508,
        {
          distinct_on: [526, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [524, '[support_sessions_order_by!]'],
          where: [515],
        },
      ],
      support_sessions_aggregate: [
        509,
        {
          distinct_on: [526, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [524, '[support_sessions_order_by!]'],
          where: [515],
        },
      ],
      support_sessions_by_pk: [
        508,
        {
          id: [804, 'uuid!'],
        },
      ],
      support_sessions_stream: [
        508,
        {
          batch_size: [3, 'Int!'],
          cursor: [528, '[support_sessions_stream_cursor_input]!'],
          where: [515],
        },
      ],
      swap_assets: [
        532,
        {
          distinct_on: [544, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [542, '[swap_assets_order_by!]'],
          where: [535],
        },
      ],
      swap_assets_aggregate: [
        533,
        {
          distinct_on: [544, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [542, '[swap_assets_order_by!]'],
          where: [535],
        },
      ],
      swap_assets_by_pk: [
        532,
        {
          asset: [8, 'String!'],
          chain: [8, 'String!'],
        },
      ],
      swap_assets_stream: [
        532,
        {
          batch_size: [3, 'Int!'],
          cursor: [546, '[swap_assets_stream_cursor_input]!'],
          where: [535],
        },
      ],
      swap_orders: [
        550,
        {
          distinct_on: [582, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [580, '[swap_orders_order_by!]'],
          where: [569],
        },
      ],
      swap_orders_aggregate: [
        551,
        {
          distinct_on: [582, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [580, '[swap_orders_order_by!]'],
          where: [569],
        },
      ],
      swap_orders_by_pk: [
        550,
        {
          id: [804, 'uuid!'],
        },
      ],
      swap_orders_stream: [
        550,
        {
          batch_size: [3, 'Int!'],
          cursor: [598, '[swap_orders_stream_cursor_input]!'],
          where: [569],
        },
      ],
      system_notification_read_status: [
        610,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      system_notification_read_status_aggregate: [
        611,
        {
          distinct_on: [628, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [626, '[system_notification_read_status_order_by!]'],
          where: [617],
        },
      ],
      system_notification_read_status_by_pk: [
        610,
        {
          notification_id: [804, 'uuid!'],
          reg_account: [804, 'uuid!'],
        },
      ],
      system_notification_read_status_stream: [
        610,
        {
          batch_size: [3, 'Int!'],
          cursor: [630, '[system_notification_read_status_stream_cursor_input]!'],
          where: [617],
        },
      ],
      system_notifications: [
        634,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      system_notifications_aggregate: [
        635,
        {
          distinct_on: [653, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [651, '[system_notifications_order_by!]'],
          where: [641],
        },
      ],
      system_notifications_by_pk: [
        634,
        {
          id: [804, 'uuid!'],
        },
      ],
      system_notifications_stream: [
        634,
        {
          batch_size: [3, 'Int!'],
          cursor: [655, '[system_notifications_stream_cursor_input]!'],
          where: [641],
        },
      ],
      system_type_notification: [
        659,
        {
          distinct_on: [674, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [672, '[system_type_notification_order_by!]'],
          where: [662],
        },
      ],
      system_type_notification_aggregate: [
        660,
        {
          distinct_on: [674, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [672, '[system_type_notification_order_by!]'],
          where: [662],
        },
      ],
      system_type_notification_by_pk: [
        659,
        {
          type: [8, 'String!'],
        },
      ],
      system_type_notification_stream: [
        659,
        {
          batch_size: [3, 'Int!'],
          cursor: [676, '[system_type_notification_stream_cursor_input]!'],
          where: [662],
        },
      ],
      table_rows: [
        680,
        {
          distinct_on: [697, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [694, '[table_rows_order_by!]'],
          where: [684],
        },
      ],
      table_rows_aggregate: [
        681,
        {
          distinct_on: [697, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [694, '[table_rows_order_by!]'],
          where: [684],
        },
      ],
      table_rows_by_pk: [
        680,
        {
          contract: [8, 'String!'],
          primary_key: [8, 'String!'],
          scope: [8, 'String!'],
          table: [8, 'String!'],
        },
      ],
      table_rows_stream: [
        680,
        {
          batch_size: [3, 'Int!'],
          cursor: [699, '[table_rows_stream_cursor_input]!'],
          where: [684],
        },
      ],
      trust_network: [
        709,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      trust_network_aggregate: [
        710,
        {
          distinct_on: [754, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [751, '[trust_network_order_by!]'],
          where: [718],
        },
      ],
      trust_network_by_pk: [
        709,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_notification: [
        726,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trust_network_notification_aggregate: [
        727,
        {
          distinct_on: [744, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [742, '[trust_network_notification_order_by!]'],
          where: [733],
        },
      ],
      trust_network_notification_by_pk: [
        726,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_notification_stream: [
        726,
        {
          batch_size: [3, 'Int!'],
          cursor: [746, '[trust_network_notification_stream_cursor_input]!'],
          where: [733],
        },
      ],
      trust_network_status: [
        758,
        {
          distinct_on: [770, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [768, '[trust_network_status_order_by!]'],
          where: [761],
        },
      ],
      trust_network_status_aggregate: [
        759,
        {
          distinct_on: [770, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [768, '[trust_network_status_order_by!]'],
          where: [761],
        },
      ],
      trust_network_status_by_pk: [
        758,
        {
          status: [8, 'String!'],
        },
      ],
      trust_network_status_stream: [
        758,
        {
          batch_size: [3, 'Int!'],
          cursor: [772, '[trust_network_status_stream_cursor_input]!'],
          where: [761],
        },
      ],
      trust_network_stream: [
        709,
        {
          batch_size: [3, 'Int!'],
          cursor: [776, '[trust_network_stream_cursor_input]!'],
          where: [718],
        },
      ],
      tx_statistics: [
        780,
        {
          distinct_on: [791, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [790, '[tx_statistics_order_by!]'],
          where: [784],
        },
      ],
      tx_statistics_aggregate: [
        781,
        {
          distinct_on: [791, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [790, '[tx_statistics_order_by!]'],
          where: [784],
        },
      ],
      tx_statistics_stream: [
        780,
        {
          batch_size: [3, 'Int!'],
          cursor: [796, '[tx_statistics_stream_cursor_input]!'],
          where: [784],
        },
      ],
      volume_statistics: [
        808,
        {
          distinct_on: [819, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [818, '[volume_statistics_order_by!]'],
          where: [812],
        },
      ],
      volume_statistics_aggregate: [
        809,
        {
          distinct_on: [819, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [818, '[volume_statistics_order_by!]'],
          where: [812],
        },
      ],
      volume_statistics_stream: [
        808,
        {
          batch_size: [3, 'Int!'],
          cursor: [824, '[volume_statistics_stream_cursor_input]!'],
          where: [812],
        },
      ],
      web_push: [
        832,
        {
          distinct_on: [844, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [842, '[web_push_order_by!]'],
          where: [835],
        },
      ],
      web_push_aggregate: [
        833,
        {
          distinct_on: [844, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [842, '[web_push_order_by!]'],
          where: [835],
        },
      ],
      web_push_by_pk: [
        832,
        {
          auth: [8, 'String!'],
          p256dh: [8, 'String!'],
        },
      ],
      web_push_stream: [
        832,
        {
          batch_size: [3, 'Int!'],
          cursor: [846, '[web_push_stream_cursor_input]!'],
          where: [835],
        },
      ],
      __typename: [8],
    },
  },
}
