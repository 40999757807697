import { config } from 'app-config'
import { FlexBox } from 'app-view/components/Styled'
import { BitcashLoginLogoIcon, ChromeIcon, OperaIcon, SafariIcon } from 'app-view/components/icons'
import BitcashLogoBackgroundIcon from 'app-view/components/icons/stableicons/BitcashLogoBackgroundIcon'
import { bitcashBgLogoAnimationProps } from 'pages/MaintenanceView'
import {
  BackgroundContainer,
  Branding,
  MaintenanceContainer,
  LogoContainer,
  Slogan,
} from 'pages/MaintenanceView/components/style'
import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

export const DesktopAccessView = () => {
  const { t } = useTranslation(['desktop', 'common'])
  useEffect(() => {
    if (window.location.pathname === '/') return
    window.location.href = '/'
  }, [])
  return (
    <>
      <MaintenanceContainer>
        <div>
          <Branding>
            <BackgroundContainer {...bitcashBgLogoAnimationProps}>
              <BitcashLogoBackgroundIcon />
            </BackgroundContainer>
            <LogoContainer>
              <BitcashLoginLogoIcon width={290} />
            </LogoContainer>
          </Branding>
          <Slogan
            dangerouslySetInnerHTML={{
              __html: `${t('desktop_access')}:`,
            }}
            style={{ margin: '0 5%', whiteSpace: 'pre-line' }}
          />
          <FlexBox
            justifyContent="center"
            style={{ gap: '32px', marginTop: '32px', marginBottom: '32px' }}
          >
            <ChromeIcon />
            <SafariIcon />
            <OperaIcon />
          </FlexBox>
          <Slogan style={{ margin: '0 5%', whiteSpace: 'pre-line' }}>
            {t('desktop_access_2')}
          </Slogan>
        </div>
      </MaintenanceContainer>
    </>
  )
}
