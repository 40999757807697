import theme from 'app-config/theme'
import { TimesIcon } from 'app-view/components/icons'
import { H3 } from 'pages/AccountView/components/TextElements'
import React from 'react'
import { ModalTypeIcon } from '.'
import { FlexBox } from '../Styled'
import { ButtonClose, ModalHeaderContainer } from './Styled'
import { ModalHeaderProperties } from './types'

export type { ModalHeaderProperties } from './types'

export const ModalHeader: React.FC<ModalHeaderProperties> = ({
  loader,
  iconType,
  title,
  showClose,
  onClose,
}) => {
  const showHeaderBg = Boolean(
    iconType === 'EXCHANGE' ||
      iconType === 'LANGUAGE' ||
      iconType === 'CURRENCY' ||
      iconType === 'NOTIFICATION' ||
      iconType === 'SUCCESS' ||
      iconType === 'FILTER' ||
      iconType === 'RECOVERY' ||
      iconType === 'NOICON-BGGREEN',
  )
  const color = showHeaderBg ? 'white' : ''
  let bgColor = ''
  if (iconType === 'RECOVERY') bgColor = theme.colors.lightBlack
  else if (iconType === 'FILTER' || iconType === 'NOICON-BGGREEN') bgColor = theme.colors.green
  return (
    <ModalHeaderContainer
      loader={Boolean(loader)}
      showHeaderBg={showHeaderBg}
      style={{ backgroundColor: bgColor }}
    >
      <FlexBox style={{ color }}>
        {iconType && iconType.indexOf('NOICON') === -1 && <ModalTypeIcon type={iconType} />}
        {title && <H3 style={{ color }}>{title}</H3>}
      </FlexBox>

      {showClose && (
        <ButtonClose type="button" onClick={onClose} aria-label="Close Modal">
          <TimesIcon color={color} />
        </ButtonClose>
      )}
    </ModalHeaderContainer>
  )
}
