import { bitcashClient, mutation_root, mutation_rootGenqlSelection } from 'graphql/bitcash'

async function approveAccountRequest(__args: {
  is_verified?: boolean
  request_id?: string
}): Promise<mutation_root['approve_new_account']> {
  const { approve_new_account } = await bitcashClient.mutation({
    approve_new_account: {
      __args,
    },
  })

  return approve_new_account
}

async function retryAccountKYCRequest(
  __args: mutation_rootGenqlSelection['retry_account_kyc']['__args'],
): Promise<mutation_root['retry_account_kyc']> {
  const { retry_account_kyc } = await bitcashClient.mutation({
    retry_account_kyc: {
      __args,
    },
  })

  return retry_account_kyc
}

async function rejectAccountRequest(
  __args: mutation_rootGenqlSelection['reject_new_account']['__args'],
): Promise<mutation_root['reject_new_account']> {
  const { reject_new_account } = await bitcashClient.mutation({
    reject_new_account: {
      __args,
    },
  })

  return reject_new_account
}

async function updateApprovedAccountRequest({ account }: { account: string }) {
  const { update_reg_accounts } = await bitcashClient.mutation({
    update_reg_accounts: {
      __args: {
        _set: {
          is_verified: true,
        },
        where: {
          account: {
            _eq: account,
          },
        },
      },
      returning: {
        is_verified: true,
      },
    },
  })

  return update_reg_accounts
}

export const adminAccountService = {
  approveAccountRequest,
  rejectAccountRequest,
  retryAccountKYCRequest,
  updateApprovedAccountRequest,
}
