import { ErrorFallback } from 'app-view/components/ErrorFallback'
import { Modal, ModalError, WizardContentContainer } from 'app-view/components/Modal'
import { motion, MotionProps } from 'framer-motion/dist/framer-motion'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useWizard } from './hooks/useWizard'
import { WizardProps } from './types'

//  export this from index for simpler import { useWizard, WizardProvider } from 'components/Wizard'
export * from './hooks/useWizard'
export * from './types'

const animationProps: MotionProps = {
  initial: { opacity: 0, x: 300 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -300, position: 'absolute' },
  transition: { duration: 0.42 },
}

const logError = (error: Error, info: { componentStack: string }) => {
  console.error(error, info)
}

export const Wizard: React.FC<WizardProps> = ({
  error,
  onCloseCallback,
  resetError,
  title,
  iconType,
  loading,
}) => {
  const [{ step, steps, open }, { prev, goTo, next, close }] = useWizard()
  const [showSteps, setShowSteps] = useState(true)

  // we use ErrorBoundary to catch unexpected errors
  // to close the error you must a resetError callback
  return (
    <Modal
      isOpen={open}
      onClose={() => {
        // * side-effect to close the modal
        if (onCloseCallback) {
          onCloseCallback()
        }

        close()
        resetError()
      }}
      showClose={!loading}
      loader={loading}
      title={error ? 'Error' : title || ''}
      iconType={error ? 'ERROR' : iconType || ''}
    >
      <ErrorBoundary
        onError={logError}
        onReset={() => {
          resetError()
          setShowSteps(true)
        }}
        resetKeys={[showSteps]}
        FallbackComponent={ErrorFallback}
      >
        <WizardContentContainer>
          {error && (
            <ModalError error={error} resetError={resetError} animationProps={animationProps} />
          )}
          {showSteps &&
            !error &&
            steps?.map((s) =>
              step === s.name ? (
                <motion.div key={s.name} {...animationProps}>
                  <s.component key={s.name} prev={prev} next={next} goTo={goTo} close={close} />
                </motion.div>
              ) : null,
            )}
        </WizardContentContainer>
      </ErrorBoundary>
    </Modal>
  )
}
