import { scrollbar_css_access } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'
import { motion } from 'framer-motion/dist/framer-motion'

export const ChooseWalletContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 4vh;
  justify-content: start;
  flex-direction: column;
  background: linear-gradient(180deg, #161a33 0%, #0f111e 100%);
  position: relative;
  overflow-y: auto;
  gap: 42px;

  ${scrollbar_css_access}

  @media screen and (min-width: 500px) {
    justify-content: center;
  }
`

export const BackgroundContainer = styled(motion.div)`
  position: absolute;
  opacity: 0.05;
`

export const LogoContainer = styled(motion.div)`
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Branding = styled.div`
  transition: all 0.7s ease-in;
  position: relative;
  height: 172px;
`
export const AccessViewList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 270px;
  margin-inline: auto;
  gap: 16px;
  z-index: 33;
  padding: 0;
`
export const AccessViewListItem = styled(motion.li)`
  width: 100%;
  list-style: none;
`
export const Slogan = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.162672px;
  position: relative;
  color: #f2f2f2;
`

export const SecondaryOptionsWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 132px;
  max-height: 132px;
  overflow-y: auto;

  ${scrollbar_css_access}

  @media screen and (min-width: 600px) {
    height: auto;
  }
`
